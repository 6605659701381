import { Box, Flex, Input, Text, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { Controller } from 'react-hook-form';

import { isMobile, isTablet } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
import { useBettingScreen } from '../../hooks/useStore';
import ButtonComp from '../../utils/ButtonComp';
import { useGlobalToast } from '../../utils/ShowToast';
import { currencyFormat } from '../../utils/numberFormat';
import ModalPasteBet from './ModalPasteBet';
import NumericKeyboard from './NumericKeyboard';
import { PasteBetError3D } from '../../utils/PasteBetError3D';

const Rg7Keyboard = ({
	Rg7Form,
	Rg7Value,
	type,
	time,
	gameId,
	minBetAmount,
	handleSubmit,
	list,
	totalAmount,
}) => {
	const globalShowToast = useGlobalToast();
	const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');
	// ref
	const input0Ref = useRef();
	const input1Ref = useRef();
	const input2Ref = useRef();
	const inputRamble = useRef();
	const inputStraight = useRef();
	const { isOpen, onOpen, onClose } = useDisclosure(); // textarea modal

	// zustand
	const setRg7 = useBettingScreen((state) => state.setRg7);

	// state
	const [numberArray] = Rg7Value;
	const [activeIndex, setActiveIndex] = useState(0);
	const [textAreaError, setTextAreaError] = useState(false); // textarea error
	const [textAreaValue, setTextAreaValue] = useState(''); //textarea value
	const [errorMessage, setErrorMessage] = useState([]); // handle textarea error messages
	const textAreaValueSplit = textAreaValue.split('\n'); //split textarea value
	const randomnumber0 = Math.floor(Math.random() * 10);
	const randomnumber1 = Math.floor(Math.random() * 10);
	const randomnumber2 = Math.floor(Math.random() * 10);
	const sameDigitChecking =
		numberArray[0]?.digit !== '' &&
		numberArray[1]?.digit !== '' &&
		numberArray[2]?.digit !== '' &&
		numberArray[0]?.digit === numberArray[1]?.digit &&
		numberArray[1]?.digit === numberArray[2]?.digit;

	// function
	// keyboard 0-9 button
	const handleKeyPress = (value) => {
		if (time !== '') {
			let newInputs = [...numberArray];
			if (activeIndex < 3 && value < 10) {
				newInputs[activeIndex].digit = value;
			} else {
				newInputs[activeIndex].digit = (numberArray[activeIndex].digit + value).slice(0, 5); // Limit input to five characters
			}

			Rg7Form.setValue(`numberArray[${activeIndex}].digit`, `${newInputs[activeIndex].digit}`);

			if (activeIndex < 2) {
				setActiveIndex((activeIndex) => activeIndex + 1);
			} else if (activeIndex === 2 && numberArray[3].digit === '') {
				setActiveIndex((activeIndex) => activeIndex + 1);
			} else if (activeIndex === 3 && numberArray[3]?.digit?.length > 4) {
				setActiveIndex(4);
			}
		}
	};
	// keyboard backspace button
	const handleBackspace = () => {
		if (time !== '') {
			let newInputs = [...numberArray];
			if (activeIndex === 4) {
				newInputs[4].digit = newInputs[4].digit.slice(0, -1);
				if (numberArray[activeIndex].digit.length === 0) {
					setActiveIndex(3);
				}
			} else if (activeIndex === 3) {
				newInputs[3].digit = newInputs[3].digit.slice(0, -1);
				if (numberArray[activeIndex].digit.length === 0) {
					setActiveIndex(2);
				}
			} else if (activeIndex > 0 && activeIndex < 3 && numberArray[activeIndex].digit.length >= 0) {
				newInputs[activeIndex].digit = newInputs[activeIndex].digit.slice(0, -1);
				setActiveIndex(activeIndex - 1);
				Rg7Form.setValue(`numberArray[${activeIndex}].lp`, false);
			} else if (activeIndex === 0 && numberArray[activeIndex].digit.length > 0) {
				newInputs[0].digit = newInputs[0].digit.slice(0, -1);
				setActiveIndex(0);
				Rg7Form.setValue(`numberArray[${activeIndex}].lp`, false);
			}
			Rg7Form.setValue(`numberArray[${activeIndex}].digit`, `${newInputs[activeIndex].digit}`);
		}
	};
	// 3D luckypick button function
	const handleLuckypick3D = () => {
		if (time !== '') {
			Rg7Form.setValue(`numberArray[0].digit`, `${randomnumber0}`);
			Rg7Form.setValue(`numberArray[0].lp`, true);

			Rg7Form.setValue(`numberArray[1].digit`, `${randomnumber1}`);
			Rg7Form.setValue(`numberArray[1].lp`, true);

			Rg7Form.setValue(`numberArray[2].digit`, `${randomnumber2}`);
			Rg7Form.setValue(`numberArray[2].lp`, true);

			if (numberArray[3].digit === '') {
				setActiveIndex(3);
				inputStraight.current.focus();
			}
		}
	};
	// keyboard left-arrow button
	const handleMoveCursorLeft = () => {
		if (time !== '') {
			const newactiveIndex = activeIndex === 0 ? 0 : activeIndex - 1;
			setActiveIndex(newactiveIndex);
		}
	};
	// keyboard right-arrow button
	const handleMoveCursorRight = () => {
		if (time !== '') {
			if (activeIndex >= 0 && activeIndex < 3) {
				setActiveIndex(activeIndex + 1);
			} else if (activeIndex === 3) {
				setActiveIndex(4);
			}
		}
	};
	// Paste add function
	const handlePasteBet = () => {
		if (textAreaValueSplit && time !== '') {
			let newValue = '';
			textAreaValueSplit.forEach((items) => {
				const splitItems = items.trim().split(' ');
				let priceStraght = Number(splitItems[1]);
				let priceRamble = Number(splitItems[2]);
				let betNumber = splitItems?.[0];
				let digit3D = betNumber?.split('').map(Number);
				let sameNumber = ['000', '111', '222', '333', '444', '555', '666', '777', '888', '999'];

				if (
					splitItems.length === 3 &&
					(splitItems?.[0].length === 3 || splitItems?.[0] === 'L' || splitItems?.[0] === 'l') &&
					priceStraght < 100000 &&
					priceStraght >= 0 &&
					priceRamble < 100000 &&
					priceRamble >= 0
				) {
					// Lucky pick ----------------------------------------------------------------
					if (betNumber === 'L' || betNumber === 'l') {
						let luckypickNumber1 = Math.floor(Math.random() * 10);
						let luckypickNumber2 = Math.floor(Math.random() * 10);
						let luckypickNumber3 = Math.floor(Math.random() * 10);
						if (luckypickNumber1 === luckypickNumber2 && luckypickNumber1 === luckypickNumber3) {
							if (priceStraght >= minBetAmount) {
								setRg7([
									{
										id: uuidv4(),
										gameId: gameId,
										gameType: type,
										betPrice: priceStraght,
										ramble: false,
										lp: true,
										number: [
											{ digit: luckypickNumber1, lp: true, name: 'number3DNum1', position: 1 },
											{ digit: luckypickNumber2, lp: true, name: 'number3DNum2', position: 2 },
											{ digit: luckypickNumber3, lp: true, name: 'number3DNum3', position: 3 },
										],
									},
								]);
							} else {
								newValue += items.toString().trim() + '\n';
							}
						} else {
							if (priceStraght >= minBetAmount && priceRamble >= minBetAmount) {
								setRg7([
									{
										id: uuidv4(),
										gameId: gameId,
										gameType: type,
										betPrice: priceStraght,
										ramble: false,
										lp: true,
										number: [
											{ digit: luckypickNumber1, lp: true, name: 'number3DNum1', position: 1 },
											{ digit: luckypickNumber2, lp: true, name: 'number3DNum2', position: 2 },
											{ digit: luckypickNumber3, lp: true, name: 'number3DNum3', position: 3 },
										],
									},
									{
										id: uuidv4(),
										gameId: gameId,
										gameType: type,
										betPrice: priceRamble,
										ramble: true,
										lp: true,
										number: [
											{ digit: luckypickNumber1, lp: true, name: 'number3DNum1', position: 1 },
											{ digit: luckypickNumber2, lp: true, name: 'number3DNum2', position: 2 },
											{ digit: luckypickNumber3, lp: true, name: 'number3DNum3', position: 3 },
										],
									},
								]);
							} else if (priceStraght >= minBetAmount && priceRamble === 0) {
								setRg7([
									{
										id: uuidv4(),
										gameId: gameId,
										gameType: type,
										betPrice: priceStraght,
										ramble: false,
										lp: true,
										number: [
											{ digit: luckypickNumber1, lp: true, name: 'number3DNum1', position: 1 },
											{ digit: luckypickNumber2, lp: true, name: 'number3DNum2', position: 2 },
											{ digit: luckypickNumber3, lp: true, name: 'number3DNum3', position: 3 },
										],
									},
								]);
							} else if (priceStraght === 0 && priceRamble >= minBetAmount) {
								setRg7([
									{
										id: uuidv4(),
										gameId: gameId,
										gameType: type,
										betPrice: priceRamble,
										ramble: true,
										lp: true,
										number: [
											{ digit: luckypickNumber1, lp: true, name: 'number3DNum1', position: 1 },
											{ digit: luckypickNumber2, lp: true, name: 'number3DNum2', position: 2 },
											{ digit: luckypickNumber3, lp: true, name: 'number3DNum3', position: 3 },
										],
									},
								]);
							} else {
								newValue += items.toString().trim() + '\n';
							}
						}
					}

					// self insert
					else if (sameNumber.includes(betNumber)) {
						if (priceStraght >= minBetAmount) {
							setRg7([
								{
									id: uuidv4(),
									gameId: gameId,
									gameType: type,
									betPrice: priceStraght,
									ramble: false,
									lp: false,
									number: [
										{ digit: digit3D?.[0], lp: false, name: 'number3DNum1', position: 1 },
										{ digit: digit3D?.[1], lp: false, name: 'number3DNum2', position: 2 },
										{ digit: digit3D?.[2], lp: false, name: 'number3DNum3', position: 3 },
									],
								},
							]);
						} else {
							newValue += items.toString().trim() + '\n';
						}
					} else {
						if (priceStraght >= minBetAmount && priceRamble >= minBetAmount) {
							setRg7([
								{
									id: uuidv4(),
									gameId: gameId,
									gameType: type,
									betPrice: priceStraght,
									ramble: false,
									lp: false,
									number: [
										{ digit: digit3D?.[0], lp: false, name: 'number3DNum1', position: 1 },
										{ digit: digit3D?.[1], lp: false, name: 'number3DNum2', position: 2 },
										{ digit: digit3D?.[2], lp: false, name: 'number3DNum3', position: 3 },
									],
								},
								{
									id: uuidv4(),
									gameId: gameId,
									gameType: type,
									betPrice: priceRamble,
									ramble: true,
									lp: false,
									number: [
										{ digit: digit3D?.[0], lp: false, name: 'number3DNum1', position: 1 },
										{ digit: digit3D?.[1], lp: false, name: 'number3DNum2', position: 2 },
										{ digit: digit3D?.[2], lp: false, name: 'number3DNum3', position: 3 },
									],
								},
							]);
						} else if (priceStraght >= minBetAmount && priceRamble === 0) {
							setRg7([
								{
									id: uuidv4(),
									gameId: gameId,
									gameType: type,
									betPrice: priceStraght,
									ramble: false,
									lp: false,
									number: [
										{ digit: digit3D?.[0], lp: false, name: 'number3DNum1', position: 1 },
										{ digit: digit3D?.[1], lp: false, name: 'number3DNum2', position: 2 },
										{ digit: digit3D?.[2], lp: false, name: 'number3DNum3', position: 3 },
									],
								},
							]);
						} else if (priceStraght === 0 && priceRamble >= minBetAmount) {
							setRg7([
								{
									id: uuidv4(),
									gameId: gameId,
									gameType: type,
									betPrice: priceRamble,
									ramble: true,
									lp: false,
									number: [
										{ digit: digit3D?.[0], lp: false, name: 'number3DNum1', position: 1 },
										{ digit: digit3D?.[1], lp: false, name: 'number3DNum2', position: 2 },
										{ digit: digit3D?.[2], lp: false, name: 'number3DNum3', position: 3 },
									],
								},
							]);
						} else {
							newValue += items.toString().trim() + '\n';
						}
					}
				} else {
					// invalid format setback to textarea
					if (items.toString() !== '\n' && items.toString().length > 0) {
						newValue += items.toString().trim() + '\n';
					}
				}
			});

			// if all success closed the modal
			if (newValue === '') {
				onClose();
				setTextAreaError(false);
			} else {
				setTextAreaError(true);
				setErrorMessage([]);
				PasteBetError3D({ newValue, minBetAmount, setErrorMessage });
			}

			setTextAreaValue(newValue);
		}
	};
	//+ Add Bet button function
	const onSubmit = () => {
		if (time !== '') {
			if (sameDigitChecking && numberArray[3].digit >= minBetAmount) {
				setRg7([
					{
						id: uuidv4(),
						gameId: gameId,
						gameType: type,
						betPrice: Number(numberArray[3].digit),
						ramble: false,
						lp:
							numberArray[0].lp === true || numberArray[1].lp === true || numberArray[2].lp === true
								? true
								: false,
						number: [numberArray[0], numberArray[1], numberArray[2]],
					},
				]);
				Rg7Form.setValue('numberArray[0].digit', '');
				Rg7Form.setValue('numberArray[1].digit', '');
				Rg7Form.setValue('numberArray[2].digit', '');
				Rg7Form.setValue('numberArray[0].lp', false);
				Rg7Form.setValue('numberArray[1].lp', false);
				Rg7Form.setValue('numberArray[2].lp', false);
				setActiveIndex(0);
				input0Ref.current.focus();
			} else if (sameDigitChecking && numberArray[4].digit) {
			} else {
				if (
					numberArray[0].digit &&
					numberArray[1].digit &&
					numberArray[2].digit &&
					numberArray[3].digit >= minBetAmount &&
					numberArray[4].digit >= minBetAmount
				) {
					setRg7([
						{
							id: uuidv4(),
							gameId: gameId,
							gameType: type,
							betPrice: Number(numberArray[3].digit),
							ramble: false,
							lp:
								numberArray[0].lp === true ||
								numberArray[1].lp === true ||
								numberArray[2].lp === true
									? true
									: false,
							number: [numberArray[0], numberArray[1], numberArray[2]],
						},
						{
							id: uuidv4(),
							gameId: gameId,
							gameType: type,
							betPrice: Number(numberArray[4].digit),
							ramble: true,
							lp:
								numberArray[0].lp === true ||
								numberArray[1].lp === true ||
								numberArray[2].lp === true
									? true
									: false,
							number: [numberArray[0], numberArray[1], numberArray[2]],
						},
					]);

					Rg7Form.setValue('numberArray[0].digit', '');
					Rg7Form.setValue('numberArray[1].digit', '');
					Rg7Form.setValue('numberArray[2].digit', '');
					Rg7Form.setValue('numberArray[0].lp', false);
					Rg7Form.setValue('numberArray[1].lp', false);
					Rg7Form.setValue('numberArray[2].lp', false);
					setActiveIndex(0);
					input0Ref.current.focus();
				} else if (
					numberArray[0].digit &&
					numberArray[1].digit &&
					numberArray[2].digit &&
					numberArray[3].digit >= minBetAmount &&
					numberArray[4].digit === ''
				) {
					setRg7([
						{
							id: uuidv4(),
							gameId: gameId,
							gameType: type,
							betPrice: Number(numberArray[3].digit),
							ramble: false,
							lp:
								numberArray[0].lp === true ||
								numberArray[1].lp === true ||
								numberArray[2].lp === true
									? true
									: false,
							number: [numberArray[0], numberArray[1], numberArray[2]],
						},
					]);

					Rg7Form.setValue('numberArray[0].digit', '');
					Rg7Form.setValue('numberArray[1].digit', '');
					Rg7Form.setValue('numberArray[2].digit', '');
					Rg7Form.setValue('numberArray[0].lp', false);
					Rg7Form.setValue('numberArray[1].lp', false);
					Rg7Form.setValue('numberArray[2].lp', false);
					setActiveIndex(0);
					input0Ref.current.focus();
				} else if (
					numberArray[0].digit &&
					numberArray[1].digit &&
					numberArray[2].digit &&
					numberArray[4].digit >= minBetAmount &&
					numberArray[3].digit === ''
				) {
					setRg7([
						{
							id: uuidv4(),
							gameId: gameId,
							gameType: type,
							betPrice: Number(numberArray[4].digit),
							ramble: true,
							lp:
								numberArray[0].lp === true ||
								numberArray[1].lp === true ||
								numberArray[2].lp === true
									? true
									: false,
							number: [numberArray[0], numberArray[1], numberArray[2]],
						},
					]);

					Rg7Form.setValue('numberArray[0].digit', '');
					Rg7Form.setValue('numberArray[1].digit', '');
					Rg7Form.setValue('numberArray[2].digit', '');
					Rg7Form.setValue('numberArray[0].lp', false);
					Rg7Form.setValue('numberArray[1].lp', false);
					Rg7Form.setValue('numberArray[2].lp', false);
					setActiveIndex(0);
					input0Ref.current.focus();
				}
			}
			if (sameDigitChecking) {
				if (numberArray[3].digit < minBetAmount) {
					globalShowToast({
						title: `Straight Min bet is ${minBetAmount} PHP`,
						status: 'error',
					});
				}
			} else if (numberArray[0].digit && numberArray[1].digit && numberArray[2].digit) {
				if (
					(numberArray[3].digit && numberArray[3].digit < minBetAmount) ||
					(numberArray[4].digit && numberArray[4].digit < minBetAmount)
				) {
					globalShowToast({
						title: `Minimum bet amount is ${minBetAmount} PHP`,
						status: 'error',
					});
				} else if (numberArray[3].digit === '' && numberArray[4].digit === '') {
					globalShowToast({
						title: `Price input is empty.`,
						status: 'error',
					});
				}
			} else {
				globalShowToast({
					title: `Number input is empty.`,
					status: 'error',
				});
			}
		}
	};

	const styles = {
		width: '13%',
		borderWidth: 1,
		borderRadius: '8px',
		borderColor: time === '' ? '#000000' : '#003198',
		height: isLargerThan1024 ? '56px' : '42px',
		textAlign: 'center',
		fontSize: isLargerThan1024 ? '36px' : '20px',
		padding: '7px',
		fontWeight: '600',
	};

	const stylesPrice = {
		width: '30%',
		borderWidth: '1px',
		borderRadius: '8px',
		borderColor: time === '' ? '#000000' : '#003198',
		height: isLargerThan1024 ? '56px' : '42px',
		fontSize: isLargerThan1024 ? '36px' : '16px',
		padding: '7px',
		fontWeight: '600',
		color: '#000000',
		textAlign: 'center',
	};

	return (
		<Flex flexDirection='column'>
			<Flex paddingX={2}>
				<Controller
					name='numberArray[0].digit'
					control={Rg7Form.control}
					rules={{ lazy: true, required: true }}
					render={({ field: { onBlur, value } }) => (
						<Input
							tabIndex={1}
							ref={input0Ref}
							inputMode={isTablet === false && isMobile === true ? 'none' : 'numeric'}
							marginRight={['1vw']}
							style={{
								...styles,
								borderColor: activeIndex === 0 && time !== '' ? '#184BD5' : '#003198',
								borderWidth: activeIndex === 0 && time !== '' ? '2px' : '1px',
							}}
							value={value}
							onBlur={onBlur}
							maxLength={1}
							color='black'
							onChange={(e) => {
								e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 1);
								Rg7Form.setValue('numberArray[0].digit', e.target.value);
							}}
							onFocus={() => {
								setActiveIndex(0);
								Rg7Form.setValue('numberArray[0].digit', '');
								Rg7Form.setValue('numberArray[0].lp', false);
							}}
							onContextMenu={(event) => event.preventDefault()}
							onKeyDown={(event) => {
								if (event.nativeEvent.key === 'Backspace') {
									if (numberArray[0].digit === '') {
										Rg7Form.setValue('numberArray[0].lp', false);
										setActiveIndex(0);
										input0Ref.current.focus();
									}
								} else if (Number(event.nativeEvent.key) < 10 && numberArray[1].digit === '') {
									setTimeout(() => {
										setActiveIndex(1);
										input1Ref.current.focus();
									}, 50);
								}
								if (event.nativeEvent.key === 'Enter') {
									onSubmit();
								}
							}}
							disabled={time === '' ? true : false}
						/>
					)}
				/>
				<Controller
					name='numberArray[1].digit'
					control={Rg7Form.control}
					rules={{ lazy: true, required: true }}
					render={({ field: { onBlur, value } }) => (
						<Input
							tabIndex={2}
							ref={input1Ref}
							marginRight={['1vw']}
							inputMode={isTablet === false && isMobile === true ? 'none' : 'numeric'}
							style={{
								...styles,
								borderColor: activeIndex === 1 && time !== '' ? '#184BD5' : '#003198',
								borderWidth: activeIndex === 1 && time !== '' ? '2px' : '1px',
							}}
							value={value}
							onBlur={onBlur}
							maxLength={1}
							color='black'
							onChange={(e) => {
								e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 1);

								Rg7Form.setValue('numberArray[1].digit', e.target.value);
							}}
							onFocus={() => {
								setActiveIndex(1);
								Rg7Form.setValue('numberArray[1].digit', '');
								Rg7Form.setValue('numberArray[1].lp', false);
							}}
							onContextMenu={(event) => event.preventDefault()}
							onKeyDown={(event) => {
								if (event.nativeEvent.key === 'Backspace') {
									if (numberArray[1].digit === '') {
										Rg7Form.setValue('numberArray[1].lp', false);
										setActiveIndex(0);
										input0Ref.current.focus();
									}
								} else if (Number(event.nativeEvent.key) < 10 && numberArray[2].digit === '') {
									setTimeout(() => {
										setActiveIndex(2);
										input2Ref.current.focus();
									}, 50);
								}
								if (event.nativeEvent.key === 'Enter') {
									onSubmit();
								}
							}}
							disabled={time === '' ? true : false}
						/>
					)}
				/>

				<Controller
					name='numberArray[2].digit'
					control={Rg7Form.control}
					rules={{ lazy: true, required: true }}
					render={({ field: { onBlur, value } }) => (
						<Input
							tabIndex={3}
							ref={input2Ref}
							inputMode={isTablet === false && isMobile === true ? 'none' : 'numeric'}
							marginRight={['1vw']}
							style={{
								...styles,
								borderColor: activeIndex === 2 && time !== '' ? '#184BD5' : '#003198',
								borderWidth: activeIndex === 2 && time !== '' ? '2px' : '1px',
							}}
							value={value}
							onBlur={onBlur}
							maxLength={1}
							color='black'
							onChange={(e) => {
								e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 1);
								Rg7Form.setValue('numberArray[2].digit', e.target.value);
							}}
							onFocus={() => {
								setActiveIndex(2);
								Rg7Form.setValue('numberArray[2].digit', '');
								Rg7Form.setValue('numberArray[2].lp', false);
							}}
							onContextMenu={(event) => event.preventDefault()}
							onKeyDown={(event) => {
								if (event.nativeEvent.key === 'Backspace') {
									if (numberArray[2].digit === '') {
										Rg7Form.setValue('numberArray[2].lp', false);
										setActiveIndex(1);
										input1Ref.current.focus();
									}
								} else if (Number(event.nativeEvent.key) < 10 && numberArray[3].digit === '') {
									setTimeout(() => {
										inputStraight.current.focus();
									}, 50);
								}
								if (event.nativeEvent.key === 'Enter') {
									onSubmit();
								}
							}}
							disabled={time === '' ? true : false}
						/>
					)}
				/>

				<Controller
					name='numberArray[3].digit'
					control={Rg7Form.control}
					rules={{ lazy: true, required: true }}
					render={({ field: { onBlur, value } }) => (
						<Input
							placeholder={activeIndex === 3 ? '' : 'Straight'}
							tabIndex={3}
							ref={inputStraight}
							inputMode={isTablet === false && isMobile === true ? 'none' : 'numeric'}
							marginRight={['1vw']}
							style={{
								...stylesPrice,
								borderColor: activeIndex === 3 && time !== '' ? '#184BD5' : '#003198',
								borderWidth: activeIndex === 3 && time !== '' ? '2px' : '1px',
							}}
							value={value}
							onBlur={onBlur}
							maxLength={5}
							color='black'
							onChange={(e) => {
								e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 5);
								Rg7Form.setValue('numberArray[3].digit', e.target.value);
							}}
							onFocus={() => {
								setActiveIndex(3);
								Rg7Form.setValue('numberArray[3].digit', '');
							}}
							onContextMenu={(event) => event.preventDefault()}
							onKeyDown={(event) => {
								if (numberArray[3].digit.length === 0 && event.nativeEvent.key === 'Backspace') {
									setActiveIndex(2);
									input2Ref.current.focus();
								} else if (numberArray[3].digit.length > 3) {
									if (
										numberArray[0].digit === numberArray[1].digit &&
										numberArray[1].digit === numberArray[2].digit
									) {
									} else {
										if (event.nativeEvent.key === 'Backspace') {
										} else {
											setActiveIndex(4);
											setTimeout(() => {
												inputRamble.current.focus();
											}, 50);
										}
									}
								}

								if (event.nativeEvent.key === 'Enter') {
									onSubmit();
								}
							}}
							disabled={time === '' ? true : false}
						/>
					)}
				/>
				<Controller
					name='numberArray[4].digit'
					control={Rg7Form.control}
					rules={{ lazy: true, required: true }}
					render={({ field: { onBlur, value } }) => (
						<Input
							placeholder={activeIndex === 4 ? '' : 'Ramble'}
							tabIndex={3}
							ref={inputRamble}
							inputMode={isTablet === false && isMobile === true ? 'none' : 'numeric'}
							style={{
								...stylesPrice,
								borderColor: activeIndex === 4 && time !== '' ? '#184BD5' : '#003198',
								borderWidth: activeIndex === 4 && time !== '' ? '2px' : '1px',
							}}
							value={value}
							onBlur={onBlur}
							maxLength={5}
							color='black'
							onChange={(e) => {
								e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 5);
								Rg7Form.setValue('numberArray[4].digit', e.target.value);
							}}
							onFocus={() => {
								setActiveIndex(4);
								Rg7Form.setValue('numberArray[4].digit', '');
							}}
							onContextMenu={(event) => event.preventDefault()}
							onKeyDown={(event) => {
								if (numberArray[4].digit.length === 0 && event.nativeEvent.key === 'Backspace') {
									setActiveIndex(3);
									inputStraight.current.focus();
								}
								if (event.nativeEvent.key === 'Enter') {
									onSubmit();
								}
							}}
							disabled={time === '' ? true : false}
						/>
					)}
				/>
			</Flex>
			{isTablet === false && isMobile === true ? (
				<>
					<Flex
						width='100%'
						bgColor='white'
						position='fixed'
						bottom={0}
						flexDirection='column'
						alignItems='center'
						borderColor='#959595'
						borderTopWidth='1px'
						borderTopRadius='5px'
						zIndex={999}
					>
						<Flex justifyContent='space-between' width='96vw' fontSize='16px' fontWeight='500'>
							<Text>Total ({list})</Text>
							<Text color='green.500' fontWeight='700'>
								{currencyFormat(totalAmount)} PHP
							</Text>
						</Flex>
						{!isOpen && (
							<NumericKeyboard
								onKeyPress={handleKeyPress}
								onBackspace={handleBackspace}
								onLuckyPick={handleLuckypick3D}
								onLeftClick={handleMoveCursorLeft}
								onRightClick={handleMoveCursorRight}
								onAddToBet={Rg7Form.handleSubmit(onSubmit)}
								onBetSubmit={handleSubmit}
								length={list}
								time={time}
							/>
						)}
					</Flex>
				</>
			) : (
				<Flex width='100%'>
					<ButtonComp
						value='Paste Bet'
						color='white'
						width={['33%']}
						height='48px'
						fontSize={['14px', '14px', '14px', '14px', '26px']}
						borderColor='blue.800'
						backgroundColor='blue.800'
						onClick={onOpen}
						disabled={time === '' ? true : false}
						activeColor='blue.500'
						hoverColor='blue.500'
					/>
					<ButtonComp
						value='Lucky Pick'
						color='white'
						width={['33%']}
						height='48px'
						fontSize={['14px', '14px', '14px', '14px', '26px']}
						borderColor='red.500'
						backgroundColor='red.500'
						onClick={handleLuckypick3D}
						disabled={time === '' ? true : false}
						activeColor='red.400'
						hoverColor='red.400'
					/>
					<ButtonComp
						value='Add Bet'
						color='white'
						width={['33%']}
						height='48px'
						fontSize={['14px', '14px', '14px', '14px', '26px']}
						borderColor='green.500'
						backgroundColor='green.500'
						onClick={onSubmit}
						disabled={time === '' ? true : false}
						activeColor='green.450'
						hoverColor='green.450'
						hasImg={true}
						svgName='whiteAdd'
					/>
				</Flex>
			)}

			{isOpen && (
				<ModalPasteBet
					isOpen={isOpen}
					onClose={onClose}
					placeholder='Example : 123 10 10 / L 10 10'
					type={type}
					reg={/[^0-9lL\s]/g}
					error={textAreaError}
					errorMessage={errorMessage}
					minBetAmount={minBetAmount}
					textAreaValue={textAreaValue}
					setTextAreaValue={setTextAreaValue}
					onChangeTextArea={(e) => {
						e.target.value = e.target.value.replace(/[^0-9lL\s]/g, '');
						setTextAreaValue(e.target.value);
						if (textAreaError) {
							setTextAreaError(false);
						}
					}}
					onBlurTextArea={() => {
						setTextAreaValue((pre) => pre.trim());
					}}
					onClickCancel={() => {
						setTextAreaError(false);
						setTextAreaValue('');
					}}
					onClickAdd={() => {
						handlePasteBet();
					}}
				>
					<Text fontWeight={900} mt='-2'>
						How To Play
					</Text>
					<Text fontWeight={500} fontSize='16px' mt={2}>
						Betting format :
					</Text>
					<Text fontSize='16px' mt={2}>
						Insert{' '}
						<Box as='span' fontWeight={900}>
							three number
						</Box>{' '}
						from{' '}
						<Box as='span' fontWeight={900}>
							0 - 9
						</Box>
						, then betting price between{' '}
						<Box as='span' fontWeight={900}>
							{minBetAmount} - 99999 PHP
						</Box>
					</Text>
				</ModalPasteBet>
			)}
		</Flex>
	);
};

export default Rg7Keyboard;
