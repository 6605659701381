import { Box, Flex, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import { DesktopTitleHeader, MobileTitleHeader2 } from '../components/titleHeader/TitleHeader';
import Prize from '../components/prize/Prize';

const PrizeScreen = () => {
	const [isSmallerThan1024] = useMediaQuery('(max-width: 1023px)');
	return (
		<Box
			paddingX={['3', '3', '5', '5', '10']}
			marginBottom={['100px', '100px', '100px', '30px', '30px', '30px', '30px']}
			paddingTop={['80px', '80px', '80px', '80px', '30px', '30px', '30px']}
			width='100%'
			minHeight={['100vh', '100vh', '100vh', '100vh', 'calc(100vh - 150px)']}
		>
			<Box width='100%'>
				<Flex flexDirection='column'>
					{isSmallerThan1024 ? (
						<MobileTitleHeader2 title='Prize' />
					) : (
						<DesktopTitleHeader title='Prize' />
					)}
					<Prize />
				</Flex>
			</Box>
		</Box>
	);
};

export default PrizeScreen;
