import { Flex, Text, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import ImgComp from '../../utils/ImgComp';
import { currencyFormat } from '../../utils/numberFormat';

const SummaryRow = ({ ramble, onClickDel, number, amount, games, lp }) => {
	const [isLargerThan1200] = useMediaQuery('(min-width: 1200px)');
	return (
		<>
			<Flex
				height={['32px', '32px', '42px']}
				flexDirection='row'
				padding={1}
				alignItems='center'
				fontSize={['15px', '15px', '15px', '15px', '15px', '20px']}
			>
				<Text width={['20%']} color='#000000'>
					{games}
				</Text>
				<Flex width={['35%']} textAlign='center' color='#000000' justifyContent='space-between'>
					<Flex flexDirection='row' marginRight={[1, 2, 3]}>
						{number.map((num) => {
							return (
								<Flex key={num.position}>
									<Text marginRight={2}>{num.digit}</Text>
								</Flex>
							);
						})}
					</Flex>
					{lp && <Text paddingRight={3}>(LP)</Text>}
					{isLargerThan1200 ? (
						<>{ramble ? <Text>Ramble</Text> : <Text>Straight</Text>}</>
					) : (
						<>{ramble ? <Text>R</Text> : <Text>S</Text>}</>
					)}
				</Flex>
				<Text width={['30%']} textAlign='right' color='#000000'>
					{currencyFormat(Number(amount))}
				</Text>
				<Flex
					justifyContent='center'
					alignItems='center'
					height='100%'
					onClick={onClickDel}
					width={['15%']}
					cursor='pointer'
					userSelect='none'
				>
					<ImgComp svgName='delete' height={6} width={6} />
				</Flex>
			</Flex>
		</>
	);
};

export default SummaryRow;
