import {
	CircularProgress,
	Flex,
	Input,
	Modal,
	ModalBody,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';

import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { postCreditWithdrawalRequestApi } from '../../api/query/betApi';
import { getCreditRemainApi, getUserProfileApi } from '../../api/query/profileApi';
import ImgComp from '../../utils/ImgComp';
import { useGlobalToast } from '../../utils/ShowToast';

const WithdrawalModal = ({ isOpen, onClose, getCreditWithdrawalList }) => {
	const globalShowToast = useGlobalToast();

	// state
	const [step, setStep] = useState(1);
	const [creditLimit, setCreditLimit] = useState('');
	const getCreditRemain = useQuery(['getCreditRemainApi'], getCreditRemainApi);
	const userId = sessionStorage.getItem('userId');

	//* api
	const getUserProfile = useQuery([`getUserProfileApi`, userId], () =>
		getUserProfileApi({ userId: userId }),
	);
	const postCreditWithdrawalRequest = useMutation({
		mutationFn: (body) => postCreditWithdrawalRequestApi(body),
		onSuccess: (succ) => {
			setStep(1);
			getCreditRemain.refetch();
			getCreditWithdrawalList.refetch();
			onClose();
			globalShowToast({
				title: `${succ.message}`,
				position: 'top-right',
				status: 'success',
				duration: 10000,
			});
		},
		onError: (err) => {
			setStep(1);
			globalShowToast({
				title: `${err.message}`,
				position: 'top-right',
				status: 'error',
				duration: 10000,
			});
		},
	});

	const limitCredit = getUserProfile?.data?.remainCredit;

	// function
	const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, '');
	const handleChangeCreditLimit = (event) => {
		const value = event.target.value;
		const newValue = addCommas(removeNonNumeric(value));

		// Remove commas and convert newValue to a number
		const numericValue = parseInt(removeNonNumeric(newValue), 10);

		// Check if the numericValue exceeds the limitCredit
		if (numericValue > limitCredit) {
			setCreditLimit(addCommas(limitCredit)); // Set the input value to the formatted limitCredit
		} else if (numericValue === 0) {
			setCreditLimit(''); // If numericValue is zero, set the input value to an empty string
		} else if (newValue.length > 1 && newValue.charAt(0) === '0') {
			setCreditLimit(addCommas(removeNonNumeric(newValue))); // Set the input value to the formatted newValue without leading zeros
		} else {
			setCreditLimit(newValue); // Set the input value to the formatted newValue
		}
	};

	return (
		<>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				isCentered
				closeOnOverlayClick={false}
				size={['sm', 'sm', 'md', 'lg', 'xl']}
			>
				<ModalOverlay />
				<ModalContent marginX={3}>
					<ModalHeader borderBottomWidth={step === 1 ? '0px' : '1px'}>
						<Flex justifyContent='space-between' alignItems='center' paddingY={2}>
							<Text>Credit Withdrawal Request</Text>
							{step === 1 || step === 2 ? (
								<Flex cursor='pointer' paddingY={1} paddingLeft={2} onClick={() => onClose()}>
									<ImgComp svgName='close-icon' />
								</Flex>
							) : null}
						</Flex>
					</ModalHeader>
					<ModalBody>
						{step === 1 && (
							<Flex flexDirection='column'>
								<Flex>
									<Text fontSize={['14px', '14px', '16px']} width='60%'>
										Requested By
									</Text>
									<Text
										fontSize={['14px', '14px', '16px']}
										maxW='45vw'
										warp='warp'
										textAlign='right'
									>
										{getUserProfile?.data?.firstName}
									</Text>
								</Flex>
								<Flex flexDirection={['column', 'row']} alignItems='center'>
									<Text fontSize={['14px', '14px', '16px']} width={['100%', '60%']}>
										Request Credit Amount (PHP)
									</Text>
									<Input
										value={creditLimit}
										inputMode='numeric'
										onChange={handleChangeCreditLimit}
										height='30px'
										width={['100%', '40%']}
									/>
								</Flex>
							</Flex>
						)}
						{step === 2 && (
							<Flex flexDirection='column' alignItems='center'>
								<Text marginRight={1}>Are you confirm to withdraw </Text>
								<Flex flexDirection='row'>
									<Text
										fontWeight='700'
										marginRight={1}
										fontSize={['14px', '16px', '16px', '18px']}
										color='green.500'
									>
										{creditLimit} PHP &nbsp;
									</Text>
									<Text>?</Text>
								</Flex>
							</Flex>
						)}

						{step === 1 && (
							<Flex width='100%' justifyContent='flex-end' paddingTop={5}>
								<Flex
									bgColor={'green.500'}
									userSelect='none'
									cursor='pointer'
									onClick={() => {
										if (
											Number(creditLimit.replace(/[^0-9]/g, '')) > 0 &&
											Number(creditLimit.replace(/[^0-9]/g, '')) <= limitCredit
										) {
											setStep(2);
										}
										if (Number(creditLimit.replace(/[^0-9]/g, '')) > limitCredit) {
											globalShowToast({
												title: `Credit Remain insufficient`,
												position: 'top-right',
											});
										}
										if (Number(creditLimit.replace(/[^0-9]/g, '')) <= 0) {
											globalShowToast({
												title: `Please enter amount`,
												position: 'top-right',
												status: 'error',
											});
										}
									}}
									height='40px'
									width='104px'
									justifyContent='center'
									alignItems='center'
									borderRadius={8}
								>
									<Text color='white' textAlign='center' fontSize='16px' fontWeight={600}>
										Next
									</Text>
								</Flex>
							</Flex>
						)}
						{step === 2 && (
							<Flex justifyContent='center' width='100%' alignItems='center' marginY='20px'>
								<Flex
									backgroundColor={postCreditWithdrawalRequest.isLoading ? 'gray.100' : 'red.400'}
									onClick={
										postCreditWithdrawalRequest.isLoading
											? () => {}
											: () => {
													setStep(1);
											  }
									}
									height='40px'
									width='104px'
									justifyContent='center'
									alignItems='center'
									borderRadius={8}
									marginRight={2}
									fontWeight={600}
									userSelect='none'
									cursor='pointer'
								>
									<Text color='white' textAlign='center' fontSize='16px' fontWeight={600}>
										Back
									</Text>
								</Flex>
								<Flex
									backgroundColor={postCreditWithdrawalRequest.isLoading ? 'gray.100' : 'green.500'}
									onClick={
										postCreditWithdrawalRequest.isLoading
											? () => {}
											: () => {
													setStep(3);
													postCreditWithdrawalRequest.mutate({
														amount: creditLimit.replaceAll(',', ''),
													});
											  }
									}
									height='40px'
									width='104px'
									justifyContent='center'
									alignItems='center'
									borderRadius={8}
									userSelect='none'
									cursor='pointer'
								>
									<Text color='white' textAlign='center' fontSize='16px' fontWeight={600}>
										Confirm
									</Text>
								</Flex>
							</Flex>
						)}

						{step === 3 && (
							<Flex justifyContent='center' alignItems='center' height='150px'>
								<CircularProgress size='50px' isIndeterminate color='blue.600' />
							</Flex>
						)}
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default WithdrawalModal;
