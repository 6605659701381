import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { severAxiosInstance } from '../api/query/api';
import { getValidateTokenLogin } from '../api/query/authApi';
import { findParam } from '../utils/FindParam';

const ThirdPartyLoginScreen = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const URLToken = findParam({ locationSearch: location.search, key: 'token' });
	const URLRedirect = findParam({ locationSearch: location.search, key: 'redirect' });

	//# api
	const ValidateTokenLogin = useMutation({
		mutationFn: (body) => getValidateTokenLogin(body),
		onSuccess: (succ) => {
			severAxiosInstance.defaults.headers.common.Authorization = 'Bearer ' + succ.data.token;
			navigate('/authenticate');
			sessionStorage.setItem('accessToken', succ.data.token);
			sessionStorage.setItem('userId', succ.data.id);
			sessionStorage.setItem('firstName', succ.data.firstName);
			sessionStorage.setItem('isThirdPartyLogin', succ.data.isThirdParty);
			sessionStorage.setItem('returnUrl', succ.data.returnUrl);
			sessionStorage.setItem('isSeamless', succ.data.isSeamless);
			sessionStorage.setItem('balanceSyncInterval', succ.data.balanceSyncInterval);

			if (window.ReactNativeWebView) {
				localStorage.setItem('isThirdPartyLogin', succ.data.isThirdParty);
				localStorage.setItem('accessToken', succ.data.token);
				localStorage.setItem('userId', succ.data.id);
				localStorage.setItem('firstName', succ.data.firstName);
				localStorage.setItem('returnUrl', succ.data.returnUrl);
				localStorage.setItem('isSeamless', succ.data.isSeamless);
				localStorage.setItem('balanceSyncInterval', succ.data.balanceSyncInterval);
			}
		},
		onError: (err) => {
			window.location.href = URLRedirect;
		},
	});

	useEffect(() => {
		sessionStorage.removeItem('accessToken');
		sessionStorage.removeItem('userId');
		sessionStorage.removeItem('firstName');
		sessionStorage.removeItem('isThirdPartyLogin');
		sessionStorage.removeItem('returnUrl');

		if (URLToken) {
			ValidateTokenLogin.mutate({
				token: URLToken,
			});
		} else {
			if (location.search) {
				window.location.href = URLRedirect;
			}
		}
	}, [location]);

	//live chat
	useEffect(() => {
		if (window.smartsupp) {
			window.smartsupp(function () {
				document.getElementById('smartsupp-widget-container').style.display = 'none';
			});
		} else {
			setTimeout(() => {
				window.smartsupp(function () {
					document.getElementById('smartsupp-widget-container').style.display = 'none';
				});
			}, 3000);
		}
	}, []);

	return null;
};

export default ThirdPartyLoginScreen;
