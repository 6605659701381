import { serverApi } from './api';

const API = '/api/auth';

export const postUserLogin = (body) =>
	serverApi.post(`${API}/login`, body).then((res) => res.data.data);

export const postAuthRegisterApi = (body) =>
	serverApi.post(`${API}/webregister`, body).then((res) => res.data.data);

export const getSrvTimeApi = () =>
	serverApi.get(`${API}/getServerTimeNoToken`).then((res) => res.data.data);

export const getReferralCodeApi = ({ referralCode }) =>
	serverApi.get(`${API}/validateqr/${referralCode}`).then((res) => res.data.data);

export const getVersionApi = () => serverApi.get(`${API}/version`).then((res) => res.data);
export const getSuppKeyApi = () => serverApi.get(`${API}/getSmartsuppKey`).then((res) => res.data);

// third party api
export const getValidateTokenLogin = ({ token, config }) =>
	serverApi.get(`${API}/validateTokenLogin?token=${token}`, config).then((res) => res.data);
