import {
	Box,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';

import React from 'react';
import QRCode from 'react-qr-code';

const ShowQRModal = ({ isOpen, onClose, qrString, requestId }) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent marginX={3}>
				<ModalCloseButton />
				<ModalBody>
					<Box align='center' padding={6}>
						<QRCode
							size={60}
							style={{ height: 'auto', maxWidth: '60%', width: '60%' }}
							value={qrString ?? '0'}
						/>
						<Text paddingTop={4}>Request ID : {requestId}</Text>
					</Box>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default ShowQRModal;
