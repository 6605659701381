import { serverApi } from './api';

const API = '/api/bet';

export const getSiteApi = () => serverApi.get(`${API}/site`).then((res) => res.data.data);
export const getGameApi = (siteId) =>
	serverApi.get(`${API}/game?siteId=${siteId}`).then((res) => res.data.data);

export const getDrawApi = (gameId) =>
	serverApi.get(`${API}/draw?gameId=${gameId}`).then((res) => res.data.data);

export const getDrawByDateApi = ({ date, gameId }) =>
	serverApi.get(`${API}/drawByDate?date=${date}&gameId=${gameId}`).then((res) => res.data.data);

export const postPostApi = (body, config) =>
	serverApi.post(`${API}/post`, body, config).then((res) => res.data.data);

export const getTicketlistApi = ({ drawDate, pageParam, pageSize }) =>
	serverApi
		.get(`${API}/ticketlist?drawDate=${drawDate}&pageNum=${pageParam}&pageSize=${pageSize}`)
		.then((res) => res.data.data);

export const getCutOffTimeApi = () =>
	serverApi.get(`${API}/drawCutoffTime`).then((res) => res.data.data);

export const getTicketlistWithFilterApi = ({
	drawDate,
	ticketNumber,
	siteName,
	ticketStatus,
	pageParam,
	pageSize,
}) =>
	serverApi
		.get(
			`${API}/ticketlist?drawDate=${drawDate}&ticketNumber=${ticketNumber}&siteName=${siteName}&ticketStatus=${ticketStatus}&pageNum=${pageParam}&pageSize=${pageSize}`,
		)
		.then((res) => res.data.data);

export const getTicketIdApi = (ticketId) =>
	serverApi.get(`${API}/ticket/${ticketId}`).then((res) => res.data.data);

export const deleteTicketApi = (ticketId, config) =>
	serverApi.delete(`${API}/ticketvoid/${ticketId}`, config).then((res) => res.data.data);

export const getClaimedTicketApi = ({ date, pageParam, pageSize, ticketNumber }) => {
	return serverApi
		.get(
			`${API}/claims?claimDate=${date}&ticketNumber=${ticketNumber}&page=${pageParam}&size=${pageSize}`,
		)
		.then((res) => res.data.data);
};

export const putBetClaim = (body) =>
	serverApi.put(`${API}/claim`, body).then((res) => res.data.data);

export const getCheckStrikeQueryApi = (ticketNum, config) =>
	serverApi.get(`${API}/checkstrike/${ticketNum}`, config).then((res) => res.data.data);

export const getQRStringDataQueryApi = (qrString, config) =>
	serverApi.get(`${API}/claims/qr?qrString=${qrString}`, config).then((res) => res.data.data);

export const postRebetApi = ({ ticketId, config }) =>
	serverApi.post(`${API}/ticket/${ticketId}/reBet`, config).then((res) => res.data.data);

export const getCreditWithdrawalListApi = ({
	date,
	requestId,
	status,
	isAgent,
	pageSize,
	pageParam,
}) =>
	serverApi
		.get(
			`${API}/creditWithdrawalList?date=${date}&requestId=${requestId}&status=${status}&isAgent=${isAgent}&pageNum=${pageParam}&pageSize=${pageSize}`,
		)
		.then((res) => res.data.data);

export const getCreditWithdrawalDetailsApi = (requestId) =>
	serverApi
		.get(`${API}/creditWithdrawalDetail?requestId=${requestId}`)
		.then((res) => res.data.data);

export const postCreditWithdrawalRequestApi = (body) =>
	serverApi.post(`${API}/creditWithdrawal/request`, body).then((res) => res.data.data);

export const putCreditWithdrawalUpdateApi = (body) =>
	serverApi.put(`${API}/creditWithdrawal/update`, body).then((res) => res.data.data);

export const putTicketPrintApi = (ticketId, config) =>
	serverApi.put(`${API}/ticketprinted?ticketId=${ticketId}`, config).then((res) => res.data.data);
