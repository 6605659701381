import {
	Box,
	Button,
	Card,
	CardBody,
	Flex,
	Heading,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { putProfileChangeDownlinePassApi } from '../api/query/profileApi';
import { CustomInput } from '../components/loginRegister/CustomInput';
import DownlineProfileCard from '../components/profile/DownlineProfileCard';
import { ErrorText } from '../utils/CustomText';
import ImgComp from '../utils/ImgComp';
import { useGlobalToast } from '../utils/ShowToast';
import { useNavigate } from 'react-router-dom';

const DownlineProfileScreen = () => {
	const navigate = useNavigate();
	const globalShowToast = useGlobalToast();

	// modal
	const { isOpen, onOpen, onClose } = useDisclosure();

	//a useForm
	//@ change password
	const schema = yup
		.object({
			newPassword: yup
				.string()
				.required('Please enter the password.')
				.trim('Password cannot include leading and trailing spaces.')
				.strict(true)
				.min(8, 'Password is too short - should be 8 Characters Long minimum.')
				.max(16, 'Password is too long - should be 16 Characters Long maximum.')
				.matches(/[a-z]+/, 'Password must have at least one Lowercase Character.')
				.matches(/[A-Z]+/, 'Password must have at least one Uppercase Character.')
				.matches(/\d+/, 'Password must contain at least one Digit.'),
			confirmPassword: yup
				.string()
				.required('Please enter confirm password.')
				.oneOf([yup.ref('newPassword'), null], 'Passwords not match.'),
		})
		.required();

	const {
		control,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			newPassword: '',
			confirmPassword: '',
		},
	});
	const formValues = useWatch({
		control,
		name: ['newPassword', 'confirmPassword'],
	});
	const [newPassword] = formValues;

	//* api
	const putProfileChangeDownlinePass = useMutation({
		mutationFn: (update) => putProfileChangeDownlinePassApi(update),
		onSuccess: (succ) => {
			reset({
				newPassword: '',
				confirmPassword: '',
			});
			globalShowToast({
				title: `${succ.message}`,
				status: 'success',
			});

			onClose();
		},
		onError: (err) => {
			globalShowToast({
				title: `${err.response.data.data.message}`,
				status: 'error',
			});
			onClose();
		},
	});
	const onSubmit = () => {
		putProfileChangeDownlinePass.mutate({
			userId: sessionStorage.getItem('downlineId'),
			newPassword: newPassword,
		});
	};
	useEffect(() => {
		if (sessionStorage.getItem('isThirdPartyLogin') === 'true') {
			navigate('/pcso');
		}
	}, []);

	return (
		<>
			<Box
				paddingX={['3', '3', '5', '5', '10']}
				paddingTop={['80px', '80px', '80px', '80px', '30px', '30px', '30px']}
				width='100%'
				minHeight={['100vh', '100vh', '100vh', '100vh', 'calc(100vh - 150px)']}
			>
				<Heading
					fontSize={['20px', '20px', '20px', '20px', '20px', '32px']}
					alignSelf='center'
					marginTop='48px'
					marginBottom='20px'
				>
					Downline Profile Details
				</Heading>
				<Card>
					<CardBody paddingBottom='4'>
						<DownlineProfileCard />
					</CardBody>
				</Card>
				<Flex
					onClick={onOpen}
					justifyContent='space-between'
					alignItems='center'
					borderWidth='1px'
					borderRadius='8px'
					backgroundColor='white'
					padding='3px 10px'
					marginY={5}
					cursor='pointer'
					userSelect='none'
				>
					<Text fontSize={['14px', '', '', '18px']} fontWeight={['500', '', '', '600']}>
						Change Password
					</Text>
					<ImgComp svgName='right-arrow' />
				</Flex>
			</Box>
			{isOpen && (
				<Modal
					isOpen={isOpen}
					onClose={() => {
						onClose();
						reset();
					}}
					isCentered
					closeOnOverlayClick={false}
				>
					<ModalOverlay />
					<ModalContent marginX={3}>
						<ModalHeader></ModalHeader>
						<ModalCloseButton />
						<ModalBody marginTop={4}>
							<Flex flexDirection='column'>
								<Controller
									name='newPassword'
									control={control}
									rules={{ required: true }}
									render={({ field: { value } }) => (
										<CustomInput
											isPasswordInput
											title={'New Password'}
											maxLegth={16}
											onChange={(e) => {
												e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
												setValue('newPassword', `${e.target.value}`);
											}}
											value={value}
											placeholder='Abc12345'
											errorMessage={
												errors.newPassword ? (
													<ErrorText
														color='red'
														fontSize='12px'
														text={errors.newPassword?.message}
													/>
												) : (
													''
												)
											}
										/>
									)}
								/>
								<Controller
									name='confirmPassword'
									control={control}
									rules={{ required: true }}
									render={({ field: { value } }) => (
										<CustomInput
											isPasswordInput
											title={'Confirm Password'}
											maxLegth={16}
											onChange={(e) => {
												e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
												setValue('confirmPassword', `${e.target.value}`);
											}}
											value={value}
											placeholder='Abc12345'
											errorMessage={
												errors.confirmPassword ? (
													<ErrorText
														color='red'
														fontSize='12px'
														text={errors.confirmPassword?.message}
													/>
												) : (
													''
												)
											}
										/>
									)}
								/>
							</Flex>
							<Flex>
								<Flex justifyContent='space-between' width='50%'></Flex>
								<Flex justifyContent='space-evenly' width='50%' align='flex-end' marginY='20px'>
									<Button bgColor='#22943B' onClick={handleSubmit(onSubmit)} marginRight={2}>
										<Text color='white'>Save</Text>
									</Button>
									<Button
										onClick={() => {
											onClose();
											reset();
										}}
									>
										<Text>Cancel</Text>
									</Button>
								</Flex>
							</Flex>
						</ModalBody>
					</ModalContent>
				</Modal>
			)}
		</>
	);
};

export default DownlineProfileScreen;
