import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	CircularProgress,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Spacer,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { animated, useSpring } from '@react-spring/web';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { severAxiosInstance } from '../../api/query/api';
import { getSrvTimeApi, getSuppKeyApi, getVersionApi } from '../../api/query/authApi';
import { getCreditRemainApi } from '../../api/query/profileApi';
import { useBettingScreen, useGameStore } from '../../hooks/useStore';
import ImgComp from '../../utils/ImgComp';
import { ConvertedServerTime } from '../../utils/Time';
import UiBlock from '../../utils/UiBlock';
import { currencyFormat } from '../../utils/numberFormat';
import { DrawerButton, DrawerNavLink } from '../drawer/Drawer';
import { ResultModal } from '../iframe/IframeModal';
import './drawer.css';

const MobileToggleHeader = ({ isAgent = false, setDate }) => {
	const navigate = useNavigate();
	const serverTime = ConvertedServerTime();

	// zustand
	const removeAll = useBettingScreen((state) => state.removeAll);
	const removeAllStl = useBettingScreen((state) => state.removeAllStl);

	// useState
	const [isOpen, setIsOpen] = useState(false);
	const [isLogout, setIsLogout] = useState(false);
	const [isPopover, setIsPopover] = useState(false);
	const [jsParseLocal, setJsParseLocal] = useState();
	const [isLoading, setIsLoading] = useState(false);

	// modal
	const {
		isOpen: isOpenResultPage,
		onOpen: onOpenResultPage,
		onClose: onCloseResultPage,
	} = useDisclosure();

	//* api
	const getCreditRemain = useQuery(['getCreditRemainApi'], getCreditRemainApi, { enabled: false });
	const getSuppKey = useQuery(['getSuppKeyApi'], getSuppKeyApi, { enabled: false });

	// function
	const toggleDrawer = () => {
		setIsOpen(!isOpen);
		if (window.smartsupp) {
			window.smartsupp(function () {
				document.getElementById('smartsupp-widget-container').firstElementChild.style.zIndex =
					'999';
				document.getElementById('widgetButtonFrame').firstElementChild.style.zIndex = '999';
			});
		}
	};

	const handleReloadCredit = () => {
		setIsLoading(true);
		getCreditRemain.refetch();
		setTimeout(() => {
			setIsLoading(false);
		}, 300);
	};

	// useEffect

	useEffect(() => {
		severAxiosInstance.defaults.headers.common.Authorization =
			'Bearer ' + sessionStorage.getItem('accessToken');

		if (
			sessionStorage.getItem('accessToken') !== null &&
			sessionStorage.getItem('accessToken') !== undefined
		) {
			getCreditRemain.refetch();
			getSuppKey.refetch();
		}
	}, [sessionStorage.getItem('accessToken')]);

	useEffect(() => {
		if (getSuppKey.isSuccess && getSuppKey.data) {
			if (localStorage.getItem(`ssupp_${getSuppKey.data?.data?.string}`)) {
				setJsParseLocal(JSON.parse(localStorage.getItem(`ssupp_${getSuppKey.data?.data?.string}`)));
			}
		}
	}, [
		getSuppKey.isSuccess,
		getSuppKey.data,
		localStorage.getItem(`ssupp_${getSuppKey.data?.data?.string}`),
	]);

	return (
		<Flex
			// className='app'
			flexDirection='column'
			position='relative'
			display={['block', 'block', 'block', 'block', 'none', 'none', 'none']}
			zIndex={9999}
			overflowX='none'
		>
			<Box width='100%' height='50px' bgColor='blue.700' position='fixed' zIndex='999'>
				<Flex padding={2} alignContent={'center'} justifyContent='space-between'>
					<Box onClick={toggleDrawer}>
						<ImgComp svgName={'hamburger'} width={8} height={8} />
					</Box>
					<MobileSideBar
						setIsOpen={setIsOpen}
						isOpen={isOpen}
						toggleDrawer={toggleDrawer}
						setDate={setDate}
						isAgent={isAgent}
						onClickLogout={() => {
							setIsOpen(false);
							setIsLogout(true);
							if (window.smartsupp) {
								window.smartsupp(function () {
									document.getElementById(
										'smartsupp-widget-container',
									).firstElementChild.style.zIndex = '999';
									document.getElementById('widgetButtonFrame').firstElementChild.style.zIndex =
										'999';
								});
							}
						}}
						onOpenResultPage={onOpenResultPage}
						isOpenResultPage={isOpenResultPage}
						onCloseResultPage={onCloseResultPage}
					/>

					{isOpen ? <UiBlock /> : null}

					<Flex justifyContent='center'>
						<ImgComp svgName={'logo'} width={8} height={8} />
						<Flex marginLeft={2}>
							<ImgComp svgName={'panalo1'} width={36} height={8} />
						</Flex>
					</Flex>
					{sessionStorage.getItem('isThirdPartyLogin') === 'false' ? (
						<Popover size='sm' marginX={6} isOpen={isPopover} onClose={() => setIsPopover(false)}>
							<PopoverTrigger>
								<Box onClick={() => setIsPopover(!isPopover)}>
									<ImgComp svgName={'user1'} width={8} height={8} />
								</Box>
							</PopoverTrigger>
							<PopoverContent>
								<PopoverArrow />
								<PopoverBody>
									<Flex
										justifyContent='space-between'
										padding={1}
										marginLeft={1}
										onClick={() => {
											setIsLogout(true);
											if (window.smartsupp) {
												window.smartsupp(function () {
													document.getElementById(
														'smartsupp-widget-container',
													).firstElementChild.style.zIndex = '999';
													document.getElementById(
														'widgetButtonFrame',
													).firstElementChild.style.zIndex = '999';
												});
											}
										}}
									>
										<Box>
											<Text
												paddingTop={[1, 1, 1, 1, 1, 0, 0]}
												fontSize={['16px', '16px', '16px', '16px', '16px', '16px', '19.2px']}
												fontWeight='500'
												color={'#000000'}
											>
												Log out
											</Text>
										</Box>

										<Flex marginLeft={3}>
											<ImgComp svgName={'logout'} width={8} height={8} />
										</Flex>
									</Flex>
								</PopoverBody>
							</PopoverContent>
						</Popover>
					) : (
						<ImgComp svgName={'user1'} width={8} height={8} />
					)}
				</Flex>

				{/* Logout confirm modal */}
				{isLogout && (
					<Modal
						isOpen={isLogout}
						onClose={() => {
							setIsLogout(false);
						}}
						isCentered
						size='sm'
					>
						<ModalOverlay />
						<ModalContent marginX={3}>
							<ModalCloseButton />
							<ModalHeader></ModalHeader>
							<ModalBody>
								<Flex>
									<Text fontSize='18px' margin='20px'>
										Sure to logout?
									</Text>
								</Flex>

								<Flex justifyContent='flex-end' width='100%' align='flex-end' marginY='20px'>
									<Button
										marginRight={2}
										bgColor='blue.700'
										onClick={() => {
											setIsLogout(false);
											navigate('/');
											removeAll();
											removeAllStl();
											if (window.ReactNativeWebView) {
												localStorage.clear();
											}
											sessionStorage.clear();
											if (window.smartsupp && jsParseLocal) {
												if (jsParseLocal.opened === 'true') {
													delete jsParseLocal?.opened;
													localStorage.setItem(
														`ssupp_${jsParseLocal.data?.data?.string}`,
														JSON.stringify(jsParseLocal),
													);
												}
											}
										}}
									>
										<Text color='white'>OK</Text>
									</Button>
									<Button
										onClick={() => {
											setIsLogout(false);
										}}
									>
										<Text>Cancel</Text>
									</Button>
								</Flex>
							</ModalBody>
						</ModalContent>
					</Modal>
				)}
			</Box>

			<Box
				width='100%'
				height='55px'
				bgColor='blue.100'
				position='fixed'
				zIndex='998'
				marginTop='40px'
			>
				<Flex
					justifyContent='start'
					alignItems='center'
					paddingX={2}
					paddingTop='16px'
					direction='row'
					width='100%'
				>
					<Flex direction={['column', 'row']} fontSize='14px'>
						<Text fontWeight='light'>Current Time:&nbsp;</Text>
						<Text fontWeight='bold'>{serverTime === 'Invalid Date' ? '--:-- --' : serverTime}</Text>
					</Flex>
					<Spacer />
					<Flex direction='row'>
						<Flex
							padding='5px'
							borderRadius='8px'
							border='1px'
							borderColor='green.500'
							backgroundColor='green.100'
							color='green.500'
							fontSize='14px'
						>
							<Text>PHP</Text>
							<Text marginLeft={2} fontWeight='bold'>
								{getCreditRemain.data?.creditRemain === undefined
									? 0
									: currencyFormat(Number(getCreditRemain.data?.creditRemain))}
							</Text>

							{isLoading === true ? (
								<CircularProgress marginLeft={3} isIndeterminate size='20px' color='blue.500' />
							) : (
								<Flex
									marginLeft={3}
									onClick={handleReloadCredit}
									cursor='pointer'
									userSelect='none'
								>
									<ImgComp svgName='reload' />
								</Flex>
							)}
						</Flex>
					</Flex>
				</Flex>
			</Box>
		</Flex>
	);
};

export default MobileToggleHeader;

const MobileSideBar = ({
	setIsOpen,
	isOpen,
	isAgent = false,
	setDate,
	onClickLogout,
	onOpenResultPage,
	isOpenResultPage,
	onCloseResultPage,
	src,
}) => {
	const navigate = useNavigate();
	const location = useLocation();

	// zustand
	const removeAll = useBettingScreen((state) => state.removeAll);
	const removeAllStl = useBettingScreen((state) => state.removeAllStl);
	const setSiteId = useGameStore((state) => state.setSiteId);

	//* api
	const getSrvTime = useQuery(['getSrvTimeApi'], getSrvTimeApi);
	const getVersion = useQuery(['getVersionApi'], getVersionApi);

	// Animation
	const drawerAnimation = useSpring({
		transform: isOpen ? 'translateX(0%)' : 'translateX(-100%)',
		delay: 0,
	});
	// function
	const handleClick = () => {
		setDate(dayjs(getSrvTime.data?.currTime).utc().tz('Asia/Kuala_Lumpur').$d);
	};

	return (
		<animated.div className='drawer' style={drawerAnimation}>
			{/* Drawer content goes here */}
			<Flex flexDirection='column' overflowY='scroll' overflowX='hidden' maxHeight='100vh'>
				<Flex justifyContent='space-between' width='100%'>
					<Flex alignItems='center' marginY={7}>
						<Flex paddingX={2}>
							<ImgComp svgName={'logo'} width={50} height={50} />
						</Flex>
						<Flex flexDirection='column'>
							<Text color='black' fontSize='26px' fontWeight='700'>
								PANALO 29
							</Text>
							<Text color='gray.500' fontSize='12px' width='100%'>
								Version {getVersion.data?.version}
							</Text>
						</Flex>
					</Flex>

					<Flex
						alignItems='flex-start'
						padding={7}
						onClick={() => {
							setIsOpen(false);
						}}
					>
						<ImgComp svgName='close-icon' />
					</Flex>
				</Flex>
				<Flex minHeight={'80vh'} direction='column'>
					<Accordion defaultIndex={[0]} allowMultiple>
						<AccordionItem style={{ border: 'none' }}>
							<AccordionButton
								paddingBottom={2.5}
								_hover={{ bg: 'none' }}
								display='flex'
								justifyContent='space-between'
							>
								<Box flex='0.8' textAlign='left'>
									<Flex>
										<Flex marginRight={3}>
											<ImgComp
												svgName={
													location.pathname === '/pcso' || location.pathname === '/stl'
														? 'bet1'
														: 'bet'
												}
												width={8}
												height={8}
											/>
										</Flex>
										<NavLink>
											<Text
												paddingTop={[1, 1, 1, 1, 1, 0, 0]}
												fontSize={['16px', '16px', '16px', '16px', '16px', '16px', '19.2px']}
												fontWeight='500'
												color={
													location.pathname === '/pcso' || location.pathname === '/stl'
														? 'blue.350'
														: '#000000'
												}
											>
												Bet
											</Text>
										</NavLink>
									</Flex>
								</Box>
								<AccordionIcon />
							</AccordionButton>
							<AccordionPanel
								marginLeft={12}
								paddingBottom={1}
								onClick={() => {
									setSiteId(1);
									handleClick();
									setIsOpen(false);
									if (location.pathname === '/pcso') {
										removeAllStl();
									} else {
										navigate('/pcso');
										removeAllStl();
									}
								}}
								cursor='pointer'
								userSelect='none'
							>
								<DrawerNavLink name={'PCSO'} path={'/pcso'} />
							</AccordionPanel>
							<AccordionPanel
								marginLeft={12}
								paddingBottom={1}
								onClick={() => {
									setSiteId(2);
									setIsOpen(false);
									handleClick();
									if (location.pathname === '/stl') {
										removeAll();
									} else {
										navigate('/stl');
										removeAll();
									}
								}}
								cursor='pointer'
								userSelect='none'
							>
								<DrawerNavLink name={'STL'} path={'/stl'} />
							</AccordionPanel>
						</AccordionItem>
					</Accordion>
					<Flex direction='column'>
						<DrawerButton
							name={'Bet History'}
							path={'/history'}
							imgSelect={'history1'}
							img={'history'}
							onClick={() => {
								setIsOpen(false);
								handleClick();
								navigate('/history');
								removeAll();
								removeAllStl();
							}}
							color={
								location.pathname === '/history' || location.pathname === '/history/ticketdetail'
									? 'blue.350'
									: '#000000'
							}
						/>
						<DrawerButton
							name={'Transaction'}
							path={'/transaction'}
							imgSelect={'transaction1'}
							img={'transaction'}
							onClick={() => {
								setIsOpen(false);
								handleClick();
								navigate('/transaction');
								removeAll();
								removeAllStl();
							}}
							color={location.pathname === '/transaction' ? 'blue.350' : '#000000'}
						/>
						{sessionStorage.getItem('isThirdPartyLogin') === 'false' && (
							<DrawerButton
								name={'Credit Withdrawal'}
								path={'/creditwithdrawal'}
								imgSelect={'withdraw1'}
								img={'withdraw'}
								onClick={() => {
									setIsOpen(false);
									handleClick();
									navigate('/creditwithdrawal');
									removeAll();
									removeAllStl();
								}}
								color={location.pathname === '/creditwithdrawal' ? 'blue.350' : '#000000'}
							/>
						)}

						{(() => {
							if (sessionStorage.getItem('isThirdPartyLogin') === 'false') {
								if (isAgent) {
									return (
										<>
											<DrawerButton
												name={'Claimed Ticket'}
												path={'/claimedticket'}
												imgSelect={'claim1'}
												img={'claim'}
												onClick={() => {
													setIsOpen(false);
													handleClick();
													navigate('/claimedticket');
													removeAll();
													removeAllStl();
												}}
												color={location.pathname === '/claimedticket' ? 'blue.350' : '#000000'}
											/>
											<DrawerButton
												name={'Report'}
												path={'/report'}
												imgSelect={'report1'}
												img={'report'}
												onClick={() => {
													setIsOpen(false);
													handleClick();
													navigate('/report');
													removeAll();
													removeAllStl();
												}}
												color={
													location.pathname === '/report' ||
													location.pathname === '/reportAgent' ||
													location.pathname === '/reportDownlineList' ||
													location.pathname === '/reportDownline'
														? 'blue.350'
														: '#000000'
												}
											/>
										</>
									);
								}
							}
						})()}

						<DrawerButton
							name={'Result'}
							imgSelect={'result1'}
							img={'result'}
							onClick={() => {
								setIsOpen(false);
								// window.open('https://dev-pnlresult.devtoz.com/', '_blank');
								onOpenResultPage();
							}}
							color={location.pathname === '/result' ? 'blue.350' : '#000000'}
						/>
						<DrawerButton
							name={'Prize'}
							path={'/prize'}
							imgSelect={'prize1'}
							img={'prize'}
							onClick={() => {
								setIsOpen(false);
								handleClick();
								navigate('/prize');
								removeAll();
								removeAllStl();
							}}
							color={location.pathname === '/prize' ? 'blue.350' : '#000000'}
						/>

						<DrawerButton
							name={'Profile'}
							path={'/profile'}
							imgSelect={'profile1'}
							img={'profile'}
							onClick={() => {
								setIsOpen(false);
								handleClick();
								navigate('/profile');
								removeAll();
								removeAllStl();
							}}
							color={
								location.pathname === '/profile' ||
								location.pathname === '/profile/downline' ||
								location.pathname === '/profile/downlineProfile'
									? 'blue.350'
									: '#000000'
							}
						/>

						{sessionStorage.getItem('isThirdPartyLogin') === 'false' && (
							<DrawerButton
								name={'Log Out'}
								imgSelect={'logout'}
								img={'logout'}
								onClick={onClickLogout}
							/>
						)}
					</Flex>
				</Flex>
			</Flex>
			<ResultModal isOpen={isOpenResultPage} onClose={onCloseResultPage} />
		</animated.div>
	);
};
