import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import QRCode from 'react-qr-code';

const WithDrawalCard = ({
	status,
	requestID,
	requestTime,
	requestCreditAmount,
	processBy,
	processTime,
	isAgent,
	onClick,
	processDate,
	qrString,
	requestDate,
	requestBy,
}) => {
	return (
		<>
			<Box
				backgroundColor='blue.400'
				borderColor={status === 'Rejected' ? 'red.300' : status === 'Approved' ? 'green.400' : ''}
				borderWidth={2}
				width={['100%', '100%', '95%']}
				borderRadius={6}
				marginY={3}
				cursor='pointer'
				padding={4}
				onClick={onClick}
			>
				<Flex flexDirection='row' justifyContent='space-between' width='100%'>
					<Flex flexDirection='column' width='80%'>
						<Flex
							marginBottom='8px'
							color={
								status === 'Rejected'
									? 'red.400'
									: status === 'Approved'
									? 'green.500'
									: 'orange.500'
							}
							fontWeight='500'
							fontSize={
								window.ReactNativeWebView
									? ['14px']
									: ['14px', '14px', '14px', '14px', '14px', '16px']
							}
						>
							{status}
						</Flex>
						<Text
							marginBottom='8px'
							color='blue.500'
							fontWeight='500'
							fontSize={
								window.ReactNativeWebView
									? ['14px']
									: ['14px', '14px', '14px', '14px', '14px', '16px']
							}
						>
							{requestID}
						</Text>
					</Flex>
					<Flex width='20%' justifyContent='flex-end'>
						{!isAgent && status === 'Pending' ? (
							<Flex>
								<QRCode
									style={{
										width: '40px',
										height: 'auto',
									}}
									value={qrString ?? '0'}
								/>
							</Flex>
						) : (
							<Box width={['0px', '', '', '24px']} />
						)}
					</Flex>
				</Flex>
				<Box>
					<WithDrawalRow
						title='Requested Date'
						data={
							<Flex flexDirection='column' textAlign='right'>
								<Text>{requestDate}</Text>
								<Text>{requestTime}</Text>
							</Flex>
						}
					/>

					{isAgent && <WithDrawalRow width={['100%']} title='Requested By' data={requestBy} />}
					<WithDrawalRow
						title='Processed Date'
						data={
							processDate !== '-' ? (
								<Flex flexDirection='column' textAlign='right'>
									<Text>{processDate}</Text>
									<Text>{processTime}</Text>
								</Flex>
							) : (
								<Flex flexDirection='column'>
									<Text>-</Text>
									<Box height='20px'></Box>
								</Flex>
							)
						}
					/>
					<WithDrawalRow title='Processed By' data={processBy} />
					<WithDrawalRow
						title='Requested Amount(PHP)'
						data={requestCreditAmount}
						color='green.500'
					/>
				</Box>
			</Box>
		</>
	);
};

export default WithDrawalCard;

const WithDrawalRow = ({
	title,
	data,
	color,
	width,
	textAlign = 'left',
	dataTextAlign = 'right',
}) => {
	return (
		<Flex flexDirection={['row']} width={width}>
			<Box
				width={['100%']}
				fontWeight='300'
				fontSize={['14px', '14px', '14px', '14px', '14px', '16px']}
				marginBottom={'2'}
				textAlign={textAlign}
			>
				{title}
			</Box>
			<Box
				// width={dataWidth}
				fontWeight='500'
				fontSize={
					window.ReactNativeWebView ? ['14px'] : ['14px', '14px', '14px', '14px', '14px', '16px']
				}
				width='200px'
				color={color}
				marginBottom={'2'}
			>
				<Text noOfLines={1} textAlign={dataTextAlign}>
					{data}
				</Text>
			</Box>
		</Flex>
	);
};
