import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { currencyFormat } from '../../utils/numberFormat';

const BetListCard = ({
	gameType,
	number,
	combineText,
	betType,
	betTotal,
	odds,
	winAmount,
	showText,
	backgroundColor,
	isStrike,
}) => {
	return (
		<>
			<Flex
				fontSize='14px'
				fontWeight='500'
				padding='2'
				backgroundColor={backgroundColor}
				borderRadius='8px'
				marginBottom='2'
			>
				<Flex flexDirection='column' width={['60%', '60%', '50%']}>
					<Text>Games</Text>
					<Text marginTop={2}>Numbers</Text>
					<Text marginTop={2}>Bet Type:</Text>
					<Text marginTop={2}>Bet (PHP):</Text>
					<Text marginTop={2}>Odds</Text>
					<Text marginTop={2} display={showText ? 'block' : 'none'}>
						Result
					</Text>
					<Text marginTop={2} display={showText ? 'block' : 'none'}>
						Winning Amount (PHP)
					</Text>
				</Flex>
				<Flex flexDirection='column' width={['40%', '40%', '50%']}>
					<Text>{gameType}</Text>
					<Flex marginTop={2}>
						<Flex>
							{number.map((num, index) => {
								return (
									<Text marginRight={2} key={index}>
										{num.digit}
									</Text>
								);
							})}
						</Flex>
						<Text>{combineText ? '(LP)' : ''} </Text>
					</Flex>
					<Text marginTop={2}>{betType === true ? 'Ramble' : 'Straight'}</Text>
					<Text marginTop={2}>{betTotal}</Text>
					<Text marginTop={2}>{odds && Number(odds).toFixed(2)}</Text>
					<Text
						marginTop={2}
						color={isStrike === true ? '#22943B' : '#000000'}
						display={showText ? 'block' : 'none'}
					>
						{isStrike === true ? 'WIN' : '--'}
					</Text>

					<Text
						marginTop={2}
						color={winAmount > 0 ? '#22943B' : '#000000'}
						display={showText ? 'block' : 'none'}
					>
						{winAmount > 0 ? currencyFormat(winAmount) : '--'}
					</Text>
				</Flex>
			</Flex>
		</>
	);
};

export default BetListCard;
