import {
	Box,
	CircularProgress,
	Divider,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { putBetClaim } from '../../api/query/betApi.js';
import { useCheckingStore } from '../../hooks/useStore.js';
import ImgComp from '../../utils/ImgComp.js';
import { ConvertedTime } from '../../utils/Time.js';
import { currencyFormat } from '../../utils/numberFormat.js';
import HistoryCustomButton from '../history/HistoryCustomButton.js';

// scanned result & searched result by typing in search input
const ScannedQrData = ({ isOpen, onClose, qrError, data, latitude, longitude, setInputValue }) => {
	// zustand
	const setCheckClaim = useCheckingStore((state) => state.setCheckClaim);
	// state
	const [isMsg, setIsMsg] = useState('');
	const [step, setStep] = useState(1);
	const [ticketNo, setTicketNo] = useState('');
	// modal state
	const { isOpen: isOpenClaim, onOpen: onOpenClaim, onClose: onCloseClaim } = useDisclosure();
	const {
		isOpen: isOpenClaimedSuccess,
		onOpen: onOpenClaimedSuccess,
		onClose: onCloseClaimedSuccess,
	} = useDisclosure();

	//* api
	const queryClient = useQueryClient();
	const invalidatePutBetClaim = () => queryClient.invalidateQueries({ queryKey: ['putBetClaim'] });
	const putBetClaimQuery = useMutation({
		mutationFn: () =>
			putBetClaim({
				ticketNo: `${ticketNo}`,
				isFullyClaimed: true,
				// claimAmount: winningPrice,
				latitude: latitude,
				longitude: longitude,
			}),

		onSuccess: (succ) => {
			invalidatePutBetClaim();
			setIsMsg(succ.message);
			setCheckClaim(false);
			handleClearInput();
			setStep(3);
		},
		onError: (err) => {
			setIsMsg(err.response.data.data.message);
			setCheckClaim(false);
			handleClearInput();
			setStep(3);
		},
	});

	// function
	const handleClearInput = () => {
		const inputElement = document.getElementById('search-input');
		setInputValue('');
		inputElement.value = '';
	};

	// useEffect
	useEffect(() => {
		if (data?.length > 0) {
			setTicketNo(data[0].ticketNo);
		}
	}, [data]);

	return (
		<>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				isCentered
				closeOnOverlayClick={false}
				size={['sm', 'sm', 'md', 'lg', 'xl']}
			>
				<ModalOverlay />
				{step === 1 && (
					<ModalContent marginX={3}>
						{qrError !== '' ? null : <ModalCloseButton />}
						<ModalBody
							borderTopWidth={10}
							borderRadius={5}
							borderColor={qrError !== '' ? 'red.500' : 'white'}
							position={qrError !== '' ? 'relative' : 'unset'}
						>
							<Flex marginY='10px' width='100%'>
								{qrError !== '' ? <ImgComp svgName={'redCross'} /> : null}
								<Flex flexDirection='column' marginX='10px' justifyContent='center' width='100%'>
									{qrError !== '' ? (
										<Flex flexDirection='column' marginX='10px'>
											<Text fontSize='20px' fontWeight='700'>
												{qrError}
											</Text>
											{qrError === 'Permission denied' ? (
												<Text fontSize='16px' fontWeight='500'>
													Please allow camera permission in browser and refresh page to scan ticket.
												</Text>
											) : null}
										</Flex>
									) : (
										<>
											<Text fontSize='20px' fontWeight='700'>
												Scanned Result
											</Text>
											{data?.length > 0 &&
												data.map((item) => {
													return (
														<Flex
															key={item.ticketNo}
															flexDirection='column'
															borderWidth={['0px', '', '', '1px', '1px']}
															borderRadius='8px'
															borderColor='gray.300'
															padding={['0px', '', '', '20px', '20px']}
														>
															<DesktopScannedResult
																ticketNo={item.ticketNo}
																ticketExpired={item.ticketExpired}
																ticketAgentCode={item.ticketAgentCode}
																ticketAgentName={item.userFirstName}
																winningNumbers={item.winningNumbers}
																winningPrice={item.winningPrice}
																claim={item.claim}
																claimDate={item.claimDate}
																onClick={
																	item.claim !== true
																		? () => {
																				if (window.ReactNativeWebView) {
																					setStep(2);
																				} else {
																					onOpenClaim();
																				}
																		  }
																		: () => {}
																}
															/>
															<MobileScannedResult
																ticketNo={item.ticketNo}
																ticketExpired={item.ticketExpired}
																ticketAgentCode={item.ticketAgentCode}
																ticketAgentName={item.userFirstName}
																winningNumbers={item.winningNumbers}
																winningPrice={item.winningPrice}
																claim={item.claim}
																claimDate={item.claimDate}
																onClick={
																	item.claim !== true
																		? () => {
																				// onClose();
																				if (window.ReactNativeWebView) {
																					setStep(2);
																				} else {
																					onOpenClaim();
																				}
																		  }
																		: () => {}
																}
															/>
														</Flex>
													);
												})}
										</>
									)}
								</Flex>
							</Flex>
							{qrError !== '' ? (
								<>
									<Divider borderColor='gray.600' borderWidth={1} />
									<Flex justifyContent='flex-end' marginTop={2}>
										<Flex
											backgroundColor={'red.200'}
											borderRadius={8}
											padding='8px 10px'
											cursor='pointer'
											alignItems='center'
											onClick={onClose}
											marginY='10px'
										>
											<Text color={'red.500'} fontWeight='600' fontSize='16px' userSelect='none'>
												OK
											</Text>
										</Flex>
									</Flex>
								</>
							) : null}
						</ModalBody>
					</ModalContent>
				)}
				{step === 2 && (
					<ModalContent marginX={3}>
						<ModalBody
							p={5}
							borderTopWidth={10}
							borderRadius={5}
							borderColor='green.500'
							position='relative'
						>
							<Flex alignItems='center' marginY='15px'>
								<ImgComp svgName='lowBalance' />
								<Flex flexDirection='column' marginX='15px'>
									<Text fontSize='20px' fontWeight='700'>
										Confirm to claim?
									</Text>
									<Flex flexDirection={['column', 'row']}>
										<Text fontWeight='500' fontSize='14px' marginRight={1}>
											Ticket can be claim once only.
										</Text>
										<Text fontWeight='500' fontSize='14px'></Text>
									</Flex>
								</Flex>
							</Flex>
							<Flex flexDirection='column'>
								<Flex>
									<Text>Ticket Number: </Text>
									<Text color='blue.500'>&nbsp;{data?.[0]?.ticketNo}</Text>
								</Flex>
								<Flex>
									<Text>Claim Amount (PHP):</Text>
									<Text color='green.500'>
										&nbsp;{data?.length > 0 && currencyFormat(data?.[0]?.winningPrice)}
									</Text>
								</Flex>
							</Flex>
							<Divider borderColor='gray.600' borderWidth={1} />
							<Flex justifyContent='flex-end' marginTop={2} marginY='15px'>
								<Flex
									backgroundColor='gray.100'
									borderRadius={8}
									padding='8px 10px'
									cursor='pointer'
									alignItems='center'
									onClick={() => {
										setStep(1);
									}}
								>
									<Text fontWeight='600' fontSize='16px'>
										Cancel
									</Text>
								</Flex>
								<Flex
									marginLeft='10px'
									backgroundColor='green.100'
									borderRadius={8}
									padding='8px 10px'
									cursor='pointer'
									alignItems='center'
									onClick={() => {
										putBetClaimQuery.mutate();
									}}
								>
									<ImgComp svgName='greenSmallTick' width={4} height={4} />
									<Text
										paddingLeft={2}
										color='green.500'
										fontWeight='600'
										fontSize='16px'
										userSelect='none'
									>
										Confirm
									</Text>
								</Flex>
							</Flex>
						</ModalBody>
					</ModalContent>
				)}
				{step === 3 && (
					<ModalContent marginX={3}>
						<ModalBody
							borderTopWidth={10}
							borderRadius={5}
							borderColor={
								putBetClaimQuery.isSuccess
									? 'green.500'
									: putBetClaimQuery.isError
									? 'red.500'
									: null
							}
							position='relative'
						>
							{putBetClaimQuery.isLoading ? (
								<Flex justifyContent='center' alignItems='center' height='150px'>
									<CircularProgress size='50px' isIndeterminate color='blue.600' />
								</Flex>
							) : (
								<>
									<Flex alignItems='center' marginY='10px'>
										<ImgComp
											svgName={
												putBetClaimQuery.isSuccess
													? 'greenTick'
													: putBetClaimQuery.isError
													? 'redCross'
													: null
											}
										/>
										<Flex flexDirection='column' marginX='10px'>
											<Text fontSize='20px' fontWeight='700'>
												{isMsg}
											</Text>
										</Flex>
									</Flex>
									<Divider borderColor='gray.600' borderWidth={1} />
									<Flex justifyContent='flex-end' marginTop={2}>
										<Flex
											backgroundColor={
												putBetClaimQuery.isSuccess
													? 'green.100'
													: putBetClaimQuery.isError
													? 'red.100'
													: 'white'
											}
											borderRadius={8}
											padding='8px 10px'
											cursor='pointer'
											alignItems='center'
											onClick={() => {
												setCheckClaim(true);
												setStep(1);
											}}
											marginY='10px'
										>
											<Text
												color={
													putBetClaimQuery.isSuccess
														? 'green.500'
														: putBetClaimQuery.isError
														? 'red.500'
														: 'white'
												}
												fontWeight='600'
												fontSize='16px'
												userSelect='none'
											>
												OK
											</Text>
										</Flex>
									</Flex>
								</>
							)}
						</ModalBody>
					</ModalContent>
				)}
			</Modal>

			{/* confirm claim modal */}
			{isOpenClaim && (
				<Modal
					isCentered
					closeOnOverlayClick={false}
					size={['sm', 'sm', 'md', 'lg', 'xl']}
					isOpen={isOpenClaim}
				>
					<ModalOverlay />
					<ModalContent marginX={3}>
						<ModalBody
							p={5}
							borderTopWidth={10}
							borderRadius={5}
							borderColor='green.500'
							position='relative'
						>
							<Flex alignItems='center' marginY='15px'>
								<ImgComp svgName='lowBalance' />
								<Flex flexDirection='column' marginX='15px'>
									<Text fontSize='20px' fontWeight='700'>
										Confirm to claim?
									</Text>
									<Flex flexDirection={['column', 'row']}>
										<Text fontWeight='500' fontSize='14px' marginRight={1}>
											Ticket can be claim once only.
										</Text>
										<Text fontWeight='500' fontSize='14px'></Text>
									</Flex>
								</Flex>
							</Flex>
							<Flex flexDirection='column'>
								<Flex>
									<Text>Ticket Number: </Text>
									<Text color='blue.500'>&nbsp;{data?.[0]?.ticketNo}</Text>
								</Flex>
								<Flex>
									<Text>Claim Amount (PHP):</Text>
									<Text color='green.500'>
										&nbsp;{data?.length > 0 && currencyFormat(data?.[0]?.winningPrice)}
									</Text>
								</Flex>
							</Flex>
							<Divider borderColor='gray.600' borderWidth={1} />
							<Flex justifyContent='flex-end' marginTop={2} marginY='15px'>
								<Flex
									backgroundColor='gray.100'
									borderRadius={8}
									padding='8px 10px'
									cursor='pointer'
									alignItems='center'
									onClick={() => {
										onCloseClaim();
									}}
								>
									<Text fontWeight='600' fontSize='16px'>
										Cancel
									</Text>
								</Flex>
								<Flex
									marginLeft='10px'
									backgroundColor='green.100'
									borderRadius={8}
									padding='8px 10px'
									cursor='pointer'
									alignItems='center'
									onClick={() => {
										putBetClaimQuery.mutate();
										onClose();
										onCloseClaim();
										onOpenClaimedSuccess();
									}}
								>
									<ImgComp svgName='greenSmallTick' width={4} height={4} />
									<Text
										paddingLeft={2}
										color='green.500'
										fontWeight='600'
										fontSize='16px'
										userSelect='none'
									>
										Confirm
									</Text>
								</Flex>
							</Flex>
						</ModalBody>
					</ModalContent>
				</Modal>
			)}

			{/* success claim modal */}
			{isOpenClaimedSuccess && (
				<Modal
					isCentered
					closeOnOverlayClick={false}
					size={['sm', 'sm', 'md', 'lg', 'xl']}
					isOpen={isOpenClaimedSuccess}
				>
					<ModalOverlay />
					<ModalContent marginX={3}>
						<ModalBody
							borderTopWidth={10}
							borderRadius={5}
							borderColor={
								putBetClaimQuery.isSuccess
									? 'green.500'
									: putBetClaimQuery.isError
									? 'red.500'
									: null
							}
							position='relative'
						>
							{putBetClaimQuery.isLoading ? (
								<Flex justifyContent='center' alignItems='center' height='150px'>
									<CircularProgress size='50px' isIndeterminate color='blue.600' />
								</Flex>
							) : (
								<>
									<Flex alignItems='center' marginY='10px'>
										<ImgComp
											svgName={
												putBetClaimQuery.isSuccess
													? 'greenTick'
													: putBetClaimQuery.isError
													? 'redCross'
													: null
											}
										/>
										<Flex flexDirection='column' marginX='10px'>
											<Text fontSize='20px' fontWeight='700'>
												{isMsg}
											</Text>
										</Flex>
									</Flex>
									<Divider borderColor='gray.600' borderWidth={1} />
									<Flex justifyContent='flex-end' marginTop={2}>
										<Flex
											backgroundColor={
												putBetClaimQuery.isSuccess
													? 'green.100'
													: putBetClaimQuery.isError
													? 'red.100'
													: 'white'
											}
											borderRadius={8}
											padding='8px 10px'
											cursor='pointer'
											alignItems='center'
											onClick={() => {
												setCheckClaim(true);
												onCloseClaimedSuccess();
											}}
											marginY='10px'
										>
											<Text
												color={
													putBetClaimQuery.isSuccess
														? 'green.500'
														: putBetClaimQuery.isError
														? 'red.500'
														: 'white'
												}
												fontWeight='600'
												fontSize='16px'
												userSelect='none'
											>
												OK
											</Text>
										</Flex>
									</Flex>
								</>
							)}
						</ModalBody>
					</ModalContent>
				</Modal>
			)}
		</>
	);
};

export default ScannedQrData;

const MobileScannedResult = ({
	ticketNo,
	ticketExpired,
	ticketAgentName,
	winningNumbers,
	winningPrice,
	claim,
	claimDate,
	onClick,
}) => {
	return (
		<Box display={['block', 'block', 'block', 'none']}>
			<Flex flexDirection='column' justifyContent='space-between'>
				<Flex justifyContent='space-between'>
					<Text>Ticket No: </Text>
					<Text color='blue.500'>{ticketNo}</Text>
				</Flex>
				<Flex justifyContent='space-between'>
					<Text>Agent Name: </Text>
					<Text>{ticketAgentName}</Text>
				</Flex>
				<Divider marginY={1} />
				<Flex justifyContent='space-between'>
					<Text>Winning Price (PHP): </Text>
					<Text color='green.500'>{currencyFormat(winningPrice ?? 0)}</Text>
				</Flex>
			</Flex>
			<Divider marginY={2} borderWidth='1.5px' />
			<Text textAlign='right'>
				{winningNumbers.length}&nbsp;
				{winningNumbers.length === 1 ? 'List' : 'Lists'}
			</Text>
			<Flex
				flexDirection='column'
				borderRadius='8px'
				borderColor='blue.500'
				borderWidth='1px'
				overflowY='scroll'
				height='35vh'
			>
				{winningNumbers.map((data, index) => {
					return (
						<Flex
							key={index}
							fontSize='14px'
							fontWeight='500'
							padding='2'
							backgroundColor='#F9FBFF'
							borderRadius='8px'
							marginBottom='2'
						>
							<Flex flexDirection='column' width={['60%', '60%', '50%']}>
								<Text>Games</Text>
								<Text marginTop={2}>Numbers</Text>
								<Text marginTop={2}>Bet Type:</Text>
								<Text marginTop={2}>Bet (PHP):</Text>
								<Text marginTop={2}>Winning Amount (PHP)</Text>
							</Flex>

							<Flex flexDirection='column' width={['40%', '40%', '50%']}>
								<Text>{data.gameName}</Text>
								<Flex marginTop={2}>
									<Flex>{data.num}</Flex>
								</Flex>
								<Text marginTop={2}>{data.betType === 'R' ? 'Ramble' : 'Straight'}</Text>
								<Text marginTop={2}>{currencyFormat(data.betAmount)}</Text>

								<Text marginTop={2} color={'green.500'}>
									{currencyFormat(data.winPrice)}
								</Text>
							</Flex>
						</Flex>
					);
				})}
			</Flex>
			{ticketExpired === true ? (
				<Flex justifyContent='space-between' marginY={3}>
					<Text
						alignSelf='center'
						fontSize={['12px', '12px', '12px', '14px', '14px', '14px', '24px']}
						fontWeight='600'
						color='red.500'
					>
						Ticket Expired.
					</Text>

					<HistoryCustomButton
						width={['61px', '61px', '61px', '61px', '61px', '81px']}
						height={['32px', '32px', '32px', '32px', '32px', '40px']}
						backgroundColor={
							ticketExpired === true ? 'gray.600' : claim === true ? 'blue.200' : 'green.100'
						}
						borderColor={
							ticketExpired === true ? 'gray.500' : claim === true ? 'blue.500' : 'green.500'
						}
						textColor={
							ticketExpired === true ? 'gray.500' : claim === true ? 'blue.500' : 'green.500'
						}
						fontSize={['14px', '14px', '14px', '14px', '14px', '18px']}
						fontWeight={'600'}
						buttonTitle={claim === true ? 'Claimed' : 'Claim'}
						onClick={ticketExpired === true ? null : onClick}
						disabled={ticketExpired === true ? true : false}
					/>
				</Flex>
			) : (
				<Flex justifyContent='space-between' marginY={3}>
					{claim === true ? (
						<Text
							alignSelf='center'
							fontSize={['12px', '12px', '12px', '14px', '14px', '14px', '22px']}
						>
							Claimed Date Time: {ConvertedTime({ time: claimDate, format: 'DD/MM/YYYY hh:mm A' })}
						</Text>
					) : (
						<Text>*Claim available within one week.</Text>
					)}

					<HistoryCustomButton
						width={['61px', '61px', '61px', '61px', '61px', '81px']}
						height={['32px', '32px', '32px', '32px', '32px', '40px']}
						backgroundColor={claim === true ? 'blue.200' : 'green.100'}
						borderColor={claim === true ? 'blue.500' : 'green.500'}
						textColor={claim === true ? 'blue.500' : 'green.500'}
						fontSize={['14px', '14px', '14px', '14px', '14px', '18px']}
						fontWeight={'600'}
						buttonTitle={claim === true ? 'Claimed' : 'Claim'}
						onClick={onClick}
					/>
				</Flex>
			)}
		</Box>
	);
};

const DesktopScannedResult = ({
	ticketNo,
	ticketExpired,
	ticketAgentName,
	winningNumbers,
	winningPrice,
	claim,
	claimDate,
	onClick,
}) => {
	return (
		<Box display={['none', 'none', 'none', 'block']}>
			<Flex flexDirection='column' justifyContent='space-between'>
				<Flex justifyContent='space-between'>
					<Text>Ticket No: </Text>
					<Text color='blue.500'>{ticketNo}</Text>
				</Flex>
				<Flex justifyContent='space-between'>
					<Text>Agent Name: </Text>
					<Text>{ticketAgentName}</Text>
				</Flex>
			</Flex>
			<Divider marginY={2} borderWidth='1.5px' />
			<Text textAlign='right'>
				{winningNumbers.length}&nbsp;
				{winningNumbers.length === 1 ? 'List' : 'Lists'}
			</Text>
			<Flex
				flexDirection='column'
				borderRadius='8px'
				borderColor='blue.500'
				borderWidth='1px'
				overflowY='scroll'
				maxHeight='35vh'
			>
				<Flex
					fontWeight={'400'}
					fontSize={'14px'}
					padding={3}
					alignItems={'center'}
					bgColor={'#EDF3FF'}
					borderRadius={'8px'}
				>
					<Box width={'15%'}>Games</Box>
					<Box width={'25%'}>Numbers</Box>
					<Box width={'25%'} textAlign={'right'}>
						Total Bets (PHP)
					</Box>

					<Box width={'35%'} textAlign={'right'}>
						Winning Amount (PHP)
					</Box>
				</Flex>
				{winningNumbers.map((data, index) => {
					return (
						<Flex key={index} width={['100%']} padding={3} fontSize='15px'>
							<Box width={'15%'}>Games</Box>
							<Flex width={'25%'} justifyContent='space-between'>
								<Text>{data.num}</Text>
								<Text>{data.betType === 'R' ? 'Ramble' : 'Straight'}</Text>
							</Flex>
							<Box width={'25%'} textAlign={'right'}>
								{data.betAmount}
							</Box>

							<Box width={'35%'} textAlign={'right'}>
								<Text color='green.500' fontWeight='500' textAlign='right'>
									{currencyFormat(data.winPrice)}
								</Text>
							</Box>
						</Flex>
					);
				})}
				<Divider marginY='10px' />
				<Flex width={['100%']} paddingX={3} fontSize='18px' paddingBottom={1}>
					<Text width='70%'>Total Win</Text>
					<Text color='green.500' fontWeight='500' width='30%' textAlign='right'>
						{currencyFormat(winningPrice ?? 0)}
					</Text>
				</Flex>
			</Flex>
			{ticketExpired === true ? (
				<Flex justifyContent='space-between' marginY={3}>
					<Text
						alignSelf='center'
						fontSize={['12px', '12px', '12px', '14px', '14px', '14px', '24px']}
						fontWeight='600'
						color='red.500'
					>
						Ticket Expired.
					</Text>

					<HistoryCustomButton
						width={['61px', '61px', '61px', '61px', '61px', '81px']}
						height={['32px', '32px', '32px', '32px', '32px', '40px']}
						backgroundColor={
							ticketExpired === true ? 'gray.600' : claim === true ? 'blue.200' : 'green.100'
						}
						borderColor={
							ticketExpired === true ? 'gray.500' : claim === true ? 'blue.500' : 'green.500'
						}
						textColor={
							ticketExpired === true ? 'gray.500' : claim === true ? 'blue.500' : 'green.500'
						}
						fontSize={['14px', '14px', '14px', '14px', '14px', '18px']}
						fontWeight={'600'}
						buttonTitle={claim === true ? 'Claimed' : 'Claim'}
						onClick={ticketExpired === true ? null : onClick}
						disabled={ticketExpired === true ? true : false}
					/>
				</Flex>
			) : (
				<Flex justifyContent='space-between' marginY={3}>
					{claim === true ? (
						<Text
							alignSelf='center'
							fontSize={['12px', '12px', '12px', '14px', '14px', '14px', '22px']}
						>
							Claimed Date Time: {ConvertedTime({ time: claimDate, format: 'DD/MM/YYYY hh:mm A' })}
						</Text>
					) : (
						<Text>*Claim available within one week.</Text>
					)}

					<HistoryCustomButton
						width={['61px', '61px', '61px', '61px', '61px', '81px']}
						height={['32px', '32px', '32px', '32px', '32px', '40px']}
						backgroundColor={claim === true ? 'blue.200' : 'green.100'}
						borderColor={claim === true ? 'blue.500' : 'green.500'}
						textColor={claim === true ? 'blue.500' : 'green.500'}
						fontSize={['14px', '14px', '14px', '14px', '14px', '18px']}
						fontWeight={'600'}
						buttonTitle={claim === true ? 'Claimed' : 'Claim'}
						onClick={onClick}
					/>
				</Flex>
			)}
		</Box>
	);
};
