import { Box, Flex, Heading, Input, InputGroup, InputRightElement, Text } from '@chakra-ui/react';
import React, { useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import dayjs from 'dayjs';
import ImgComp from '../../utils/ImgComp';

const MobileTitleHeader = ({
	title,
	onChangeValue,
	onFocus,
	hasInputSearch = false,
	hasCalendar = false,
	hasFilter = false,
	hasQrScan = false,
	selected,
	onChangeDate,
	onFilterClick,
	onQrClick,
	onKeyDown,
	defaultValue,
	isRequest = false,
	handleSearch,
	maxDate,
}) => {
	return (
		<Flex
			marginTop='48px'
			marginBottom='20px'
			flexDirection='column'
			display={['flex', '', '', '', 'none']}
		>
			<Flex justifyContent='space-between'>
				{hasInputSearch === true ? (
					<InputGroup
						width={['190px', '200px', '280px', '280px']}
						borderColor='#0052FF'
						alignSelf='center'
						backgroundColor='#ECF2FF'
					>
						<Input
							id='search-input'
							inputMode='numeric'
							placeholder={isRequest ? 'Request ID' : 'Ticket No.'}
							height={['45px', '45px', '45px', '45px', '48px', '48px', '52px']}
							onChange={onChangeValue}
							onFocus={onFocus}
							onKeyDown={onKeyDown}
							defaultValue={defaultValue}
						/>
						<InputRightElement
							onClick={handleSearch}
							height={['40px', '40px', '40px', '40px', '48px', '48px', '52px']}
							backgroundColor='blue.300'
							marginTop='3px'
							marginRight='2px'
							borderColor={'blue.700'}
							borderRadius={5}
							_active={{ backgroundColor: 'blue.230' }}
							children={
								<ImgComp
									svgName={'search-icon'}
									width={['18px', '16px', '16px', '16px', '21px']}
									height={['18px', '18px', '18px', '18px', '24px']}
								/>
							}
						/>
					</InputGroup>
				) : (
					<Box />
				)}

				<Flex>
					{hasCalendar === true ? (
						<Box
							marginRight={[1, 2, 2]}
							alignItems='center'
							justifyContent='center'
							userSelect='none'
							cursor='pointer'
						>
							<DatePicker
								selected={selected}
								onChange={onChangeDate}
								minDate={new Date('2023/04/01')}
								maxDate={maxDate}
								customInput={
									<Box
										display='flex'
										border='1px'
										borderRadius='8px'
										borderColor='#0052FF'
										padding='2'
										backgroundColor='#ECF2FF'
									>
										<ImgComp svgName={'calender-icon'} width={['32px']} height={['32px']} />
									</Box>
								}
							/>
						</Box>
					) : (
						<Box />
					)}
					{hasFilter === true ? (
						<Box
							display='flex'
							border='1px'
							marginRight={hasQrScan ? [1, 2, 2] : 0}
							borderRadius='8px'
							borderColor='#0052FF'
							padding='2'
							backgroundColor='#ECF2FF'
							onClick={onFilterClick}
							alignItems='center'
							justifyContent='center'
							userSelect='none'
							cursor='pointer'
						>
							<ImgComp svgName='filter' width={['28px']} height={['28px']} />
						</Box>
					) : null}
					{hasQrScan === true ? (
						<Box
							display='flex'
							border='1px'
							borderRadius='8px'
							borderColor='#0052FF'
							padding='2'
							backgroundColor='#ECF2FF'
							onClick={onQrClick}
							alignItems='center'
							justifyContent='center'
							userSelect='none'
							cursor='pointer'
						>
							<ImgComp svgName='scanQR' width={['28px']} height={['28px']} />
						</Box>
					) : null}
				</Flex>
			</Flex>
			<Heading
				marginTop={5}
				fontSize={['15px', '20px', '20px', '20px', '20px', '32px']}
				alignSelf='left'
			>
				{title}
			</Heading>
		</Flex>
	);
};

export default MobileTitleHeader;

export const MobileTitleHeader2 = ({
	title,
	hasCalendar = false,
	hasDownload = false,
	hasFilter = false,
	hasQrScan = false,
	selected,
	onChangeDate,
	hasLeftArrow = false,
	onBackClick,
	onDownloadClick,
	onFilterClick,
	maxDate,
}) => {
	return (
		<>
			{hasLeftArrow === true ? (
				<Box
					display={['flex', '', '', '', 'none']}
					onClick={onBackClick}
					cursor='pointer'
					width={10}
					marginTop={10}
					marginBottom={-10}
				>
					<ImgComp svgName='left-arrow' />
				</Box>
			) : null}
			<Flex
				marginTop='48px'
				marginBottom='20px'
				justifyContent='space-between'
				display={['flex', '', '', '', 'none']}
			>
				<Heading fontSize={['20px', '20px', '20px', '20px', '20px', '32px']} alignSelf='center'>
					{title}
				</Heading>
				<Flex>
					{hasCalendar === true ? (
						<Box userSelect='none' cursor='pointer'>
							<DatePicker
								selected={selected}
								onChange={onChangeDate}
								minDate={new Date('2023/04/01')}
								maxDate={maxDate}
								customInput={
									<Box
										display='flex'
										border='1px'
										borderRadius='8px'
										borderColor='#0052FF'
										padding='2'
										backgroundColor='#ECF2FF'
									>
										<ImgComp
											svgName={'calender-icon'}
											width={['', '', '', '', '', '32px']}
											height={['', '', '', '', '', '32px']}
										/>
									</Box>
								}
							/>
						</Box>
					) : (
						<Box />
					)}

					{hasFilter === true ? (
						<Box
							display='flex'
							border='1px'
							marginLeft={hasCalendar ? [1, 2, 2] : 0}
							borderRadius='8px'
							borderColor='#0052FF'
							padding='2'
							backgroundColor='#ECF2FF'
							onClick={onFilterClick}
							alignItems='center'
							justifyContent='center'
							userSelect='none'
							cursor='pointer'
						>
							<ImgComp svgName='filter' width={['28px']} height={['28px']} />
						</Box>
					) : null}

					{hasDownload && (
						<Box
							display='flex'
							border='1px'
							borderRadius='8px'
							borderColor='#0052FF'
							marginLeft={hasCalendar || hasFilter || hasQrScan ? [1, 2, 2] : 0}
							padding='2'
							backgroundColor='#ECF2FF'
							onClick={onDownloadClick}
							alignItems='center'
							justifyContent='center'
							userSelect='none'
							cursor='pointer'
						>
							<ImgComp svgName='download' width={['25px', '28px']} height={['25px', '28px']} />
						</Box>
					)}
				</Flex>
			</Flex>
		</>
	);
};

export const DesktopTitleHeader = ({
	title,
	onChangeValue,
	onFocus,
	hasInputSearch = false,
	hasCalendar = false,
	hasDownload = false,
	hasFilter = false,
	hasQrScan = false,
	selected,
	onChangeDate,
	onFilterClick,
	onQrClick,
	onDownloadClick,
	onBackClick,
	onKeyDown,
	hasLeftArrow = false,
	defaultValue,
	handleSearch,
	isRequest = false,
	hasWallet,
	onWalletClick,
	maxDate,
}) => {
	return (
		<>
			{hasLeftArrow === true ? (
				<Box
					display={['none', '', '', 'flex']}
					onClick={onBackClick}
					cursor='pointer'
					width={10}
					marginTop={20}
					marginBottom={-10}
				>
					<ImgComp svgName='left-arrow' />
				</Box>
			) : null}
			<Flex
				marginTop='48px'
				marginBottom='20px'
				justifyContent='space-between'
				display={['none', '', '', 'flex']}
			>
				<Heading fontSize={['15px', '20px', '20px', '20px', '29px', '32px']} alignSelf='center'>
					{title}
				</Heading>
				<Flex>
					{hasInputSearch === true ? (
						<Box>
							<InputGroup
								width={['150px', '150px', '200px', '240px', '240px', '275px', '280px']}
								borderColor='#0052FF'
								alignSelf='center'
								backgroundColor='#ECF2FF'
								marginRight={['3', '5', '5', '5', '5']}
							>
								<Input
									id='search-input'
									placeholder={isRequest ? 'Request ID' : 'Ticket No.'}
									height={['35px', '38px', '38px', '38px', '48px', '52px']}
									onChange={onChangeValue}
									onKeyDown={onKeyDown}
									onFocus={onFocus}
									defaultValue={defaultValue}
								/>
								<InputRightElement
									onClick={handleSearch}
									height={['', '', '', '', '43px', '47px']}
									backgroundColor='blue.300'
									marginTop='3px'
									marginRight='2px'
									borderColor={'blue.700'}
									borderRadius={5}
									_active={{ backgroundColor: 'blue.230' }}
									children={
										<ImgComp
											svgName={'search-icon'}
											width={['16px', '16px', '16px', '16px', '21px']}
											height={['18px', '18px', '18px', '18px', '24px']}
										/>
									}
								/>
							</InputGroup>
						</Box>
					) : (
						<Box />
					)}
					{hasCalendar ? (
						<Flex
							alignItems='center'
							justifyContent='center'
							userSelect='none'
							cursor='pointer'
							onClick={() => {
								if (window.smartsupp) {
									window.smartsupp(function () {
										document.getElementById(
											'smartsupp-widget-container',
										).firstElementChild.style.zIndex = '999';
										document.getElementById('widgetButtonFrame').firstElementChild.style.zIndex =
											'999';
									});
								}
							}}
						>
							<DatePicker
								selected={selected}
								timeZone='Asia/Kuala_Lumpur'
								onChange={onChangeDate}
								minDate={new Date('2023/04/01')}
								maxDate={maxDate}
								customInput={
									<Box
										display='flex'
										border='1px'
										borderRadius='8px'
										borderColor='#0052FF'
										padding='2'
										backgroundColor='#ECF2FF'
									>
										<ImgComp
											svgName={'calender-icon'}
											width={['', '', '', '', '', '32px']}
											height={['', '', '', '', '', '32px']}
										/>
									</Box>
								}
							/>
						</Flex>
					) : (
						<Box />
					)}
					{hasFilter && (
						<Flex
							display='flex'
							marginLeft={hasCalendar ? [1, 2, 2] : 0}
							border='1px'
							borderRadius='8px'
							borderColor='#0052FF'
							padding='2'
							backgroundColor='#ECF2FF'
							onClick={onFilterClick}
							alignItems='center'
							justifyContent='center'
							userSelect='none'
							cursor='pointer'
						>
							<ImgComp svgName='filter' width={['28px']} height={['28px']} />
						</Flex>
					)}
					{hasQrScan && (
						<Flex
							display='flex'
							border='1px'
							borderRadius='8px'
							borderColor='#0052FF'
							marginLeft={hasFilter || hasCalendar ? [1, 2, 2] : 0}
							padding='2'
							backgroundColor='#ECF2FF'
							onClick={onQrClick}
							alignItems='center'
							justifyContent='center'
							userSelect='none'
							cursor='pointer'
						>
							<ImgComp svgName='scanQR' width={['28px']} height={['28px']} />
						</Flex>
					)}

					{hasWallet && (
						<Box
							display='flex'
							border='1px'
							borderRadius='8px'
							borderColor='#0052FF'
							marginLeft={hasFilter || hasQrScan ? [1, 2, 2] : 0}
							padding='2'
							backgroundColor='#ECF2FF'
							onClick={onWalletClick}
							alignItems='center'
							justifyContent='center'
							userSelect='none'
							cursor='pointer'
						>
							<ImgComp svgName='withdraw' width={['25px', '28px']} height={['25px', '28px']} />
						</Box>
					)}
					{hasDownload && (
						<Box
							display='flex'
							border='1px'
							borderRadius='8px'
							borderColor='#0052FF'
							marginLeft={hasCalendar || hasFilter || hasQrScan ? [1, 2, 2] : 0}
							padding='2'
							backgroundColor='#ECF2FF'
							onClick={onDownloadClick}
							alignItems='center'
							justifyContent='center'
							userSelect='none'
							cursor='pointer'
						>
							<ImgComp svgName='download' width={['25px', '28px']} height={['25px', '28px']} />
						</Box>
					)}
				</Flex>
			</Flex>
		</>
	);
};

export const MobileTitleCreditWithdrawal = ({
	title,
	onChangeValue,
	hasInputSearch = false,
	hasCalendar = false,
	hasFilter = false,
	hasQrScan = false,
	selected,
	onChangeDate,
	onFilterClick,
	onQrClick,
	onKeyDown,
	defaultValue,
	handleSearch,
	date,
	isRequest = false,
	hasWallet = false,
	onWalletClick,
	maxDate,
}) => {
	const searchRef = useRef();

	return (
		<Flex
			marginTop='30px'
			marginBottom='20px'
			flexDirection='column'
			display={['flex', '', '', '', 'none']}
		>
			<Heading fontSize={['15px', '20px', '20px', '20px', '20px', '32px']} alignSelf='left'>
				{title}
			</Heading>
			<Flex flexDirection='column' justifyContent='space-between' marginTop={2}>
				{hasInputSearch === true ? (
					<InputGroup
						width='100%'
						borderColor='#0052FF'
						alignSelf='center'
						backgroundColor='#ECF2FF'
					>
						<Input
							ref={searchRef}
							id='search-input'
							placeholder={isRequest ? 'Request ID' : 'Ticket No.'}
							height={['45px', '45px', '45px', '45px', '48px', '52px']}
							onChange={onChangeValue}
							onKeyDown={onKeyDown}
							defaultValue={defaultValue}
						/>
						<InputRightElement
							onClick={handleSearch}
							height={['40px', '40px', '40px', '40px', '48px', '52px']}
							backgroundColor='blue.300'
							marginTop='3px'
							marginRight='2px'
							borderColor={'blue.700'}
							borderRadius={5}
							_active={{ backgroundColor: 'blue.230' }}
							children={
								<ImgComp
									svgName={'search-icon'}
									width={['18px', '16px', '16px', '21px']}
									height={['18px', '18px', '18px', '24px']}
								/>
							}
						/>
					</InputGroup>
				) : null}
				<Flex justifyContent='space-between'>
					<Flex alignItems='flex-end'>
						<Text fontWeight='500' fontSize={['16px', '16px', '16px', '16px', '16px', '20px']}>
							{dayjs(date).format('ddd, DD/MM/YYYY')}
						</Text>
					</Flex>
					<Flex justifyContent='flex-end' marginTop={2}>
						{hasCalendar === true ? (
							<Box alignItems='center' justifyContent='center' userSelect='none' cursor='pointer'>
								<DatePicker
									selected={selected}
									onChange={onChangeDate}
									minDate={new Date('2023/04/01')}
									maxDate={maxDate}
									customInput={
										<Box
											display='flex'
											border='1px'
											borderRadius='8px'
											borderColor='#0052FF'
											padding='2'
											backgroundColor='#ECF2FF'
										>
											<ImgComp
												svgName={'calender-icon'}
												width={['25px', '28px']}
												height={['25px', '28px']}
											/>
										</Box>
									}
								/>
							</Box>
						) : null}
						{hasFilter === true ? (
							<Box
								marginLeft={[1, 2, 2]}
								display='flex'
								border='1px'
								borderRadius='8px'
								borderColor='#0052FF'
								padding='2'
								backgroundColor='#ECF2FF'
								onClick={onFilterClick}
								alignItems='center'
								justifyContent='center'
								userSelect='none'
								cursor='pointer'
							>
								<ImgComp svgName='filter' width={['25px', '28px']} height={['25px', '28px']} />
							</Box>
						) : null}
						{hasQrScan === true ? (
							<Box
								display='flex'
								border='1px'
								borderRadius='8px'
								borderColor='#0052FF'
								padding='2'
								marginLeft={hasFilter ? [1, 2, 2] : 0}
								backgroundColor='#ECF2FF'
								onClick={onQrClick}
								alignItems='center'
								justifyContent='center'
								userSelect='none'
								cursor='pointer'
							>
								<ImgComp svgName='scanQR' width={['25px', '28px']} height={['25px', '28px']} />
							</Box>
						) : null}
						{hasWallet && (
							<Box
								display='flex'
								border='1px'
								borderRadius='8px'
								borderColor='#0052FF'
								marginLeft={hasFilter || hasQrScan ? [1, 2, 2] : 0}
								padding='2'
								backgroundColor='#ECF2FF'
								onClick={onWalletClick}
								alignItems='center'
								justifyContent='center'
								userSelect='none'
								cursor='pointer'
							>
								<ImgComp svgName='withdraw' width={['25px', '28px']} height={['25px', '28px']} />
							</Box>
						)}
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	);
};
