import {
	Box,
	Divider,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import QRCode from 'react-qr-code';
import ImgComp from '../../utils/ImgComp';

const TitleList = ({ title, data, color, hasQR = false, qrString, ticketNumber, isAgent }) => {
	// zustand

	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<>
			<Flex fontWeight='500' fontSize={['14px', '14px', '14px', '14px', '14px', '22px']}>
				<Box marginBottom='2' width={['40%', '40%', '40%', '40%', '45%', '30%']}>
					{title}
				</Box>
				<Flex width={['60%', '60%', '60%', '60%', '55%', '70%']}>
					<Box
						marginBottom='2'
						marginRight={[2, 2, 2, 2, 3, 3]}
						width={hasQR ? 'fit-content' : ['48vw']}
						color={color}
					>
						<Text width='100%' warp='warp'>
							{data}
						</Text>
					</Box>
					{sessionStorage.getItem('isThirdPartyLogin') === 'false' && (
						<Flex marginBottom='2' alignItems='center'>
							{hasQR === true && isAgent === true ? (
								<Box onClick={onOpen} userSelect='none' cursor='pointer'>
									<ImgComp svgName='qr-code' />
								</Box>
							) : null}
						</Flex>
					)}
				</Flex>
			</Flex>
			{isOpen && (
				<Modal isOpen={isOpen} onClose={onClose} isCentered>
					<ModalOverlay />
					<ModalContent marginX={3}>
						<ModalCloseButton />
						<ModalBody>
							<Box align='center' padding={6}>
								<QRCode
									size={60}
									style={{ height: 'auto', maxWidth: '60%', width: '60%' }}
									value={qrString ?? '0'}
								/>
								<Text paddingTop={4}>Ticket Number: {ticketNumber}</Text>
							</Box>
						</ModalBody>
					</ModalContent>
				</Modal>
			)}
		</>
	);
};

const SubjectList = ({
	ticketId,
	betDT,
	drawDT,
	totalBet,
	ticketStatus,
	qrString,
	ticketNumber,
	isAgent,
	firstName,
	mobileNo,
	totalWins,
	gameType,
}) => {
	return (
		<Flex flexDirection='column' marginBottom={3}>
			<TitleList title='First Name' data={firstName} />
			{sessionStorage.getItem('isThirdPartyLogin') === 'false' && (
				<TitleList title='Mobile Number' data={mobileNo} />
			)}
			<TitleList title='User Type' data={isAgent ? 'Agent' : 'Player'} />
			<Divider my={3} />
			<TitleList
				title='Ticket Number'
				data={ticketId}
				color={ticketStatus === true ? 'red.500' : '#0052FF'}
				hasQR={true}
				isAgent={isAgent}
				qrString={qrString}
				ticketNumber={ticketNumber}
			/>
			<TitleList title='Bet Date/Time' data={betDT} />
			<TitleList title='Draw Date/Time' data={drawDT} />
			<TitleList title='Game Type' data={gameType} />
			<TitleList title='Total Bets' data={totalBet} />
			<TitleList title='Total Wins' data={totalWins} />
			<TitleList
				title='Ticket Status'
				data={
					ticketStatus === 'Voided' || ticketStatus === 'Denied' ? (
						<Text color='#E52A2A' fontWeight='700'>
							{ticketStatus === 'Voided' ? 'Voided' : 'Denied'}
						</Text>
					) : ticketStatus === 'Striked' ? (
						<Text color='#22943B' fontWeight='700'>
							Striked
						</Text>
					) : ticketStatus === 'Claimed' ? (
						<Text color='blue.500' fontWeight='700'>
							Claimed
						</Text>
					) : (
						<Text color='' fontWeight='700'>
							Posted
						</Text>
					)
				}
			/>
		</Flex>
	);
};

export default SubjectList;
