import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import ImgComp from './ImgComp';

const ButtonComp = ({
	value,
	color,
	width,
	height,
	fontSize,
	borderColor,
	backgroundColor,
	onClick,
	disabled,
	hoverColor,
	activeColor,
	hasImg = false,
	svgName,
}) => {
	const [isLargerThan1200] = useMediaQuery('(min-width: 1200px)');
	return (
		<>
			{disabled ? (
				<Flex
					marginY={3}
					marginX={3}
					width={width}
					height={height}
					justifyContent='center'
					alignItems='center'
					borderWidth={2}
					borderRadius={8}
					borderColor='gray.300'
					cursor='default'
					color='gray.300'
					userSelect='none'
				>
					<Box marginX='5px'>{hasImg === true ? <ImgComp svgName={svgName} /> : null}</Box>
					<Text textAlign='center' fontWeight='600' userSelect='none' fontSize={fontSize}>
						{value}
					</Text>
				</Flex>
			) : (
				<Flex
					marginY={3}
					marginX={3}
					width={width}
					height={height}
					justifyContent='center'
					alignItems='center'
					borderWidth={2}
					borderRadius={8}
					borderColor={borderColor}
					onClick={onClick}
					cursor='pointer'
					color={color}
					userSelect='none'
					backgroundColor={backgroundColor}
					_hover={{
						backgroundColor: isLargerThan1200 ? hoverColor : 'none',
						color: isLargerThan1200 ? color : 'none',
					}}
					_active={{ backgroundColor: isLargerThan1200 ? activeColor : 'none' }}
				>
					{hasImg === true ? <ImgComp svgName={svgName} /> : null}
					<Text textAlign='center' userSelect='none' fontWeight='600' fontSize={fontSize}>
						{value}
					</Text>
				</Flex>
			)}
		</>
	);
};

export default ButtonComp;
