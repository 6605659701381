import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

const HistoryCustomButton = ({
	onClick,
	_active,
	width,
	height,
	backgroundColor,
	borderColor,
	textColor,
	fontSize,
	fontWeight,
	buttonTitle,
	alignSelf,
	disabled,
}) => {
	return (
		<Flex
			onClick={onClick}
			_active={_active}
			width={width}
			height={height}
			backgroundColor={backgroundColor}
			borderColor={borderColor}
			borderRadius={'8px'}
			borderWidth='1px'
			alignItems='center'
			justifyContent='center'
			padding={'10px'}
			alignSelf={alignSelf}
			disabled={disabled}
			userSelect='none'
			cursor='pointer'
		>
			<Text textColor={textColor} fontSize={fontSize} fontWeight={fontWeight}>
				{buttonTitle}
			</Text>
		</Flex>
	);
};

export default HistoryCustomButton;
