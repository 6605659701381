import { Flex, Text, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import ImgComp from '../../utils/ImgComp';

const TimePick = ({ time, isSelected = false, onClick, disable = false, cancelled }) => {
	const [isLargerThan1440] = useMediaQuery('(min-width: 1440px)');
	const borderWidth = '1px';
	const borderRadius = [8];
	const alignItems = 'center';
	const justifyContent = 'center';
	const textAlign = ' center';
	const width = ['32%'];
	const height = ['40px', '40px', '40px', '40px', '49px'];
	const padding = [('12px', '10px', '12px', '10px')];
	const fontWeight = ['600'];
	const fontSize = ['14px', '16px', '16px', '16px', '20px'];
	return (
		<>
			{isSelected ? (
				<Flex
					justifyContent={justifyContent}
					alignItems={alignItems}
					borderRadius={borderRadius}
					borderWidth={borderWidth}
					borderColor='blue.500'
					backgroundColor='blue.300'
					width={width}
					height={height}
					padding={padding}
					userSelect='none'
					cursor='default'
				>
					<Text
						textAlign={textAlign}
						fontWeight={fontWeight}
						fontSize={fontSize}
						color='black'
						userSelect='none'
						cursor='default'
					>
						{time}
					</Text>
				</Flex>
			) : disable ? (
				<Flex
					justifyContent={justifyContent}
					alignItems={alignItems}
					borderRadius={[8]}
					borderWidth='1px'
					borderColor='gray.300'
					width={width}
					height={height}
					padding={padding}
					userSelect='none'
					cursor='default'
				>
					<Text
						textAlign={textAlign}
						fontWeight={fontWeight}
						fontSize={fontSize}
						color='gray.300'
						cursor='default'
						userSelect='none'
					>
						{time}
					</Text>
					{cancelled && (
						<Flex position='absolute' width={width} height={height}>
							<ImgComp
								svgName='cancelled'
								width={['90', '90', '90', '90', '90', '100', '100']}
								height={['21', '21', '21', '21', '27', '31', '31']}
								position='absolute'
								top={[0, 0, 0, 0, 0, 1, 1]}
								right={[-1, -1, -1, -1, 8, 10, 10]}
								opacity='80%'
								transform={'rotate(25deg)'}
								fill='white'
							/>
						</Flex>
					)}
				</Flex>
			) : (
				<Flex
					justifyContent={justifyContent}
					alignItems={alignItems}
					borderRadius={borderRadius}
					borderWidth={borderWidth}
					borderColor={cancelled ? 'gray.300' : '#000000'}
					width={width}
					height={height}
					padding={padding}
					color={cancelled ? 'gray.300' : '#000000'}
					userSelect='none'
					_hover={
						!cancelled && {
							borderColor: isLargerThan1440 ? 'blue.500' : 'none',
							color: isLargerThan1440 ? 'blue.500' : 'none',
						}
					}
					cursor='pointer'
					onClick={() => {
						if (!cancelled) {
							onClick();
						}
					}}
				>
					<Text
						textAlign={textAlign}
						fontWeight={fontWeight}
						fontSize={fontSize}
						cursor='pointer'
						userSelect='none'
						_hover={!cancelled && { color: isLargerThan1440 ? 'blue.500' : 'none' }}
					>
						{time}
					</Text>
					{cancelled && (
						<Flex position='absolute' width={width} height={height}>
							<ImgComp
								svgName='cancelled'
								width={['90', '90', '90', '90', '90', '100', '100']}
								height={['21', '21', '21', '21', '27', '31', '31']}
								position='absolute'
								top={[0, 0, 0, 0, 0, 1, 1]}
								right={[-1, -1, -1, -1, 8, 10, 10]}
								opacity='80%'
								transform={'rotate(25deg)'}
								fill='white'
							/>
						</Flex>
					)}
				</Flex>
			)}
		</>
	);
};

export default TimePick;
