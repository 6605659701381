import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
	useMediaQuery,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getSrvTimeApi, getSuppKeyApi, getVersionApi } from '../../api/query/authApi';
import { useBettingScreen, useGameStore } from '../../hooks/useStore';
import '../../styles/global.css';
import ImgComp from '../../utils/ImgComp';
import { ResultModal } from '../iframe/IframeModal';

dayjs.extend(utc);
dayjs.extend(timezone);

const Drawer = ({ isAgent = false, setDate }) => {
	const navigate = useNavigate();
	const location = useLocation();
	// usemedia query
	const [isSmallerThan1024] = useMediaQuery('(max-width: 1023px)');
	// zustand
	const setSiteId = useGameStore((state) => state.setSiteId);
	const removeAll = useBettingScreen((state) => state.removeAll);
	const removeAllStl = useBettingScreen((state) => state.removeAllStl);

	// state
	const [jsParseLocal, setJsParseLocal] = useState();
	// modal
	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		isOpen: isOpenResultPage,
		onOpen: onOpenResultPage,
		onClose: onCloseResultPage,
	} = useDisclosure();
	//* api
	const getSrvTime = useQuery(['getSrvTimeApi'], getSrvTimeApi);
	const getVersion = useQuery(['getVersionApi'], getVersionApi);
	const getSuppKey = useQuery(['getSuppKeyApi'], getSuppKeyApi);

	// function
	const handleClick = () => {
		setDate(dayjs(getSrvTime.data?.currTime).utc().tz('Asia/Kuala_Lumpur').$d);
	};
	const handleReload = () => {
		setTimeout(() => {
			window.location.reload();
		}, 20);
	};

	// useEffect
	useEffect(() => {
		if (getSuppKey.isSuccess && getSuppKey.data) {
			if (localStorage.getItem(`ssupp_${getSuppKey.data?.data?.string}`)) {
				setJsParseLocal(JSON.parse(localStorage.getItem(`ssupp_${getSuppKey.data?.data?.string}`)));
			}
		}
	}, [
		getSuppKey.isSuccess,
		getSuppKey.data,
		localStorage.getItem(`ssupp_${getSuppKey.data?.data?.string}`),
	]);

	useEffect(() => {
		if (isOpen) {
			window.addEventListener('click', handleReload);
			return () => {
				window.removeEventListener('click', handleReload);
			};
		}
	}, []);

	return (
		<>
			{!isSmallerThan1024 && (
				<Box
					position='fixed'
					width={['', '', '', '250px', '250px', '280px', '330px']}
					height={'100vh'}
					minHeight='100vh'
					maxHeight='100vh'
					display={['none', 'none', 'none', 'block', 'block']}
					zIndex={1000}
					bgColor='#FFFFFF'
					borderRightRadius='50px'
					borderBottomRightRadius='50px'
				>
					<Box
						position='fixed'
						left='25px'
						top='0'
						zIndex={1000}
						display={['none', 'none', 'none', 'none', 'block', 'block', 'block']}
						overflowY='scroll'
						maxHeight='100vh'
						overflowX='hidden'
						className='drawerBox'
					>
						<Flex alignItems='center' marginY={5}>
							<ImgComp svgName={'logo'} width={50} height={50} />
							<Flex flexDirection='column' padding={2}>
								<Text color='black' fontSize='24px' fontWeight='700'>
									PANALO 29
								</Text>
								{getVersion.isSuccess && (
									<Text color='gray.500' fontSize='12px' width='100%' overflowX='none'>
										Version {getVersion.data?.version}
									</Text>
								)}
							</Flex>
						</Flex>

						<Accordion defaultIndex={[0]} allowToggle>
							<AccordionItem style={{ border: 'none' }}>
								<AccordionButton
									paddingBottom={2.5}
									_hover={{ bg: 'none' }}
									display='flex'
									justifyContent='space-between'
								>
									<Box textAlign='left'>
										<Flex>
											<Flex marginRight={3}>
												<ImgComp
													svgName={
														location.pathname === '/pcso' || location.pathname === '/stl'
															? 'bet1'
															: 'bet'
													}
													width={8}
													height={8}
												/>
											</Flex>
											{/* <NavLink> */}
											<Text
												paddingTop={[1, 1, 1, 1, 1, 0, 0]}
												fontSize={['16px', '16px', '16px', '16px', '16px', '16px', '19.2px']}
												fontWeight='500'
												color={
													location.pathname === '/pcso' || location.pathname === '/stl'
														? 'blue.350'
														: '#000000'
												}
											>
												Bet
											</Text>
										</Flex>
									</Box>
									<AccordionIcon />
								</AccordionButton>

								<AccordionPanel
									marginLeft={12}
									paddingBottom={1}
									onClick={() => {
										setSiteId(1);
										if (location.pathname === '/pcso') {
											removeAllStl();
										} else {
											navigate('/pcso');
											removeAllStl();
										}
										handleClick();
									}}
									cursor='pointer'
									userSelect='none'
								>
									<DrawerNavLink name={'PCSO'} path={'/pcso'} />
								</AccordionPanel>
								<AccordionPanel
									marginLeft={12}
									paddingBottom={1}
									onClick={() => {
										setSiteId(2);
										if (location.pathname === '/stl') {
											removeAll();
										} else {
											navigate('/stl');
											removeAll();
										}
										handleClick();
									}}
									cursor='pointer'
									userSelect='none'
								>
									<DrawerNavLink name={'STL'} path={'/stl'} />
								</AccordionPanel>
							</AccordionItem>
						</Accordion>
						<Flex direction='column'>
							<DrawerButton
								name={'Bet History'}
								path={'/history'}
								imgSelect={'history1'}
								img={'history'}
								onClick={() => {
									navigate('/history');
									handleClick();
									removeAll();
									removeAllStl();
								}}
								color={
									location.pathname === '/history' || location.pathname === '/history/ticketdetail'
										? 'blue.350'
										: '#000000'
								}
							/>
							<DrawerButton
								name={'Transaction'}
								path={'/transaction'}
								imgSelect={'transaction1'}
								img={'transaction'}
								onClick={() => {
									navigate('/transaction');
									handleClick();
									removeAll();
									removeAllStl();
								}}
								color={location.pathname === '/transaction' ? 'blue.350' : '#000000'}
							/>

							{sessionStorage.getItem('isThirdPartyLogin') === 'false' && (
								<DrawerButton
									name={'Credit Withdrawal'}
									path={'/creditwithdrawal'}
									img={'withdraw'}
									imgSelect={'withdraw1'}
									onClick={() => {
										navigate('/creditwithdrawal');
										handleClick();
										removeAll();
										removeAllStl();
									}}
									color={location.pathname === '/creditwithdrawal' ? 'blue.350' : '#000000'}
								/>
							)}

							{isAgent && sessionStorage.getItem('isThirdPartyLogin') === 'false' && (
								<>
									<DrawerButton
										name={'Claimed Ticket'}
										path={'/claimedticket'}
										imgSelect={'claim1'}
										img={'claim'}
										onClick={() => {
											handleClick();
											navigate('/claimedticket');
											removeAll();
											removeAllStl();
										}}
										color={location.pathname === '/claimedticket' ? 'blue.350' : '#000000'}
									/>
									<DrawerButton
										onClick={() => {
											handleClick();
											navigate('/report');
											removeAll();
											removeAllStl();
										}}
										name={'Report'}
										path={'report'}
										imgSelect={'report1'}
										img={'report'}
										color={
											location.pathname === '/report' ||
											location.pathname === '/reportAgent' ||
											location.pathname === '/reportDownlineList' ||
											location.pathname === '/reportDownline' ||
											location.pathname === '/downloadReports'
												? 'blue.350'
												: '#000000'
										}
									/>
								</>
							)}

							<DrawerButton
								name={'Result'}
								imgSelect={'result1'}
								img={'result'}
								onClick={() => {
									onClose();
									// window.open('https://pnlresult.uattoz.com', '_blank');
									// window.open('https://dev-pnlresult.devtoz.com/', '_self');
									onOpenResultPage();
									removeAll();
									removeAllStl();
								}}
								color={location.pathname === '/result' ? 'blue.350' : '#000000'}
							/>
							<DrawerButton
								name={'Prize'}
								path={'/prize'}
								imgSelect={'prize1'}
								img={'prize'}
								onClick={() => {
									onClose();
									handleClick();
									navigate('/prize');
									removeAll();
									removeAllStl();
								}}
								color={location.pathname === '/prize' ? 'blue.350' : '#000000'}
							/>

							<DrawerButton
								name={'Profile'}
								path={'/profile'}
								imgSelect={'profile1'}
								img={'profile'}
								onClick={() => {
									navigate('/profile');
									handleClick();
									removeAll();
									removeAllStl();
								}}
								color={
									location.pathname === '/profile' ||
									location.pathname === '/profile/downline' ||
									location.pathname === '/profile/downlineProfile'
										? 'blue.350'
										: '#000000'
								}
							/>
							{sessionStorage.getItem('isThirdPartyLogin') === 'false' && (
								<DrawerButton
									name={'Log Out'}
									imgSelect={'logout'}
									img={'logout'}
									onClick={() => {
										onOpen();
										if (window.smartsupp) {
											window.smartsupp(function () {
												document.getElementById(
													'smartsupp-widget-container',
												).firstElementChild.style.zIndex = '999';
												document.getElementById(
													'widgetButtonFrame',
												).firstElementChild.style.zIndex = '999';
											});
										}
									}}
								/>
							)}
						</Flex>
					</Box>
				</Box>
			)}

			{isOpen && (
				<Modal isOpen={isOpen} onClose={onClose} isCentered>
					<ModalOverlay />
					<ModalContent marginX={3} zIndex={'19000001'}>
						<ModalCloseButton />
						<ModalHeader></ModalHeader>
						<ModalBody>
							<Flex>
								<Text fontSize='18px' margin='20px'>
									Sure to logout?
								</Text>
							</Flex>

							<Flex justifyContent='flex-end' width='100%' align='flex-end' marginY='20px'>
								<Button
									bgColor='blue.700'
									onClick={() => {
										onClose();
										navigate('/');
										removeAll();
										removeAllStl();
										if (window.ReactNativeWebView) {
											localStorage.clear();
										}
										sessionStorage.clear();
										if (window.smartsupp && jsParseLocal) {
											if (
												jsParseLocal.opened === 'true' ||
												jsParseLocal.message !== undefined ||
												jsParseLocal.message !== ''
											) {
												delete jsParseLocal.opened;
												delete jsParseLocal.message;
												localStorage.setItem(
													`ssupp_${getSuppKey.data?.data?.string}`,
													JSON.stringify(jsParseLocal),
												);
											}
										}
									}}
									marginRight={2}
								>
									<Text color='white'>OK</Text>
								</Button>
								<Button onClick={onClose}>
									<Text>Cancel</Text>
								</Button>
							</Flex>
						</ModalBody>
					</ModalContent>
				</Modal>
			)}

			{isOpenResultPage && <ResultModal isOpen={isOpenResultPage} onClose={onCloseResultPage} />}
		</>
	);
};

export default Drawer;

export function DrawerButton({ name, path, imgSelect, img, onClick, color }) {
	const location = useLocation();
	const lowerCase = location.pathname.toLowerCase();

	return (
		<Flex
			padding={3}
			marginLeft={1}
			width='100%'
			onClick={onClick}
			path={path}
			cursor='pointer'
			userSelect='none'
		>
			<Flex marginRight={3}>
				<ImgComp
					svgName={lowerCase.includes(path) === true ? imgSelect : img}
					width={8}
					height={8}
				/>
			</Flex>
			<Text
				paddingTop={[1, 1, 1, 1, 1, 0, 0]}
				fontSize={['16px', '16px', '16px', '16px', '16px', '16px', '19.2px']}
				fontWeight='500'
				color={color}
			>
				{name}
			</Text>
		</Flex>
	);
}

export function DrawerNavLink({ name, path }) {
	const location = useLocation();
	return (
		<Text
			paddingTop={[1, 1, 1, 1, 1, 0, 0]}
			fontSize={['16px', '16px', '16px', '16px', '16px', '16px', '19.2px']}
			fontWeight='500'
			color={location.pathname === path ? 'blue.350' : '#000000'}
		>
			{name}
		</Text>
	);
}
