import { serverApi } from './api';

const API = '/api/report';

//` use to display own report
export const getReportApi = ({ date, siteId, gameId }) =>
	serverApi
		.get(`${API}/sales?date=${date}&siteId=${siteId}&gameId=${gameId}`)
		.then((res) => res.data.data);

//` use to display report include own report and downline report or downline report
export const getAgentReportApi = ({ date, userId, siteId, gameId }) =>
	serverApi
		.get(
			`${API}/salesIncludeDownline?date=${date}&siteId=${siteId}&gameId=${gameId}&userId=${userId}`,
		)
		.then((res) => res.data.data);

export const getExportDataApi = ({ type, drawDate, siteId, drawId }) =>
	serverApi
		.get(
			`${API}/exportFile?reportTypeDescription=${type}&drawDate=${drawDate}&siteId=${siteId}&drawId=${drawId}`,
		)
		.then((res) => res.data.data);
