import {
	Box,
	Button,
	Divider,
	Flex,
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { getSrvTimeApi } from '../api/query/authApi';
import { getCutOffTimeApi, getDrawApi, getGameApi, postPostApi } from '../api/query/betApi';
import { getCreditRemainApi } from '../api/query/profileApi';
import GamePickWithImg from '../components/betting/GamePickWithImg';
import SummaryRow from '../components/betting/SummaryRow';
import TimePick from '../components/betting/TimePick';
import Rg7Keyboard from '../components/keyboard/Rg7Keyboard';
import CustomButton from '../components/loginRegister/CustomButton';
import ConfirmCheckoutModal from '../components/modal/ConfirmCheckoutModal';
import IsLoadingModal from '../components/modal/IsLoadingModal';
import LowBalanceModal from '../components/modal/LowBalanceModal';
import SuccFailModal from '../components/modal/SuccFailModal';
import { useBettingScreen, useCheckingStore, useGameStore } from '../hooks/useStore';
import ImgComp from '../utils/ImgComp';
import { useGlobalToast } from '../utils/ShowToast';
import ServerTime from '../utils/Time';
import { currencyFormat } from '../utils/numberFormat';

const StlKeyboardScreen = () => {
	const globalShowToast = useGlobalToast();
	//dayjs
	var utc = require('dayjs/plugin/utc');
	var timezone = require('dayjs/plugin/timezone');
	dayjs.extend(utc);
	dayjs.extend(timezone);

	const { serverTime } = ServerTime();
	//zustand
	const rg7 = useBettingScreen((state) => state.rg7);
	const removeRg7 = useBettingScreen((state) => state.removeRg7);
	const removeAllStl = useBettingScreen((state) => state.removeAllStl);
	const siteId = useGameStore((state) => state.siteId);
	const setSiteId = useGameStore((state) => state.setSiteId);
	const setCheckPost = useCheckingStore((state) => state.setCheckPost);

	// state
	//@ select game === twoOrThreeD
	//state game button
	const [selectGame, setSelectGame] = useState();
	const [gameId, setGameId] = useState(1);
	const [rg7List, setRg7List] = useState([]);
	const [timeBtnId, setTimeBtnId] = useState();
	const [time, setTime] = useState('');
	const [timeName, setTimeName] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [succFail, setSuccFail] = useState(false);
	const [lowBalance, setLowBalance] = useState(false);
	const [activeTimeBtn, setActiveTimeBtn] = useState(false);
	const [hasServerTime, setHasServerTime] = useState(false);

	// modal
	const {
		isOpen: isOpenCheckout,
		onOpen: onOpenCheckout,
		onClose: onCloseCheckout,
	} = useDisclosure();

	//& api
	const getSrvTime = useQuery(['getSrvTimeApi'], getSrvTimeApi);
	const getGame = useQuery(['getGameApi', siteId], () => getGameApi(Number(siteId)));
	const getDraw = useQuery(['getDrawApi', gameId], () => getDrawApi(gameId));
	const getCreditRemain = useQuery(['getCreditRemainApi'], getCreditRemainApi);
	const getCutOffTime = useQuery(['getCutOffTimeApi'], getCutOffTimeApi);
	const postPost = useMutation({
		mutationFn: (bet) => postPostApi(bet),
		onSuccess: (succ) => {
			setSuccFail(true);
			setIsLoading(false);
			globalShowToast({
				title: succ.message,
				status: 'success',
			});

			clearBettingInput();
			removeAllStl();

			setCheckPost(false);
			getCreditRemain.refetch();
		},
		onError: (err) => {
			setIsLoading(false);
			globalShowToast({
				title: err.response.data.data.message,
				status: 'error',
			});
			setCheckPost(false);
		},
	});

	// form
	let schema3D = yup.object({
		numberArray: yup.array().of(
			yup.object({
				position: yup.number(),
				digit: yup.string(),
				lp: yup.boolean(),
				name: yup.string(),
			}),
		),
	});

	const Rg7Form = useForm({
		resolver: yupResolver(schema3D),
		defaultValues: {
			numberArray: [
				{ position: 1, digit: '', lp: false, name: 'number3DNum1' },
				{ position: 2, digit: '', lp: false, name: 'number3DNum2' },
				{ position: 3, digit: '', lp: false, name: 'number3DNum3' },
				{ position: 4, digit: '', name: 'priceStraight3D' },
				{ position: 5, digit: '', name: 'priceRamble3D' },
			],
		},
	});

	const Rg7Value = useWatch({
		control: Rg7Form.control,
		name: ['numberArray'],
	});

	// function
	// dependecies to run the checkDrawTime useEffect
	const checkDrawTime = () => {
		let drawSelect = getDraw.data?.find(
			(draw) =>
				dayjs(serverTime).format('YYYYMMDDHHmmss') <
					dayjs(draw?.endTime).format('YYYYMMDDHHmmss') &&
				dayjs(serverTime).format('YYYYMMDDHHmmss') >
					dayjs(draw.startTime).format('YYYYMMDDHHmmss') &&
				draw.cancelled === false,
		);
		if (drawSelect) {
			setTime(dayjs(drawSelect.drawTime).format('h mm A'));
			setTimeBtnId(drawSelect.id);
			setTimeName(drawSelect.name);
		} else {
			setTime('');
		}
	};

	const clearBettingInput = () => {
		Rg7Form.setValue('numberArray[0].digit', '');
		Rg7Form.setValue('numberArray[1].digit', '');
		Rg7Form.setValue('numberArray[2].digit', '');
		Rg7Form.setValue('numberArray[3].digit', '');
		Rg7Form.setValue('numberArray[4].digit', '');
		Rg7Form.setValue('numberArray[0].lp', false);
		Rg7Form.setValue('numberArray[1].lp', false);
		Rg7Form.setValue('numberArray[2].lp', false);
	};

	// useEffect
	useEffect(() => {
		if (getGame.isSuccess) {
			setGameId(getGame?.data[0]?.id);
			setSelectGame(getGame?.data[0]?.name);
		}
		setSiteId(2);
	}, [getGame.isSuccess, getGame.data]);

	useEffect(() => {
		if (
			getDraw.data?.[0].endTime === dayjs(serverTime).format('YYYYMMDDHHmmss') ||
			getDraw.data?.[1].endTime === dayjs(serverTime).format('YYYYMMDDHHmmss') ||
			getDraw.data?.[2].endTime === dayjs(serverTime).format('YYYYMMDDHHmmss') ||
			dayjs(getCutOffTime.data?.drawCutoffTime).format('YYYYMMDDHHmmss') ===
				dayjs(serverTime).format('YYYYMMDDHHmmss')
		) {
			if (
				dayjs(getCutOffTime.data?.drawCutoffTime).format('YYYYMMDDHHmmss') ===
				dayjs(serverTime).format('YYYYMMDDHHmmss')
			) {
				getDraw.refetch();
			}
			setActiveTimeBtn(true);
		} else {
			setActiveTimeBtn(false);
		}
	}, [getDraw.data, getDraw.isSuccess, serverTime]);

	useEffect(() => {
		if (serverTime !== null) {
			setHasServerTime(true);
		}
	}, [serverTime]);

	useEffect(() => {
		checkDrawTime();
	}, [activeTimeBtn, hasServerTime, getDraw.isSuccess, getSrvTime.isSuccess]);

	useEffect(() => {
		let filterRg7 = rg7.filter((item) => item.gameType === 'RG7');
		setRg7List(filterRg7);
	}, [rg7]);

	// calculate total
	let sumRg7 = 0;
	let sumBetting = 0;

	rg7List.forEach((element) => {
		sumRg7 += element.betPrice;
	});
	rg7.forEach((element) => {
		sumBetting += element.betPrice;
	});

	// handleSubmit
	const handleSubmit = () => {
		if (sumBetting <= getCreditRemain.data?.creditRemain) {
			setIsLoading(true);
			setCheckPost(true);
			postPost.mutate({
				drawId: timeBtnId,
				totalBetPrice: sumBetting,
				betting: [...rg7],
			});
		} else {
			setLowBalance(true);
			globalShowToast({
				title: 'Over Credit.',
				status: 'error',
			});
		}
	};

	return (
		<Box
			width='100%'
			margin={[0, 0, 0, 0, 5]}
			marginTop={['62px', '57px', '57px', '57px', '30px', '30px', '30px']}
			minHeight={[
				'calc(100vh - 62px)',
				'calc(100vh - 57px)',
				'calc(100vh - 57px)',
				'calc(100vh - 57px)',
				'calc(80vh - 30px)',
			]}
		>
			<Flex justifyContent='space-between' paddingX={2} marginTop={10} marginBottom={[0, 0, 0, 3]}>
				{getGame.data?.map((item) => {
					return (
						<GamePickWithImg
							width={['100%']}
							key={item.name}
							value={item.name}
							isSelected={selectGame === item.name}
							onClick={() => {
								setGameId(item.id);
								setSelectGame(item.name);
								clearBettingInput();
							}}
							svgName={'rg7'}
						/>
					);
				})}
			</Flex>

			{/* time button */}
			<Flex justifyContent='space-between' paddingX={2} marginY={[2, 2, 2, 3]}>
				{getDraw.data?.map((item) => {
					return (
						<TimePick
							key={item.id}
							time={item.name}
							timeName={item.name}
							cancelled={item.cancelled}
							disable={
								dayjs(serverTime).utc().tz('Asia/Kuala_Lumpur').format('YYYYMMDDHHmmss') <=
									dayjs(item.startTime).utc().tz('Asia/Kuala_Lumpur').format('YYYYMMDDHHmmss') ||
								dayjs(serverTime).utc().tz('Asia/Kuala_Lumpur').format('YYYYMMDDHHmmss') >=
									dayjs(item?.endTime).utc().tz('Asia/Kuala_Lumpur').format('YYYYMMDDHHmmss') ||
								(rg7List.length > 0 && rg7List.drawId !== item.id)
									? true
									: false
							}
							onClick={() => {
								setTime(dayjs(item.drawTime).format('h mm A'));
								setTimeBtnId(item.id);
								setTimeName(item.name);
							}}
							isSelected={time === dayjs(item.drawTime).format('h mm A') && timeBtnId === item.id}
						/>
					);
				})}
			</Flex>

			{/* game Body */}
			{getGame.isSuccess && (
				<Rg7Keyboard
					Rg7Form={Rg7Form}
					Rg7Value={Rg7Value}
					type={selectGame}
					time={time}
					gameId={gameId}
					minBetAmount={getGame.data[0].minBetAmount}
					totalAmount={sumBetting ?? 0}
					list={rg7.length}
					handleSubmit={handleSubmit}
				/>
			)}
			{rg7.length > 0 ? (
				<Flex
					flexDirection='column'
					marginX={2}
					marginTop={2}
					marginBottom={['30vh', '', '', 5]}
					paddingX={[0, 0, 0, 3]}
					paddingY={[0, 0, 0, 2]}
					backgroundColor={['transparent', '', '', '#FFFFFF']}
					borderRadius={8}
				>
					{isTablet === false && isMobile === true ? null : (
						<Flex justifyContent='space-between' alignItems='flex-end'>
							<Text
								fontWeight='600'
								fontSize='24px'
								textAlign='center'
								width={['100%', '100%', '100%', '100%', 'auto']}
								cursor='default'
							>
								Bet Lists
							</Text>
						</Flex>
					)}
					<Flex
						flexDirection={['column', 'column', 'column', 'column', 'row']}
						width='100%'
						height='100%'
					>
						<Flex
							flexDirection='column'
							width={['100%', '100%', '100%', '100%', '50%']}
							marginBottom={5}
						>
							{rg7.length > 0 && (
								<Flex
									flexDirection='column'
									bgColor={['', '', '', '#F9FBFF']}
									marginBottom={['', '', '', 5]}
									paddingBottom={['', '', '', 1]}
									borderBottomRadius={['', '', '', 8]}
									borderRadius={[8, '', '', 0]}
									borderWidth={['1px', '', '', '0px']}
									borderColor='blue.500'
								>
									{!rg7.isLoading && <SummaryRowHeader onClick={() => removeAllStl()} />}
									{rg7.map((items, index) => {
										return (
											<SummaryRow
												bgColor={index % 2 === 0 ? 'blue.200' : 'blue.230'}
												key={items.id}
												hasTime={true}
												onClickDel={() => removeRg7(items.id)}
												games={items.gameType}
												number={items.number}
												amount={items.betPrice}
												ramble={items.ramble}
												lp={
													items.number[0].lp === true || items.number[1].lp === true ? true : false
												}
												time={timeName}
											/>
										);
									})}
								</Flex>
							)}
						</Flex>
						<Divider
							borderColor='gray.50'
							orientation='vertical'
							borderWidth={1}
							marginX={3}
							height='auto'
							display={['none', 'none', 'none', 'none', 'block']}
						/>

						{(!isTablet && !isMobile) || (isTablet && isMobile) ? (
							<Flex
								flexDirection='column'
								width={['100%', '100%', '100%', '100%', '50%']}
								justifyContent='space-between'
							>
								<Box
									flexDirection='column'
									bgColor={['', '', '', '#F9FBFF']}
									marginBottom={['', '', '', 5]}
									paddingBottom={['', '', '', 1]}
									borderBottomRadius={['', '', '', 8]}
									borderRadius={[8, '', '', 0]}
									borderWidth={['1px', '', '', '0px']}
									borderColor='blue.500'
								>
									{/* //!Header */}
									<Flex
										height={['32px', '32px', '42px']}
										flexDirection='row'
										backgroundColor='blue.200'
										borderRadius={8}
										padding={1}
										alignItems='center'
									>
										<Text width={['2%']} />
										<Text
											width={['37%', '37%', '37%', '37%', '19%']}
											fontWeight='400'
											fontSize={['16px', '16px', '16px', '16px', '18px', '20px']}
											color='#000000'
										>
											Games
										</Text>
										<Text
											width={['30%', '30%', '30%', '30%', '34%']}
											fontWeight='400'
											textAlign='right'
											fontSize={['16px', '16px', '16px', '16px', '18px', '20px']}
											color='#000000'
										>
											Total List
										</Text>
										<Text
											width={['30%', '30%', '30%', '30%', '44%']}
											textAlign='right'
											fontWeight='400'
											fontSize={['16px', '16px', '16px', '16px', '18px', '20px']}
											color='#000000'
										>
											Total Bets(PHP)
										</Text>
									</Flex>

									{/* //!RG7 */}
									<Flex
										height={['32px', '32px', '42px']}
										flexDirection='row'
										borderRadius={8}
										padding={1}
										alignItems='center'
									>
										<Text width={['2%']} />
										<Text
											width={['37%', '37%', '37%', '37%', '19%']}
											fontWeight='500'
											fontSize={['16px', '16px', '16px', '16px', '18px', '20px']}
											color='#000000'
										>
											RG7
										</Text>
										<Text
											width={['30%', '30%', '30%', '30%', '34%']}
											fontWeight='500'
											textAlign='right'
											fontSize={['16px', '16px', '16px', '16px', '18px', '20px']}
											color='#000000'
										>
											{rg7List.length}
										</Text>
										<Text
											width={['30%', '30%', '30%', '30%', '44%']}
											textAlign='right'
											fontWeight='600'
											fontSize={['16px', '16px', '16px', '16px', '18px', '20px']}
											color='#000000'
										>
											{currencyFormat(Number(sumRg7))}
										</Text>
									</Flex>

									<Divider borderColor={'gray.50'} borderWidth={1} />
									<Flex
										height={['32px', '32px', '42px']}
										flexDirection='row'
										borderRadius={8}
										padding={1}
										alignItems='center'
									>
										<Text width={['2%']} />
										<Text
											width={['37%', '37%', '37%', '37%', '19%']}
											fontWeight='500'
											fontSize={['16px', '16px', '16px', '16px', '18px', '20px']}
											color='#000000'
										>
											Total
										</Text>
										<Text
											width={['30%', '30%', '30%', '30%', '34%']}
											fontWeight='500'
											textAlign='right'
											fontSize={['16px', '16px', '16px', '16px', '18px', '20px']}
											color='#000000'
										>
											{rg7.length}
										</Text>
										<Text
											width={['30%', '30%', '30%', '30%', '44%']}
											textAlign='right'
											fontWeight='600'
											fontSize={['16px', '16px', '16px', '16px', '18px', '20px']}
											color='green.500'
										>
											{currencyFormat(Number(sumBetting))}
										</Text>
									</Flex>
								</Box>
								{time === '' ? (
									<CustomButton
										backgroundColor='gray.100'
										btntitle='Bet'
										borderRadius={10}
										borderColor='gray.300'
										marginTop={5}
										marginBottom={0}
										color='gray.300'
										fontWeight='600'
										fontSize='22px'
										width='100%'
										height={['42px', '42px', '56px']}
										_active={{ backgroundColor: 'green.400' }}
										disable={true}
									/>
								) : (
									<CustomButton
										isLoading={isLoading}
										backgroundColor='green.500'
										btntitle='Bet'
										borderRadius={10}
										borderColor='green.300'
										marginTop={5}
										marginBottom={0}
										color='#FFFFFF'
										fontWeight='600'
										fontSize='22px'
										width='100%'
										height={['42px', '42px', '56px']}
										_active={{ backgroundColor: 'green.400' }}
										onClick={onOpenCheckout}
										disable={true}
									/>
								)}
							</Flex>
						) : null}
					</Flex>
				</Flex>
			) : null}

			{/* Modals */}
			<LowBalanceModal
				isOpen={lowBalance}
				balance={getCreditRemain.data?.creditRemain}
				onClick={() => {
					setLowBalance(false);
				}}
			/>

			<ConfirmCheckoutModal
				isOpen={isOpenCheckout}
				onClose={onCloseCheckout}
				onClick={handleSubmit}
			/>
			{postPost.isLoading && <IsLoadingModal isOpen={isLoading} />}
			{postPost.isSuccess && succFail === true ? (
				<SuccFailModal
					isOpen={succFail}
					onClick={() => {
						setSuccFail(false);
					}}
					data={postPost.data}
					isLoading={isLoading}
				/>
			) : null}
		</Box>
	);
};

export default StlKeyboardScreen;

const SummaryRowHeader = ({ onClick }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<>
			<Flex
				height={['32px', '32px', '42px']}
				flexDirection='row'
				backgroundColor='blue.200'
				borderRadius={8}
				padding={1}
				alignItems='center'
			>
				<Text
					width={['20%']}
					fontWeight='400'
					fontSize={['14px', '16px', '16px', '16px', '16px', '20px']}
					color='#000000'
				>
					Games
				</Text>
				<Text
					width={['35%']}
					fontWeight='400'
					fontSize={['14px', '16px', '16px', '16px', '16px', '20px']}
					color='#000000'
				>
					Numbers
				</Text>
				<Text
					width={['30%']}
					textAlign='right'
					fontWeight='400'
					fontSize={['14px', '16px', '16px', '16px', '16px', '20px']}
					color='#000000'
				>
					Bets(PHP)
				</Text>
				<Flex
					justifyContent='center'
					alignItems='center'
					height='100%'
					onClick={onOpen}
					width={['15%']}
					cursor='pointer'
					userSelect='none'
				>
					<ImgComp svgName='clearAll' />
				</Flex>
			</Flex>

			{isOpen && (
				<Modal isOpen={isOpen} onClose={onClose} isCentered size='sm'>
					<ModalOverlay />
					<ModalContent marginX={3}>
						<ModalBody
							p={5}
							borderTopWidth={10}
							borderRadius={5}
							borderColor='red.500'
							position='relative'
						>
							<Flex alignItems='center'>
								<ImgComp svgName='redLowBalance' width={14} height={12} />
								<Flex flexDirection='column'>
									<Text fontSize='20px' fontWeight='700'>
										Are you sure to clear all?
									</Text>
								</Flex>
							</Flex>

							<Flex justifyContent='flex-end' width='100%' align='flex-end' marginY='20px'>
								<Button
									marginRight={2}
									bgColor='red.400'
									onClick={() => {
										onClick();
										onClose();
									}}
								>
									<Text color='white'>OK</Text>
								</Button>
								<Button onClick={onClose}>
									<Text>Cancel</Text>
								</Button>
							</Flex>
						</ModalBody>
					</ModalContent>
				</Modal>
			)}
		</>
	);
};
