import { Box, Flex, Grid, Heading, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDownlineListApi } from '../api/query/downlineApi';
import DownlineReportCard from '../components/report/DownlineReportCard';
import ImgComp from '../utils/ImgComp';

const ReportListsScreen = () => {
	const navigate = useNavigate();

	//# api
	const getDownlineList = useQuery(['getDownlineListApi'], getDownlineListApi);

	useEffect(() => {
		if (sessionStorage.getItem('isThirdPartyLogin') === 'true') {
			navigate('/pcso');
		}
	}, []);

	return (
		<Box
			width='100%'
			paddingX={['3', '3', '5', '5', '10']}
			paddingTop={['80px', '80px', '80px', '80px', '30px', '30px', '30px']}
			paddingBottom={['80px']}
			minHeight='100vh'
		>
			<Flex marginTop='48px' justifyContent='space-between' flexDirection='column'>
				<Heading
					fontSize={['20px', '20px', '20px', '20px', '20px', '32px']}
					alignSelf='center'
					width='100%'
					textAlign='left'
					paddingBottom='20px'
				>
					Downline Report List
				</Heading>

				<Flex backgroundColor='white' padding={5} borderRadius={8} flexDirection='column'>
					<Box onClick={() => navigate('/report')} cursor='pointer' width={10}>
						<ImgComp svgName='left-arrow' />
					</Box>
					{getDownlineList.data !== undefined && getDownlineList.data?.length !== 0 ? (
						<Grid
							templateColumns={[
								'repeat(1, 1fr)',
								'repeat(1, 1fr)',
								'repeat(2, 1fr)',
								'repeat(2, 1fr)',
								'repeat(2, 1fr)',
								'repeat(3, 1fr)',
							]}
							alignItems='center'
							justifyContent='center'
							paddingLeft={['0', '0', '2%']}
						>
							{getDownlineList.data?.map((items) => {
								return (
									<DownlineReportCard
										key={items.id}
										downlineName={items.userName}
										mobileNo={items.mobileNumber}
										isAgent={items.isAgent}
										downlineCode={items.userCode}
										onClick={() => {
											navigate('/reportDownline');
											sessionStorage.setItem('downlineId', items.id);
											sessionStorage.setItem('downlineCode', items.userCode);
											sessionStorage.setItem('downlineName', items.userName);
										}}
									/>
								);
							})}
						</Grid>
					) : (
						<Flex
							justifyContent='center'
							alignItems='center'
							minHeight={['55vh', '', '', '', '70vh']}
						>
							<Text>No downline reports</Text>
						</Flex>
					)}
				</Flex>
			</Flex>
		</Box>
	);
};

export default ReportListsScreen;
