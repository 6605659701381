import { Card, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { getReferralCodeApi, getVersionApi, postAuthRegisterApi } from '../api/query/authApi';
import CustomButton from '../components/loginRegister/CustomButton';
import { CustomInput } from '../components/loginRegister/CustomInput';
import ScanRefCodeModal from '../components/modal/ScanRefCodeModal';
import { useLoginStore, useStore } from '../hooks/useStore';
import { ErrorText } from '../utils/CustomText';
import ImgComp from '../utils/ImgComp';
import { useGlobalToast } from '../utils/ShowToast';

const RegisterScreen = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const globalShowToast = useGlobalToast();

	// zustand
	const { countryCode } = useStore();
	const isRegisterLink = useLoginStore((state) => state.isRegisterLink);
	const setIsRegisterLink = useLoginStore((state) => state.setIsRegisterLink);
	// state
	const [refCode, setRefCode] = useState('');
	const [isAgent, setIsAgent] = useState(false);
	// is url or referral code
	const [isUrlCode, setIsUrlCode] = useState(false);
	const [isMobileLandscape, setIsMobileLandscape] = useState(false);
	const [scanError, setScanError] = useState('');
	const [refCodeStatus, setRefCodeStatus] = useState('');

	// modal
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isOpenError, onOpen: onOpenError, onClose: onCloseError } = useDisclosure();

	const schema = yup
		.object({
			mobileNumber: yup
				.string()
				.required('Please enter mobile number.')
				.trim('Mobile number cannot include leading and trailing spaces.')
				.strict(true)
				.matches(/^[0-9]{10}$/, 'Invalid phone format.'),
			username: yup
				.string()
				.trim('The username cannot include leading and trailing spaces.')
				.strict(true)
				.required('Please enter username.'),
			referralCode: yup
				.string()
				.trim('The referral code cannot include leading and trailing spaces.')
				.strict(true)
				.required('Please enter the referral code.'),
			password: yup
				.string()
				.required('Please enter the password.')
				.trim('Password cannot include leading and trailing spaces.')
				.strict(true)
				.min(8, 'Password is too short - should be 8 Characters Long minimum.')
				.max(16, 'Password is too long - should be 16 Characters Long maximum.')
				.matches(/[a-z]+/, 'Password must have at least one Lowercase Character.')
				.matches(/[A-Z]+/, 'Password must have at least one Uppercase Character.')
				.matches(/\d+/, 'Password must contain at least one Digit.'),
			confirmPassword: yup
				.string()
				.required('Please enter confirm password.')
				.oneOf([yup.ref('password'), null], 'Passwords not match.'),
		})
		.required();

	const {
		control,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			mobileNumber: '',
			username: '',
			userId: '',
			referralCode: '',
			password: '',
			confirmPassword: '',
			registerAgent: isAgent,
		},
	});

	const loginValue = useWatch({
		control,
		name: ['mobileNumber', 'username', 'userId', 'referralCode', 'password', 'registerAgent'],
	});

	const [mobileNumber, username, userId, referralCode, password, registerAgent] = loginValue;

	const phoneNumber = countryCode + mobileNumber;
	const submitedRefCode = isUrlCode === true ? refCode : referralCode;

	//* api
	const queryClient = useQueryClient();
	const invalidateRefCodeQuery = () =>
		queryClient.invalidateQueries({ queryKey: ['getReferralCodeApi'] });

	const getVersion = useQuery(['getVersionApi'], getVersionApi);
	const registerNewAccount = useMutation({
		mutationFn: (data) => postAuthRegisterApi(data),
		onSuccess: (succ) => {
			globalShowToast({
				title: `${succ.message}`,
				status: 'success',
				duration: 10000,
			});
			setRefCode('');
			setIsAgent(false);
			setIsUrlCode(false);
			navigate('/');
			setIsRegisterLink('');
		},
		onError: (err) => {
			globalShowToast({
				title: `${err.response.data.data.message}`,
				status: 'error',
			});
			setRefCode('');
			setIsAgent(false);
			setIsUrlCode(false);
			setIsRegisterLink('');
		},
	});

	const checkRefCode = useMutation({
		mutationFn: (data) => getReferralCodeApi(data),
		onSuccess: (succ) => {
			invalidateRefCodeQuery();
			setRefCodeStatus(succ.status);
		},
		onError: (err) => {
			setRefCodeStatus(err.response.data.data.message);
		},
	});

	// function
	// Get data from webview when user scan qr
	const handleOnMessage = (event) => {
		const receivedData = JSON.parse(event.data);
		setRefCode(receivedData.refCode);
		setIsAgent(receivedData.isAgent);
		setValue('referralCode', receivedData.refCode);
		setValue('registerAgent', receivedData.isAgent);
	};

	useEffect(() => {
		if (location.search !== '') {
			let param = new URLSearchParams(decodeURI(location.search));
			const refCode = param.get('referralCode');
			const isAgent = param.get('registerAgent');
			setRefCode(refCode);
			setIsAgent(isAgent);
			setIsUrlCode(true);
			setValue('referralCode', refCode);
			setValue('registerAgent', isAgent);
		}
	}, [location]);

	// Set data when user click link to register
	useEffect(() => {
		if (isRegisterLink !== '') {
			let param = new URLSearchParams(decodeURI(location.search));
			const refCode = param.get('referralCode');
			const isAgent = param.get('registerAgent');
			setRefCode(refCode);
			setIsAgent(isAgent);
			setIsUrlCode(true);
			setValue('referralCode', refCode);
			setValue('registerAgent', isAgent);
		}
	}, [isRegisterLink]);

	useEffect(() => {
		checkRefCode.mutate({ referralCode: submitedRefCode });
	}, [refCode, referralCode, submitedRefCode]);

	//detect rotation in mobile
	useEffect(() => {
		const handleOrientationChange = () => {
			setIsMobileLandscape(window.orientation === 90 || window.orientation === -90);
		};
		handleOrientationChange();
		window.addEventListener('orientationchange', handleOrientationChange);
		return () => {
			window.removeEventListener('orientationchange', handleOrientationChange);
		};
	}, []);

	// close inspect
	useEffect(() => {
		const handleKeyDown = (event) => {
			// command + option + I (ios)
			// command + shift + C (ios)
			// F12
			// ctrl + shift + C (windows)
			if (
				(event.metaKey && 'KeyI' && event.altKey) ||
				(event.metaKey && event.shiftKey && event.keyCode === 67) ||
				event.keyCode === 123 ||
				(event.keyCode === 17 && event.shiftKey && event.keyCode === 67) ||
				(event.keyCode === 16 && event.keyCode === 17 && 'KeyI')
			) {
				event.preventDefault(); // Prevent opening inspect mode
			}
		};
		window.addEventListener('keydown', handleKeyDown);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	useEffect(() => {
		document.addEventListener('message', handleOnMessage);
		return () => {
			document.removeEventListener('message', handleOnMessage);
		};
	}, []);

	//live chat
	useEffect(() => {
		if (window.smartsupp) {
			window.smartsupp(function () {
				document.getElementById('smartsupp-widget-container').style.display = 'none';
			});
		} else {
			setTimeout(() => {
				window.smartsupp(function () {
					document.getElementById('smartsupp-widget-container').style.display = 'none';
				});
			}, 3000);
		}
	}, []);

	// handle submit
	const onSubmit = () => {
		registerNewAccount.mutate({
			mobileNo: phoneNumber,
			password: password,
			firstName: username,
			userCode: userId,
			referralCode: submitedRefCode,
			maxCredit: 0,
			registerAgent: registerAgent,
		});
	};

	if (isMobileOnly && isMobileLandscape)
		return (
			<Flex
				bgColor='black'
				height='100vh'
				width='100vw'
				justifyContent='center'
				alignItems='center'
				flexDirection='column'
			>
				<ImgComp svgName='rotate' />
				<Text fontSize='3xl' color='white'>
					Please rotate your device.
				</Text>
			</Flex>
		);

	return (
		<>
			<Flex
				flexDirection='column'
				display='flex'
				alignItems='center'
				justifyContent='center'
				padding='40px'
				bgGradient='linear(to-b, blue.200, blue.100)'
				width='100%'
				minHeight='100vh'
				onContextMenu={(e) => {
					e.preventDefault();
				}}
			>
				<Flex
					alignItems='center'
					justifyContent='center'
					width='100%'
					bgColor='blue.200'
					marginBottom={[2, 2, 2, 2, 5]}
				>
					<ImgComp svgName={'logo'} width={['50px']} height={['50px']} />
					<Flex paddingLeft={2}>
						<ImgComp svgName={'panalo'} width={['150px']} height={['50px']} />
					</Flex>
				</Flex>
				<Flex justifyContent='center'>
					<Card
						padding={['20px', '30px', '40px', '40px', '40px', '40px', '40px']}
						width={[
							'90vw',
							'90vw',
							'fit-content',
							'fit-content',
							'fit-content',
							'fit-content',
							'fit-content',
						]}
						borderRadius={'8px'}
					>
						<Flex flexDirection='column'>
							<Flex
								paddingBottom={['10px', '10px', '10px', '10px', '48px', '48px', '48px']}
								alignItems='baseline'
								cursor='default'
								userSelect='none'
							>
								<Text
									fontSize={['18px', '18px', '32px', '32px', '32px', '32px', '32px']}
									fontWeight='700'
								>
									Register as {isAgent === 'true' ? 'Agent' : 'Player'}
								</Text>
							</Flex>
							<Controller
								name='mobileNumber'
								control={control}
								rules={{ required: true }}
								render={({ field: { value } }) => (
									<CustomInput
										title={'Mobile Number'}
										type='tel'
										isMobileInput
										onChange={(e) => {
											setValue('mobileNumber', `${e.target.value.replace(/[^0-9]/g, '')}`);
										}}
										placeholder='960 763 7229'
										value={value}
										maxLength={10}
										errorMessage={
											errors.mobileNumber ? (
												<ErrorText
													color='red'
													fontSize='12px'
													text={errors.mobileNumber?.message}
												/>
											) : (
												''
											)
										}
									/>
								)}
							/>
							<Controller
								name='referralCode'
								control={control}
								rules={{ required: true }}
								render={({ field: { value } }) => (
									<CustomInput
										title={'Referral Code'}
										placeholder={'AG0019VSY'}
										onChange={(e) => {
											if (refCode !== '') {
												setValue('referralCode', refCode);
											} else {
												setValue('referralCode', `${e.target.value}`);
											}
										}}
										type={'text'}
										value={refCode !== '' ? refCode : value}
										errorMessage={
											errors.referralCode ? (
												<ErrorText
													color='red'
													fontSize='12px'
													text={errors.referralCode?.message}
												/>
											) : submitedRefCode !== '' ? (
												refCodeStatus.includes('Invalid Referral Code.') === false ? (
													<ErrorText color='green' fontSize='12px' text={refCodeStatus} />
												) : (
													<ErrorText color='red' fontSize='12px' text='Invalid Referral Code.' />
												)
											) : (
												''
											)
										}
										readOnly={isUrlCode || isRegisterLink !== '' ? true : false}
										hasQrIcon={true}
										onQrClick={() => {
											if (isRegisterLink === '') {
												onOpen();
											} else {
												if (window.ReactNativeWebView) {
													window.ReactNativeWebView.postMessage(
														JSON.stringify({
															function: 'hasQrIcon',
														}),
													);
												}
												onOpen();
											}
										}}
									/>
								)}
							/>

							<Controller
								name='password'
								control={control}
								rules={{ required: true }}
								render={({ field: { value } }) => (
									<CustomInput
										isPasswordInput
										title={'Password'}
										maxLegth={16}
										onChange={(e) => {
											setValue('password', `${e.target.value.replace(/[^a-zA-Z0-9]/g, '')}`);
										}}
										value={value}
										placeholder='Abc12345'
										errorMessage={
											errors.password ? (
												<ErrorText color='red' fontSize='12px' text={errors.password?.message} />
											) : (
												''
											)
										}
									/>
								)}
							/>
							<Controller
								name='confirmPassword'
								control={control}
								rules={{ required: true }}
								render={({ field: { value } }) => (
									<CustomInput
										isPasswordInput
										title={'Confirm Password'}
										maxLegth={16}
										onChange={(e) => {
											setValue('confirmPassword', `${e.target.value.replace(/[^a-zA-Z0-9]/g, '')}`);
										}}
										value={value}
										placeholder='Abc12345'
										errorMessage={
											errors.confirmPassword ? (
												<ErrorText
													color='red'
													fontSize='12px'
													text={errors.confirmPassword?.message}
												/>
											) : (
												''
											)
										}
									/>
								)}
							/>
							<Controller
								name='username'
								control={control}
								rules={{ required: true }}
								render={({ field: { value } }) => (
									<CustomInput
										title={'First Name'}
										placeholder={'John'}
										onChange={(e) => {
											setValue('username', `${e.target.value.replace(/[^a-zA-Z0-9]/g, '')}`);
										}}
										type={'text'}
										value={value}
										errorMessage={
											errors.username ? (
												<ErrorText color='red' fontSize='12px' text={errors.username?.message} />
											) : (
												''
											)
										}
									/>
								)}
							/>
							{/* Submit button */}
							{referralCode !== '' && refCodeStatus.includes('Invalid Referral Code.') === true ? (
								<CustomButton
									btntitle='Submit'
									marginTop={[2, 2, 2, 2, 5]}
									borderColor='gray.600'
									color='gray.500'
									fontWeight='600'
									fontSize={['22px', '22px', '26px', '26px', '26px', '26px', '26px']}
									height='58px'
									width='100%'
									backgroundColor='gray.600'
									borderRadius='10px'
									isLoading={registerNewAccount.isLoading}
								/>
							) : (
								<CustomButton
									btntitle='Submit'
									onClick={handleSubmit(onSubmit)}
									marginTop={[2, 2, 2, 2, 5]}
									borderColor='green.300'
									color='#FFFFFF'
									fontWeight='600'
									fontSize={['22px', '22px', '26px', '26px', '26px', '26px', '26px']}
									height='58px'
									width='100%'
									backgroundColor='green.500'
									borderRadius='10px'
									_active={{ backgroundColor: 'green.400' }}
									isLoading={registerNewAccount.isLoading}
								/>
							)}

							<Flex alignItems='center' justifyContent='center'>
								<Text
									fontSize='13px'
									fontWeight='400'
									color='#000000'
									paddingBottom='8px'
									textAlign='center'
									textUnderlineOffset={true}
									_hover={{ textDecoration: 'underline' }}
									cursor='pointer'
									onClick={() => {
										setIsRegisterLink('');
										setTimeout(() => {
											navigate('/');
										}, 50);
									}}
								>
									Sign in
								</Text>
							</Flex>
						</Flex>
					</Card>
				</Flex>
				{getVersion.isSuccess && (
					<Flex alignItems='center' justifyContent='center' width='100%' marginTop={5}>
						<Text
							textAlign='center'
							color='gray.500'
							fontSize='14px'
							position='absolute'
							bottom={0}
						>
							Version {getVersion.data?.version}
						</Text>
					</Flex>
				)}
			</Flex>

			{/* qr scan */}
			<ScanRefCodeModal
				isOpen={isOpen}
				onClose={onClose}
				isOpenError={isOpenError}
				onCloseError={onCloseError}
				error={scanError}
				onDecode={onClose}
				onResult={(result) => {
					if (result.text.includes('register?referralCode=')) {
						let r = result.text;
						const refCode = r.split('=')[1].split('&')[0];
						const isAgent = r.split('=')[2];
						setRefCode(refCode);
						setIsAgent(isAgent);
						setIsUrlCode(true);
						setValue('referralCode', refCode);
						setValue('registerAgent', isAgent);
						setTimeout(() => {
							onClose();
						}, 300);
					} else {
						onOpenError();
						setScanError('Invalid Referral Code');
					}
				}}
				onError={(error) => {
					setScanError(error?.message);
					onClose();
					onOpenError();
				}}
			/>
		</>
	);
};

export default RegisterScreen;
