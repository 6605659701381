import {
	Box,
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Popover,
	PopoverContent,
	PopoverTrigger,
	Textarea,
	useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { ErrorText } from '../../utils/CustomText';
import ImgComp from '../../utils/ImgComp';
import { useGlobalToast } from '../../utils/ShowToast';

const ModalPasteBet = ({
	isOpen,
	onClose,
	placeholder,
	type,
	reg,
	error,
	errorMessage,
	minBetAmount,
	textAreaValue,
	setTextAreaValue,
	onBlurTextArea,
	onChangeTextArea,
	onClickCancel,
	onClickAdd,
	children,
}) => {
	const globalShowToast = useGlobalToast();
	const { onOpen: onOpenPopover, onClose: onClosePopover, isOpen: isOpenPopover } = useDisclosure();
	const handlePaste = () => {
		if (!navigator.clipboard) {
			// Clipboard API not supported
			globalShowToast({
				title: 'Clipboard API not supported on this platform.',
				status: 'error',
			});
			return null;
		}

		window.navigator.clipboard
			?.readText()
			.then((text) => {
				text = text.replace(reg, '');
				setTextAreaValue((pre) => pre + text.trim());
			})
			.catch((error) => {
				globalShowToast({
					title: 'Please allow clipboard permission.',
					status: 'error',
				});
			});
	};
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			isCentered
			closeOnOverlayClick={false}
			closeOnEsc={false}
		>
			<ModalOverlay />
			<ModalContent marginX={3}>
				<ModalHeader>
					<Flex flexDirection='row' alignItems='center' justifyContent='space-between'>
						Paste Bet {type}
						<Popover
							isOpen={isOpenPopover}
							onOpen={onOpenPopover}
							onClose={onClosePopover}
							placement='bottom-end'
							// closeOnBlur={false}
						>
							<PopoverTrigger>
								<button type='button'>
									<ImgComp svgName='question' />
								</button>
							</PopoverTrigger>
							<PopoverContent
								py={3}
								px={5}
								width='70vw'
								maxWidth='250px'
								maxHeight='190px'
								overflowY='scroll'
								scrollBehavior='smooth'
								css={{
									'&::-webkit-scrollbar': {
										display: 'none', // hide scroll bar（仅在WebKit浏览器中有效，例如Chrome和Safari）
									},
									scrollbarWidth: 'none', // hide scroll bar（仅在非WebKit浏览器中有效，例如Firefox）
								}}
							>
								{children}
							</PopoverContent>
						</Popover>
					</Flex>
				</ModalHeader>

				<ModalBody position='relative'>
					<ErrorText
						fontSize='12px'
						color='#A0A0A0'
						width={['70vw', '70vw', '44vw', '44vw', '44vw', '44vw', '44vw']}
						text={`** Minimum bet ${minBetAmount} - 99,999 PHP`}
					/>

					<Textarea
						borderColor={error ? '#FF0000' : 'gray.500'}
						value={textAreaValue}
						onChange={onChangeTextArea}
						onBlur={onBlurTextArea}
						placeholder={placeholder}
						_hover={{ borderColor: error ? '#FF0000' : 'gray.500' }}
						minHeight='80px'
						onContextMenu={(e) => {
							e.stopPropagation();
						}}
						css={{
							'&:focus': {
								boxShadow: 'none',
								borderColor: error ? '#FF0000' : '#696969',
							},
						}}
					/>

					{error ? (
						<Flex flexDirection='column'>
							{errorMessage?.map((items, index) => {
								return (
									<ErrorText
										key={index}
										fontSize='12px'
										color='red'
										width={['70vw', '70vw', '44vw', '44vw', '44vw', '44vw', '44vw']}
										text={items}
									/>
								);
							})}
						</Flex>
					) : (
						<Box height='54px' />
					)}
				</ModalBody>

				<ModalFooter style={{ paddingLeft: 12, paddingRight: 12 }}>
					<Button
						colorScheme='red'
						mr={3}
						onClick={() => {
							onClickCancel();
							onClose();
						}}
					>
						Cancel
					</Button>
					<Button
						textColor='white'
						colorScheme={textAreaValue === '' ? 'gray' : 'green'}
						mr={3}
						onClick={textAreaValue === '' ? null : onClickAdd}
					>
						Add
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default ModalPasteBet;
