import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import ImgComp from '../../utils/ImgComp';
import { currencyFormat } from '../../utils/numberFormat';

const DownlineCard = ({
	downlineName,
	creditLimit,
	balanceCredit,
	totalCommision,
	onClick,
	isAgent,
	mobileNo,
}) => {
	return (
		<Box
			backgroundColor='blue.400'
			borderColor='blue.700'
			borderWidth={1}
			// width={['100%', '100%', '95%']}

			borderRadius={6}
			marginY={3}
			onClick={onClick}
			cursor='pointer'
			padding={4}
		>
			<Flex
				justifyContent='flex-end'
				display={['none', 'none', 'none', 'flex']}
				paddingX={3}
				paddingBottom={4}
			>
				<ImgComp svgName='arrowRight' />
			</Flex>
			<Divider display={['none', 'none', 'none', 'block']} />
			<Flex flexDirection='row' alignItems='center' justifyContent='space-between' paddingY={5}>
				<Flex alignItems='center'>
					<ImgComp svgName='user' width={[10, 14]} height={[10, 14]} marginRight={3} />
					<Flex flexDirection='column'>
						<Text
							fontSize='16px'
							fontWeight='500'
							color='black'
							maxWidth={['45vw', '50vw', '150px', '180px', '165px', '230px', '250px']}
							noOfLines={1}
						>
							{downlineName}
						</Text>
						<Text fontSize='12px' fontWeight='300' color='black'>
							Mobile No: {mobileNo}
						</Text>
						<Text fontSize='12px' fontWeight='300' color='black'>
							User Type: {isAgent === true ? 'Downline Agent' : 'Player'}
						</Text>
					</Flex>
				</Flex>
				<Box display={['block', 'block', 'block', 'none']}>
					<ImgComp svgName='arrowRight' />
				</Box>
			</Flex>

			<Divider />
			<Box>
				<Flex justifyContent='space-between' marginY='10px' alignItems='center'>
					<Text fontSize='12px' fontWeight='300' color='black'>
						Balance Amount
					</Text>
					<Text fontSize='14px' fontWeight='500' color='black'>
						PHP {currencyFormat(balanceCredit)}
					</Text>
				</Flex>
				{isAgent ? (
					<Flex justifyContent='space-between' marginY='10px' alignItems='center'>
						<Text fontSize='12px' fontWeight='300' color='black'>
							Credit Limit
						</Text>
						<Text fontSize='14px' fontWeight='500' color='black'>
							PHP {currencyFormat(creditLimit)}
						</Text>
					</Flex>
				) : (
					<Box justifyContent='space-between' minHeight='30px' alignItems='center'></Box>
				)}

				{isAgent === true ? (
					<Flex justifyContent='space-between' marginY='10px' alignItems='center'>
						<Text fontSize='12px' fontWeight='300' color='black'>
							Commission
						</Text>
						<Text fontSize='14px' fontWeight='500' color='black'>
							{currencyFormat(totalCommision)} %
						</Text>
					</Flex>
				) : (
					<Box justifyContent='space-between' minHeight='30px' alignItems='center'></Box>
				)}
			</Box>
		</Box>
	);
};

export default DownlineCard;
