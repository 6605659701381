import { Button, Flex, Modal, ModalBody, ModalContent, ModalOverlay, Text } from '@chakra-ui/react';
import React from 'react';
import ImgComp from '../../utils/ImgComp';

const ConfirmCheckoutModal = ({ onClick, isOpen, onOpen, onClose }) => {
	return (
		<>
			{/* confirm checkout modal */}
			<Modal isOpen={isOpen} onClose={onClose} isCentered size='sm'>
				<ModalOverlay />
				<ModalContent marginX={3}>
					<ModalBody
						p={5}
						borderTopWidth={10}
						borderRadius={5}
						borderColor='green.500'
						position='relative'
					>
						<Flex alignItems='center'>
							<ImgComp svgName='lowBalance' width={14} height={12} />
							<Flex flexDirection='column'>
								<Text fontSize='20px' fontWeight='700'>
									Confirm to Bet?
								</Text>
							</Flex>
						</Flex>

						<Flex justifyContent='flex-end' width='100%' align='flex-end' marginY='20px'>
							<Button
								marginRight={2}
								bgColor='green.500'
								onClick={() => {
									onClick();
									onClose();
								}}
							>
								<Text color='white'>Yes</Text>
							</Button>
							<Button onClick={onClose}>
								<Text>Cancel</Text>
							</Button>
						</Flex>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default ConfirmCheckoutModal;
