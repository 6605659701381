import { CircularProgress, Flex, Text } from '@chakra-ui/react';

const CustomButton = ({
	btntitle,
	onClick,
	width,
	height,
	color,
	fontWeight,
	fontSize,
	borderColor,
	backgroundColor,
	borderRadius,
	marginTop,
	marginY,
	_active,
	display,
	marginBottom = 5,
	onKeyDown,
	cursor = 'pointer',
	isLoading = false,
}) => {
	return (
		<>
			{isLoading ? (
				<Flex
					width={width}
					backgroundColor={backgroundColor}
					borderRadius={borderRadius}
					height={height}
					borderWidth='1px'
					borderColor={borderColor}
					marginBottom={marginBottom}
					onKeyDown={onKeyDown}
					marginTop={marginTop}
					marginY={marginY}
					alignItems='center'
					justifyContent='center'
					cursor={cursor}
					color={color}
					display={display}
					userSelect='none'
					disabled={true}
				>
					<CircularProgress isIndeterminate size='30px' color='blue.600' />
				</Flex>
			) : (
				<Flex
					width={width}
					backgroundColor={backgroundColor}
					borderRadius={borderRadius}
					height={height}
					borderWidth='1px'
					borderColor={borderColor}
					marginBottom={marginBottom}
					onClick={onClick}
					onKeyDown={onKeyDown}
					marginTop={marginTop}
					marginY={marginY}
					alignItems='center'
					justifyContent='center'
					_active={_active}
					cursor={cursor}
					color={color}
					display={display}
					userSelect='none'
				>
					<Text fontSize={fontSize} userSelect='none' fontWeight={fontWeight}>
						{btntitle}
					</Text>
				</Flex>
			)}
		</>
	);
};

export default CustomButton;
