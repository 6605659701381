//import liraries
import { Box } from '@chakra-ui/react';
import React from 'react';

// create a component
const UiBlock = () => {
	return (
		<Box
			width='100vw'
			height='100vh'
			backgroundColor='rgba(0,0,0,0.5)'
			zIndex={999}
			position='absolute'
			top={0}
			left={0}
		/>
	);
};

export default UiBlock;
