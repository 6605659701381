import axios from 'axios';

// testing
export const serverAxiosParams = {
	baseURL: process.env.REACT_APP_API_BASE_URL,
};

// Create axios instance with default params
export const severAxiosInstance = axios.create(serverAxiosParams);

severAxiosInstance.interceptors.request.use(
	(config) => {
		const accessToken = sessionStorage.getItem('accessToken');
		if (accessToken) {
			config.headers.Authorization = `Bearer ${accessToken}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);
severAxiosInstance.interceptors.response.use(
	(response) => response,
	async (error) => {
		if (error?.response?.data?.code === '4001' || error?.response?.data?.data?.status === 401) {
			if (sessionStorage.getItem('isThirdPartyLogin') === 'true') {
				window.location.href = `/logout`;
			} else {
				localStorage.clear();
				sessionStorage.clear();
				window.location.href = `/`;
			}
		}
		// Pass the error to the component
		return Promise.reject(error);
	},
);
// Main api function
const api = (axiosInstance) => {
	return {
		get: (url, config) => axiosInstance.get(url, config),
		delete: (url, config) => axiosInstance.delete(url, config),
		post: (url, body, config) => axiosInstance.post(url, body, config),
		put: (url, body, config) => axiosInstance.put(url, body, config),
		patch: (url, body, config) => axiosInstance.patch(url, body, config),
	};
};

// export const localApi = api(localAxiosInstance);
export const serverApi = api(severAxiosInstance);
