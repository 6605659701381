import {
	CircularProgress,
	Divider,
	Flex,
	Input,
	InputGroup,
	InputRightElement,
	Modal,
	ModalBody,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Select,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useOutletContext } from 'react-router-dom';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getDrawByDateApi, getSiteApi } from '../../api/query/betApi';
import { getExportDataApi } from '../../api/query/reportApi';
import ImgComp from '../../utils/ImgComp';

const DownloadReportModal = ({ isOpen, onClose }) => {
	// state
	const [type, setType] = useState('Number Sales Report');
	const [date, setDate, maxDate] = useOutletContext();
	const [siteId, setSiteId] = useState(1);
	const [gameId, setGameId] = useState(1);
	const [drawTimeId, setDrawTimeId] = useState();
	const [disable, setDisable] = useState(false);
	const [error, setError] = useState();
	const reportType = ['Number Sales Report', 'Overall Sales Report'];

	// modal
	const { isOpen: isOpenError, onOpen: onOpenError, onClose: onCloseError } = useDisclosure();

	//* api
	const getSiteType = useQuery(['getSiteApi'], getSiteApi);
	const getDrawByDate = useQuery(['getDrawByDateApi', date, gameId], () =>
		getDrawByDateApi({ date: dayjs(date).format('YYYY-MM-DD HH:mm:ss'), gameId: gameId }),
	);

	const getExportData = useMutation({
		mutationFn: () =>
			getExportDataApi({
				type: type,
				drawDate: dayjs(date).format('YYYY-MM-DD HH:mm:ss'),
				siteId: siteId,
				drawId: drawTimeId,
			}),
		onSuccess: (succ) => {
			const fileUrl = succ.url;
			const fileName = succ.filename;
			downloadFile(fileUrl, fileName);
		},
		onError: (err) => {
			setError(err.response.data.data.message);
			onOpenError();
		},
	});

	// function
	const downloadFile = (url, filename) => {
		const anchor = document.createElement('a');
		anchor.href = url;
		anchor.download = filename;
		anchor.click();
		setTimeout(() => {
			setDisable(false);
		}, 3000);
	};

	// useEffect
	useEffect(() => {
		setDisable(true);
		getDrawByDate.refetch().then(() => {
			setDrawTimeId(getDrawByDate.data[0].id);
			setDisable(false);
		});
	}, [gameId, getDrawByDate.isSuccess, date]);

	return (
		<>
			{isOpen && (
				<Modal
					isOpen={isOpen}
					onClose={onClose}
					isCentered
					closeOnOverlayClick={false}
					size={['sm', 'sm', 'md', 'lg', 'xl']}
				>
					<ModalOverlay />
					<ModalContent marginX={3}>
						<ModalHeader>
							<Flex justifyContent='space-between'>
								<Text>Download Reports</Text>
								<Flex
									cursor='pointer'
									alignItems='center'
									justifyContent='center'
									paddingY={2}
									paddingX={3}
									onClick={() => {
										onClose();
										setType('Number Sales Report');
										setDate(new Date());
										setSiteId(1);
										setGameId(1);
										setDrawTimeId(getDrawByDate.data[0]?.id);
										setError('');
									}}
								>
									<ImgComp svgName='close-icon' />
								</Flex>
							</Flex>
						</ModalHeader>
						<ModalBody>
							<Flex flexDirection='column'>
								<Flex flexDirection='column' marginY='2'>
									<Text color='#000000' marginBottom={1}>
										Report Type
									</Text>
									<Select
										value={type}
										onChange={(e) => {
											setType(e.target.value);
										}}
										backgroundColor='blue.150'
										userSelect='none'
										cursor='pointer'
									>
										{reportType &&
											reportType?.map((item, index) => (
												<option key={index} value={item}>
													{item}
												</option>
											))}
									</Select>
								</Flex>

								<Flex flexDirection='column' marginY='2'>
									<Text color='#000000' marginBottom={1}>
										Draw Date
									</Text>
									<DatePicker
										selected={new Date(date)}
										maxDate={new Date(maxDate)}
										onChange={(date) => {
											setDate(date);
										}}
										minDate={new Date('2023/04/01')}
										userSelect='none'
										cursor='pointer'
										backgroundColor='blue.150'
										alignItems='flex-end'
										customInput={
											<InputGroup
												userSelect='none'
												cursor='pointer'
												backgroundColor='blue.150'
												borderRadius={8}
											>
												<Input
													id='calender-input'
													_placeholder={{ color: '#000000' }}
													placeholder={dayjs(date).format('DD/MM/YYYY')}
													userSelect='none'
													cursor='pointer'
													readOnly={true}
												/>
												<InputRightElement children={<ImgComp svgName={'calender-icon'} />} />
											</InputGroup>
										}
									/>
								</Flex>

								{type === 'Number Sales Report' ? (
									<>
										{getSiteType.isLoading ? (
											<Flex flexDirection='column' marginY='2'>
												<Text color='#000000' marginBottom={1}>
													Draw Time
												</Text>
												<Flex
													borderRadius='8px'
													borderWidth='1px'
													borderColor='gray.200'
													justifyContent='flex-start'
													alignItems='center'
													backgroundColor='blue.150'
													height='40px'
													paddingLeft='20px'
												>
													<CircularProgress size='30px' isIndeterminate color='blue.600' />
												</Flex>
											</Flex>
										) : (
											<Flex flexDirection='column' marginY='2'>
												<Text color='#000000' marginBottom={1}>
													Site Type
												</Text>
												<Select
													value={siteId}
													onChange={(e) => {
														if (e.target.value === '2') {
															setSiteId(2);
															setGameId(3);
														} else {
															setSiteId(1);
															setGameId(1);
														}
													}}
													backgroundColor='blue.150'
													userSelect='none'
													cursor='pointer'
												>
													{getSiteType.isSuccess &&
														getSiteType.data?.map((item) => (
															<option key={item.id} value={item.id}>
																{item.value}
															</option>
														))}
												</Select>
											</Flex>
										)}

										{getDrawByDate.isLoading ? (
											<Flex flexDirection='column' marginY='2'>
												<Text color='#000000' marginBottom={1}>
													Draw Time
												</Text>
												<Flex
													borderRadius='8px'
													borderWidth='1px'
													borderColor='gray.200'
													justifyContent='flex-start'
													alignItems='center'
													backgroundColor='blue.150'
													height='40px'
													paddingLeft='20px'
												>
													<CircularProgress size='30px' isIndeterminate color='blue.600' />
												</Flex>
											</Flex>
										) : (
											<Flex flexDirection='column' marginY='2'>
												<Text color='#000000' marginBottom={1}>
													Draw Time
												</Text>

												{getDrawByDate.isSuccess && (
													<>
														<Select
															value={drawTimeId}
															onChange={(e) => {
																setDrawTimeId(Number(e.target.value));
															}}
															backgroundColor='blue.150'
															userSelect='none'
															cursor='pointer'
														>
															{getDrawByDate.data?.map((item) => (
																<option key={item.id} value={item.id}>
																	{item.name}
																</option>
															))}
														</Select>
														{getDrawByDate.data.length < 1 && (
															<Text fontSize={['12px', '12px', '12px', '16']} color='red.500'>
																** No draw found on this date.
															</Text>
														)}
													</>
												)}
											</Flex>
										)}
									</>
								) : (
									<Flex height='170px'></Flex>
								)}
							</Flex>
							<Flex
								flexDirection='row'
								width='100%'
								justifyContent='space-between'
								alignContent='flex-end'
								marginY={5}
							>
								<Flex
									height={['36px', '', '', '', '42px']}
									width='49%'
									justifyContent='center'
									alignItems='center'
									backgroundColor='blue.100'
									borderRadius={8}
									borderColor='#000000'
									borderWidth={1}
									userSelect='none'
									cursor='pointer'
									_hover={{ backgroundColor: 'gray.50' }}
									onClick={() => {
										if (!getDrawByDate.isLoading && !getSiteType.isLoading) {
											setType('Number Sales Report');
											setDate(new Date());
											setSiteId(1);
											setGameId(1);
										}
									}}
								>
									<ImgComp
										svgName={'reset'}
										width={['14px', '', '', '', '20px']}
										height={['14px', '', '', '', '20px']}
									/>
									<Text
										fontWeight='500'
										marginLeft={1}
										fontSize={['14px', '', '', '', '18px']}
										color='#000000'
									>
										Reset
									</Text>
								</Flex>

								{!disable && !getDrawByDate.isLoading && !getSiteType.isLoading ? (
									<Flex
										height={['36px', '', '', '', '42px']}
										width='49%'
										justifyContent='center'
										alignItems='center'
										backgroundColor='green.100'
										borderRadius={8}
										borderColor='green.100'
										borderWidth={1}
										userSelect='none'
										cursor='pointer'
										_hover={{ backgroundColor: 'green.200' }}
										onClick={() => {
											getExportData.mutate();
										}}
									>
										<ImgComp
											svgName={'greenDownload'}
											width={['18px', '', '', '', '28px']}
											height={['18px', '', '', '', '28px']}
										/>
										<Text
											fontWeight='500'
											marginLeft={1}
											fontSize={['14px', '', '', '', '18px']}
											color='green.500'
										>
											Download
										</Text>
									</Flex>
								) : (
									<Flex
										height={['36px', '', '', '', '42px']}
										width='49%'
										justifyContent='center'
										alignItems='center'
										backgroundColor='gray.600'
										borderRadius={8}
										borderColor='gray.600'
										borderWidth={1}
										userSelect='none'
										cursor='pointer'
									>
										<ImgComp
											svgName={'download'}
											width={['18px', '', '', '', '28px']}
											height={['18px', '', '', '', '28px']}
										/>
										<Text
											fontWeight='500'
											marginLeft={1}
											fontSize={['14px', '', '', '', '18px']}
											color='#000000'
										>
											Download
										</Text>
									</Flex>
								)}
							</Flex>
						</ModalBody>
					</ModalContent>
				</Modal>
			)}

			{isOpenError && error && (
				<Modal
					isOpen={isOpenError}
					onClose={() => {
						onCloseError();
						setError(null);
					}}
					isCentered
					closeOnOverlayClick={false}
					size={['sm', 'sm', 'md', 'lg', 'xl']}
				>
					<ModalOverlay />
					<ModalContent marginX={3}>
						<ModalHeader>
							<Flex
								cursor='pointer'
								alignItems='center'
								justifyContent='flex-end'
								// paddingY={2}
								paddingX={3}
								onClick={() => {
									onClose();
									onCloseError();
									setType('Number Sales Report');
									setDate(new Date());
									setSiteId(1);
									setGameId(1);
									setDrawTimeId(getDrawByDate.data[0]?.id);
								}}
							>
								<ImgComp svgName='close-icon' />
							</Flex>
						</ModalHeader>
						<ModalBody>
							<Flex alignItems='center' marginY='10px'>
								<ImgComp svgName={'redCross'} />
								<Flex flexDirection='column' marginX='10px'>
									<Text fontSize='20px' fontWeight='700'>
										{error}
									</Text>
								</Flex>
							</Flex>
							<Divider borderColor='gray.600' borderWidth={1} />
							<Flex justifyContent='flex-end' marginTop={2}>
								<Flex
									backgroundColor={'red.100'}
									borderRadius={8}
									padding='8px 10px'
									cursor='pointer'
									alignItems='center'
									onClick={() => {
										onCloseError();
										setError('');
									}}
									marginY='10px'
								>
									<Text color={'red.500'} fontWeight='600' fontSize='16px' userSelect='none'>
										OK
									</Text>
								</Flex>
							</Flex>
						</ModalBody>
					</ModalContent>
				</Modal>
			)}
		</>
	);
};

export default DownloadReportModal;
