import { serverApi } from './api';

const API = '/api/profile';

export const getCreditRemainApi = () =>
	serverApi.get(`${API}/creditremain`).then((res) => res.data.data);

export const putProfileChangePassword = (body) =>
	serverApi.put(`${API}/changepass`, body).then((res) => res.data.data);

export const putProfileUpdateDownlineApi = (body) =>
	serverApi.put(`${API}/updatedownline`, body).then((res) => res.data.data);

export const putProfileChangeDownlinePassApi = (body) =>
	serverApi.put(`${API}/changedownlinepass`, body).then((res) => res.data.data);

export const getProfileUserpackageApi = (config) =>
	serverApi.get(`${API}/userpackage`, config).then((res) => res.data.data);

export const getUserProfileApi = ({ userId, config }) =>
	serverApi.get(`${API}/userProfile?userId=${userId}`, config).then((res) => res.data.data);

export const getProfileReferralCodeApi = ({ isAgent, config }) =>
	serverApi.get(`${API}/getReferralCode?isAgent=${isAgent}`, config).then((res) => res.data.data);

export const getProfileCreditTransactionApi = ({
	userId,
	pageParam,
	pageSize,
	transactionType,
	date,
}) =>
	serverApi
		.get(
			`${API}/creditTransaction?date=${date}&userId=${userId}&pageNum=${pageParam}&pageSize=${pageSize}&transactionType=${transactionType}`,
		)
		.then((res) => res.data.data);

export const getProfileUserProfileApi = (userId) =>
	serverApi.get(`${API}/userProfile?userId=${userId}`).then((res) => res.data.data);

export const putProfileTopUpCreditApi = (body) =>
	serverApi.put(`${API}/topUpCredit`, body).then((res) => res.data.data);

export const putCreateConversationApi = (id) =>
	serverApi.put(`${API}/createConversation?conversationId=${id}`);

export const getLiveChatAccessApi = () =>
	serverApi.get(`${API}/hasLiveChatAccess`).then((res) => res.data.data);

