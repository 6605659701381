import {
	Box,
	CircularProgress,
	Divider,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';

import dayjs from 'dayjs';
import React, { useState } from 'react';
import { putCreditWithdrawalUpdateApi } from '../../api/query/betApi';
import ImgComp from '../../utils/ImgComp';

const WithdrawallQrDataModal = ({
	headerTitle,
	isOpen,
	onClose,
	data,
	hasError,
	setHasError,
	isLoading,
	errMsg,
	setInputValue,
	latitude,
	longitude,
}) => {
	// state
	const [step, setStep] = useState(1);
	const [apiMsg, setApiMsg] = useState();

	//* api
	const putCreditWithdrawalUpdate = useMutation({
		mutationFn: (body) => putCreditWithdrawalUpdateApi(body),
		onSuccess: (succ) => {
			setApiMsg(succ);
			setStep(5);
		},
		onError: (err) => {
			setApiMsg(err.response.data.data);
			setStep(5);
		},
	});

	// function
	const handleClearInput = () => {
		const inputElement = document.getElementById('search-input');
		setInputValue('');
		inputElement.value = '';
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={() => {
				onClose();
				setStep(1);
				handleClearInput();
			}}
			isCentered
			closeOnOverlayClick={false}
			size={['sm', 'sm', 'md', 'lg', 'xl']}
		>
			<ModalOverlay />
			<ModalContent
				marginX={3}
				borderTopWidth={(() => {
					if (hasError || step === 4) {
						return 10;
					} else {
						return 0;
					}
				})()}
				borderRadius={5}
				borderColor={(() => {
					if (hasError || apiMsg?.status === 'Fail') {
						return 'red.500';
					} else if (apiMsg?.status === 'Success') {
						return 'green.500';
					} else {
						return 'white';
					}
				})()}
			>
				{!hasError && !isLoading && (
					<>
						{step === 5 ? null : (
							<ModalHeader borderBottomWidth={step === 1 ? '0px' : '1px'}>
								{headerTitle}
							</ModalHeader>
						)}
						{(step === 1 || step === 2 || step === 3) && <ModalCloseButton />}
					</>
				)}

				<ModalBody>
					{(() => {
						if (!hasError && !isLoading) {
							return (
								<>
									{step === 1 && (
										<>
											<Flex
												flexDirection='column'
												marginBottom={data?.status === 'Pending' ? 0 : 5}
												borderWidth={1}
												padding={2}
												borderRadius={8}
												backgroundColor='#F9FBFF'
											>
												<ResultData
													title='Request ID'
													data={data?.requestId}
													colorData={'blue.500'}
												/>
												<ResultData
													title='Requested Date'
													data={dayjs(data?.requestTime).format('YYYY/MM/DD hh:mm A')}
												/>
												<ResultData title='Requested By' data={data?.requestedByUsername} />
												<ResultData
													title='Requested Amount(PHP)'
													data={data?.requestCreditAmount}
													colorData={'green.500'}
												/>
												{data?.status === 'Pending' ? null : (
													<>
														<ResultData title='Processed By' data={data?.processedByUsername} />
														<ResultData
															title='Processed Date'
															data={dayjs(data?.processedTime).format('YYYY/MM/DD hh:mm A')}
														/>
													</>
												)}
												<ResultData
													title='Status'
													paddingBottom={0}
													data={data?.status}
													colorData={
														data?.status === 'Rejected'
															? 'red.400'
															: data?.status === 'Approved'
															? 'green.500'
															: 'orange.500'
													}
												/>
											</Flex>
											{data?.status === 'Pending' && (
												<Flex marginTop={3} gap={3} width={'100%'}>
													<Box
														as='button'
														width={'50%'}
														fontWeight={600}
														bgColor={'#FCE5E6'}
														color={'#E52A2A'}
														borderRadius={'8px'}
														height={'40px'}
														onClick={() => {
															setStep(2);
														}}
													>
														Reject
													</Box>
													<Box
														as='button'
														width={'50%'}
														fontWeight={600}
														bgColor={'#CFF1D7'}
														color={'#22943B'}
														borderRadius={'8px'}
														height={'40px'}
														onClick={() => {
															setStep(3);
														}}
													>
														Approve
													</Box>
												</Flex>
											)}
										</>
									)}

									{step === 2 && (
										<Flex flexDirection='column' alignItems='center'>
											<Flex flexDirection={['column', 'row']} alignItems='center'>
												<Text marginRight={1}>Are you confirm to </Text>
												<Flex flexDirection='row'>
													<Text
														fontWeight='700'
														marginRight={1}
														fontSize={['14px', '16px', '16px', '18px']}
														color='red.400'
													>
														Reject
													</Text>
													<Text>?</Text>
												</Flex>
											</Flex>
											<Flex marginTop={3} gap={3} width={'100%'}>
												<Box
													as='button'
													width={'50%'}
													fontWeight={600}
													bgColor={'#FCE5E6'}
													color={'#E52A2A'}
													borderRadius={'8px'}
													height={'40px'}
													onClick={() => {
														setStep(1);
													}}
												>
													Cancel
												</Box>
												<Box
													as='button'
													width={'50%'}
													fontWeight={600}
													bgColor={'#CFF1D7'}
													color={'#22943B'}
													borderRadius={'8px'}
													height={'40px'}
													onClick={() => {
														setStep(4);
														setApiMsg(null);
														putCreditWithdrawalUpdate.mutate({
															reqNo: data?.requestId,
															refStatusCode: 'REJECTED',
															latitude: latitude,
															longitude: longitude,
														});
													}}
												>
													Confirm
												</Box>
											</Flex>
										</Flex>
									)}

									{step === 3 && (
										<Flex flexDirection='column' alignItems='center'>
											<Flex flexDirection={['column', 'row']} alignItems='center'>
												<Text marginRight={1}>Are you confirm to </Text>
												<Flex flexDirection='row'>
													<Text
														fontWeight='700'
														marginRight={1}
														fontSize={['14px', '16px', '16px', '18px']}
														color='green.500'
													>
														Approved
													</Text>
													<Text>?</Text>
												</Flex>
											</Flex>
											<Flex marginTop={3} gap={3} width={'100%'}>
												<Box
													as='button'
													width={'50%'}
													fontWeight={600}
													bgColor={'#FCE5E6'}
													color={'#E52A2A'}
													borderRadius={'8px'}
													height={'40px'}
													onClick={() => {
														// handleClearInput();
														setStep(1);
														// onClose();
													}}
												>
													Cancel
												</Box>
												<Box
													as='button'
													width={'50%'}
													fontWeight={600}
													bgColor={'#CFF1D7'}
													color={'#22943B'}
													borderRadius={'8px'}
													height={'40px'}
													onClick={() => {
														setApiMsg(null);
														setStep(4);
														putCreditWithdrawalUpdate.mutate({
															reqNo: data?.requestId,
															refStatusCode: 'APPROVED',
															latitude: latitude,
															longitude: longitude,
														});
													}}
												>
													Confirm
												</Box>
											</Flex>
										</Flex>
									)}
									{step === 4 && (
										<Flex justifyContent='center' alignItems='center' height='150px'>
											<CircularProgress size='50px' isIndeterminate color='blue.600' />
										</Flex>
									)}
									{step === 5 && (
										<Box paddingY={5}>
											<Flex alignItems='center'>
												{apiMsg?.status === 'Fail' && (
													<ImgComp svgName='redCross' alt='redCross' height={16} />
												)}
												{apiMsg?.status === 'Success' && (
													<ImgComp svgName='greenTick' alt='greenTick' height={16} />
												)}

												<Text marginLeft={2} fontSize='18px' fontWeight='600'>
													{apiMsg?.message}
												</Text>
											</Flex>
											<Divider borderColor='gray.600' borderWidth={1} marginY={3} />
											<Flex justifyContent='flex-end'>
												<Flex
													cursor='pointer'
													justifyContent='center'
													alignItems='center'
													width={'30%'}
													fontWeight={600}
													bgColor={apiMsg?.status === 'Fail' ? 'red.100' : 'green.100'}
													color={apiMsg?.status === 'Fail' ? 'red.400' : 'green.500'}
													borderRadius={'8px'}
													height={'40px'}
													onClick={() => {
														setStep(1);
														onClose();
														handleClearInput();
														setApiMsg(null);
													}}
												>
													OK
												</Flex>
											</Flex>
										</Box>
									)}
								</>
							);
						} else if (hasError) {
							return (
								<Flex flexDirection='column' width='100%'>
									<Flex marginY='10px'>
										{hasError !== '' ? <ImgComp svgName={'redCross'} /> : null}
										<Flex flexDirection='column' marginX='10px' justifyContent='center'>
											<Text fontSize='20px' fontWeight='700'>
												{errMsg}
											</Text>
											{errMsg === 'Camera Permission denied' ? (
												<Text fontSize='16px' fontWeight='500'>
													Please allow camera permission in browser and refresh page to scan ticket.
												</Text>
											) : null}
										</Flex>
									</Flex>
									<Divider borderColor='gray.600' borderWidth={1} />
									<Flex justifyContent='flex-end' marginTop={2}>
										<Flex
											backgroundColor={'red.100'}
											borderRadius={8}
											padding='8px 10px'
											cursor='pointer'
											alignItems='center'
											onClick={() => {
												onClose();
												handleClearInput();
												setHasError(false);
											}}
											marginY='10px'
										>
											<Text
												// paddingLeft={1}
												color={'red.500'}
												fontWeight='600'
												fontSize='16px'
												userSelect='none'
											>
												OK
											</Text>
										</Flex>
									</Flex>
								</Flex>
							);
						} else if (isLoading) {
							return (
								<Flex justifyContent='center' alignItems='center' height='150px'>
									<CircularProgress size='50px' isIndeterminate color='blue.600' />
								</Flex>
							);
						}
					})()}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default WithdrawallQrDataModal;

const ResultData = ({ title, data, colorData, paddingBottom = 3 }) => {
	return (
		<Flex
			flexDirection={['column', 'column', 'row']}
			paddingBottom={paddingBottom}
			justifyContent='space-between'
		>
			<Text fontSize={['14px', '14px', '16px', '16px']} fontWeight='500'>
				{title}
			</Text>
			<Text
				fontSize={['14px', '14px', '16px', '16px']}
				fontWeight='500'
				color={colorData}
			>
				{data}
			</Text>
		</Flex>
	);
};
