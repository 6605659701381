import { Box, Flex, Select, Text, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { Fragment, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { getUserProfileApi } from '../api/query/profileApi';
import { getAgentReportApi } from '../api/query/reportApi';
import { getSiteGameNameApi } from '../api/query/setupApi';
import DownloadReportModal from '../components/report/DownloadReport';
import ReportCard from '../components/report/ReportCard';
import { DesktopTitleHeader, MobileTitleHeader2 } from '../components/titleHeader/TitleHeader';
import { useCheckingAgentStore } from '../hooks/useStore';
import ImgComp from '../utils/ImgComp';
import Loading from '../utils/Loading';

const ReportScreen = () => {
	const navigate = useNavigate();
	const [isSmallerThan1024] = useMediaQuery('(max-width: 1023px)');

	// outlet
	const [date, setDate, maxDate] = useOutletContext();
	// zustand
	const checkAgent = useCheckingAgentStore((state) => state.checkAgent);
	const setCheckAgent = useCheckingAgentStore((state) => state.setCheckAgent);
	// state
	const [siteGameId, setSiteGameId] = useState([0, 0]);
	const userId = sessionStorage.getItem('userId');

	// modal
	const { isOpen, onOpen, onClose } = useDisclosure();
	//* api
	const getUserProfile = useQuery([`getUserProfileApi`, userId], () =>
		getUserProfileApi({ userId: userId }),
	);
	const getSiteGameName = useQuery(['getSiteGameNameApi'], getSiteGameNameApi);
	const getReport = useQuery(['getReportApi', date, userId, siteGameId], () =>
		getAgentReportApi({
			date: dayjs(date).format('YYYY-MM-DD hh:mm:ss'),
			userId: userId,
			siteId: siteGameId[0],
			gameId: siteGameId[1],
		}),
	);

	useEffect(() => {
		if (sessionStorage.getItem('isThirdPartyLogin') === 'true') {
			navigate('/pcso');
		}
	}, []);

	return (
		<>
			<Box
				width='100%'
				paddingX={['3', '3', '5', '5', '10']}
				paddingTop={['80px', '80px', '80px', '80px', '30px', '30px', '30px']}
				minHeight={['100vh', '100vh', '100vh', '100vh', 'calc(100vh - 150px)']}
			>
				{isSmallerThan1024 ? (
					<MobileTitleHeader2
						title={'Total Sales Report'}
						selected={new Date(date)}
						maxDate={new Date(new Date(maxDate).setDate(new Date(maxDate).getDate() + 1))}
						onChangeDate={(date) => setDate(date)}
						hasCalendar={true}
						hasDownload={true}
						onDownloadClick={onOpen}
					/>
				) : (
					<DesktopTitleHeader
						title={'Total Sales Report'}
						selected={new Date(date)}
						maxDate={new Date(new Date(maxDate).setDate(new Date(maxDate).getDate() + 1))}
						onChangeDate={(date) => setDate(date)}
						hasCalendar={true}
						hasDownload={true}
						onDownloadClick={onOpen}
					/>
				)}

				<Flex flexDirection='column' marginY='5'>
					<Box backgroundColor='white'>
						<Select
							value={siteGameId}
							onChange={(e) => {
								setSiteGameId(e.target.value.split(','));
							}}
						>
							{getSiteGameName.isSuccess &&
								getSiteGameName.data?.map((item, index) => (
									<option key={index} value={[item.siteId, item.gameId]}>
										{item.name}
									</option>
								))}
						</Select>
					</Box>

					{getReport.isLoading ? (
						<Loading />
					) : getReport.isSuccess ? (
						getReport.data?.map((item, index) => {
							return (
								<Fragment key={index}>
									<ReportCard
										date={date}
										mobileNo={item.agentSalesSummaryReportDto.mobileNumber}
										svgName={String(item.gameName).toLowerCase()}
										totalClaim={item.agentSalesSummaryReportDto.totalClaim}
										totalClaimAmt={item.agentSalesSummaryReportDto.totalClaimAmt}
										totalSales={item.agentSalesSummaryReportDto.totalSales}
										totalSalesAmt={item.agentSalesSummaryReportDto.totalSalesAmt}
										totalCommision={item.agentSalesSummaryReportDto.totalCommision}
										totalCommisionAmt={item.agentSalesSummaryReportDto.totalCommisionAmt}
										net={item.agentSalesSummaryReportDto.net}
										checkAgent={checkAgent}
										isAgent={getUserProfile.data?.isAgent}
									/>
									{siteGameId[0] == 0 ? (
										<Flex height='20px'>
											<Text fontSize={['12px', '12px', '12px', '16']} color='red.500'>
												** The payout amount is inclusive of approved credit withdrawal amount.
											</Text>
										</Flex>
									) : (
										<Flex height='20px'></Flex>
									)}
									<Flex marginY={5} flexDirection='column'>
										<Flex
											onClick={() => {
												navigate('/reportAgent');
												setCheckAgent(true);
											}}
											justifyContent='space-between'
											alignItems='center'
											borderWidth='1px'
											borderRadius='8px'
											backgroundColor='white'
											padding='3px 10px'
											marginBottom={2}
											cursor='pointer'
											userSelect='none'
										>
											<Text fontSize={['14px', '', '', '18px']} fontWeight={['500', '', '', '600']}>
												Agent Report
											</Text>
											<ImgComp svgName='right-arrow' />
										</Flex>

										{getUserProfile.data.referralCode !== '' && (
											<Flex
												onClick={() => {
													navigate('/reportDownlineList');
													setCheckAgent(true);
												}}
												cursor='pointer'
												userSelect='none'
												justifyContent='space-between'
												alignItems='center'
												borderWidth='1px'
												borderRadius='8px'
												backgroundColor='white'
												marginBottom={2}
												padding='3px 10px'
											>
												<Text
													fontSize={['14px', '', '', '18px']}
													fontWeight={['500', '', '', '600']}
												>
													Downline Reports
												</Text>
												<ImgComp svgName='right-arrow' />
											</Flex>
										)}
									</Flex>
								</Fragment>
							);
						})
					) : (
						<Text>No report</Text>
					)}
				</Flex>
			</Box>

			<DownloadReportModal isOpen={isOpen} onClose={onClose} />
		</>
	);
};

export default ReportScreen;
