import { Box, Flex, Input, Text, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Controller } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { useBettingScreen } from '../../hooks/useStore';
import ButtonComp from '../../utils/ButtonComp';
import { useGlobalToast } from '../../utils/ShowToast';
import { currencyFormat } from '../../utils/numberFormat';
import ModalPasteBet from './ModalPasteBet';
import NumericKeyboard from './NumericKeyboard';
import { PasteBetErrorEz2 } from '../../utils/PasteBetErrorEz2';

const Ez2Keyboard = ({
	Ez2Form,
	Ez2Value,
	type,
	time,
	gameId,
	minBetAmount,
	handleSubmit,
	list,
	totalAmount,
}) => {
	const globalShowToast = useGlobalToast();
	const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');
	const { isOpen, onOpen, onClose } = useDisclosure(); // textarea modal
	// zustand
	const setBetting = useBettingScreen((state) => state.setBetting);

	// ref
	const input0Ref = useRef();
	const input1Ref = useRef();
	const inputRamble = useRef();
	const inputStraight = useRef();

	// state
	const [activeIndex, setActiveIndex] = useState(0);
	const [textAreaError, setTextAreaError] = useState(false); // textarea error
	const [textAreaValue, setTextAreaValue] = useState(''); //textarea value
	const [errorMessage, setErrorMessage] = useState([]); // handle textarea error messages
	const textAreaValueSplit = textAreaValue.split('\n'); //split textarea value
	const randomnumber0 = Math.floor(Math.random() * 31) + 1;
	const randomnumber1 = Math.floor(Math.random() * 31) + 1;

	const [numberArray] = Ez2Value;

	const sameDigitChecking =
		numberArray[0].digit !== '' &&
		numberArray[1].digit !== '' &&
		(`0${numberArray[0].digit}` === numberArray[1].digit ||
			numberArray[0].digit === `0${numberArray[1].digit}` ||
			numberArray[0].digit === numberArray[1].digit);

	// function
	// keyboard 0-9 button
	const handleKeyPress = (value) => {
		if (time !== '') {
			let newInputs = [...numberArray];
			if (time !== '') {
				if (activeIndex < 2) {
					newInputs[activeIndex].digit = (numberArray[activeIndex].digit + value).slice(0, 2);
				} else {
					newInputs[activeIndex].digit = (numberArray[activeIndex].digit + value).slice(0, 5); // Limit input to five characters
				}

				if (activeIndex === 0) {
					if (newInputs[0].digit?.length > 1) {
						setTimeout(() => {
							input1Ref.current.focus();
							setActiveIndex(1);
						}, 50);
					}
				}

				if (activeIndex === 1) {
					if (newInputs[1].digit?.length > 1 && newInputs[2].digit === '') {
						setTimeout(() => {
							inputStraight.current.focus();
							setActiveIndex(2);
						}, 50);
					}
				}

				if (activeIndex === 2) {
					if (newInputs[2].digit?.length > 4) {
						if (
							newInputs[0].digit !== '' &&
							newInputs[1].digit !== '' &&
							(`0${newInputs[0].digit}` === newInputs[1].digit ||
								newInputs[0].digit === `0${newInputs[1].digit}` ||
								newInputs[0].digit === newInputs[1].digit)
						) {
						} else {
							setTimeout(() => {
								inputRamble.current.focus();
								setActiveIndex(3);
							}, 50);
						}
					}
				}

				Ez2Form.setValue(`numberArray[${activeIndex}].digit`, `${newInputs[activeIndex].digit}`);
			}
		}
	};
	// keyboard backspace button
	const handleBackspace = () => {
		if (time !== '') {
			let newInputs = [...numberArray];
			if (activeIndex === 3) {
				newInputs[3].digit = newInputs[3].digit.slice(0, -1);
				if (numberArray[activeIndex].digit.length === 0) {
					setActiveIndex(2);
				}
			} else if (activeIndex === 2) {
				newInputs[2].digit = newInputs[2].digit.slice(0, -1);
				if (numberArray[activeIndex].digit.length === 0) {
					setActiveIndex(1);
				}
			} else if (activeIndex === 1) {
				newInputs[1].digit = newInputs[1].digit.slice(0, -1);
				if (numberArray[activeIndex].digit.length === 0) {
					setActiveIndex(0);
					Ez2Form.setValue(`numberArray[1].lp`, false);
				}
			} else if (activeIndex === 0) {
				newInputs[0].digit = newInputs[0].digit.slice(0, -1);
				if (numberArray[activeIndex].digit.length === 0) {
					setActiveIndex(0);
					Ez2Form.setValue(`numberArray[0].lp`, false);
				}
			}
			Ez2Form.setValue(`numberArray[${activeIndex}].digit`, `${newInputs[activeIndex].digit}`);
		}
	};
	// 3D luckypick button function
	const handleLuckypick2D = () => {
		if (time !== '') {
			if (randomnumber0 < 10) {
				Ez2Form.setValue('numberArray[0].digit', `0${randomnumber0}`);
				Ez2Form.setValue('numberArray[0].lp', true);
			} else {
				Ez2Form.setValue('numberArray[0].digit', `${randomnumber0}`);
				Ez2Form.setValue('numberArray[0].lp', true);
			}

			if (randomnumber1 < 10) {
				Ez2Form.setValue('numberArray[1].digit', `0${randomnumber1}`);
				Ez2Form.setValue('numberArray[1].lp', true);
			} else {
				Ez2Form.setValue('numberArray[1].digit', `${randomnumber1}`);
				Ez2Form.setValue('numberArray[1].lp', true);
			}
			if (numberArray[2].digit === '') {
				setActiveIndex(2);
				inputStraight.current.focus();
			}
		}
	};
	// keyboard left-arrow button
	const handleMoveCursorLeft = () => {
		if (time !== '') {
			const newactiveIndex = activeIndex === 0 ? 0 : activeIndex - 1;
			setActiveIndex(newactiveIndex);
		}
	};
	// keyboard right-arrow button
	const handleMoveCursorRight = () => {
		if (time !== '') {
			if (activeIndex >= 0 && activeIndex < 2) {
				setActiveIndex(activeIndex + 1);
			} else if (activeIndex === 2) {
				setActiveIndex(3);
			}
		}
	};

	const handlePasteBet = () => {
		if (textAreaValueSplit && time !== '') {
			let newValue = ''; // set invalid format back to text area
			textAreaValueSplit.forEach((items) => {
				const splitItems = items.trim().split(' '); // split items with space
				let priceStraight = Number(splitItems[1]);
				let priceRamble = Number(splitItems[2]);
				let betNumber = splitItems?.[0];
				let digit2D = betNumber?.split(',').map(Number);
				let digit2DNum1 = digit2D?.[0];
				let digit2DNum2 = digit2D?.[1];

				// checking range of number 1 - 31 only
				let accessDigitRange =
					digit2DNum1 > 0 && digit2DNum1 < 32 && digit2DNum2 > 0 && digit2DNum2 < 32;

				// check same digit
				let sameNumber =
					digit2DNum1 === digit2DNum2 ||
					`0${digit2DNum1}` === digit2DNum2 ||
					digit2DNum1 === `0${digit2DNum2}` ||
					`${digit2DNum1}` === `${digit2DNum2}`;

				if (
					splitItems.length === 3 &&
					((digit2D.length === 2 && accessDigitRange) ||
						splitItems?.[0] === 'L' ||
						splitItems?.[0] === 'l') &&
					priceStraight < 100000 &&
					priceStraight >= 0 &&
					priceRamble < 100000 &&
					priceRamble >= 0
				) {
					// Lucky pick ----------------------------------------------------------------
					if (betNumber === 'L' || betNumber === 'l') {
						let luckypickNumber1 = Math.floor(Math.random() * 31 + 1);
						let luckypickNumber2 = Math.floor(Math.random() * 31 + 1);

						// luckypick same number
						if (luckypickNumber1 === luckypickNumber2) {
							if (priceStraight >= minBetAmount) {
								// only add starigth
								if (luckypickNumber1 > 9 && luckypickNumber2 > 9) {
									setBetting([
										{
											id: uuidv4(),
											gameId: gameId,
											gameType: type,
											betPrice: priceStraight,
											ramble: false,
											lp: true,
											number: [
												{ digit: luckypickNumber1, lp: true, name: 'number2DNum1', position: 1 },
												{ digit: luckypickNumber2, lp: true, name: 'number2DNum2', position: 2 },
											],
										},
									]);
								}
								// only add starigth
								else if (luckypickNumber1 < 10 && luckypickNumber2 < 10) {
									setBetting([
										{
											id: uuidv4(),
											gameId: gameId,
											gameType: type,
											betPrice: priceStraight,
											ramble: false,
											lp: true,
											number: [
												{
													digit: `0${luckypickNumber1}`,
													lp: true,
													name: 'number2DNum1',
													position: 1,
												},
												{
													digit: `0${luckypickNumber2}`,
													lp: true,
													name: 'number2DNum2',
													position: 2,
												},
											],
										},
									]);
								}
								// only add starigth
								else if (luckypickNumber1 < 10 && luckypickNumber2 > 9) {
									setBetting([
										{
											id: uuidv4(),
											gameId: gameId,
											gameType: type,
											betPrice: priceStraight,
											ramble: false,
											lp: true,
											number: [
												{
													digit: `0${luckypickNumber1}`,
													lp: true,
													name: 'number2DNum1',
													position: 1,
												},
												{
													digit: luckypickNumber2,
													lp: true,
													name: 'number2DNum2',
													position: 2,
												},
											],
										},
									]);
								}
								// only add starigth
								else if (luckypickNumber1 > 9 && luckypickNumber2 < 10) {
									setBetting([
										{
											id: uuidv4(),
											gameId: gameId,
											gameType: type,
											betPrice: priceStraight,
											ramble: false,
											lp: true,
											number: [
												{
													digit: luckypickNumber1,
													lp: true,
													name: 'number2DNum1',
													position: 1,
												},
												{
													digit: `0${luckypickNumber2}`,
													lp: true,
													name: 'number2DNum2',
													position: 2,
												},
											],
										},
									]);
								}
							}
							// error, add text back to textarea
							else {
								newValue += items.toString().trim() + '\n';
							}
						}
						//- luckypick diff number
						else {
							// straight and ramble > 0 and > minBetAmount
							if (priceStraight >= minBetAmount && priceRamble >= minBetAmount) {
								// add straight and ramble
								if (luckypickNumber1 > 9 && luckypickNumber2 > 9) {
									setBetting([
										{
											id: uuidv4(),
											gameId: gameId,
											gameType: type,
											betPrice: priceStraight,
											ramble: false,
											lp: true,
											number: [
												{ digit: luckypickNumber1, lp: true, name: 'number2DNum1', position: 1 },
												{ digit: luckypickNumber2, lp: true, name: 'number2DNum2', position: 2 },
											],
										},
										{
											id: uuidv4(),
											gameId: gameId,
											gameType: type,
											betPrice: priceRamble,
											ramble: true,
											lp: true,
											number: [
												{ digit: luckypickNumber1, lp: true, name: 'number2DNum1', position: 1 },
												{ digit: luckypickNumber2, lp: true, name: 'number2DNum2', position: 2 },
											],
										},
									]);
								}
								// add straight and ramble
								else if (luckypickNumber1 < 10 && luckypickNumber2 > 9) {
									setBetting([
										{
											id: uuidv4(),
											gameId: gameId,
											gameType: type,
											betPrice: priceStraight,
											ramble: false,
											lp: true,
											number: [
												{
													digit: `0${luckypickNumber1}`,
													lp: true,
													name: 'number2DNum1',
													position: 1,
												},
												{ digit: luckypickNumber2, lp: true, name: 'number2DNum2', position: 2 },
											],
										},
										{
											id: uuidv4(),
											gameId: gameId,
											gameType: type,
											betPrice: priceRamble,
											ramble: true,
											lp: true,
											number: [
												{
													digit: `0${luckypickNumber1}`,
													lp: true,
													name: 'number2DNum1',
													position: 1,
												},
												{ digit: luckypickNumber2, lp: true, name: 'number2DNum2', position: 2 },
											],
										},
									]);
								}
								// add straight and ramble
								else if (luckypickNumber1 > 9 && luckypickNumber2 < 10) {
									setBetting([
										{
											id: uuidv4(),
											gameId: gameId,
											gameType: type,
											betPrice: priceStraight,
											ramble: false,
											lp: true,
											number: [
												{
													digit: luckypickNumber1,
													lp: true,
													name: 'number2DNum1',
													position: 1,
												},
												{
													digit: `0${luckypickNumber2}`,
													lp: true,
													name: 'number2DNum2',
													position: 2,
												},
											],
										},
										{
											id: uuidv4(),
											gameId: gameId,
											gameType: type,
											betPrice: priceRamble,
											ramble: true,
											lp: true,
											number: [
												{
													digit: luckypickNumber1,
													lp: true,
													name: 'number2DNum1',
													position: 1,
												},
												{
													digit: `0${luckypickNumber2}`,
													lp: true,
													name: 'number2DNum2',
													position: 2,
												},
											],
										},
									]);
								} else if (luckypickNumber1 < 10 && luckypickNumber2 < 10) {
									setBetting([
										{
											id: uuidv4(),
											gameId: gameId,
											gameType: type,
											betPrice: priceStraight,
											ramble: false,
											lp: true,
											number: [
												{
													digit: `0${luckypickNumber1}`,
													lp: true,
													name: 'number2DNum1',
													position: 1,
												},
												{
													digit: `0${luckypickNumber2}`,
													lp: true,
													name: 'number2DNum2',
													position: 2,
												},
											],
										},
										{
											id: uuidv4(),
											gameId: gameId,
											gameType: type,
											betPrice: priceRamble,
											ramble: true,
											lp: true,
											number: [
												{
													digit: `0${luckypickNumber1}`,
													lp: true,
													name: 'number2DNum1',
													position: 1,
												},
												{
													digit: `0${luckypickNumber2}`,
													lp: true,
													name: 'number2DNum2',
													position: 2,
												},
											],
										},
									]);
								}
							}
							// straight >= minBetAmount & priceRamble === 0
							else if (priceStraight >= minBetAmount && priceRamble === 0) {
								// add straight
								if (luckypickNumber1 > 9 && luckypickNumber2 > 9) {
									setBetting([
										{
											id: uuidv4(),
											gameId: gameId,
											gameType: type,
											betPrice: priceStraight,
											ramble: false,
											lp: true,
											number: [
												{ digit: luckypickNumber1, lp: true, name: 'number2DNum1', position: 1 },
												{ digit: luckypickNumber2, lp: true, name: 'number2DNum2', position: 2 },
											],
										},
									]);
								}
								// add straight
								else if (luckypickNumber1 < 10 && luckypickNumber2 > 9) {
									setBetting([
										{
											id: uuidv4(),
											gameId: gameId,
											gameType: type,
											betPrice: priceStraight,
											ramble: false,
											lp: true,
											number: [
												{
													digit: `0${luckypickNumber1}`,
													lp: true,
													name: 'number2DNum1',
													position: 1,
												},
												{ digit: luckypickNumber2, lp: true, name: 'number2DNum2', position: 2 },
											],
										},
									]);
								}
								// add straight
								else if (luckypickNumber1 > 9 && luckypickNumber2 < 10) {
									setBetting([
										{
											id: uuidv4(),
											gameId: gameId,
											gameType: type,
											betPrice: priceStraight,
											ramble: false,
											lp: true,
											number: [
												{
													digit: luckypickNumber1,
													lp: true,
													name: 'number2DNum1',
													position: 1,
												},
												{
													digit: `0${luckypickNumber2}`,
													lp: true,
													name: 'number2DNum2',
													position: 2,
												},
											],
										},
									]);
								}
								// add straight
								else if (luckypickNumber1 < 10 && luckypickNumber2 < 10) {
									setBetting([
										{
											id: uuidv4(),
											gameId: gameId,
											gameType: type,
											betPrice: priceStraight,
											ramble: false,
											lp: true,
											number: [
												{
													digit: `0${luckypickNumber1}`,
													lp: true,
													name: 'number2DNum1',
													position: 1,
												},
												{
													digit: `0${luckypickNumber2}`,
													lp: true,
													name: 'number2DNum2',
													position: 2,
												},
											],
										},
									]);
								}
							}
							// priceStraight === 0 & ramble >= minBetAmount
							else if (priceStraight === 0 && priceRamble >= minBetAmount) {
								// add ramble
								if (luckypickNumber1 > 9 && luckypickNumber2 > 9) {
									setBetting([
										{
											id: uuidv4(),
											gameId: gameId,
											gameType: type,
											betPrice: priceRamble,
											ramble: true,
											lp: true,
											number: [
												{ digit: luckypickNumber1, lp: true, name: 'number2DNum1', position: 1 },
												{ digit: luckypickNumber2, lp: true, name: 'number2DNum2', position: 2 },
											],
										},
									]);
								}
								// add ramble
								else if (luckypickNumber1 < 10 && luckypickNumber2 > 9) {
									setBetting([
										{
											id: uuidv4(),
											gameId: gameId,
											gameType: type,
											betPrice: priceRamble,
											ramble: true,
											lp: true,
											number: [
												{
													digit: `0${luckypickNumber1}`,
													lp: true,
													name: 'number2DNum1',
													position: 1,
												},
												{ digit: luckypickNumber2, lp: true, name: 'number2DNum2', position: 2 },
											],
										},
									]);
								}
								// add ramble
								else if (luckypickNumber1 > 9 && luckypickNumber2 < 10) {
									setBetting([
										{
											id: uuidv4(),
											gameId: gameId,
											gameType: type,
											betPrice: priceRamble,
											ramble: true,
											lp: true,
											number: [
												{
													digit: luckypickNumber1,
													lp: true,
													name: 'number2DNum1',
													position: 1,
												},
												{
													digit: `0${luckypickNumber2}`,
													lp: true,
													name: 'number2DNum2',
													position: 2,
												},
											],
										},
									]);
								}
								// add ramble
								else if (luckypickNumber1 < 10 && luckypickNumber2 < 10) {
									setBetting([
										{
											id: uuidv4(),
											gameId: gameId,
											gameType: type,
											betPrice: priceRamble,
											ramble: true,
											lp: true,
											number: [
												{
													digit: `0${luckypickNumber1}`,
													lp: true,
													name: 'number2DNum1',
													position: 1,
												},
												{
													digit: `0${luckypickNumber2}`,
													lp: true,
													name: 'number2DNum2',
													position: 2,
												},
											],
										},
									]);
								}
							}
							// straight !== 0 and < minBetAmount && ramble > minbetAmount
							// staright > minBetAmount && ramble !==0 and < minBetAmount
							// error, add text back to textarea
							else {
								newValue += items.toString().trim() + '\n';
							}
						}
					}
					// self insert same digit
					else if (sameNumber) {
						if (priceStraight >= minBetAmount) {
							// add straight
							if (digit2DNum1 > 9 && digit2DNum2 > 9) {
								setBetting([
									{
										id: uuidv4(),
										gameId: gameId,
										gameType: type,
										betPrice: priceStraight,
										ramble: false,
										lp: false,
										number: [
											{ digit: digit2DNum1, lp: false, name: 'number2DNum1', position: 1 },
											{ digit: digit2DNum2, lp: false, name: 'number2DNum2', position: 2 },
										],
									},
								]);
							}
							// add straight
							else if (digit2DNum1 < 10 && digit2DNum2 > 9) {
								setBetting([
									{
										id: uuidv4(),
										gameId: gameId,
										gameType: type,
										betPrice: priceStraight,
										ramble: false,
										lp: false,
										number: [
											{ digit: `0${digit2DNum1}`, lp: false, name: 'number2DNum1', position: 1 },
											{ digit: digit2DNum2, lp: false, name: 'number2DNum2', position: 2 },
										],
									},
								]);
							}
							// add straight
							else if (digit2DNum1 > 9 && digit2DNum2 < 10) {
								setBetting([
									{
										id: uuidv4(),
										gameId: gameId,
										gameType: type,
										betPrice: priceStraight,
										ramble: false,
										lp: false,
										number: [
											{ digit: digit2DNum1, lp: false, name: 'number2DNum1', position: 1 },
											{ digit: `0${digit2DNum2}`, lp: false, name: 'number2DNum2', position: 2 },
										],
									},
								]);
							}
							// add straight
							else if (digit2DNum1 < 10 && digit2DNum2 < 10) {
								setBetting([
									{
										id: uuidv4(),
										gameId: gameId,
										gameType: type,
										betPrice: priceStraight,
										ramble: false,
										lp: false,
										number: [
											{ digit: `0${digit2DNum1}`, lp: false, name: 'number2DNum1', position: 1 },
											{ digit: `0${digit2DNum2}`, lp: false, name: 'number2DNum2', position: 2 },
										],
									},
								]);
							}
						}
						// error, add text back to textarea
						else {
							newValue += items.toString().trim() + '\n';
						}
					}
					// self insert diff number
					else {
						// price straight >= minBetAmount and priceRamble >= minBetAmount
						if (priceStraight >= minBetAmount && priceRamble >= minBetAmount) {
							// add straight and ramble
							if (digit2DNum1 > 9 && digit2DNum2 > 9) {
								setBetting([
									{
										id: uuidv4(),
										gameId: gameId,
										gameType: type,
										betPrice: priceStraight,
										ramble: false,
										lp: false,
										number: [
											{
												position: 1,
												digit: digit2DNum1,
												lp: false,
											},
											{
												position: 2,
												digit: digit2DNum2,
												lp: false,
											},
										],
									},
									{
										id: uuidv4(),
										gameId: gameId,
										gameType: type,
										betPrice: priceRamble,
										ramble: true,
										lp: false,
										number: [
											{
												position: 1,
												digit: digit2DNum1,
												lp: false,
											},
											{
												position: 2,
												digit: digit2DNum2,
												lp: false,
											},
										],
									},
								]);
							}
							// add straight and ramble
							else if (digit2DNum1 < 10 && digit2DNum2 > 9) {
								setBetting([
									{
										id: uuidv4(),
										gameId: gameId,
										gameType: type,
										betPrice: priceStraight,
										ramble: false,
										lp: false,
										number: [
											{
												position: 1,
												digit: `0${digit2DNum1}`,
												lp: false,
											},
											{
												position: 2,
												digit: digit2DNum2,
												lp: false,
											},
										],
									},
									{
										id: uuidv4(),
										gameId: gameId,
										gameType: type,
										betPrice: priceRamble,
										ramble: true,
										lp: false,
										number: [
											{
												position: 1,
												digit: `0${digit2DNum1}`,
												lp: false,
											},
											{
												position: 2,
												digit: digit2DNum2,
												lp: false,
											},
										],
									},
								]);
							}
							// add straight and ramble
							else if (digit2DNum1 > 9 && digit2DNum2 < 10) {
								setBetting([
									{
										id: uuidv4(),
										gameId: gameId,
										gameType: type,
										betPrice: priceStraight,
										ramble: false,
										lp: false,
										number: [
											{
												position: 1,
												digit: digit2DNum1,
												lp: false,
											},
											{
												position: 2,
												digit: `0${digit2DNum2}`,
												lp: false,
											},
										],
									},
									{
										id: uuidv4(),
										gameId: gameId,
										gameType: type,
										betPrice: priceRamble,
										ramble: true,
										lp: false,
										number: [
											{
												position: 1,
												digit: digit2DNum1,
												lp: false,
											},
											{
												position: 2,
												digit: `0${digit2DNum2}`,
												lp: false,
											},
										],
									},
								]);
							}
							// add straight and ramble
							else if (digit2DNum1 < 10 && digit2DNum2 < 10) {
								setBetting([
									{
										id: uuidv4(),
										gameId: gameId,
										gameType: type,
										betPrice: priceStraight,
										ramble: false,
										lp: false,
										number: [
											{
												position: 1,
												digit: `0${digit2DNum1}`,
												lp: false,
											},
											{
												position: 2,
												digit: `0${digit2DNum2}`,
												lp: false,
											},
										],
									},
									{
										id: uuidv4(),
										gameId: gameId,
										gameType: type,
										betPrice: priceRamble,
										ramble: true,
										lp: false,
										number: [
											{
												position: 1,
												digit: `0${digit2DNum1}`,
												lp: false,
											},
											{
												position: 2,
												digit: `0${digit2DNum2}`,
												lp: false,
											},
										],
									},
								]);
							}
						}
						// price straight >= minBetAmount and priceRamble === 0
						else if (priceStraight >= minBetAmount && priceRamble === 0) {
							// add straight
							if (digit2DNum1 > 9 && digit2DNum2 > 9) {
								setBetting([
									{
										id: uuidv4(),
										gameId: gameId,
										gameType: type,
										betPrice: priceStraight,
										ramble: false,
										lp: false,
										number: [
											{
												position: 1,
												digit: digit2DNum1,
												lp: false,
											},
											{
												position: 2,
												digit: digit2DNum2,
												lp: false,
											},
										],
									},
								]);
							}
							// add straight
							else if (digit2DNum1 < 10 && digit2DNum2 > 9) {
								setBetting([
									{
										id: uuidv4(),
										gameId: gameId,
										gameType: type,
										betPrice: priceStraight,
										ramble: false,
										lp: false,
										number: [
											{
												position: 1,
												digit: `0${digit2DNum1}`,
												lp: false,
											},
											{
												position: 2,
												digit: digit2DNum2,
												lp: false,
											},
										],
									},
								]);
							}
							// add straight
							else if (digit2DNum1 > 9 && digit2DNum2 < 10) {
								setBetting([
									{
										id: uuidv4(),
										gameId: gameId,
										gameType: type,
										betPrice: priceStraight,
										ramble: false,
										lp: false,
										number: [
											{
												position: 1,
												digit: digit2DNum1,
												lp: false,
											},
											{
												position: 2,
												digit: `0${digit2DNum2}`,
												lp: false,
											},
										],
									},
								]);
							}
							// add straight
							else if (digit2DNum1 < 10 && digit2DNum2 < 10) {
								setBetting([
									{
										id: uuidv4(),
										gameId: gameId,
										gameType: type,
										betPrice: priceStraight,
										ramble: false,
										lp: false,
										number: [
											{
												position: 1,
												digit: `0${digit2DNum1}`,
												lp: false,
											},
											{
												position: 2,
												digit: `0${digit2DNum2}`,
												lp: false,
											},
										],
									},
								]);
							}
						}
						// price straight === 0 and priceRamble >= minBetAmount
						else if (priceStraight === 0 && priceRamble >= minBetAmount) {
							// add  ramble
							if (digit2DNum1 > 9 && digit2DNum2 > 9) {
								setBetting([
									{
										id: uuidv4(),
										gameId: gameId,
										gameType: type,
										betPrice: priceRamble,
										ramble: true,
										lp: false,
										number: [
											{
												position: 1,
												digit: digit2DNum1,
												lp: false,
											},
											{
												position: 2,
												digit: digit2DNum2,
												lp: false,
											},
										],
									},
								]);
							}
							// add straight and ramble
							else if (digit2DNum1 < 10 && digit2DNum2 > 9) {
								setBetting([
									{
										id: uuidv4(),
										gameId: gameId,
										gameType: type,
										betPrice: priceRamble,
										ramble: true,
										lp: false,
										number: [
											{
												position: 1,
												digit: `0${digit2DNum1}`,
												lp: false,
											},
											{
												position: 2,
												digit: digit2DNum2,
												lp: false,
											},
										],
									},
								]);
							}
							// add ramble
							else if (digit2DNum1 > 9 && digit2DNum2 < 10) {
								setBetting([
									{
										id: uuidv4(),
										gameId: gameId,
										gameType: type,
										betPrice: priceRamble,
										ramble: true,
										lp: false,
										number: [
											{
												position: 1,
												digit: digit2DNum1,
												lp: false,
											},
											{
												position: 2,
												digit: `0${digit2DNum2}`,
												lp: false,
											},
										],
									},
								]);
							}
							// add ramble
							else if (digit2DNum1 < 10 && digit2DNum2 < 10) {
								setBetting([
									{
										id: uuidv4(),
										gameId: gameId,
										gameType: type,
										betPrice: priceRamble,
										ramble: true,
										lp: false,
										number: [
											{
												position: 1,
												digit: `0${digit2DNum1}`,
												lp: false,
											},
											{
												position: 2,
												digit: `0${digit2DNum2}`,
												lp: false,
											},
										],
									},
								]);
							}
						}
						// straight !== 0 and < minBetAmount && ramble > minbetAmount
						// staright > minBetAmount && ramble !==0 and < minBetAmount
						// error add text back to textarea
						else {
							newValue += items.toString().trim() + '\n';
						}
					}
				} else {
					// invalid format setback to textarea
					if (items.toString() !== '\n' && items.toString().length > 0) {
						newValue += items.toString().trim() + '\n';
					}
				}
			});

			// if all success closed the modal
			if (newValue === '') {
				onClose();
				setTextAreaError(false);
			} else {
				setTextAreaError(true);
				setErrorMessage([]);
				PasteBetErrorEz2({ newValue, minBetAmount, setErrorMessage });
			}

			setTextAreaValue(newValue);
		}
	};

	//+ Add Bet button function
	const onSubmit = () => {
		if (time !== '') {
			if (
				(numberArray[0].digit === numberArray[1].digit ||
					`0${numberArray[0].digit}` === numberArray[1].digit ||
					numberArray[0].digit === `0${numberArray[1].digit}`) &&
				Number(numberArray[0].digit) > 0 &&
				Number(numberArray[1].digit) > 0 &&
				Number(numberArray[0].digit) < 32 &&
				Number(numberArray[1].digit) < 32
			) {
				if (
					numberArray[0].digit.length < 2 &&
					numberArray[1].digit.length < 2 &&
					numberArray[2].digit >= minBetAmount
				) {
					setBetting([
						{
							id: uuidv4(),
							gameId: gameId,
							gameType: type,
							betPrice: Number(numberArray[2].digit),
							ramble: false,
							lp: numberArray[0].lp === true || numberArray[1].lp === true ? true : false,
							number: [
								{
									position: numberArray[0].position,
									digit: `0${numberArray[0].digit}`,
									lp: numberArray[0].lp,
								},
								{
									position: numberArray[1].position,
									digit: `0${numberArray[1].digit}`,
									lp: numberArray[1].lp,
								},
							],
						},
					]);
					Ez2Form.setValue('numberArray[0].digit', '');
					Ez2Form.setValue('numberArray[1].digit', '');
					Ez2Form.setValue('numberArray[0].lp', false);
					Ez2Form.setValue('numberArray[1].lp', false);
					setActiveIndex(0);
					input0Ref.current.focus();
				} else if (
					numberArray[0].digit === numberArray[1].digit &&
					numberArray[2].digit >= minBetAmount
				) {
					setBetting([
						{
							id: uuidv4(),
							gameId: gameId,
							gameType: type,
							betPrice: Number(numberArray[2].digit),
							ramble: false,
							lp: numberArray[0].lp === true || numberArray[1].lp === true ? true : false,
							number: [
								{
									position: numberArray[0].position,
									digit: numberArray[0].digit,
									lp: numberArray[0].lp,
								},
								{
									position: numberArray[1].position,
									digit: numberArray[1].digit,
									lp: numberArray[1].lp,
								},
							],
						},
					]);
					Ez2Form.setValue('numberArray[0].digit', '');
					Ez2Form.setValue('numberArray[1].digit', '');
					Ez2Form.setValue('numberArray[0].lp', false);
					Ez2Form.setValue('numberArray[1].lp', false);
					setActiveIndex(0);
					input0Ref.current.focus();
				} else if (
					`0${numberArray[0].digit}` === numberArray[1].digit &&
					numberArray[2].digit >= minBetAmount
				) {
					setBetting([
						{
							id: uuidv4(),
							gameId: gameId,
							gameType: type,
							betPrice: Number(numberArray[2].digit),
							ramble: false,
							lp: numberArray[0].lp === true || numberArray[1].lp === true ? true : false,
							number: [
								{
									position: numberArray[0].position,
									digit: `0${numberArray[0].digit}`,
									lp: numberArray[0].lp,
								},
								numberArray[1],
							],
						},
					]);
					Ez2Form.setValue('numberArray[0].digit', '');
					Ez2Form.setValue('numberArray[1].digit', '');
					Ez2Form.setValue('numberArray[0].lp', false);
					Ez2Form.setValue('numberArray[1].lp', false);
					setActiveIndex(0);
					input0Ref.current.focus();
				} else if (
					numberArray[0].digit === `0${numberArray[1].digit}` &&
					numberArray[2].digit >= minBetAmount
				) {
					setBetting([
						{
							id: uuidv4(),
							gameId: gameId,
							gameType: type,
							betPrice: Number(numberArray[2].digit),
							ramble: false,
							lp: numberArray[0].lp === true || numberArray[1].lp === true ? true : false,
							number: [
								numberArray[0],
								{
									position: numberArray[1].position,
									digit: `0${numberArray[1].digit}`,
									lp: numberArray[1].lp,
								},
							],
						},
					]);
					Ez2Form.setValue('numberArray[0].digit', '');
					Ez2Form.setValue('numberArray[1].digit', '');
					Ez2Form.setValue('numberArray[0].lp', false);
					Ez2Form.setValue('numberArray[1].lp', false);
					setActiveIndex(0);
					input0Ref.current.focus();
				}
			} else {
				if (
					numberArray[0].digit !== '' &&
					numberArray[1].digit !== '' &&
					Number(numberArray[0].digit) > 0 &&
					Number(numberArray[1].digit) > 0 &&
					Number(numberArray[0].digit) < 32 &&
					Number(numberArray[1].digit) < 32 &&
					numberArray[2].digit >= minBetAmount &&
					numberArray[3].digit >= minBetAmount &&
					Number(numberArray[2].digit) >= 1 &&
					Number(numberArray[3].digit) >= 1
				) {
					if (numberArray[0].digit.length < 2 && numberArray[1].digit.length < 2) {
						setBetting([
							{
								id: uuidv4(),
								gameId: gameId,
								gameType: type,
								betPrice: Number(numberArray[2].digit),
								ramble: false,
								lp: numberArray[0].lp === true || numberArray[1].lp === true ? true : false,
								number: [
									{
										position: numberArray[0].position,
										digit: `0${numberArray[0].digit}`,
										lp: numberArray[0].lp,
									},
									{
										position: numberArray[1].position,
										digit: `0${numberArray[1].digit}`,
										lp: numberArray[1].lp,
									},
								],
							},
							{
								id: uuidv4(),
								gameId: gameId,
								gameType: type,
								betPrice: Number(numberArray[3].digit),
								ramble: true,
								lp: numberArray[0].lp === true || numberArray[1].lp === true ? true : false,
								number: [
									{
										position: numberArray[0].position,
										digit: `0${numberArray[0].digit}`,
										lp: numberArray[0].lp,
									},
									{
										position: numberArray[1].position,
										digit: `0${numberArray[1].digit}`,
										lp: numberArray[1].lp,
									},
								],
							},
						]);
					} else if (numberArray[0].digit.length < 2) {
						setBetting([
							{
								id: uuidv4(),
								gameId: gameId,
								gameType: type,
								betPrice: Number(numberArray[2].digit),
								ramble: false,
								lp: numberArray[0].lp === true || numberArray[1].lp === true ? true : false,
								number: [
									{
										position: numberArray[0].position,
										digit: `0${numberArray[0].digit}`,
										lp: numberArray[0].lp,
									},
									numberArray[1],
								],
							},
							{
								id: uuidv4(),
								gameId: gameId,
								gameType: type,
								betPrice: Number(numberArray[3].digit),
								ramble: true,
								lp: numberArray[0].lp === true || numberArray[1].lp === true ? true : false,
								number: [
									{
										position: numberArray[0].position,
										digit: `0${numberArray[0].digit}`,
										lp: numberArray[0].lp,
									},
									numberArray[1],
								],
							},
						]);
					} else if (numberArray[1].digit.length < 2) {
						setBetting([
							{
								id: uuidv4(),
								gameId: gameId,
								gameType: type,
								betPrice: Number(numberArray[2].digit),
								ramble: false,
								lp: numberArray[0].lp === true || numberArray[1].lp === true ? true : false,
								number: [
									numberArray[0],
									{
										position: numberArray[1].position,
										digit: `0${numberArray[1].digit}`,
										lp: numberArray[1].lp,
									},
								],
							},
							{
								id: uuidv4(),
								gameId: gameId,
								gameType: type,
								betPrice: Number(numberArray[3].digit),
								ramble: true,
								lp: numberArray[0].lp === true || numberArray[1].lp === true ? true : false,
								number: [
									numberArray[0],
									{
										position: numberArray[1].position,
										digit: `0${numberArray[1].digit}`,
										lp: numberArray[1].lp,
									},
								],
							},
						]);
					} else {
						setBetting([
							{
								id: uuidv4(),
								gameId: gameId,
								gameType: type,
								betPrice: Number(numberArray[2].digit),
								ramble: false,
								lp: numberArray[0].lp === true || numberArray[1].lp === true ? true : false,
								number: [numberArray[0], numberArray[1]],
							},
							{
								id: uuidv4(),
								gameId: gameId,
								gameType: type,
								betPrice: Number(numberArray[3].digit),
								ramble: true,
								lp: numberArray[0].lp === true || numberArray[1].lp === true ? true : false,
								number: [numberArray[0], numberArray[1]],
							},
						]);
					}
					Ez2Form.setValue('numberArray[0].digit', '');
					Ez2Form.setValue('numberArray[1].digit', '');
					Ez2Form.setValue('numberArray[0].lp', false);
					Ez2Form.setValue('numberArray[1].lp', false);
					setActiveIndex(0);
					input0Ref.current.focus();
				} else if (
					numberArray[0].digit !== '' &&
					numberArray[1].digit !== '' &&
					Number(numberArray[0].digit) > 0 &&
					Number(numberArray[1].digit) > 0 &&
					Number(numberArray[0].digit) < 32 &&
					Number(numberArray[1].digit) < 32 &&
					numberArray[2].digit >= minBetAmount &&
					numberArray[3].digit === '' &&
					Number(numberArray[2].digit) >= minBetAmount
				) {
					if (numberArray[0].digit.length < 2 && numberArray[1].digit.length < 2) {
						setBetting([
							{
								id: uuidv4(),
								gameId: gameId,
								gameType: type,
								betPrice: Number(numberArray[2].digit),
								ramble: false,
								lp: numberArray[0].lp === true || numberArray[1].lp === true ? true : false,
								number: [
									{
										position: numberArray[0].position,
										digit: `0${numberArray[0].digit}`,
										lp: numberArray[0].lp,
									},
									{
										position: numberArray[1].position,
										digit: `0${numberArray[1].digit}`,
										lp: numberArray[1].lp,
									},
								],
							},
						]);
					} else if (numberArray[0].digit.length < 2) {
						setBetting([
							{
								id: uuidv4(),
								gameId: gameId,
								gameType: type,
								betPrice: Number(numberArray[2].digit),
								ramble: false,
								lp: numberArray[0].lp === true || numberArray[1].lp === true ? true : false,
								number: [
									{
										position: numberArray[0].position,
										digit: `0${numberArray[0].digit}`,
										lp: numberArray[0].lp,
									},
									numberArray[1],
								],
							},
						]);
					} else if (numberArray[1].digit.length < 2) {
						setBetting([
							{
								id: uuidv4(),
								gameId: gameId,
								gameType: type,
								betPrice: Number(numberArray[2].digit),
								ramble: false,
								lp: numberArray[0].lp === true || numberArray[1].lp === true ? true : false,
								number: [
									numberArray[0],
									{
										position: numberArray[1].position,
										digit: `0${numberArray[1].digit}`,
										lp: numberArray[1].lp,
									},
								],
							},
						]);
					} else {
						setBetting([
							{
								id: uuidv4(),
								gameId: gameId,
								gameType: type,
								betPrice: Number(numberArray[2].digit),
								ramble: false,
								lp: numberArray[0].lp === true || numberArray[1].lp === true ? true : false,
								number: [numberArray[0], numberArray[1]],
							},
						]);
					}
					Ez2Form.setValue('numberArray[0].digit', '');
					Ez2Form.setValue('numberArray[1].digit', '');
					Ez2Form.setValue('numberArray[0].lp', false);
					Ez2Form.setValue('numberArray[1].lp', false);

					setActiveIndex(0);
					input0Ref.current.focus();
				} else if (
					numberArray[0].digit !== '' &&
					numberArray[1].digit !== '' &&
					Number(numberArray[0].digit) > 0 &&
					Number(numberArray[1].digit) > 0 &&
					Number(numberArray[0].digit) < 32 &&
					Number(numberArray[1].digit) < 32 &&
					numberArray[2].digit === '' &&
					numberArray[3].digit >= minBetAmount &&
					Number(numberArray[3].digit) >= minBetAmount
				) {
					if (numberArray[0].digit.length < 2 && numberArray[1].digit.length < 2) {
						setBetting([
							{
								id: uuidv4(),
								gameId: gameId,
								gameType: type,
								betPrice: Number(numberArray[3].digit),
								ramble: true,
								lp: numberArray[0].lp === true || numberArray[1].lp === true ? true : false,
								number: [
									{
										position: numberArray[0].position,
										digit: `0${numberArray[0].digit}`,
										lp: numberArray[0].lp,
									},
									{
										position: numberArray[1].position,
										digit: `0${numberArray[1].digit}`,
										lp: numberArray[1].lp,
									},
								],
							},
						]);
					} else if (numberArray[0].digit.length < 2) {
						setBetting([
							{
								id: uuidv4(),
								gameId: gameId,
								gameType: type,
								betPrice: Number(numberArray[3].digit),
								ramble: true,
								lp: numberArray[0].lp === true || numberArray[1].lp === true ? true : false,
								number: [
									{
										position: numberArray[0].position,
										digit: `0${numberArray[0].digit}`,
										lp: numberArray[0].lp,
									},
									numberArray[1],
								],
							},
						]);
					} else if (numberArray[1].digit.length < 2) {
						setBetting([
							{
								id: uuidv4(),
								gameId: gameId,
								gameType: type,
								betPrice: Number(numberArray[3].digit),
								ramble: true,
								lp: numberArray[0].lp === true || numberArray[1].lp === true ? true : false,
								number: [
									numberArray[0],
									{
										position: numberArray[1].position,
										digit: `0${numberArray[1].digit}`,
										lp: numberArray[1].lp,
									},
								],
							},
						]);
					} else {
						setBetting([
							{
								id: uuidv4(),
								gameId: gameId,
								gameType: type,
								betPrice: Number(numberArray[3].digit),
								ramble: true,
								lp: numberArray[0].lp === true || numberArray[1].lp === true ? true : false,
								number: [numberArray[0], numberArray[1]],
							},
						]);
					}
					Ez2Form.setValue('numberArray[0].digit', '');
					Ez2Form.setValue('numberArray[1].digit', '');
					Ez2Form.setValue('numberArray[0].lp', false);
					Ez2Form.setValue('numberArray[1].lp', false);
					setActiveIndex(0);
					input0Ref.current.focus();
				}
			}

			if (sameDigitChecking) {
				if (
					numberArray[0].digit < 1 ||
					numberArray[0].digit > 31 ||
					numberArray[1].digit < 1 ||
					numberArray[1].digit > 31
				) {
					globalShowToast({
						title: 'Number Input 1 - 31 only.',
						status: 'error',
					});
				} else if (numberArray[2].digit < minBetAmount) {
					globalShowToast({
						title: `Straight Min bet is ${minBetAmount} PHP`,
						status: 'error',
					});
				}
			} else if (numberArray[0].digit && numberArray[1].digit) {
				if (
					numberArray[0].digit < 1 ||
					numberArray[0].digit > 31 ||
					numberArray[1].digit < 1 ||
					numberArray[1].digit > 31
				) {
					globalShowToast({
						title: 'Number Input 1 - 31 only.',
						status: 'error',
					});
				} else if (
					(numberArray[2].digit && numberArray[2].digit < minBetAmount) ||
					(numberArray[3].digit && numberArray[3].digit < minBetAmount)
				) {
					globalShowToast({
						title: `Minimum bet amount is ${minBetAmount} PHP`,
						status: 'error',
					});
				} else if (numberArray[2].digit === '' && numberArray[3].digit === '') {
					globalShowToast({
						title: `Price input is empty.`,
						status: 'error',
					});
				}
			} else {
				globalShowToast({
					title: 'Number input is empty.',
					status: 'error',
				});
			}
		}
	};

	// cannot use blue.800 in styles or ...styles
	const styles = {
		width: '20%',
		borderWidth: 1,
		borderRadius: '8px',
		borderColor: time === '' ? '#000000' : '#003198',
		height: isLargerThan1024 ? '56px' : '42px',
		textAlign: 'center',
		fontSize: isLargerThan1024 ? '36px' : '20px',
		padding: '7px',
		fontWeight: '600',
	};

	const stylesPrice = {
		width: '30%',
		borderWidth: '1px',
		borderRadius: '8px',
		borderColor: time === '' ? '#000000' : '#003198',
		height: isLargerThan1024 ? '56px' : '42px',
		fontSize: isLargerThan1024 ? '36px' : '16px',
		padding: '7px',
		fontWeight: '600',
		color: '#000000',
		textAlign: 'center',
	};

	return (
		<>
			<Flex flexDirection='column'>
				<Flex paddingX={2}>
					<Controller
						name='numberArray[0].digit'
						control={Ez2Form.control}
						rules={{ lazy: true, required: true }}
						render={({ field: { onBlur, value } }) => (
							<Input
								tabIndex={1}
								ref={input0Ref}
								inputMode={isTablet === false && isMobile === true ? 'none' : 'numeric'}
								marginRight={['1vw']}
								style={{
									...styles,
									borderColor: activeIndex === 0 && time !== '' ? '#184BD5' : '#003198',
									borderWidth: activeIndex === 0 && time !== '' ? '2px' : '1px',
								}}
								value={value}
								onBlur={onBlur}
								maxLength={2}
								color='black'
								onChange={(e) => {
									e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 2);
									Ez2Form.setValue('numberArray[0].digit', e.target.value);
								}}
								onFocus={() => {
									setActiveIndex(0);
									Ez2Form.setValue('numberArray[0].digit', '');
									Ez2Form.setValue('numberArray[0].lp', false);
								}}
								onContextMenu={(event) => event.preventDefault()}
								onKeyDown={(event) => {
									if (event.nativeEvent.key === 'Backspace') {
										if (numberArray[0].digit === '') {
											Ez2Form.setValue('numberArray[0].lp', false);
											setActiveIndex(0);
											input0Ref.current.focus();
										}
									} else if (numberArray[0].digit.length > 0 && numberArray[1].digit === '') {
										setActiveIndex(1);
										setTimeout(() => {
											input1Ref.current.focus();
										}, 50);
									}

									if (event.nativeEvent.key === 'Enter') {
										onSubmit();
									}
								}}
								disabled={time === '' ? true : false}
							/>
						)}
					/>
					<Controller
						name='numberArray[1].digit'
						control={Ez2Form.control}
						rules={{ lazy: true, required: true }}
						render={({ field: { onBlur, value } }) => (
							<Input
								tabIndex={2}
								ref={input1Ref}
								inputMode={isTablet === false && isMobile === true ? 'none' : 'numeric'}
								marginRight={['1vw']}
								style={{
									...styles,
									borderColor: activeIndex === 1 && time !== '' ? '#184BD5' : '#003198',
									borderWidth: activeIndex === 1 && time !== '' ? '2px' : '1px',
								}}
								value={value}
								onBlur={onBlur}
								maxLength={2}
								color='black'
								onChange={(e) => {
									e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 2);
									Ez2Form.setValue('numberArray[1].digit', e.target.value);
								}}
								onFocus={() => {
									setActiveIndex(1);
									Ez2Form.setValue('numberArray[1].digit', '');
									Ez2Form.setValue('numberArray[1].lp', false);
								}}
								onContextMenu={(event) => event.preventDefault()}
								onKeyDown={(event) => {
									if (event.nativeEvent.key === 'Backspace') {
										if (numberArray[1].digit === '') {
											Ez2Form.setValue('numberArray[1].lp', false);
											setActiveIndex(0);
											input0Ref.current.focus();
										}
									} else if (numberArray[1].digit.length > 0 && numberArray[2].digit === '') {
										setActiveIndex(2);
										setTimeout(() => {
											inputStraight.current.focus();
										}, 50);
									}

									if (event.nativeEvent.key === 'Enter') {
										onSubmit();
									}
								}}
								disabled={time === '' ? true : false}
							/>
						)}
					/>

					<Controller
						name='numberArray[2].digit'
						control={Ez2Form.control}
						rules={{ lazy: true, required: true }}
						render={({ field: { onBlur, value } }) => (
							<Input
								placeholder={activeIndex === 2 ? '' : 'Straight'}
								tabIndex={3}
								ref={inputStraight}
								inputMode={isTablet === false && isMobile === true ? 'none' : 'numeric'}
								marginRight={['1vw']}
								style={{
									...stylesPrice,
									borderColor: activeIndex === 2 && time !== '' ? '#184BD5' : '#003198',
									borderWidth: activeIndex === 2 && time !== '' ? '2px' : '1px',
								}}
								value={value}
								onBlur={onBlur}
								maxLength={5}
								color='black'
								onChange={(e) => {
									e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 5);
									Ez2Form.setValue('numberArray[2].digit', e.target.value);
								}}
								onFocus={() => {
									setActiveIndex(2);
									Ez2Form.setValue('numberArray[2].digit', '');
								}}
								onContextMenu={(event) => event.preventDefault()}
								onKeyDown={(event) => {
									if (numberArray[2].digit.length === 0 && event.nativeEvent.key === 'Backspace') {
										setActiveIndex(1);
										input1Ref.current.focus();
									} else if (numberArray[2].digit.length > 3) {
										if (numberArray[0].digit === numberArray[1].digit) {
										} else {
											if (event.nativeEvent.key === 'Backspace') {
											} else {
												setActiveIndex(3);
												setTimeout(() => {
													inputRamble.current.focus();
												}, 50);
											}
										}
									}
									if (event.nativeEvent.key === 'Enter') {
										onSubmit();
									}
								}}
								disabled={time === '' ? true : false}
							/>
						)}
					/>

					<Controller
						name='numberArray[3].digit'
						control={Ez2Form.control}
						rules={{ lazy: true, required: true }}
						render={({ field: { onBlur, value } }) => (
							<Input
								placeholder={activeIndex === 3 ? '' : 'Ramble'}
								tabIndex={3}
								ref={inputRamble}
								inputMode={isTablet === false && isMobile === true ? 'none' : 'numeric'}
								style={{
									...stylesPrice,
									borderColor: activeIndex === 3 && time !== '' ? '#184BD5' : '#003198',
									borderWidth: activeIndex === 3 && time !== '' ? '2px' : '1px',
								}}
								value={value}
								onBlur={onBlur}
								maxLength={5}
								color='black'
								onChange={(e) => {
									e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 5);
									Ez2Form.setValue('numberArray[3].digit', e.target.value);
								}}
								onFocus={() => {
									setActiveIndex(3);
									Ez2Form.setValue('numberArray[3].digit', '');
								}}
								onContextMenu={(event) => event.preventDefault()}
								onKeyDown={(event) => {
									if (numberArray[3].digit.length === 0 && event.nativeEvent.key === 'Backspace') {
										setActiveIndex(2);
										inputStraight.current.focus();
									} else if (numberArray[3].digit.length === 5) {
										if (numberArray[0].digit === numberArray[1].digit) {
										} else {
											setActiveIndex(3);
											inputRamble.current.focus();
										}
									}

									if (event.nativeEvent.key === 'Enter') {
										onSubmit();
									}
								}}
								disabled={time === '' ? true : false}
							/>
						)}
					/>
				</Flex>
				{isTablet === false && isMobile === true ? (
					<>
						<Flex
							width='100%'
							bgColor='white'
							position='fixed'
							bottom={0}
							flexDirection='column'
							alignItems='center'
							borderColor='#959595'
							borderTopWidth='1px'
							borderTopRadius='5px'
							zIndex={999}
						>
							<Flex
								justifyContent='space-between'
								width='96vw'
								fontSize='16px'
								fontWeight='500'
								// paddingY={1}
							>
								<Text>Total ({list})</Text>
								<Text color='green.500' fontWeight='700'>
									{currencyFormat(totalAmount)} PHP
								</Text>
							</Flex>
							{!isOpen && (
								<NumericKeyboard
									onKeyPress={handleKeyPress}
									onBackspace={handleBackspace}
									onLuckyPick={handleLuckypick2D}
									onLeftClick={handleMoveCursorLeft}
									onRightClick={handleMoveCursorRight}
									onAddToBet={Ez2Form.handleSubmit(onSubmit)}
									onBetSubmit={handleSubmit}
									length={list}
									time={time}
								/>
							)}
						</Flex>
					</>
				) : (
					<Flex width='100%'>
						<ButtonComp
							value='Paste Bet'
							color='white'
							width={['33%']}
							height='48px'
							fontSize={['14px', '14px', '14px', '14px', '26px']}
							borderColor='blue.800'
							backgroundColor='blue.800'
							onClick={onOpen}
							disabled={time === '' ? true : false}
							activeColor='blue.500'
							hoverColor='blue.500'
						/>
						<ButtonComp
							value='Lucky Pick'
							color='white'
							width={['33%']}
							height='48px'
							fontSize={['14px', '14px', '14px', '14px', '26px']}
							borderColor='red.500'
							backgroundColor='red.500'
							onClick={handleLuckypick2D}
							disabled={time === '' ? true : false}
							activeColor='red.400'
							hoverColor='red.400'
						/>
						<ButtonComp
							value='Add Bet'
							color='white'
							width={['33%']}
							height='48px'
							fontSize={['14px', '14px', '14px', '14px', '26px']}
							borderColor='green.500'
							backgroundColor='green.500'
							onClick={onSubmit}
							disabled={time === '' ? true : false}
							activeColor='green.450'
							hoverColor='green.450'
							hasImg={true}
							svgName='whiteAdd'
						/>
					</Flex>
				)}

				{isOpen && (
					<ModalPasteBet
						isOpen={isOpen}
						onClose={onClose}
						placeholder='Example : 1,12 10 10 / L 10 10'
						type={type}
						reg={/[^0-9,lL\s]/g}
						error={textAreaError}
						errorMessage={errorMessage}
						minBetAmount={minBetAmount}
						textAreaValue={textAreaValue}
						setTextAreaValue={setTextAreaValue}
						onChangeTextArea={(e) => {
							e.target.value = e.target.value.replace(/[^0-9,lL\s]/g, '');
							setTextAreaValue(e.target.value);
							if (textAreaError) {
								setTextAreaError(false);
							}
						}}
						onBlurTextArea={() => {
							setTextAreaValue((pre) => pre.trim());
						}}
						onClickCancel={() => {
							setTextAreaError(false);
							setTextAreaValue('');
						}}
						onClickAdd={() => {
							handlePasteBet();
						}}
					>
						<Text fontWeight={900} mt='-2'>
							How To Play
						</Text>
						<Text fontWeight={500} fontSize='16px' mt={2}>
							Betting format :
						</Text>
						<Text fontSize='16px' mt={2}>
							Insert{' '}
							<Box as='span' fontWeight={900}>
								two number
							</Box>{' '}
							from{' '}
							<Box as='span' fontWeight={900}>
								1 - 31
							</Box>
							, then betting price between{' '}
							<Box as='span' fontWeight={900}>
								{minBetAmount} - 99999 PHP
							</Box>
						</Text>
					</ModalPasteBet>
				)}
			</Flex>
		</>
	);
};

export default Ez2Keyboard;
