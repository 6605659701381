import React from 'react';

import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Divider,
	Flex,
	Text,
} from '@chakra-ui/react';
import HistoryCustomButton from '../history/HistoryCustomButton';
import { currencyFormat } from '../../utils/numberFormat';

import { ConvertedTime } from '../../utils/Time';
const ClaimTicketHeaderText = ({
	color = '#000000',
	text,
	fontSize = ['12px', '14px', '16px'],
}) => {
	return (
		<Text fontWeight='500' fontSize={fontSize} textAlign='left' color={color}>
			{text}
		</Text>
	);
};

const ClaimTicketBodyMobile = ({
	winningPrice,
	winningNumbers,
	ticketDate,
	ticketNo,
	ticketAgentName,
	claimedDate,
}) => {
	return (
		<Box display={['block', 'block', 'block', 'block', 'none']}>
			<Accordion
				allowMultiple
				style={{
					borderWidth: '0px',
					motion: {
						duration: 100.2, // Set the duration to 0.2 seconds
					},
				}}
				marginTop={5}
				backgroundColor='white'
			>
				<AccordionItem borderRightWidth='1px' borderLeftWidth='1px' borderRadius={8}>
					<AccordionButton
						style={{ borderWidth: '0px' }}
						_hover={{ bg: 'transparent' }}
						_focus={{ boxShadow: 'none' }}
						_active={{ bg: 'transparent' }}
					>
						<Flex flexDirection='column' width='100%'>
							<Flex flexDirection='column'>
								<Flex width='100%' justifyContent='space-between' marginBottom={2}>
									<HistoryCustomButton
										width='76px'
										height='32px'
										backgroundColor='blue.150'
										borderColor='blue.350'
										textColor='blue.350'
										fontSize={['14px']}
										fontWeight={'600'}
										buttonTitle='Claimed'
									/>
									<AccordionIcon />
								</Flex>
								<Flex width='100%' justifyContent='space-between'>
									<ClaimTicketHeaderText text='Ticket No.:' />
									<ClaimTicketHeaderText color='blue.500' text={ticketNo} />
								</Flex>
								<Flex width='100%' justifyContent='space-between'>
									<ClaimTicketHeaderText text='Bet Date Time:' />
									<ClaimTicketHeaderText
										text={ConvertedTime({ time: ticketDate, format: 'DD/MM/YYYY hh:mm A' })}
									/>
								</Flex>
								<Flex width='100%' justifyContent='space-between'>
									<ClaimTicketHeaderText text='Claimed By:' />
									<ClaimTicketHeaderText text={ticketAgentName} />
								</Flex>
								<Flex width='100%' justifyContent='space-between'>
									<ClaimTicketHeaderText text='Claimed Date Time:' />
									<ClaimTicketHeaderText
										text={ConvertedTime({ time: claimedDate, format: 'DD/MM/YYYY hh:mm A' })}
									/>
								</Flex>
							</Flex>
							<Divider marginY={2} />
							<Flex width='100%' justifyContent='space-between'>
								<ClaimTicketHeaderText text='Winning Price (PHP):' />
								<ClaimTicketHeaderText text={currencyFormat(winningPrice ?? 0)} color='green.500' />
							</Flex>
						</Flex>
					</AccordionButton>

					<AccordionPanel pb={4} style={{ borderTopWidth: '1px' }}>
						{winningNumbers?.map((data, index) => {
							return (
								<Flex
									key={index}
									fontSize={['12px', '14px', '16px']}
									fontWeight='500'
									padding='2'
									backgroundColor='#F9FBFF'
									borderRadius='8px'
									marginBottom='2'
								>
									<Flex flexDirection='column' width={['60%', '60%', '50%']}>
										<Text>Games:</Text>
										<Text marginTop={2}>Numbers:</Text>
										<Text marginTop={2}>Bet Type:</Text>
										<Text marginTop={2}>Bet (PHP):</Text>
										<Text marginTop={2}>Winning Amount (PHP):</Text>
									</Flex>

									<Flex flexDirection='column' width={['40%', '40%', '50%']}>
										<Text>{data.gameName}</Text>
										<Flex marginTop={2}>
											<Flex>{data.num}</Flex>
											<Text marginLeft={2}>{data.lp === true ? '(LP)' : ''} </Text>
											{/* <Text>13</Text> */}
										</Flex>
										<Text marginTop={2}>{data.betType === 'R' ? 'Ramble' : 'Straight'}</Text>
										<Text marginTop={2}>{data.betAmount}</Text>

										<Text marginTop={2} color={winningPrice > 0 ? 'green.500' : '#000000'}>
											{currencyFormat(data.winPrice)}
										</Text>
									</Flex>
								</Flex>
							);
						})}
					</AccordionPanel>
				</AccordionItem>
			</Accordion>
		</Box>
	);
};

export default ClaimTicketBodyMobile;
