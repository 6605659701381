export const PasteBetError3D = ({ newValue, minBetAmount, setErrorMessage }) => {
	const newValueSplit = newValue.split('\n');
	newValueSplit.slice(0, 3).forEach((value, index) => {
		let valueSplit = value.split(' ');
		let valuePriceStraight = valueSplit[1];
		let valuePriceRamble = valueSplit[2];
		let maxBetAmount = 99999;

		if (value === '') {
			return null;
		} else if (
			(valueSplit.length !== 3 && value !== '') ||
			(valueSplit.length > 0 &&
				valueSplit[0].length !== 3 &&
				valueSplit[0] !== 'l' &&
				valueSplit[0] !== 'L')
		) {
			setErrorMessage((pre) => [...pre, `${index + 1}.Invalid format`]);
		} else if (
			valueSplit.length > 0 &&
			valuePriceStraight !== 0 &&
			valuePriceStraight < minBetAmount
		) {
			setErrorMessage((pre) => [
				...pre,
				`${index + 1}.Price Straight min bet ${minBetAmount} PHP `,
			]);
		} else if (
			valueSplit.length > 0 &&
			valuePriceStraight !== 0 &&
			valuePriceStraight > maxBetAmount
		) {
			setErrorMessage((pre) => [
				...pre,
				`${index + 1}.Price Straight max bet ${maxBetAmount} PHP `,
			]);
		} else if (valueSplit.length > 0 && valuePriceRamble !== 0 && valuePriceRamble < minBetAmount) {
			setErrorMessage((pre) => [...pre, `${index + 1}.Price Ramble min bet ${minBetAmount} PHP `]);
		} else if (valueSplit.length > 0 && valuePriceRamble !== 0 && valuePriceRamble > maxBetAmount) {
			setErrorMessage((pre) => [...pre, `${index + 1}.Price Ramble max bet ${maxBetAmount} PHP `]);
		} else {
			setErrorMessage((pre) => [...pre, `${index + 1}.other error`]);
		}
	});
};
