import {
	Box,
	Button,
	Card,
	CardBody,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
	useMediaQuery,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import QRCode from 'react-qr-code';
import { NavLink, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import {
	getCreditRemainApi,
	getProfileReferralCodeApi,
	getProfileUserpackageApi,
	getUserProfileApi,
	putProfileChangePassword,
} from '../api/query/profileApi';
import { CustomInput } from '../components/loginRegister/CustomInput';
import ProfileCard from '../components/profile/ProfileCard';
import { DesktopTitleHeader, MobileTitleHeader2 } from '../components/titleHeader/TitleHeader';
import { useLoginStore } from '../hooks/useStore';
import { ErrorText } from '../utils/CustomText';
import ImgComp from '../utils/ImgComp';
import { useGlobalToast } from '../utils/ShowToast';

const ProfileScreen = () => {
	const currentHost = window.location.host;
	const navigate = useNavigate();
	const globalShowToast = useGlobalToast();
	const [isSmallerThan1024] = useMediaQuery('(max-width: 1023px)');
	// zustand
	const setIsRegisterLink = useLoginStore((state) => state.setIsRegisterLink);
	// state
	const [isAgentQR, setIsAgentQR] = useState();
	const [refCode, setRefCode] = useState('');
	const [data, setData] = useState();
	// modal
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isOpenQR, onOpen: onOpenQR, onClose: onCloseQR } = useDisclosure();
	const { isOpen: isOpenPackage, onOpen: onOpenPackage, onClose: onClosePackage } = useDisclosure();

	//* api
	let userId = sessionStorage.getItem('userId');
	const getUserProfile = useQuery([`getUserProfileApi`, userId], () =>
		getUserProfileApi({ userId: userId }),
	);

	const getProfileReferralCode = useQuery(
		[`getProfileReferralCodeApi`, isAgentQR],
		() => getProfileReferralCodeApi({ isAgent: isAgentQR }),
		{ enabled: false },
	);
	const getCreditRemain = useQuery(['getCreditRemainApi'], getCreditRemainApi);
	const getProfileUserpackage = useQuery(['getProfileUserpackageApi'], getProfileUserpackageApi);
	const queryClient = useQueryClient();

	const invalidateConfirmChangePasswordQuery = () =>
		queryClient.invalidateQueries({ queryKey: ['putProfileChangePassword'] });
	const profileChangePassword = useMutation({
		mutationFn: () => putProfileChangePassword(update),
		onSuccess: (succ) => {
			invalidateConfirmChangePasswordQuery();
			reset({
				oldPassword: '',
				newPassword: '',
				confirmPassword: '',
			});
			globalShowToast({
				title: `${succ.message}`,
				status: 'success',
			});
			onClose();
		},
		onError: (err) => {
			reset({
				oldPassword: '',
				newPassword: '',
				confirmPassword: '',
			});
			globalShowToast({
				title: `${
					err.response.data.data.message === 'Bad credentials' ? 'Current password incorrect.' : ''
				}`,
				status: 'error',
			});
			onClose();
		},
	});

	const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/profile/getReferralCode`;
	const authToken = sessionStorage.getItem('accessToken');

	const fetchRefCodeApi = () => {
		axios
			.get(apiUrl, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			})
			.then((response) => {
				setData(response.data.data);
			})
			.catch(() => {});
	};

	// form
	const schema = yup
		.object({
			oldPassword: yup.string().required('Please enter current password.'),
			newPassword: yup
				.string()
				.required('Please enter new password.')
				.trim('Password cannot include leading and trailing spaces.')
				.strict(true)
				.min(8, 'Password is too short - should be 8 Characters Long minimum.')
				.max(16, 'Password is too long - should be 16 Characters Long maximum.')
				.matches(/[a-z]+/, 'Password must have at least one Lowercase Character.')
				.matches(/[A-Z]+/, 'Password must have at least one Uppercase Character.')
				.matches(/\d+/, 'Password must contain at least one Digit.'),
			confirmPassword: yup
				.string()
				.required('Please enter confirm password.')
				.oneOf([yup.ref('newPassword'), null], 'Passwords not match.'),
		})
		.required();

	const {
		control,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			oldPassword: '',
			newPassword: '',
			confirmPassword: '',
		},
	});

	const formValues = useWatch({
		control,
		name: ['oldPassword', 'newPassword', 'confirmPassword'],
	});

	const [oldPassword, newPassword] = formValues;
	const update = { oldPassword, newPassword };

	// function
	const handleURL = (event) => {
		const receivedData = JSON.parse(event.data);
		setIsRegisterLink(receivedData.link);
		setTimeout(() => {
			navigate(`${receivedData.navigate}`);
		}, 300);
	};

	// useEffect
	useEffect(() => {
		if (isAgentQR !== undefined && data !== undefined) {
			setRefCode(currentHost + '/register?referralCode=' + data);
		}
	}, [isAgentQR]);

	useEffect(() => {
		document.addEventListener('message', handleURL);
		return () => {
			document.removeEventListener('message', handleURL);
		};
	}, []);

	useEffect(() => {
		fetchRefCodeApi();
	}, [isAgentQR]);

	// handleSubmit
	const onSubmit = () => {
		profileChangePassword.mutate();
	};

	return (
		<>
			<Box
				paddingX={['3', '3', '5', '5', '10']}
				paddingTop={['80px', '80px', '80px', '80px', '30px', '30px', '30px']}
				width='100%'
				minHeight={['100vh', '100vh', '100vh', '100vh', 'calc(100vh - 150px)']}
				marginBottom={10}
			>
				{isSmallerThan1024 ? (
					<MobileTitleHeader2 title={'Profile'} />
				) : (
					<DesktopTitleHeader title={'Profile'} />
				)}

				<Card>
					<CardBody paddingBottom='4'>
						{getCreditRemain.isSuccess && (
							<ProfileCard
								isAgent={getUserProfile.data?.isAgent}
								mobileNo={getUserProfile.data?.mobileNumber}
								userName={getUserProfile.data?.firstName}
								userCode={getUserProfile.data?.userCode}
								creditLimit={getCreditRemain.data?.creditLimit}
								balance={getCreditRemain.data?.creditRemain}
								referralCode={getUserProfile.data?.referralCode}
								buttonLabel='Change Password'
								onClick={() => {
									onOpen();
									if (window.smartsupp) {
										window.smartsupp(function () {
											document.getElementById(
												'smartsupp-widget-container',
											).firstElementChild.style.zIndex = '999';
											document.getElementById('widgetButtonFrame').firstElementChild.style.zIndex =
												'999';
										});
									}
								}}
								isOpen={isOpen}
								onClose={onClose}
								onClickPackage={() => onOpenPackage()}
								onClickQRPlayer={() => {
									setIsAgentQR(false);
									if (isAgentQR !== undefined && isAgentQR === false) {
										if (window.ReactNativeWebView) {
											getProfileReferralCode
												.refetch()
												.then(() =>
													setRefCode(
														currentHost + '/register?referralCode=' + getProfileReferralCode.data,
													),
												);
										} else {
											getProfileReferralCode
												.refetch()
												.then(() =>
													setRefCode(
														currentHost + '/register?referralCode=' + getProfileReferralCode.data,
													),
												);
										}
									}
									onOpenQR();
								}}
								onClickQRAgent={() => {
									setIsAgentQR(true);
									if (isAgentQR !== undefined && isAgentQR === false) {
										getProfileReferralCode
											.refetch()
											.then(() =>
												setRefCode(
													currentHost + '/register?referralCode=' + getProfileReferralCode.data,
												),
											);
									}
									onOpenQR();
								}}
							/>
						)}
					</CardBody>
				</Card>

				{getUserProfile.data?.isAgent === true &&
				getUserProfile.data?.referralCode !== '' &&
				sessionStorage.getItem('isThirdPartyLogin') === 'false' ? (
					<NavLink to='/profile/downline'>
						<Flex
							justifyContent='space-between'
							borderWidth='1px'
							borderRadius='8px'
							backgroundColor='white'
							paddingX='10px'
							paddingY='5px'
							marginY='10px'
							alignItems='center'
							userSelect='none'
							cursor='pointer'
						>
							<Text
								fontSize={['14px', '', '', '18px']}
								fontWeight={['500', '', '', '600']}
								userSelect='none'
							>
								Downline Profiles
							</Text>
							<ImgComp svgName='right-arrow' />
						</Flex>
					</NavLink>
				) : null}
			</Box>

			{/* Package Modal */}
			{isOpenPackage && (
				<Modal isOpen={isOpenPackage} onClose={onClosePackage} isCentered size={['sm', 'sm', 'lg']}>
					<ModalOverlay />
					<ModalContent marginX={3}>
						<ModalHeader>
							<Text textAlign='center' marginTop={5} marginBottom={-4}>
								{getProfileUserpackage.data?.pkgName}
							</Text>
						</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							{getProfileUserpackage.data?.pkgInfo?.length > 0 ? (
								<Box alignItems='center' justifyContent='center'>
									{getProfileUserpackage.data?.pkgInfo?.map((items, index) => {
										return (
											<Flex
												key={index}
												borderWidth={1}
												borderRadius={8}
												paddingX={3}
												paddingY={5}
												marginBottom={3}
											>
												<ImgComp svgName={String(items.gameType).toLowerCase()} />
												<Flex flexDirection='column' paddingLeft='15%'>
													<Text fontWeight='400' fontSize='12px' color='blue.250'>
														Commission
													</Text>
													<Text fontWeight='500' fontSize='16px' color='black'>
														{items.commission}
													</Text>
													<Text fontWeight='400' fontSize='12px' color='blue.250'>
														Prize
													</Text>
													<Text fontWeight='500' fontSize='16px' color='black'>
														{items.prize}
													</Text>
												</Flex>
											</Flex>
										);
									})}
								</Box>
							) : (
								<Box alignSelf='center' justifyContent='center' height='100px' margin='auto'>
									<Text textAlign='center' fontWeight='400' fontSize='18px'>
										No Package.
									</Text>
								</Box>
							)}
						</ModalBody>
					</ModalContent>
				</Modal>
			)}

			{/* Change password modal */}
			{isOpen && (
				<Modal
					isOpen={isOpen}
					onClose={() => {
						onClose();
						reset();
					}}
					isCentered
					size={['sm', 'sm', 'lg']}
					closeOnOverlayClick={false}
				>
					<ModalOverlay />
					<ModalContent marginX={3}>
						<ModalHeader></ModalHeader>
						<ModalCloseButton />
						<ModalBody marginTop={4}>
							<Flex flexDirection='column'>
								<Controller
									name='oldPassword'
									control={control}
									rules={{ required: true }}
									render={({ field: { value } }) => (
										<CustomInput
											isPasswordInput
											title={'Current Password'}
											maxLegth={16}
											onChange={(e) => {
												e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
												setValue('oldPassword', `${e.target.value}`);
											}}
											value={value}
											placeholder='Abc12345'
											errorMessage={
												errors.oldPassword ? (
													<ErrorText
														color='red'
														fontSize='12px'
														text={errors.oldPassword?.message}
													/>
												) : (
													''
												)
											}
										/>
									)}
								/>
								<Controller
									name='newPassword'
									control={control}
									rules={{ required: true }}
									render={({ field: { value } }) => (
										<CustomInput
											isPasswordInput
											title={'New Password'}
											maxLegth={16}
											onChange={(e) => {
												e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
												setValue('newPassword', `${e.target.value}`);
											}}
											value={value}
											placeholder='Abc12345'
											errorMessage={
												errors.newPassword ? (
													<ErrorText
														color='red'
														fontSize='12px'
														text={errors.newPassword?.message}
													/>
												) : (
													''
												)
											}
										/>
									)}
								/>
								<Controller
									name='confirmPassword'
									control={control}
									rules={{ required: true }}
									render={({ field: { value } }) => (
										<CustomInput
											isPasswordInput
											title={'Confirm Password'}
											maxLegth={16}
											onChange={(e) => {
												e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
												setValue('confirmPassword', `${e.target.value}`);
											}}
											value={value}
											placeholder='Abc12345'
											errorMessage={
												errors.confirmPassword ? (
													<ErrorText
														color='red'
														fontSize='12px'
														text={errors.confirmPassword?.message}
													/>
												) : (
													''
												)
											}
										/>
									)}
								/>
							</Flex>
							<Flex>
								<Flex justifyContent='space-between' width='50%'></Flex>
								<Flex justifyContent='space-evenly' width='50%' align='flex-end' marginY='20px'>
									<Button bgColor='#22943B' onClick={handleSubmit(onSubmit)} marginRight={2}>
										<Text color='white'>Save</Text>
									</Button>
									<Button
										onClick={() => {
											onClose();
											reset();
										}}
									>
										<Text>Cancel</Text>
									</Button>
								</Flex>
							</Flex>
						</ModalBody>
					</ModalContent>
				</Modal>
			)}

			{/* QR Modal */}
			{isAgentQR !== undefined && (
				<>
					{isOpenQR && (
						<Modal isOpen={isOpenQR} onClose={onCloseQR} isCentered size={['sm', 'sm', 'lg']}>
							<ModalOverlay />
							<ModalContent marginX={3}>
								<ModalCloseButton />
								<ModalBody>
									<Box margin={10} alignItems='center' justifyContent='center'>
										<Text marginBottom={5} fontSize={['12px', '14px', '16px', '16px', '', '20px']}>
											QR CODE FOR {isAgentQR === true ? 'AGENT' : 'PLAYER'} :{' '}
										</Text>
										<QRCode
											style={{ height: 'auto', maxWidth: '500px', width: '100%' }}
											value={
												isAgentQR === true
													? `${refCode}&registerAgent=true`
													: `${refCode}&registerAgent=false`
											}
										/>

										<Flex flexDirection='row' marginTop={5}>
											<Text
												fontSize={['12px', '14px', '16px', '16px']}
												cursor='default'
												userSelect='none'
											>
												Click&nbsp;
											</Text>

											<Text
												textDecoration='underline'
												color='blue.500'
												fontSize={['12px', '14px', '16px', '16px']}
												onClick={() => {
													if (isAgentQR === true) {
														if (window.ReactNativeWebView) {
															window.ReactNativeWebView.postMessage(
																JSON.stringify({
																	function: 'registerScreen',
																	link: `/register?referralCode=${getUserProfile.data?.referralCode}&registerAgent=true`,
																	location: window.location.origin,
																}),
															);
														} else {
															window.open(
																`/register?referralCode=${getUserProfile.data?.referralCode}&registerAgent=true`,
																'_blank',
															);
														}
													} else {
														if (window.ReactNativeWebView) {
															window.ReactNativeWebView.postMessage(
																JSON.stringify({
																	function: 'registerScreen',
																	link: `/register?referralCode=${getUserProfile.data?.referralCode}&registerAgent=false`,
																	location: window.location.origin,
																}),
															);
														} else {
															window.open(
																`/register?referralCode=${getUserProfile.data?.referralCode}&registerAgent=false`,
																'_blank',
															);
														}
													}
												}}
												cursor='pointer'
											>
												here
											</Text>
											<Text
												fontSize={['12px', '14px', '16px', '16px']}
												cursor='default'
												userSelect='none'
											>
												&nbsp;to register.
											</Text>
										</Flex>
									</Box>
								</ModalBody>
							</ModalContent>
						</Modal>
					)}
				</>
			)}
		</>
	);
};

export default ProfileScreen;
