import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
	config: {
		initialColorMode: 'light',
		useSystemColorMode: false,
	},

	colors: {
		orange: {
			500: '#F47B0B',
		},
		blue: {
			100: '#F9FAFC',
			150: '#ECF3FF',
			200: '#EDF3FF',
			230: '#D3E1FC',
			250: '#2688E1',
			300: '#BDCCF3',
			350: '#200DF8',
			400: '#F9FBFF',
			500: '#0052FF',
			600: '#3AA9DC',
			700: '#003198',
			800: '#1650CD',
		},

		gray: {
			50: '#EDEDED',
			100: '#E0E0E0',
			200: '#D2D8E4',
			300: '#A0A0A0',
			400: '#9D9B9B',
			500: '#696969', //errorMsg in login/register
			600: '#C4C4C4',
			700: '#F0F0F0',
		},

		green: {
			100: '#CFF1D7',
			300: '#07E738',
			400: '#36CC45',
			450: '#29B247',
			500: '#22943B',
			600: '#1A6E2C',
		},

		red: {
			100:'#FED7D7',
			200: '#FCE5E6',
			400: '#E52A2A',
			500: '#DA100B',
			600: '#C53834',
		},
		myColor: {
			500: '#ff0000', // Replace with your desired color
		},
	},

	breakpoints: {
		sm: '340px',
		sm2: '700px',
		md: '960px',
		lg: '1024px',
		xl: '1440px',
		'2xl': '1920px',
		'3xl': '2560px',
	},
});

export default theme;
