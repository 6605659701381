import React from 'react';
import { Flex, Text, Box, Divider } from '@chakra-ui/react';
import ImgComp from '../../utils/ImgComp';
import { currencyFormat } from '../../utils/numberFormat';

const HistoryRow = ({ title, data, color }) => {
	return (
		<Flex justifyContent='space-between' marginY='10px' alignItems='center'>
			<Text fontSize='12px' fontWeight='300' color='black'>
				{title}
			</Text>
			<Text
				fontSize='13px'
				fontWeight={title === 'Status' ? ['700', '', '', '', '', '500'] : '500'}
				color={color}
			>
				{data}
			</Text>
		</Flex>
	);
};

const HistoryList = ({
	status,
	timeData,
	ticketId,
	gameType,
	betTotal,
	amountTtl,
	isExpire,
	mobileNo,
	userType,
	userName,
	onClick,
}) => {
	return (
		<>
			<Box
				backgroundColor='blue.400'
				borderColor={
					status === 'Voided' || status === 'Denied'
						? 'red.300'
						: status === 'Striked'
						? isExpire === true
							? ''
							: 'green.400'
						: status === 'Claimed'
						? 'blue.500'
						: ''
				}
				borderWidth={2}
				width={['100%', '100%', '95%']}
				borderRadius={6}
				marginY={3}
				cursor='pointer'
				padding={4}
				onClick={onClick}
			>
				<Flex flexDirection='row' alignItems='center' justifyContent='space-between' paddingY={0}>
					<Flex alignItems='center'>
						<Flex flexDirection='column'>
							<Text
								fontSize='16px'
								fontWeight='500'
								color='black'
								maxWidth={['50vw', '50vw', '150px', '180px', '165px', '270px', '250px']}
								wrap='wrap'
								noOfLines={1}
							>
								{userName}
							</Text>
							{sessionStorage.getItem('isThirdPartyLogin') === 'false' && (
								<Text fontSize='12px' fontWeight='300' color='black'>
									Mobile No: {mobileNo}
								</Text>
							)}
							<Text fontSize='12px' fontWeight='300' color='black'>
								User Type: {userType}
							</Text>
						</Flex>
					</Flex>
					<Box w={10} h={10}>
						<ImgComp svgName='arrowRight' />
					</Box>
				</Flex>

				<Divider mt='10px' />
				<Box>
					<HistoryRow title='Ticket No.' data={ticketId} color='blue.500' />
					<HistoryRow title='Bet Time' data={timeData} />
					<HistoryRow title='Games' data={gameType} />
					<HistoryRow title='Total Bet (PHP)' data={betTotal} color='#22943B' />

					<HistoryRow
						title='Total Winning (PHP)'
						data={Math.trunc(amountTtl) === 0 ? '-' : currencyFormat(Math.trunc(amountTtl))}
						color={Math.trunc(amountTtl) === 0 ? '' : '#22943B'}
					/>
					<HistoryRow
						title='Status'
						data={
							(() => {
								if (status === 'Voided' || status === 'Denied') {
									if (status === 'Voided') {
										return 'Voided';
									} else {
										return 'Denied';
									}
								} else if (status === 'Striked') {
									return 'Striked';
								} else if (status === 'Claimed') {
									return 'Claimed';
								} else {
									return 'Posted';
								}
							})()
							// status === 'Voided' || status === 'Denied' ? (
							// 	status === 'Voided' ? (
							// 		'Voided'
							// 	) : (
							// 		'Denied'
							// 	)
							// ) : status === 'Striked' ? (
							// 	'Striked'
							// ) : status === 'Claimed' ? (
							// 	<Text color='blue.500' fontWeight={['700', '', '', '', '', '500']}>
							// 		Claimed
							// 	</Text>
							// ) : (
							// 	<Text color='' fontWeight={['700', '', '', '', '', '500']}>
							// 		Posted
							// 	</Text>
							// )
						}
						color={
							(() => {
								if (status === 'Voided' || status === 'Denied') {
									return '#E52A2A';
								} else if (status === 'Striked') {
									if (isExpire === true) {
										return '';
									} else {
										return 'green.500';
									}
								} else if (status === 'Claimed') {
									return 'blue.500';
								} else {
									return '';
								}
							})()
							// status === 'Voided' || status === 'Denied'
							// 	? '#E52A2A'
							// 	: status === 'Striked'
							// 	? isExpire === true
							// 		? ''
							// 		: 'green.500'
							// 	: status === 'Claimed'
							// 	? 'blue.500'
							// 	: ''
						}
					/>
				</Box>
			</Box>
		</>
	);
};

export default HistoryList;
