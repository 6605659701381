import { Image } from '@chakra-ui/react';

const ImgComp = ({ svgName, onClick, zIndex, width, height, ...props }) => {
	const svgPath = `/assets/svg/${svgName}.svg`;
	return (
		<Image
			src={svgPath}
			alt={svgName || 'img'}
			width={width}
			height={height}
			pointerEvents='none'
			zIndex={zIndex}
			onClick={onClick}
			userSelect='none'
			{...props}
		/>
	);
};
export default ImgComp;
