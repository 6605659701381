import {
	Box,
	Button,
	Card,
	Checkbox,
	Divider,
	Flex,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { severAxiosInstance } from '../api/query/api';
import { getSuppKeyApi, getVersionApi, postUserLogin } from '../api/query/authApi';
import CustomButton from '../components/loginRegister/CustomButton';
import { CustomInput } from '../components/loginRegister/CustomInput';
import Title from '../components/loginRegister/Title';
import { useLoginStore, useStore } from '../hooks/useStore';
import { ErrorText } from '../utils/CustomText';
import ImgComp from '../utils/ImgComp';

const LoginScreen = () => {
	const navigate = useNavigate();
	// modal
	const { isOpen, onOpen, onClose } = useDisclosure();
	//zustand
	const { countryCode, setCountryCode } = useStore();
	const setIsLoggedIn = useLoginStore((state) => state.setIsLoggedIn);

	//state
	// const [isOnline, setIsOnline] = useState(navigator.onLine);
	const [isMobileLandscape, setIsMobileLandscape] = useState(false);
	const [errMessage, setErrMessage] = useState('');
	const [isSelected, setIsSelected] = useState(false);
	// const [apkLogin, setApkLogin] = useState(false);

	//react-hooks-form
	const schema = yup
		.object({
			mobileNum: yup
				.string()
				.required('Please enter mobile number.')
				.trim('Mobile number cannot include leading and trailing spaces.')
				.strict(true)
				.matches(/^[0-9]{10}$/, 'Invalid phone format.'),
			password: yup
				.string()
				.required('Please enter the password.')
				.trim('Password cannot include leading and trailing spaces.')
				.strict(true)
				.matches(/^.{8,16}$/, 'Password must be 8-16 characters.'),
		})
		.required();

	const {
		control,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			mobileNum: '',
			password: '',
		},
	});

	const loginValue = useWatch({
		control,
		name: ['mobileNum', 'password'],
	});

	const [mobileNum, password] = loginValue;
	const phoneNumber = countryCode + mobileNum;
	const username = phoneNumber.replace(/\+/g, '');

	//* api
	//query
	const getSuppKey = useQuery(['getSuppKeyApi'], getSuppKeyApi);
	const getVersion = useQuery(['getVersionApi'], getVersionApi);

	const postUserLoginMutation = useMutation({
		mutationFn: (user) => postUserLogin(user),
		onSuccess: (data) => {
			setIsLoggedIn(true);
			setErrMessage('');
			localStorage.removeItem('ssupp_undefined');
			severAxiosInstance.defaults.headers.common.Authorization = 'Bearer ' + data.token;
			sessionStorage.setItem('isThirdPartyLogin', data.isThirdParty);
			sessionStorage.setItem('accessToken', data.token);
			sessionStorage.setItem('userId', data.id);
			sessionStorage.setItem('firstName', data.firstName);
			sessionStorage.setItem('returnUrl', data.returnUrl);
			sessionStorage.setItem('isSeamless', data.isSeamless);
			sessionStorage.setItem('balanceSyncInterval', data.balanceSyncInterval);

			if (window.ReactNativeWebView) {
				localStorage.setItem('isThirdPartyLogin', data.isThirdParty);
				localStorage.setItem('accessToken', data.token);
				localStorage.setItem('userId', data.id);
				localStorage.setItem('firstName', data.firstName);
				localStorage.setItem('returnUrl', data.returnUrl);
				localStorage.setItem('isSeamless', data.isSeamless);
				localStorage.setItem('balanceSyncInterval', data.balanceSyncInterval);
			}

			if (window.ReactNativeWebView) {
				window.ReactNativeWebView.postMessage(
					JSON.stringify({ function: 'storeToken', authToken: data.token }),
				);
			}

			if (isSelected === true) {
				reset({ mobileNum: mobileNum, password: '' });
				localStorage.setItem('Phone Number', username);
			} else {
				reset({ mobileNum: '', password: '' });
				localStorage.removeItem('Phone Number');
			}

			if (data.conversationId === '') {
				navigate('/authenticate');
			} else {
				localStorage.setItem(
					`ssupp_${getSuppKey.data?.data?.string}`,
					JSON.stringify({ vid: data.conversationId }),
				);
				navigate('/authenticate');
			}
		},
		onError: (err) => {
			if (
				err.response.data.message === 'Bad credentials' ||
				err.response.data.message === 'No value present'
			) {
				setErrMessage('Invalid number or wrong password.');
			} else {
				setErrMessage(err.response.data.message);
			}
		},
	});

	// function
	//sessionstorage chakraui when change cannot be work
	const handleStorageChange = (event) => {
		if (event.key === 'chakra-ui-color-mode') {
			if (event.oldValue !== event.newValue) {
				localStorage.setItem('chakra-ui-color-mode', event.oldValue);
			}
		}
	};

	const getLoginData = async () => {
		const value = localStorage.getItem('Phone Number');
		const new_value = value.substring(2);
		const rememberPWValue = localStorage.getItem('isRememberPW');

		if (value.slice(0, 2) === '63') {
			setCountryCode('63');

			if (value !== null) {
				if (rememberPWValue === 'true') {
					setValue('mobileNum', new_value);
					setValue('password', '');
					setIsSelected(JSON.parse(rememberPWValue));
				} else {
					setValue('mobileNum', new_value);
				}
			}
		}
	};

	useEffect(() => {
		if (sessionStorage.getItem('editSessionStorage') === 'true') {
			onOpen();
		}
		getLoginData();
		// ===============================================================
		//detect rotation in mobile
		const handleOrientationChange = () => {
			setIsMobileLandscape(window.orientation === 90 || window.orientation === -90);
		};
		handleOrientationChange();

		window.addEventListener('orientationchange', handleOrientationChange);
		//================================================================
		// close shortcuts inspector
		const handleKeyDown = (event) => {
			// command + option + I (ios)
			// command + shift + C (ios)
			// F12
			// ctrl + shift + C (windows)
			if (
				(event.metaKey && 'KeyI' && event.altKey) ||
				(event.metaKey && event.shiftKey && event.keyCode === 67) ||
				event.keyCode === 123 ||
				(event.keyCode === 17 && event.shiftKey && event.keyCode === 67) ||
				(event.keyCode === 16 && event.keyCode === 17 && 'KeyI')
			) {
				event.preventDefault(); // Prevent opening inspect mode
			}
		};
		window.addEventListener('keydown', handleKeyDown);
		// =================================================================
		//sessionstorage chakraui when change cannot be work
		window.addEventListener('storage', handleStorageChange);
		return () => {
			window.removeEventListener('orientationchange', handleOrientationChange);
			window.removeEventListener('keydown', handleKeyDown);
			window.removeEventListener('storage', handleStorageChange);
		};
	}, []);

	useEffect(() => {
		// Set Authorization header for Axios based on the environment (sessionStorage or localStorage)
		const accessToken = sessionStorage.getItem('accessToken');

		severAxiosInstance.defaults.headers.common.Authorization = 'Bearer ' + accessToken;

		// Handle specific logic if accessToken is present
		if (accessToken) {
			getSuppKey.refetch();
		}
	}, [window.ReactNativeWebView, getSuppKey]);

	//reset sessionstorage
	useEffect(() => {
		severAxiosInstance.defaults.headers.common.Authorization =
			'Bearer ' + sessionStorage.getItem('accessToken');

		if (
			sessionStorage.getItem('isThirdPartyLogin') === 'true' &&
			!window.ReactNativeWebView &&
			sessionStorage.getItem('accessToken') !== null
		) {
			navigate('/pcso');
		} else {
			if (window.ReactNativeWebView && sessionStorage.getItem('accessToken') !== null) {
				sessionStorage.setItem('isThirdPartyLogin', 'false');
				// sessionStorage.clear();
			}
		}
	}, []);

	useEffect(() => {
		if (localStorage.getItem('accessToken') !== null) {
			sessionStorage.setItem('isThirdPartyLogin', localStorage.getItem('isThirdPartyLogin'));
			sessionStorage.setItem('accessToken', localStorage.getItem('accessToken'));
			sessionStorage.setItem('userId', localStorage.getItem('userId'));
			sessionStorage.setItem('firstName', localStorage.getItem('firstName'));
			sessionStorage.setItem('returnUrl', localStorage.getItem('returnUrl'));
			sessionStorage.setItem('isSeamless', localStorage.getItem('isSeamless'));
			sessionStorage.setItem('balanceSyncInterval', localStorage.getItem('balanceSyncInterval'));
			navigate('/pcso');
		}
	}, []);

	useEffect(() => {
		localStorage.setItem(`ssupp_${getSuppKey.data?.data?.string}`, '');
	}, [getSuppKey.isSuccess, getSuppKey.data]);

	//live chat
	useEffect(() => {
		if (window.smartsupp) {
			window.smartsupp(function () {
				document.getElementById('smartsupp-widget-container').style.display = 'none';
			});
		} else {
			setTimeout(() => {
				window.smartsupp(function () {
					document.getElementById('smartsupp-widget-container').style.display = 'none';
				});
			}, 3000);
		}
	}, []);

	// handleSubmit
	const onSubmit = () => {
		postUserLoginMutation.mutate({
			username,
			password,
			isApk: window.ReactNativeWebView ? true : false,
		});
		localStorage.setItem('isRememberPW', JSON.stringify(isSelected));
	};

	if (isMobileOnly && isMobileLandscape)
		return (
			<Flex
				bgColor='black'
				height='100vh'
				width='100vw'
				justifyContent='center'
				alignItems='center'
				flexDirection='column'
			>
				<ImgComp svgName='rotate' />
				<Text fontSize='3xl' color='white'>
					Please rotate your device.
				</Text>
			</Flex>
		);

	return (
		<>
			<Flex
				flexDirection='column'
				display='flex'
				alignItems='center'
				justifyContent='center'
				padding={['40px']}
				bgGradient='linear(to-b, blue.200, blue.100)'
				width='100%'
				// height={window.innerHeight}
				minHeight='100vh'
				// maxHeight='100vh'
				onContextMenu={(e) => {
					e.preventDefault();
				}}
				overflow='hidden'
			>
				{sessionStorage.getItem('isThirdPartyLogin') !== 'true' && (
					<>
						<Flex
							alignItems='center'
							justifyContent='center'
							width='100%'
							bgColor='blue.200'
							marginBottom={[2, 2, 2, 2, 5]}
						>
							<ImgComp svgName={'logo'} width={['50px']} height={['50px']} />
							<Flex paddingLeft={2}>
								<ImgComp svgName={'panalo'} width={['150px']} height={['50px']} />
							</Flex>
						</Flex>
						<Flex justifyContent='center'>
							<Card
								paddingX={['20px', '20px', '20px', '20px', '40px', '40px', '40px']}
								paddingY={['20px', '20px', '20px', '20px', '40px', '40px', '40px']}
								width={[
									'90vw',
									'90vw',
									'fit-content',
									'fit-content',
									'fit-content',
									'fit-content',
									'fit-content',
								]}
								borderRadius={'8px'}
							>
								<Flex flexDirection='column'>
									<Title title={'Sign In'} />
									<Controller
										name='username'
										control={control}
										rules={{ required: true }}
										render={({ field: { onChange, value } }) => (
											<CustomInput
												title={'Mobile Number'}
												type='tel'
												isMobileInput
												onChange={(e) => {
													e.target.value = e.target.value.replace(/[^0-9]/g, '');
													setValue('mobileNum', e.target.value);
												}}
												placeholder='960 763 7229'
												value={mobileNum}
												maxLength={10}
												onKeyUp={(e) => {
													if (e.key === 'Enter') {
														onSubmit();
													}
												}}
												errorMessage={
													errors.mobileNum ? (
														<ErrorText
															fontSize='12px'
															fontWeight='300'
															color='red'
															width={['70vw', '70vw', '44vw', '44vw', '44vw', '44vw', '44vw']}
															text={errors.mobileNum?.message}
														/>
													) : (
														<ErrorText
															fontSize='12px'
															fontWeight='300'
															color='red'
															width={['70vw', '70vw', '44vw', '44vw', '44vw', '44vw', '44vw']}
															text={errMessage}
														/>
													)
												}
											/>
										)}
									/>

									<Controller
										name='password'
										control={control}
										rules={{ required: true }}
										render={({ field: { value } }) => (
											<CustomInput
												isPasswordInput
												title={'Password'}
												maxLength={16}
												onChange={(e) => {
													setValue('password', e.target.value);
												}}
												value={value}
												placeholder='Abc12345'
												onKeyUp={(e) => {
													if (e.key === 'Enter') {
														onSubmit();
													}
												}}
												errorMessage={
													errors.password ? (
														<ErrorText
															fontSize='12px'
															fontWeight='300'
															color='red'
															width={['70vw', '70vw', '44vw', '44vw', '44vw', '44vw', '44vw']}
															text={errors.password?.message}
														/>
													) : (
														<ErrorText
															fontSize='12px'
															fontWeight='300'
															color='red'
															width={['70vw', '70vw', '44vw', '44vw', '44vw', '44vw', '44vw']}
															text={errMessage}
														/>
													)
												}
											/>
										)}
									/>
									<Flex
										alignItems='center'
										marginTop='15px'
										display={['wrap', 'wrap', 'flex', 'flex', 'flex', 'flex', 'flex']}
									>
										<Flex
											flexDirection='column'
											width={['50%', '', '', '', '30%']}
											paddingBottom={['15px', '', '', '', '32px']}
										>
											<Checkbox
												isChecked={isSelected}
												colorScheme='twitter'
												fontSize='14px'
												onChange={() => {
													setTimeout(() => {
														setIsSelected(!isSelected);
													}, 500);
												}}
											>
												Remember me?
											</Checkbox>
										</Flex>
									</Flex>

									{/* Sign in button */}
									<CustomButton
										btntitle='Sign In'
										onClick={handleSubmit(onSubmit)}
										borderColor='green.300'
										color='#FFFFFF'
										fontWeight='600'
										fontSize={['22px', '22px', '26px', '26px', '26px', '26px', '26px']}
										height='58px'
										width='100%'
										backgroundColor='green.500'
										borderRadius='10px'
										_active={{ backgroundColor: 'green.400' }}
										isLoading={postUserLoginMutation.isLoading ? true : false}
									/>
									<Box style={{ height: '10px', paddingBottom: ['5px', '', '', '', '', '16px'] }}>
										<Divider />
									</Box>

									<Flex width='100%' justifyContent='center'>
										<Text
											style={{
												fontSize: '13px',
												fontWeight: '400',
												color: '#000000',
												paddingBottom: '8px',
											}}
											onClick={() => {
												setValue('password', '');
												setTimeout(() => {
													navigate('/register');
												}, 50);
											}}
											cursor='pointer'
											_hover={{ textDecoration: 'underline' }}
										>
											Register
										</Text>
									</Flex>
								</Flex>
							</Card>
						</Flex>
						{getVersion.isSuccess && (
							<Flex alignItems='center' justifyContent='center' width='100%' marginTop={5}>
								<Text
									textAlign='center'
									color='gray.500'
									fontSize='14px'
									position='absolute'
									bottom={0}
								>
									Version {getVersion.data?.version}
								</Text>
							</Flex>
						)}
					</>
				)}
			</Flex>

			{isOpen && (
				<Modal isOpen={isOpen} isCentered>
					<ModalOverlay />
					<ModalContent marginX={3}>
						<ModalHeader>
							<Text>Your Session Has Expired.</Text>
						</ModalHeader>
						<ModalFooter>
							<Button
								colorScheme='green'
								mr={3}
								onClick={() => {
									sessionStorage.clear();
									onClose();
								}}
							>
								Close
							</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
			)}
		</>
	);
};

export default LoginScreen;
