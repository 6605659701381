import {
	Flex,
	Grid,
	GridItem,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import ImgComp from '../../utils/ImgComp';
import { useGlobalToast } from '../../utils/ShowToast';

const NumericKeyboard = ({
	onKeyPress,
	onBackspace,
	onLuckyPick,
	onLeftClick,
	onRightClick,
	onAddToBet,
	onBetSubmit,
	length,
	time,
}) => {
	const globalShowToast = useGlobalToast();
	// modal
	const { isOpen, onOpen, onClose } = useDisclosure();

	// webview
	const vibrate = () => {
		window.ReactNativeWebView.postMessage(JSON.stringify({ function: 'sound' }));
	};

	// function
	const handleKeyPress = (value) => {
		onKeyPress(value);
		if (window.ReactNativeWebView) {
			vibrate();
		}
	};

	const handleBackspace = () => {
		onBackspace();
		if (window.ReactNativeWebView) {
			vibrate();
		}
	};

	const handleLuckypick = () => {
		onLuckyPick();
		if (window.ReactNativeWebView) {
			vibrate();
		}
	};

	const handleLeft = () => {
		onLeftClick();
		if (window.ReactNativeWebView) {
			vibrate();
		}
	};

	const handleRight = () => {
		onRightClick();
		if (window.ReactNativeWebView) {
			vibrate();
		}
	};

	const handleAdd = () => {
		onAddToBet();
		if (window.ReactNativeWebView) {
			vibrate();
		}
	};

	const handleBet = () => {
		onBetSubmit();
	};

	return (
		<>
			<Flex width='100%' userSelect='none' justifyContent='center' alignItems='center'>
				<Grid templateColumns='repeat(4, 1fr)'>
					{[1, 2, 3].map((number, index) => (
						<GridItem
							key={index}
							width='24vw'
							height='6.5vh'
							bgColor='white'
							borderColor='#959595'
							borderWidth='1px'
							borderRadius='3px'
							fontSize='20px'
							fontWeight='600'
							_active={{ backgroundColor: '#EAEAEA' }}
						>
							<Flex
								height='100%'
								justifyContent='center'
								alignItems='center'
								onClick={() => {
									handleKeyPress(number);
								}}
								size='lg'
							>
								{number}
							</Flex>
						</GridItem>
					))}
					<GridItem
						width='24vw'
						height='6.5vh'
						bgColor='white'
						textAlign='center'
						borderColor='#959595'
						borderWidth='1px'
						borderRadius='3px'
						_active={{ backgroundColor: '#EAEAEA' }}
					>
						<Flex
							height='100%'
							justifyContent='center'
							alignItems='center'
							onClick={handleBackspace}
							size='lg'
						>
							<ImgComp svgName='backspace' />
						</Flex>
					</GridItem>
					{[4, 5, 6].map((number, index) => (
						<GridItem
							key={index}
							width='24vw'
							height='6.5vh'
							bgColor='white'
							textAlign='center'
							borderColor='#959595'
							borderWidth='1px'
							borderRadius='3px'
							fontSize='20px'
							fontWeight='600'
							_active={{ backgroundColor: '#EAEAEA' }}
						>
							<Flex
								height='100%'
								justifyContent='center'
								alignItems='center'
								onClick={() => handleKeyPress(number)}
								size='lg'
							>
								{number}
							</Flex>
						</GridItem>
					))}
					<GridItem
						width='24vw'
						height='6.5vh'
						bgColor='white'
						textAlign='center'
						borderColor='#959595'
						borderWidth='1px'
						borderRadius='3px'
						_active={{ backgroundColor: '#EAEAEA' }}
					>
						<Flex
							height='100%'
							justifyContent='center'
							alignItems='center'
							onClick={handleLuckypick}
							size='lg'
							padding='10px'
						>
							<Text fontSize='14px' fontWeight='700' color='red.700'>
								Lucky Pick
							</Text>
						</Flex>
					</GridItem>
					{[7, 8, 9].map((number, index) => (
						<GridItem
							key={index}
							width='24vw'
							height='6.5vh'
							bgColor='white'
							textAlign='center'
							borderColor='#959595'
							borderWidth='1px'
							borderRadius='3px'
							fontSize='20px'
							fontWeight='600'
							_active={{ backgroundColor: '#EAEAEA' }}
						>
							<Flex
								height='100%'
								justifyContent='center'
								alignItems='center'
								onClick={() => handleKeyPress(number)}
								size='lg'
							>
								{number}
							</Flex>
						</GridItem>
					))}
					<GridItem
						width='24vw'
						height='6.5vh'
						bgColor='white'
						textAlign='center'
						borderColor='#959595'
						borderWidth='1px'
						borderRadius='3px'
						_active={{ backgroundColor: '#EAEAEA' }}
					>
						<Flex
							height='100%'
							justifyContent='center'
							alignItems='center'
							onClick={handleAdd}
							size='lg'
						>
							<ImgComp svgName='blackAdd' />
						</Flex>
					</GridItem>

					<GridItem
						width='24vw'
						height='6.5vh'
						bgColor='white'
						textAlign='center'
						borderColor='#959595'
						borderWidth='1px'
						borderRadius='3px'
						_active={{ backgroundColor: '#EAEAEA' }}
					>
						<Flex
							height='100%'
							justifyContent='center'
							alignItems='center'
							onClick={handleLeft}
							size='lg'
						>
							<ImgComp svgName='goLeft' />
						</Flex>
					</GridItem>
					<GridItem
						width='24vw'
						height='6.5vh'
						bgColor='white'
						textAlign='center'
						borderColor='#959595'
						borderWidth='1px'
						borderRadius='3px'
						fontSize='20px'
						fontWeight='600'
						_active={{ backgroundColor: '#EAEAEA' }}
					>
						<Flex
							height='100%'
							justifyContent='center'
							alignItems='center'
							onClick={() => {
								handleKeyPress(0);
							}}
							size='lg'
						>
							0
						</Flex>
					</GridItem>
					<GridItem
						width='24vw'
						height='6.5vh'
						bgColor='white'
						textAlign='center'
						borderColor='#959595'
						borderWidth='1px'
						borderRadius='3px'
						_active={{ backgroundColor: '#EAEAEA' }}
					>
						<Flex
							height='100%'
							justifyContent='center'
							alignItems='center'
							onClick={handleRight}
							size='lg'
						>
							<ImgComp svgName='goRight' />
						</Flex>
					</GridItem>
					<GridItem
						width='24vw'
						height='6.5vh'
						bgColor='white'
						textAlign='center'
						borderColor='#959595'
						borderWidth='1px'
						borderRadius='3px'
						_active={{ backgroundColor: '#EAEAEA' }}
					>
						<Flex
							height='100%'
							justifyContent='center'
							alignItems='center'
							onClick={() => {
								if (time !== '') {
									if (length >= 1) {
										onOpen();
									} else {
										globalShowToast({
											title: 'Add (+) number to bet list first.',
											status: 'error',
										});
									}
								}
							}}
							size='lg'
							bgColor='green.500'
						>
							<Text fontSize='18px' fontWeight='700' color='white'>
								Bet
							</Text>
						</Flex>
					</GridItem>
				</Grid>
			</Flex>

			{isOpen && (
				<Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false}>
					<ModalOverlay />
					<ModalContent marginX={3}>
						<ModalCloseButton />
						<ModalBody>
							<Flex
								flexDirection='column'
								justifyContent='center'
								alignItems='center'
								marginTop='20px'
							>
								<Text fontSize='2xl' fontWeight='500'>
									Confirm to bet?
								</Text>
							</Flex>
						</ModalBody>

						<ModalFooter>
							<Flex justifyContent='center' width='100%' alignItems='center' marginY='15px'>
								<Flex
									bgColor={'red.200'}
									onClick={() => {
										onClose();
									}}
									height='40px'
									width='104px'
									justifyContent='center'
									alignItems='center'
									borderRadius={8}
									marginRight={2}
									fontWeight={600}
									userSelect='none'
									cursor='pointer'
								>
									<Text color='red.400' textAlign='center' fontSize='16px' fontWeight={600}>
										Cancel
									</Text>
								</Flex>
								<Flex
									bgColor={'green.500'}
									onClick={() => {
										handleBet();
										onClose();
									}}
									height='40px'
									width='104px'
									justifyContent='center'
									alignItems='center'
									borderRadius={8}
									userSelect='none'
									cursor='pointer'
								>
									<Text color='white' textAlign='center' fontSize='16px' fontWeight={600}>
										Confirm
									</Text>
								</Flex>
							</Flex>
						</ModalFooter>
					</ModalContent>
				</Modal>
			)}
		</>
	);
};

export default NumericKeyboard;
