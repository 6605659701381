import {
	Box,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { QrScanner } from '@yudiel/react-qr-scanner';
import React from 'react';

const ScanRefCodeModal = ({
	isOpen,
	onClose,
	onResult,
	onError,
	onDecode,
	isOpenError,
	onCloseError,
	error,
}) => {
	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false}>
				<ModalOverlay />
				<ModalContent paddingY={5} marginX={3}>
					<ModalCloseButton />

					<ModalBody>
						<Text fontSize='20px' fontWeight='700' marginBottom='10px'>
							Scan your Upline Referral Code here.
						</Text>
						<Text fontSize='12px' fontWeight='500' marginBottom='10px'>
							Please make sure you have allowed camera permission in your browser.
						</Text>
						<Box marginY={5}>
							<QrScanner
								onDecode={onDecode}
								onResult={onResult}
								constraints={{ facingMode: 'environment' }}
								onError={onError}
								scanDelay={5000}
							/>
						</Box>
					</ModalBody>
				</ModalContent>
			</Modal>
			<Modal isOpen={isOpenError} onClose={onCloseError} isCentered>
				<ModalOverlay />
				<ModalContent paddingY={5} marginX={3}>
					<ModalCloseButton />

					<ModalBody minHeight={'100px'}>
						<Text fontSize='20px' fontWeight='700' marginBottom='10px'>
							{error}
						</Text>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default ScanRefCodeModal;
