import { Box, Button, Flex, Grid, Text, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import { useInfiniteQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { Fragment, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { getTicketlistWithFilterApi } from '../api/query/betApi.js';
import FilterDrawer from '../components/drawer/FilterDrawer';
import HistoryList from '../components/history/HistoryList';
import FilterModal from '../components/modal/FilterModal';
import MobileTitleHeader, { DesktopTitleHeader } from '../components/titleHeader/TitleHeader';

import Loading from '../utils/Loading.js';
import { ConvertedTime } from '../utils/Time.js';
import { currencyFormat } from '../utils/numberFormat.js';

const HistoryDate = ({ date }) => {
	return (
		<Flex marginBottom={'3'}>
			<Text fontWeight='500' fontSize={['16px', '16px', '16px', '16px', '16px', '20px']}>
				{date}
			</Text>
		</Flex>
	);
};

const HistoryScreen = () => {
	const navigate = useNavigate();

	const [isSmallerThan768] = useMediaQuery('(max-width: 768px)');
	const [isSmallerThan1024] = useMediaQuery('(max-width: 1023px)');
	// modal
	const [date, setDate, maxDate] = useOutletContext();
	// state
	const [isLoading, setIsLoading] = useState(true);
	const [siteFilter, setSiteFilter] = useState('All');
	const [ticketStatus, setTicketStatus] = useState('All');
	const [nonShowInputValue, setNonShowInputValue] = useState('');
	const [inputValue, setInputValue] = useState('');

	// modal
	const { isOpen, onOpen, onClose } = useDisclosure();
	//* api
	const getTicketlistWithFilter = useInfiniteQuery(
		['getTicketlistWithFilterApi', date, siteFilter, ticketStatus, inputValue],
		({ pageParam = 1 }) =>
			getTicketlistWithFilterApi({
				drawDate: dayjs(date).format('YYYY-MM-DD hh:mm:ss'),
				ticketNumber: inputValue,
				siteName: siteFilter === 'All' ? '' : siteFilter,
				ticketStatus: ticketStatus === 'All' ? '' : ticketStatus,
				pageParam: pageParam,
				pageSize: 12,
			}),
		{
			getNextPageParam: (lastPage, pages) => {
				if (pages.length < lastPage?.paging?.totalPages) {
					return pages.length + 1;
				}
				return undefined;
			},
		},
	);

	// function
	const handleClearInput = () => {
		const inputElement = document.getElementById('search-input');
		setInputValue('');
		inputElement.value = '';
	};

	// useEffect
	useEffect(() => {
		if (getTicketlistWithFilter.isSuccess) {
			setIsLoading(false);
		} else {
			setIsLoading(true);
		}
	}, [getTicketlistWithFilter.isSuccess]);

	return (
		<>
			<Box
				minHeight={['100vh', '100vh', '100vh', '100vh', 'calc(100vh - 150px)']}
				width='100%'
				paddingX={['3', '3', '5', '5', '10']}
				paddingTop={['80px', '80px', '80px', '80px', '30px', '30px', '30px']}
			>
				{isSmallerThan1024 ? (
					<>
						{/* mobile heading */}
						<MobileTitleHeader
							onClose={onClose}
							title={'Bet History'}
							selected={new Date(date)}
							maxDate={new Date(new Date(maxDate).setDate(new Date(maxDate).getDate() + 1))}
							onChangeDate={(newDate) => {
								setDate(newDate);
								setSiteFilter('All');
								setTicketStatus('All');
								setInputValue('');
								handleClearInput();
							}}
							defaultValue={inputValue}
							onChangeValue={(e) => {
								e.target.value = e.target.value.replace(/[^0-9]/g, '');
								if (e.target.value === '') {
									setSiteFilter('All');
									setTicketStatus('All');
									setInputValue(e.target.value);
									setNonShowInputValue(e.target.value);
								}
								setNonShowInputValue(e.target.value);
							}}
							onKeyDown={(e) => {
								e.target.value = e.target.value.replace(/[^0-9]/g, '');
								if (e.nativeEvent.key === 'Enter') {
									setSiteFilter('All');
									setTicketStatus('All');
									setInputValue(nonShowInputValue);
								}
							}}
							handleSearch={() => {
								setSiteFilter('All');
								setTicketStatus('All');
								setInputValue(nonShowInputValue);
							}}
							hasInputSearch={true}
							hasCalendar={true}
							hasFilter={true}
							onFilterClick={onOpen}
						/>
					</>
				) : (
					<DesktopTitleHeader
						title={'Bet History'}
						selected={new Date(date)}
						maxDate={new Date(new Date(maxDate).setDate(new Date(maxDate).getDate() + 1))}
						onChangeDate={(newDate) => {
							setDate(newDate);
							setSiteFilter('All');
							setTicketStatus('All');
							handleClearInput();
						}}
						defaultValue={inputValue}
						onChangeValue={(e) => {
							e.target.value = e.target.value.replace(/[^0-9]/g, '');
							if (e.target.value === '') {
								setSiteFilter('All');
								setTicketStatus('All');
								setInputValue(e.target.value);
								setNonShowInputValue(e.target.value);
							}
							setNonShowInputValue(e.target.value);
						}}
						onKeyDown={(e) => {
							e.target.value = e.target.value.replace(/[^0-9]/g, '');
							if (e.nativeEvent.key === 'Enter') {
								setSiteFilter('All');
								setTicketStatus('All');
								setInputValue(nonShowInputValue);
							}
						}}
						handleSearch={() => {
							setSiteFilter('All');
							setTicketStatus('All');
							setInputValue(nonShowInputValue);
						}}
						hasInputSearch={true}
						hasCalendar={true}
						hasFilter={true}
						onFilterClick={() => {
							onOpen();
							if (window.smartsupp) {
								window.smartsupp(function () {
									document.getElementById(
										'smartsupp-widget-container',
									).firstElementChild.style.zIndex = '999';
									document.getElementById('widgetButtonFrame').firstElementChild.style.zIndex =
										'999';
								});
							}
						}}
					/>
				)}

				{isLoading ? (
					<Flex height='70%' alignItems='center' paddingLeft='8vw'>
						<Loading />
					</Flex>
				) : (
					<>
						{isSmallerThan768 ? (
							<FilterDrawer
								isOpen={isOpen}
								onClose={onClose}
								siteFilter={siteFilter}
								setSiteFilter={setSiteFilter}
								ticketStatus={ticketStatus}
								setTicketStatus={setTicketStatus}
								onConfirmClick={() => {
									getTicketlistWithFilter.refetch();
									onClose();
									getTicketlistWithFilter.refetch();
								}}
							/>
						) : (
							<FilterModal
								isOpen={isOpen}
								onClose={onClose}
								siteFilter={siteFilter}
								setSiteFilter={setSiteFilter}
								ticketStatus={ticketStatus}
								setTicketStatus={setTicketStatus}
								onConfirmClick={() => {
									getTicketlistWithFilter.refetch();
									onClose();
									getTicketlistWithFilter.refetch();
								}}
							/>
						)}

						<Flex flexDirection='column' marginY='5'>
							<HistoryDate date={dayjs(date).format('ddd, DD/MM/YYYY')} />
							<Grid
								alignItems='center'
								justifyContent='center'
								width='100%'
								templateColumns={
									getTicketlistWithFilter.data?.pages?.[0]?.content.length > 0
										? ['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(3, 1fr)', 'repeat(3, 1fr)']
										: ['repeat(1, 1fr)']
								}
							>
								{getTicketlistWithFilter.data?.pages.map((page, index) => {
									return (
										<Fragment key={index}>
											{page?.content.length > 0 ? (
												<>
													{page?.content.map((items, idx) => {
														return (
															<Fragment key={idx}>
																<HistoryList
																	status={items.status}
																	ticketId={items.ticketNumber}
																	timeData={ConvertedTime({
																		time: items.date,
																		format: ' hh:mm A',
																	})}
																	gameType={items.siteName}
																	betTotal={currencyFormat(items.totalBetPrice ?? 0)}
																	winTtl={`${items.winningCount}/${items.bettingCount}`}
																	amountTtl={items.winningPrice}
																	isExpire={items.isExpire}
																	userName={items.firstName}
																	mobileNo={items.mobileNo}
																	userType={items.isAgent ? 'Agent' : 'Player'}
																	onClick={() => {
																		sessionStorage.setItem('accessTicketId', items.ticketId);
																		navigate('/history/ticketdetail');
																	}}
																/>
															</Fragment>
														);
													})}
												</>
											) : (
												<Flex
													flexDirection='column'
													justifyContent='center'
													alignItems='center'
													height='50vh'
												>
													<Text>No Data Found.</Text>
												</Flex>
											)}
										</Fragment>
									);
								})}{' '}
							</Grid>
							{getTicketlistWithFilter.hasNextPage ? (
								<Button
									onClick={() => {
										getTicketlistWithFilter.fetchNextPage();
									}}
									width='100%'
								>
									{getTicketlistWithFilter.isFetchingNextPage ? 'Loading...' : 'Load More'}
								</Button>
							) : null}
						</Flex>
					</>
				)}
			</Box>
		</>
	);
};

export default HistoryScreen;
