import React from 'react';
import Lottie from 'lottie-react';
import loadingJson from '../json/loading.json';
const Loading = () => {
	return (
		<Lottie
			loop
			animationData={loadingJson}
			style={{
				width: '100%',
				height: '400px',
				alignSelf: 'center',
			}}
		/>
	);
};

export default Loading;
