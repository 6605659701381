import { useToast } from '@chakra-ui/react';
import { useState } from 'react';

export const useGlobalToast = () => {
	const toast = useToast();
	const [toastCount, setToastCount] = useState(0);

	const globalShowToast = ({ title, status, position }) => {
		if (toastCount < 1) {
			// Limit the number of toasts to 2
			setToastCount((prevCount) => prevCount + 1);

			toast({
				title,
				status,
				position: position || 'top-right', // Default position
				isClosable: true,
				onCloseComplete: () => setToastCount((prevCount) => prevCount - 1),
				containerStyle: {
					width: ['90vw', '80vw', 'auto'],
					marginTop: ['105px', '105px', '105px', '105px', '35px'],
					zIndex: 1,
				},
				duration: 3000,
			});
		}
	};

	return globalShowToast;
};
