import dayjs from 'dayjs';
import { create } from 'zustand';

export const useStore = create((set) => ({
	countryCode: '63',
	setCountryCode: (status) => set({ countryCode: status }),
	utcTime: '',
	setUtcTime: (status) => set({ utcTime: status }),
	drawerLocation: '/pcso',
	setDrawerLocation: (status) => set({ drawerLocation: status }),
}));

export const useLoginStore = create((set) => ({
	isLoggedIn: false,
	isRegisterLink: '',
	setIsLoggedIn: (status) => set({ isLoggedIn: status }),
	setIsRegisterLink: (status) => set({ isRegisterLink: status }),
}));

export const useBettingScreen = create((set) => ({
	bet: {
		drawId: '',
		drawTime: `${dayjs()}`,
		drawName: '',
		ticketId: '',
		ticketNumber: '',
		totalBetPrice: '',
		date: '',
		agentCode: '',
		betting: [],
	},
	betting: [],
	ez2: [],
	swer3: [],
	rg7: [],

	setDrawId: (drawId) => (set = { bet: { drawId: drawId } }),
	setDrawTime: (drawTime) => (set = { bet: { drawTime: drawTime } }),
	setDrawname: (drawName) => (set = { bet: { drawName: drawName } }),
	setTicketNumber: (ticketNumber) => (set = { bet: { ticketNumber: ticketNumber } }),
	setTicketId: (ticketId) => (set = { bet: { ticketId: ticketId } }),
	setTotalBetPrice: (totalBetPrice) => (set = { bet: { totalBetPrice: totalBetPrice } }),
	setDate: (date) => (set = { bet: { date: date } }),
	setAgentCode: (agentCode) => (set = { bet: { agentCode: agentCode } }),

	setBetting: (bet) => {
		set((state) => ({
			betting: [...bet, ...state.betting],
		}));
	},
	setEz2: (bet) => {
		set((state) => ({
			ez2: [...bet, ...state.ez2],
		}));
	},

	setSwer3: (bet) => {
		set((state) => ({
			swer3: [...bet, ...state.swer3],
		}));
	},
	setRg7: (bet) => {
		set((state) => ({
			rg7: [...bet, ...state.rg7],
		}));
	},
	removeBetting: (id) => {
		set((state) => ({
			betting: state.betting.filter((bet) => bet.id !== id),
		}));
	},
	removeEz2: (id) => {
		set((state) => ({
			ez2: state.ez2.filter((bet) => bet.id !== id),
		}));
	},
	removeSwer3: (id) => {
		set((state) => ({
			swer3: state.swer3.filter((bet) => bet.id !== id),
		}));
	},
	removeRg7: (id) => {
		set((state) => ({
			rg7: state.rg7.filter((bet) => bet.id !== id),
		}));
	},
	removeAll: () => {
		set(() => ({
			ez2: [],
			swer3: [],
			betting: [],
		}));
	},
	removeAllStl: () => {
		set(() => ({
			rg7: [],
			betting: [],
		}));
	},

	resetBet: () =>
		set({
			bet: {
				drawId: '',
				drawTime: `${dayjs()}`,
				drawName: '',
				ticketId: '',
				ticketNumber: '',
				totalBetPrice: '',
				date: '',
				agentCode: '',
				betting: [],
			},
		}),
}));

export const useGameStore = create((set) => ({
	siteId: 1,
	setSiteId: (status) => set({ siteId: status }),
}));

export const useCheckingStore = create((set) => ({
	checkPost: false,
	setCheckPost: (status) => set({ checkPost: status }),
	checkDel: false,
	setCheckDel: (status) => set({ checkDel: status }),
	checkClaim: false,
	setCheckClaim: (status) => set({ checkClaim: status }),
}));

export const useTicketIdStore = create((set) => ({
	ticketId: '',
	setTicketId: (status) => set({ ticketId: status }),
}));

export const useSelectedDate = create((set) => ({
	selectedDate: new Date(),
	setSelectedDate: (status) => set({ selectedDate: status }),
}));

export const useProfileStore = create((set) => ({
	userProfile: [],
	setUserProfile: () => {
		set((state) => {
			state.push(state);
			return { userProfile: state.userProfile };
		});
	},
}));

export const useCheckingAgentStore = create((set) => ({
	checkAgent: false,
	setCheckAgent: (status) => set({ checkAgent: status }),
}));


export const usePermissioStore = create((set) => ({
	hasPermissionLocation: undefined,
	setHasPermissionLocation: (status) => set({ hasPermissionLocation: status }),
}));


// store for ticket detail for printing
export const useTicketDetailStore = create((set) => ({
	ticketDetail: [],
	setTicketDetail: (status) => set({ ticketDetail: status }),
}));
