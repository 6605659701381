export const PasteBetErrorEz2 = ({ newValue, minBetAmount, setErrorMessage }) => {
	const newValueSplit = newValue.split('\n');
	newValueSplit.slice(0, 3).forEach((value, index) => {
		let valueSplit = value.split(' ');
		let digit2D = valueSplit[0].split(',').map(Number);
		let digitNum1 = digit2D[0];
		let digitNum2 = digit2D[1];
		let valuePriceStraight = valueSplit[1];
		let valuePriceRamble = valueSplit[2];
		let maxBetAmount = 99999;
		let unAccessDigitRange = digitNum1 < 1 || digitNum1 > 31 || digitNum2 < 0 || digitNum2 > 31;
		if (
			(valueSplit.length !== 3 && value !== '') ||
			(valueSplit.length === 3 &&
				digit2D.length !== 2 &&
				valueSplit[0] !== 'l' &&
				valueSplit[0] !== 'L')
		) {
			setErrorMessage((pre) => [...pre, `${index + 1}.Invalid format`]);
		} else if (value === '') {
			return null;
		} else if (digit2D.length === 2 && unAccessDigitRange) {
			setErrorMessage((pre) => [...pre, `${index + 1}.Number Input 1 - 31 only.`]);
		} else if (
			valueSplit.length > 0 &&
			valuePriceStraight !== 0 &&
			valuePriceStraight < minBetAmount
		) {
			setErrorMessage((pre) => [
				...pre,
				`${index + 1}.Straight min bet amount is ${minBetAmount} PHP `,
			]);
		} else if (valueSplit.length > 0 && valuePriceRamble !== 0 && valuePriceRamble < minBetAmount) {
			setErrorMessage((pre) => [
				...pre,
				`${index + 1}.Ramble min bet amount is ${minBetAmount} PHP `,
			]);
		} else if (
			valueSplit.length > 0 &&
			((valuePriceRamble !== 0 && valuePriceRamble > maxBetAmount) ||
				(valuePriceStraight !== 0 && valuePriceStraight > maxBetAmount))
		) {
			setErrorMessage((pre) => [...pre, `${index + 1}.Maximum bet amount ${maxBetAmount} PHP `]);
		} else {
			setErrorMessage((pre) => [...pre, `${index + 1}.other error`]);
		}
	});
};
