import {
	Button,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Modal,
	ModalBody,
	ModalContent,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';

const IframeModal = ({ isOpen, onClose }) => {
	return (
		<>
			<Drawer
				isOpen={isOpen}
				placement='bottom'
				onClose={onClose}
				closeOnOverlayClick={false}
				padding={0}
			>
				<DrawerOverlay />
				<DrawerContent borderRadius={'30px 30px 0px 0px'}>
					<DrawerHeader borderBottomWidth={1} padding={[3, 3, 5]}>
						<Button onClick={onClose} size={['sm', 'sm', 'md']}>
							Back
						</Button>
					</DrawerHeader>

					<DrawerBody padding={0}>
						<iframe
							title='result'
							src='https://phl-pnlresult.uattoz.com/'
							height={window.innerHeight - 180}
							width={window.innerWidth}
						></iframe>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	);
};

export default IframeModal;

export const ResultModal = ({ isOpen, onClose }) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} size={['xs', '', '', '', '', 'full']}>
			<ModalOverlay />
			<ModalContent marginTop='100px'>
				<ModalHeader>
					<Button onClick={onClose} size='sm'>
						Back
					</Button>
				</ModalHeader>
				<ModalBody backgroundColor={'#f1f1f1'} padding={0}>
					<iframe
						title='result'
						src='https://qa-pnlresult.devtoz.com/'
						style={{ paddingTop: '15px' }}
						height={window.innerHeight - 150}
						width={window.innerWidth}
					></iframe>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
