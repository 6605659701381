import {
	Box,
	Button,
	Divider,
	Flex,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Text,
	useMediaQuery,
} from '@chakra-ui/react';
import React from 'react';
import ImgComp from '../../utils/ImgComp';
import { currencyFormat } from '../../utils/numberFormat';

const SettingSubtitle = ({ subtitle }) => {
	return (
		<Text
			fontSize={['14px', '16px', '18px', '19px', '21px']}
			fontWeight='600'
			paddingBottom='8px'
			color='#000000'
		>
			{subtitle}
		</Text>
	);
};

const ProfileCard = ({
	userName,
	creditLimit,
	balance,
	onClick,
	onClickQRPlayer,
	onClickQRAgent,
	onClickPackage,
	isAgent = false,
	referralCode,
	mobileNo,
}) => {
	const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');
	// zustand

	return (
		<Flex
			borderRadius='8px'
			marginBottom='16px'
			flexDirection={['column', '', '', '', '', '', '']}
			width='100%'
		>
			<SettingSubtitle subtitle={isAgent === true ? 'Agent Profile ' : 'User Profile '} />
			<Divider marginY={5} />
			<Flex flexDirection='row' justifyContent='space-between' alignItems='center'>
				<Flex width={['30%', '20%']} justifyContent='center' alignItems='center'>
					<ImgComp svgName='user' width={['80%']} height={['80%']} />
				</Flex>
				<Flex
					flexDirection='column'
					width={['60%', '60%', '80%', '80%', '80%']}
					justifyContent='center'
					marginLeft='5px'
				>
					<Text
						fontSize={['14px', '20px', '25px', '30px', '38px', '42px']}
						fontWeight={['500', '', '', '600']}
					>
						{userName}
					</Text>
					{sessionStorage.getItem('isThirdPartyLogin') === 'false' && (
						<Text
							fontSize={['11px', '13px', '15px', '17px', '20px']}
							fontWeight={['500', '', '', '600']}
						>
							Mobile No.: &nbsp;
							{mobileNo}
						</Text>
					)}
					{isAgent === true && referralCode !== '' ? (
						<Text
							fontSize={['11px', '13px', '15px', '17px', '20px']}
							fontWeight={['500', '', '', '600']}
						>
							Referral Code: {referralCode}
						</Text>
					) : null}
				</Flex>

				{isAgent &&
				referralCode !== '' &&
				sessionStorage.getItem('isThirdPartyLogin') === 'false' ? (
					<Popover placement='bottom-end'>
						<PopoverTrigger>
							<Button
								width={['', '', '', '15%', '15%', '10%']}
								height={['', '', '', '60%', '60%', '60%']}
								borderWidth='1px'
								borderRadius='8px'
								borderColor='blue.500'
								padding={isLargerThan1024 ? '10px' : '15px'}
								alignItems='center'
								justifyContent='center'
								marginX={isLargerThan1024 ? 5 : 0}
								backgroundColor='gray.700'
							>
								<ImgComp
									svgName='qr-code'
									width={['30px', '', '', '60px']}
									height={['30px', '', '', '60px']}
								/>
							</Button>
						</PopoverTrigger>
						<PopoverContent width={['260px', '', '', '', '350px']}>
							<PopoverArrow />

							<PopoverBody>
								<Flex flexDirection='column' maxHeight='60%' justifyContent='space-between'>
									<Flex
										height='50%'
										borderWidth='1px'
										borderRadius='8px'
										borderColor='blue.500'
										backgroundColor='blue.300'
										alignItems='center'
										justifyContent='center'
										paddingY={1}
										marginY={1}
										onClick={onClickQRPlayer}
										cursor='pointer'
										userSelect='none'
									>
										QR for Player
									</Flex>
									<Flex
										height='50%'
										borderWidth='1px'
										borderRadius='8px'
										borderColor='blue.500'
										backgroundColor='blue.300'
										alignItems='center'
										justifyContent='center'
										paddingY={1}
										marginY={1}
										onClick={onClickQRAgent}
										cursor='pointer'
										userSelect='none'
									>
										QR for Agent
									</Flex>
								</Flex>
							</PopoverBody>
						</PopoverContent>
					</Popover>
				) : (
					<Box width={['30px', '', '', '60px']} height={['30px', '', '', '60px']} />
				)}
			</Flex>
			<Box marginY='30px'>
				{isAgent && (
					<Flex justifyContent='space-between' marginY='10px' alignItems='center'>
						<Text fontSize={['14px', '', '', '20px']} fontWeight={['500', '', '', '600']}>
							Credit Limit
						</Text>
						<Text
							fontSize={['16px', '18px', '20px', '25px', '30px']}
							fontWeight={['500', '', '', '600']}
							color='green.500'
						>
							PHP {currencyFormat(creditLimit)}
						</Text>
					</Flex>
				)}

				<Flex justifyContent='space-between' marginY='10px' alignItems='center'>
					<Text fontSize={['14px', '', '', '20px']} fontWeight={['500', '', '', '600']}>
						Balance Amount
					</Text>
					<Text
						fontSize={['16px', '18px', '20px', '25px', '30px']}
						fontWeight={['500', '', '', '600']}
						color='green.500'
					>
						PHP {currencyFormat(balance)}
					</Text>
				</Flex>
			</Box>
			{isAgent && (
				<Flex
					onClick={onClickPackage}
					justifyContent='space-between'
					alignItems='center'
					borderWidth='1px'
					borderRadius='8px'
					borderColor='blue.200'
					backgroundColor='blue.200'
					padding='3px 10px'
					marginY='10px'
					cursor='pointer'
					userSelect='none'
				>
					<Text fontSize={['14px', '', '', '18px']} fontWeight={['500', '', '', '600']}>
						Agent Package
					</Text>
					<ImgComp svgName='right-arrow' />
				</Flex>
			)}
			{sessionStorage.getItem('isThirdPartyLogin') === 'false' && (
				<Flex
					onClick={onClick}
					justifyContent='space-between'
					alignItems='center'
					borderWidth='1px'
					borderRadius='8px'
					borderColor='blue.200'
					backgroundColor='blue.200'
					padding='3px 10px'
					marginY='10px'
					cursor='pointer'
					userSelect='none'
				>
					<Text fontSize={['14px', '', '', '18px']} fontWeight={['500', '', '', '600']}>
						Change Password
					</Text>
					<ImgComp svgName='right-arrow' />
				</Flex>
			)}
		</Flex>
	);
};

export default ProfileCard;
