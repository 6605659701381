import { Button, Card, CardBody, CardHeader, Divider, Flex, Text } from '@chakra-ui/react';
import { useInfiniteQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Fragment, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { getProfileCreditTransactionApi } from '../../api/query/profileApi';
import Loading from '../../utils/Loading';
import { ConvertedTime } from '../../utils/Time';
import { currencyFormat } from '../../utils/numberFormat';

const TransactionHistory = ({ ticketStatus }) => {
	const [date] = useOutletContext();
	// state
	const [pageNum, setPageNum] = useState(1);

	//* api
	const getProfileCreditTransaction = useInfiniteQuery(
		['getProfileCreditTransactionApi', date, ticketStatus],
		({ pageParam = 1 }) =>
			getProfileCreditTransactionApi({
				date: dayjs(date).format('YYYY-MM-DD HH:mm:ss'),
				userId: sessionStorage.getItem('userId'),
				pageSize: 10,
				pageParam: pageParam,
				transactionType: ticketStatus,
			}),
		{
			getNextPageParam: (lastPage, pages) => {
				if (pages.length < lastPage?.paging?.totalPages) {
					return pages.length + 1;
				}
				return undefined;
			},
		},
	);

	if (getProfileCreditTransaction.isLoading) return <Loading />;

	return (
		<>
			<Card display={['none', 'none', 'none', 'none', 'block', 'block', 'block']}>
				<CardHeader paddingBottom='0'>
					{getProfileCreditTransaction.data?.pages?.[0]?.content.length > 0 && (
						<>
							<Flex flexDirection='row' alignItems='center' padding='24px'>
								<CustomText text={'Date Time'} width='18%' />
								<CustomText text={'Reference ID'} width='32%' />
								<CustomText text={'Details'} width='30%' />
								<CustomText text={'Debit / Credit'} width='20%' textAlign='right' />
							</Flex>
							<Divider />
						</>
					)}
				</CardHeader>
				<CardBody>
					{getProfileCreditTransaction.data?.pages.map((page, index) => {
						return (
							<Flex flexDirection='column' key={index}>
								{page?.content.length > 0 ? (
									<Fragment key={index}>
										{page?.content.map((items, idx) => {
											return (
												<Fragment key={idx}>
													{items.details.map((item, i) => {
														return (
															<TransactionHistoryRow
																key={i}
																date={item.date}
																details={`${item.description}`}
																debit={item.decreaseAmount}
																refNo={item.refNo === '' ? '-' : item.refNo}
																credit={item.increaseAmount}
															/>
														);
													})}
												</Fragment>
											);
										})}
									</Fragment>
								) : (
									<Flex
										flexDirection='column'
										justifyContent='center'
										alignItems='center'
										height='50vh'
										key={index}
									>
										<Text>No Transaction.</Text>
									</Flex>
								)}
							</Flex>
						);
					})}

					{getProfileCreditTransaction.hasNextPage ? (
						<Button
							onClick={() => {
								setPageNum(pageNum + 1);
								getProfileCreditTransaction.fetchNextPage();
							}}
							width='100%'
						>
							{getProfileCreditTransaction.isFetchingNextPage ? 'Loading...' : 'Load More'}
						</Button>
					) : null}
				</CardBody>
			</Card>
			<Card display={['block', 'block', 'block', 'block', 'none', 'none', 'none']}>
				<CardBody paddingTop='0'>
					{getProfileCreditTransaction.isSuccess &&
						getProfileCreditTransaction.data?.pages.map((page, index) => {
							return (
								<Fragment key={index}>
									{page?.content.length > 0 ? (
										<>
											<Flex flexDirection='column' key={index}>
												{page?.content.map((items, idx) => {
													return (
														<Fragment key={idx}>
															{items.details.map((item, i) => {
																return (
																	<MobileTransactionRow
																		key={i}
																		refNo={item.refNo === '' ? '-' : item.refNo}
																		date={item.date}
																		details={`${item.description}`}
																		debit={item.decreaseAmount}
																		credit={item.increaseAmount}
																	/>
																);
															})}
														</Fragment>
													);
												})}
											</Flex>
										</>
									) : (
										<Flex
											flexDirection='column'
											justifyContent='center'
											alignItems='center'
											height='50vh'
											key={index}
										>
											<Text>No Transaction.</Text>
										</Flex>
									)}
								</Fragment>
							);
						})}
					{getProfileCreditTransaction.hasNextPage ? (
						<Button
							onClick={() => {
								setPageNum(pageNum + 1);
								getProfileCreditTransaction.fetchNextPage();
							}}
							width='100%'
						>
							{getProfileCreditTransaction.isFetchingNextPage ? 'Loading...' : 'Load More'}
						</Button>
					) : null}
				</CardBody>
			</Card>
		</>
	);
};

export default TransactionHistory;

const MobileTransactionRow = ({ details, debit, date, credit, refNo }) => {
	return (
		<Flex
			flexDirection='column'
			backgroundColor='blue.200'
			paddingX={3}
			marginY='8px'
			borderRadius='8px'
		>
			<Text fontSize='12px' fontWeight='500' marginTop={5} decoration={'underline'}>
				{ConvertedTime({ time: date, format: 'DD/MM/YYYY hh:mm:ss A' })}
			</Text>
			<Flex
				flexDirection='column'
				paddingY={2}
				fontWeight='500'
				fontSize={['14px', '14px', '14px', '16px']}
				marginTop={2}
			>
				<Text width='100%'>Ref : {refNo}</Text>
				<Text width='100%'>Details : {details}</Text>
				<Text
					color={credit > 0 ? 'green.500' : 'red.500'}
					width='36%'
					marginTop='16px'
					fontWeight='700'
				>
					{credit > 0 ? `+ ${currencyFormat(credit)}` : `- ${currencyFormat(debit)}`}
				</Text>
			</Flex>
		</Flex>
	);
};

const TransactionHistoryRow = ({ date, details, debit, credit, refNo }) => {
	return (
		<Flex
			flexDirection='row'
			alignItems='center'
			marginBottom='32px'
			bgColor='blue.400'
			padding='24px'
			borderRadius='8px'
		>
			<Flex flexDirection='column' width='18%'>
				<CustomText text={ConvertedTime({ time: date, format: 'DD/MM/YYYY' })} width='100%' />
				<CustomText text={ConvertedTime({ time: date, format: 'hh:mm A' })} width='100%' />
			</Flex>
			<CustomText text={refNo} width='32%' />
			<CustomText text={details} width='30%' />
			<CustomText
				fontWeight='700'
				text={credit > 0 ? `+ ${currencyFormat(credit)}` : `- ${currencyFormat(debit)}`}
				width='20%'
				color={credit > 0 ? 'green.500' : 'red.500'}
				textAlign='right'
			/>
		</Flex>
	);
};

const CustomText = ({ text, width, minWidth, color, textAlign, fontWeight = 'semibold' }) => {
	return (
		<Text
			width={width}
			minWidth={minWidth}
			fontSize={['12px', '14px', '14px', '16px', '16px', '16px', '18px']}
			fontWeight={fontWeight}
			color={color}
			textAlign={textAlign}
		>
			{text}
		</Text>
	);
};
