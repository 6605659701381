import { serverApi } from './api';

const API = '/api/setup';

export const getSiteGameNameApi = () =>
	serverApi.get(`${API}/siteGameName`).then((res) => res.data.data);

export const getGameWinRateApi = () =>
	serverApi.get(`${API}/gameWinRate`).then((res) => res.data.data);

export const getCreditTransactionTypeApi = () =>
	serverApi.get(`${API}/creditTransactionType`).then((res) => res.data.data);
