import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import ImgComp from '../../utils/ImgComp';

const GamePickWithImg = ({ value, isSelected = false, onClick, disabled, width, svgName }) => {
	const [isLargerThan1440] = useMediaQuery('(min-width: 1440px)');
	const borderWidth = '1px';
	const borderRadius = [8];
	const alignItems = 'center';
	const justifyContent = 'center';
	const textAlign = ' center';
	const height = ['40px', '40px', '40px', '40px', '49px'];
	const padding = [('12px', '10px', '12px', '10px')];
	const fontWeight = ['600'];
	const fontSize = ['20px'];
	return (
		<>
			{isSelected ? (
				<Flex
					justifyContent={alignItems}
					alignItems={justifyContent}
					borderRadius={borderRadius}
					borderWidth={borderWidth}
					borderColor='blue.500'
					backgroundColor='blue.300'
					width={width}
					height={height}
					padding={padding}
					cursor='default'
					userSelect='none'
				>
					<Box marginRight={[2, 2, 2, 3]}>
						<ImgComp svgName={svgName} height='35px' width='35px' />
					</Box>
					<Text
						textAlign={textAlign}
						fontWeight={fontWeight}
						fontSize={fontSize}
						color='black'
						cursor='default'
						userSelect='none'
					>
						{value}
					</Text>
				</Flex>
			) : disabled ? (
				<Flex
					justifyContent={justifyContent}
					alignItems={alignItems}
					borderRadius={borderRadius}
					borderWidth={borderWidth}
					borderColor='gray.300'
					width={width}
					height={height}
					padding={padding}
					userSelect='none'
					cursor='default'
				>
					<Text
						textAlign={textAlign}
						fontWeight={fontWeight}
						fontSize={fontSize}
						color='gray.300'
						userSelect='none'
						cursor='default'
					>
						{value}
					</Text>
				</Flex>
			) : (
				<Flex
					justifyContent={justifyContent}
					alignItems={alignItems}
					borderRadius={borderRadius}
					borderWidth={borderWidth}
					borderColor='#000000'
					width={width}
					height={height}
					padding={padding}
					_hover={{
						borderColor: isLargerThan1440 ? 'blue.500' : 'none',
						color: isLargerThan1440 ? 'blue.500' : 'none',
					}}
					cursor='pointer'
					color='black'
					userSelect='none'
					onClick={onClick}
				>
					<Box marginRight={[2, 2, 2, 3]}>
						<ImgComp svgName={svgName} height='35px' width='35px' />
					</Box>
					<Text
						textAlign={textAlign}
						fontWeight={fontWeight}
						fontSize={fontSize}
						cursor='pointer'
						userSelect='none'
						_hover={{ color: isLargerThan1440 ? 'blue.500' : 'none' }}
					>
						{value}
					</Text>
				</Flex>
			)}
		</>
	);
};

export default GamePickWithImg;
