import { Flex, Grid, Text } from '@chakra-ui/react';
import React from 'react';
import ImgComp from '../../utils/ImgComp';
import { useQuery } from '@tanstack/react-query';
import { getGameWinRateApi } from '../../api/query/setupApi';

const Prize = () => {
	const getGameWinRate = useQuery(['getGameWinRateApi'], getGameWinRateApi);

	return (
		<>
			<Grid
				templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
				bgColor='white'
				paddingX={3}
				paddingY={5}
				gap={3}
				borderRadius={8}
			>
				{getGameWinRate.data?.pkgInfo?.map((items, index) => {
					return (
						<Flex
							key={index}
							borderWidth={1}
							borderRadius={8}
							paddingX={3}
							paddingY={5}
							flexDirection='column'
							alignItems='center'
							justifyContent='center'
						>
							<Flex>
								<ImgComp
									svgName={String(items.gameType).toLowerCase()}
									width={[100, 100, 120, 200]}
									height={[100, 100, 120, 200]}
								/>
							</Flex>
							<Flex marginTop={[3, 3, 3, 5]}>
								<Text fontWeight='500' fontSize='20px' color='black'>
									Prize: {items.prize}
								</Text>
							</Flex>
						</Flex>
					);
				})}
			</Grid>
		</>
	);
};

export default Prize;
