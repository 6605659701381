import { Button, Flex, Text } from '@chakra-ui/react';

const Title = ({ title, subtitle, onClick }) => {
	return (
		<>
			<Flex
				justifyContent='space-between'
				paddingBottom={['10px', '10px', '10px', '10px', '48px', '48px', '48px']}
				alignItems='center'
				cursor='default'
				userSelect='none'
			>
				<Text fontSize={['18px', '18px', '32px', '32px', '32px', '32px', '32px']} fontWeight='700'>
					{title}
				</Text>
				<Button variant='link' onClick={onClick}>
					<Text fontSize='12px' fontWeight='400' color='#000000'>
						{subtitle}
					</Text>
				</Button>
			</Flex>
		</>
	);
};

export default Title;
