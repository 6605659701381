import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import ImgComp from '../../utils/ImgComp';

const DownlineReportCard = ({ downlineName, onClick, isAgent, mobileNo }) => {
	return (
		<Box
			backgroundColor='blue.400'
			borderColor='blue.700'
			borderWidth={1}
			width={['100%', '100%', '95%']}
			borderRadius={6}
			marginY={3}
			onClick={onClick}
			cursor='pointer'
			padding={4}
		>
			<Flex flexDirection='row' alignItems='center' justifyContent='space-between' paddingY={5}>
				<Flex alignItems='center'>
					<ImgComp svgName='user' width={[10, 14]} height={[10, 14]} marginRight={3} />
					<Flex flexDirection='column'>
						<Text
							fontSize='16px'
							fontWeight='500'
							color='black'
							noOfLines={1}
							width={['45vw', '45vw', '150px', '200px']}
						>
							{downlineName}
						</Text>

						<Text fontSize='12px' fontWeight='300' color='black'>
							Mobile No.: {mobileNo}
						</Text>
						<Text fontSize='12px' fontWeight='300' color='black'>
							User Type: {isAgent === true ? 'Downline Agent' : 'Player'}
						</Text>
					</Flex>
				</Flex>
				<Box>
					<ImgComp svgName='arrowRight' />
				</Box>
			</Flex>
		</Box>
	);
};

export default DownlineReportCard;
