import { Box, Flex, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import FilterDrawer from '../components/drawer/FilterDrawer';
import FilterModal from '../components/modal/FilterModal';
import { DesktopTitleHeader, MobileTitleHeader2 } from '../components/titleHeader/TitleHeader';
import TransactionHistory from '../components/transaction/TransactionHistory';

const TransactionScreen = () => {
	const [isSmallerThan768] = useMediaQuery('(max-width: 768px)');
	const [isSmallerThan1024] = useMediaQuery('(max-width: 1023px)');

	// outlet
	const [date, setDate, maxDate] = useOutletContext();
	// state
	const [ticketStatus, setTicketStatus] = useState('ALL');
	// modal
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<Box
			paddingX={['3', '3', '5', '5', '10']}
			marginBottom={['100px', '100px', '100px', '30px', '30px', '30px', '30px']}
			paddingTop={['80px', '80px', '80px', '80px', '30px', '30px', '30px']}
			width='100%'
			minHeight={['100vh', '100vh', '100vh', '100vh', 'calc(100vh - 150px)']}
		>
			<Box width='100%'>
				<Flex flexDirection='column'>
					{isSmallerThan1024 ? (
						<MobileTitleHeader2
							title='Transaction History'
							selected={new Date(date)}
							maxDate={new Date(new Date(maxDate).setDate(new Date(maxDate).getDate() + 1))}
							onChangeDate={(newDate) => {
								setDate(newDate);
								setTicketStatus('ALL');
							}}
							hasFilter
							hasCalendar
							onFilterClick={() => {
								onOpen();
								if (window.smartsupp) {
									window.smartsupp(function () {
										document.getElementById(
											'smartsupp-widget-container',
										).firstElementChild.style.zIndex = '999';
										document.getElementById('widgetButtonFrame').firstElementChild.style.zIndex =
											'999';
									});
								}
							}}
						/>
					) : (
						<DesktopTitleHeader
							title='Transaction History'
							selected={new Date(date)}
							maxDate={new Date(new Date(maxDate).setDate(new Date(maxDate).getDate() + 1))}
							onChangeDate={(newDate) => {
								setDate(newDate);
								setTicketStatus('ALL');
							}}
							hasFilter
							hasCalendar
							onFilterClick={() => {
								onOpen();
								if (window.smartsupp) {
									window.smartsupp(function () {
										document.getElementById(
											'smartsupp-widget-container',
										).firstElementChild.style.zIndex = '999';
										document.getElementById('widgetButtonFrame').firstElementChild.style.zIndex =
											'999';
									});
								}
							}}
						/>
					)}

					<TransactionHistory ticketStatus={ticketStatus} />
				</Flex>
			</Box>

			{isSmallerThan768 ? (
				<FilterDrawer
					isOpen={isOpen}
					onClose={onClose}
					ticketStatus={ticketStatus}
					setTicketStatus={setTicketStatus}
					onConfirmClick={() => {
						onClose();
					}}
					isTransaction={true}
				/>
			) : (
				<FilterModal
					isOpen={isOpen}
					onClose={onClose}
					ticketStatus={ticketStatus}
					setTicketStatus={setTicketStatus}
					onConfirmClick={() => {
						onClose();
					}}
					isTransaction={true}
				/>
			)}
		</Box>
	);
};

export default TransactionScreen;
