import {
	Button,
	Flex,
	Input,
	InputGroup,
	InputLeftAddon,
	InputRightElement,
	Select,
	Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useStore } from '../../hooks/useStore';
import ImgComp from '../../utils/ImgComp';

export const CustomInput = ({
	title,
	placeholder,
	type,
	onChange,
	value,
	errorMessage,
	isMobileInput,
	isPasswordInput,
	hasQrIcon,
	maxLength,
	onKeyUp,
	readOnly = false,
	disabled = false,
	onQrClick,
}) => {
	const countryCodes = [
		{ value: '63', label: 'Philippine' },
		// { value: '60', label: 'Malaysia' },
		// { value: '1', label: 'USA' },
		// { value: '44', label: 'UK' },
		// { value: '91', label: 'India' },
		// { value: '86', label: 'China' },
	];
	const { countryCode, setCountryCode } = useStore();
	const [showPassword, setShowPassword] = useState(false);
	const [showPlaceholder, setShowPlaceholder] = useState(true);

	return (
		<>
			<Flex
				display={['wrap', 'wrap', 'flex', 'flex', 'flex', 'flex', 'flex']}
				marginBottom={['15px', '15px', '15px', '15px', '35px', '35px']}
			>
				<Text
					fontSize='14px'
					fontWeight='600'
					width='176px'
					height='40px'
					paddingTop='10px'
					cursor='default'
					userSelect='none'
				>
					{title}
				</Text>
				<Flex flexDirection='column' width='100%'>
					<Flex flexDirection='row' width='100%' justifyContent='space-between'>
						<InputGroup>
							{isMobileInput && (
								<InputLeftAddon
									borderRadius='5px'
									children={
										<Select
											variant='unstyled'
											value={countryCode}
											onChange={(e) => setCountryCode(e.target.value)}
											isRequired
										>
											{countryCodes.map((code) => (
												<option key={code.value} value={code.value}>
													+{code.value}
												</option>
											))}
										</Select>
									}
									backgroundColor='blue.300'
								/>
							)}
							<Input
								type={
									isPasswordInput && showPassword
										? 'text'
										: isPasswordInput && !showPassword
										? 'password'
										: type
								}
								onKeyUp={onKeyUp}
								onChange={onChange}
								maxLength={maxLength}
								value={value}
								variant='filled'
								placeholder={showPlaceholder && placeholder}
								onFocus={() => {
									setShowPlaceholder(false);
								}}
								onBlur={() => {
									setShowPlaceholder(true);
								}}
								_placeholder={{ opacity: 1, color: '#C1C1C1', fontStyle: 'italic' }}
								backgroundColor={readOnly === true ? '#e0e0e0' : 'blue.200'}
								isRequired
								readOnly={readOnly}
								disabled={disabled}
								_hover={
									readOnly === true
										? { backgroundColor: '#e0e0e0', borderColor: 'transparent' }
										: null
								}
							/>
							{isPasswordInput && (
								<InputRightElement backgroundColor='blue.300' borderRadius='5px' width={'48px'}>
									<Button
										size='sm'
										colorScheme='none'
										onClick={() => setShowPassword(!showPassword)}
									>
										{showPassword ? (
											<ImgComp svgName={'showpass'} />
										) : (
											<ImgComp svgName={'hidepass'} />
										)}
									</Button>
								</InputRightElement>
							)}
						</InputGroup>
						{hasQrIcon && (
							<Flex
								borderRadius='5px'
								width={'50px'}
								height={'40px'}
								justifyContent='center'
								alignItems='center'
								display='flex'
								border='1px'
								borderColor='#0052FF'
								padding='2'
								userSelect='none'
								cursor='pointer'
								onClick={onQrClick}
								_hover={
									readOnly === true ? { backgroundColor: '#e0e0e0', borderColor: '#0052FF' } : null
								}
							>
								<ImgComp svgName='scanQR' width={['28px']} height={['28px']} />
							</Flex>
						)}
					</Flex>
					{errorMessage}
				</Flex>
			</Flex>
		</>
	);
};
