import {
	Button,
	Flex,
	Modal,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Text } from 'react-thermal-printer';
import ImgComp from '../utils/ImgComp';

const ThirdPartyLogoutScreen = () => {
	window.history.pushState(null, '', null);
	window.onpopstate = () => {
		window.history.forward();
	};

	const navigate = useNavigate();
	// modal
	const { isOpen, onOpen, onClose } = useDisclosure();

	// useEffect
	useEffect(() => {
		sessionStorage.removeItem('isThirdPartyLogin');
		sessionStorage.removeItem('accessToken');
		if (sessionStorage.getItem('editSessionStorage') === 'true') {
			onOpen();
		}
	}, []);

	//live chat
	useEffect(() => {
		if (window.smartsupp) {
			window.smartsupp(function () {
				document.getElementById('smartsupp-widget-container').style.display = 'none';
			});
		} else {
			setTimeout(() => {
				window.smartsupp(function () {
					document.getElementById('smartsupp-widget-container').style.display = 'none';
				});
			}, 3000);
		}
	}, []);

	// useEffect
	useEffect(() => {
		// close shortcuts inspector
		const handleKeyDown = (event) => {
			// command + option + I (ios)
			// command + shift + C (ios)
			// F12
			// ctrl + shift + C (windows)
			if (
				(event.metaKey && 'KeyI' && event.altKey) ||
				(event.metaKey && event.shiftKey && event.keyCode === 67) ||
				event.keyCode === 123 ||
				(event.keyCode === 17 && event.shiftKey && event.keyCode === 67) ||
				(event.keyCode === 16 && event.keyCode === 17 && 'KeyI')
			) {
				event.preventDefault(); // Prevent opening inspect mode
			}
		};
		window.addEventListener('keydown', handleKeyDown);

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	return (
		<>
			<Flex
				flexDirection='column'
				height='100vh'
				alignItems='center'
				justifyContent='center'
				bgGradient='linear(to-b, blue.200, blue.100)'
				minW='100vw'
				onContextMenu={(e) => {
					e.preventDefault();
				}}
			>
				<Flex flexDirection='column' width='fit-content' padding={5} alignItems='center'>
					<Flex marginBottom={10} justifyContent='center'>
						<ImgComp svgName={'logo'} width={['30px']} height={['30px']} />
						<Flex paddingLeft={2}>
							<ImgComp svgName={'panalo'} width={['150px']} height={['30px']} />
						</Flex>
					</Flex>

					<Flex
						justifyContent='center'
						flexDirection='column'
						width='fit-content'
						backgroundColor='white'
						paddingX={5}
						paddingY={5}
						fontSize={['14px', '14px', '14px', '14px', '22px']}
						fontWeight='light'
					>
						<Flex width='100%' justifyContent='center' pb={2}>
							<ImgComp svgName={'bell'} width='40px' height='40px' />
						</Flex>
						{sessionStorage.getItem('returnUrl') !== 'null' &&
						sessionStorage.getItem('returnUrl') !== 'undefined' ? (
							<>
								<Text>You were signed out of your Panalo 29 account.</Text>
								<Text>Please login again.</Text>
								<Flex width='100%' justifyContent='center' marginTop={2}>
									<Button
										rounded='full'
										backgroundColor='#22943B'
										borderColor='#07E738'
										borderWidth='1px'
										maxWidth='150px'
										textColor='white'
										onClick={() => {
											if (
												sessionStorage.getItem('returnUrl') === 'null' ||
												sessionStorage.getItem('returnUrl') === 'undefined'
											) {
												navigate('/');
											} else {
												window.location.href = sessionStorage.getItem('returnUrl');
											}
										}}
									>
										Continue
									</Button>
								</Flex>
							</>
						) : (
							<>
								<Text>Thank you for using our Service!</Text>
								<Text>You have successfully logged out of Panalo 29.</Text>
								<Text>Please close this browser window or tab.</Text>
							</>
						)}
					</Flex>
				</Flex>
			</Flex>
			{isOpen && (
				<Modal isOpen={isOpen} isCentered>
					<ModalOverlay />
					<ModalContent marginX={3}>
						<ModalHeader>
							<Text>Your Session Has Expired.</Text>
						</ModalHeader>
						<ModalFooter>
							<Button
								colorScheme='green'
								mr={3}
								onClick={() => {
									sessionStorage.removeItem('editSessionStorage');
									onClose();
								}}
							>
								Close
							</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
			)}
		</>
	);
};

export default ThirdPartyLogoutScreen;
