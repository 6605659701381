import { CircularProgress, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import React from 'react';

const IsLoadingModal = ({ isOpen }) => {
	return (
		<Modal
			isCentered
			closeOnOverlayClick={false}
			size={['xs', 'sm', 'md', 'lg', 'xl']}
			isOpen={isOpen}
		>
			<ModalOverlay />
			<ModalContent alignItems='center' padding={10} marginX={3}>
				<CircularProgress isIndeterminate size='120px' color='blue.600' />
			</ModalContent>
		</Modal>
	);
};

export default IsLoadingModal;
