import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import ImgComp from '../../utils/ImgComp';

const Footer = () => {
	return (
		<Flex paddingTop={10} backgroundColor='blue.100' width='100%'>
			<Flex width={['', '', '', '', '40vw', '30vw', '']}></Flex>
			<Flex
				position='absolute'
				marginLeft={['180', '', '10', '20']}
				paddingLeft={['', '', '', '', '20vw']}
			>
				<ImgComp
					svgName={'footer'}
					width={['250', '', '', '180']}
					height={['250', '', '', '180']}
				/>
			</Flex>
			<Flex
				width={['', '', '', '', '60vw']}
				justifyContent={'flex-end'}
				fontSize={['10px']}
				padding={['55', '', '', '0']}
				flexDirection={['column', 'column', 'column', 'row']}
			>
				<Flex flexDirection={'column'} gap='2' width={['100%', '', '', '25%']}>
					<Box fontWeight={'800'}>REPUBLIC OF PHILIPPINES</Box>
					<Box fontWeight={'400'} marginTop={'2'}>
						All content is in the public domain unless otherwise stated.
					</Box>
				</Flex>
				<Flex
					flexDirection={'column'}
					width={['100%', '', '', '25%']}
					marginX={['5', '', '', '10']}
				>
					<Box fontWeight={'800'}>ABOUT GOVPH</Box>
					<Box
						marginTop={'2'}
						fontWeight={'400'}
						width={['100%', '100%', '100%', '100%', '100%', '80%']}
					>
						Learn more about the Philippine government, its structure, how government works and the
						people behind it.
					</Box>
					<Flex flexDirection='column' fontWeight={'400'} marginTop='3'>
						<Box>GOV.PH</Box>
						<Box>Open Data Portal</Box>
						<Box>Official Gazette</Box>
					</Flex>
				</Flex>
				<Flex flexDirection={'column'} width={['100%', '', '', '25%']}>
					<Box fontWeight={'800'}>GOVERNMENT LINKS</Box>
					<Flex flexDirection={'column'} fontWeight={'400'} marginTop={'2'}>
						<Box>Office of the President</Box>
						<Box>Office of Vice President </Box>
						<Box> Senate of Philippines</Box>
						<Box> House of Representatives</Box>
						<Box>Supreme Court</Box>
						<Box> Sandiganabyan</Box>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default Footer;
