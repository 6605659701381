import { Box, Flex, Select, Text, useMediaQuery } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { getUserProfileApi } from '../api/query/profileApi';
import { getAgentReportApi } from '../api/query/reportApi';
import { getSiteGameNameApi } from '../api/query/setupApi';
import ReportCard from '../components/report/ReportCard';
import { DesktopTitleHeader, MobileTitleHeader2 } from '../components/titleHeader/TitleHeader';
import { useCheckingAgentStore } from '../hooks/useStore';
import Loading from '../utils/Loading';

const ReportDownlineScreen = () => {
	const navigate = useNavigate();
	const [isSmallerThan1024] = useMediaQuery('(max-width: 1023px)');

	// outlet
	const [date, setDate, maxDate] = useOutletContext();
	// zustand
	const setCheckAgent = useCheckingAgentStore((state) => state.setCheckAgent);
	// state
	const [siteGameId, setSiteGameId] = useState([0, 0]);

	//* api
	const userId = sessionStorage.getItem('downlineId');
	const getUserProfile = useQuery([`getUserProfileApi`, userId], () =>
		getUserProfileApi({ userId: userId }),
	);
	const getSiteGameName = useQuery(['getSiteGameNameApi'], getSiteGameNameApi);
	const getReport = useQuery(['getReportApi', date, siteGameId], () =>
		getAgentReportApi({
			date: dayjs(date).format('YYYY-MM-DD hh:mm:ss'),
			userId: userId,
			siteId: siteGameId[0],
			gameId: siteGameId[1],
		}),
	);

	useEffect(() => {
		if (sessionStorage.getItem('isThirdPartyLogin') === 'true') {
			navigate('/pcso');
		}
	}, []);

	if (getReport.isLoading)
		return (
			<Flex
				width='100%'
				minHeight={['100vh', '100vh', '100vh', '100vh', 'calc(100vh - 150px)']}
				paddingTop={['80px', '80px', '80px', '80px', '30px', '30px', '30px']}
				alignItems='center'
				justifyContent='center'
			>
				<Loading />
			</Flex>
		);
	return (
		<Box
			width='100%'
			paddingX={['3', '3', '5', '5', '10']}
			paddingTop={['80px', '80px', '80px', '80px', '30px', '30px', '30px']}
			minHeight={['100vh', '100vh', '100vh', '100vh', 'calc(100vh - 150px)']}
		>
			{isSmallerThan1024 ? (
				<MobileTitleHeader2
					title={'Downline Report'}
					selected={date}
					maxDate={maxDate}
					onChangeDate={(newDate) => setDate(newDate)}
					hasCalendar={true}
					hasLeftArrow={true}
					onBackClick={() => {
						setCheckAgent(false);
						navigate('/reportDownlineList');
					}}
				/>
			) : (
				<DesktopTitleHeader
					title={'Downline Report'}
					selected={date}
					maxDate={maxDate}
					onChangeDate={(newDate) => setDate(newDate)}
					hasCalendar={true}
					hasLeftArrow={true}
					onBackClick={() => {
						setCheckAgent(false);
						navigate('/reportDownlineList');
					}}
				/>
			)}
			<Flex flexDirection='column' marginY='5'>
				<Box backgroundColor='white'>
					<Select
						value={siteGameId}
						onChange={(e) => {
							setSiteGameId(e.target.value.split(','));
						}}
					>
						{getSiteGameName.isSuccess &&
							getSiteGameName.data?.map((item, index) => (
								<option key={index} value={[item.siteId, item.gameId]}>
									{item.name}
								</option>
							))}
					</Select>
				</Box>

				{getReport.isLoading ? (
					<Loading />
				) : getReport.isSuccess ? (
					getReport.data?.map((item, index) => {
						return (
							<ReportCard
								key={index}
								date={date}
								svgName={String(item.gameName).toLowerCase()}
								mobileNo={item.agentSalesSummaryReportDto.mobileNumber}
								totalClaim={item.agentSalesSummaryReportDto.totalClaim}
								totalClaimAmt={item.agentSalesSummaryReportDto.totalClaimAmt}
								totalSales={item.agentSalesSummaryReportDto.totalSales}
								totalSalesAmt={item.agentSalesSummaryReportDto.totalSalesAmt}
								totalCommision={item.agentSalesSummaryReportDto.totalCommision}
								totalCommisionAmt={item.agentSalesSummaryReportDto.totalCommisionAmt}
								net={item.agentSalesSummaryReportDto.net}
								username={item.agentSalesSummaryReportDto.username}
								userId={item.agentSalesSummaryReportDto.userCode}
								isAgent={getUserProfile.data?.isAgent}
							/>
						);
					})
				) : (
					<Flex height='50vh' justifyContent='center' alignItems='center'>
						<Text>No report</Text>
					</Flex>
				)}
				{getReport.isSuccess && siteGameId[0] == 0 && getUserProfile.data?.isAgent === true ? (
					<Flex height='20px'>
						<Text fontSize={['12px', '12px', '12px', '16']} color='red.500'>
							** The payout amount is inclusive of approved credit withdrawal amount.
						</Text>
					</Flex>
				) : (
					<Flex height='20px'></Flex>
				)}
			</Flex>
		</Box>
	);
};

export default ReportDownlineScreen;
