import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { ConvertedTime } from '../../utils/Time';
import { currencyFormat } from '../../utils/numberFormat';
import HistoryCustomButton from '../history/HistoryCustomButton';

const ClaimTicketBodyDesktop = ({
	ticketDate,
	ticketNo,
	ticketAgentName,
	claimedDate,
	winningNumbers,
	winningPrice,
}) => {
	return (
		<Flex
			paddingY={5}
			paddingX={['2', '2', '2', '5']}
			backgroundColor={'#FFFFFF'}
			borderWidth={1}
			borderRadius='8px'
			marginBottom={'3'}
			flexDirection={'column'}
			display={['none', 'none', 'none', 'none', 'flex']}
		>
			<Flex flexDirection='row'>
				<Flex flexDirection='column' width={['60%', '60%', '60%', '60%', '60%', '50%']}>
					<Flex width={['100%']} padding={1} justifyContent='space-between'>
						<Text fontWeight='500' fontSize={['15px', '15px', '15px', '15px', '15px', '19px']}>
							Ticket No.:
						</Text>
						<Text
							color='blue.500'
							fontWeight='500'
							fontSize={['16px', '16px', '16px', '16px', '16px', '20px']}
						>
							&nbsp;
							{ticketNo}
						</Text>
					</Flex>
					<Flex width={['100%']} padding={1} justifyContent='space-between'>
						<Text fontWeight='500' fontSize={['15px', '15px', '15px', '15px', '15px', '19px']}>
							Bet Date Time:
						</Text>
						<Text fontWeight='500' fontSize={['16px', '16px', '16px', '16px', '16px', '20px']}>
							&nbsp;{ConvertedTime({ time: ticketDate, format: 'DD/MM/YYYY hh:mm A' })}
						</Text>
					</Flex>
					<Flex width={['100%']} padding={1} justifyContent='space-between'>
						<Text fontWeight='500' fontSize={['15px', '15px', '15px', '15px', '15px', '19px']}>
							Claimed By:
						</Text>
						<Text fontWeight='500' fontSize={['16px', '16px', '16px', '16px', '16px', '20px']}>
							&nbsp;{ticketAgentName}
						</Text>
					</Flex>
					<Flex width={['100%']} padding={1} justifyContent='space-between'>
						<Text fontWeight='500' fontSize={['15px', '15px', '15px', '15px', '15px', '19px']}>
							Claimed Date Time:
						</Text>
						<Text fontWeight='500' fontSize={['16px', '16px', '16px', '16px', '16px', '20px']}>
							&nbsp;{ConvertedTime({ time: claimedDate, format: 'DD/MM/YYYY hh:mm A' })}
						</Text>
					</Flex>
				</Flex>
				<Flex width={['40%', '40%', '40%', '40%', '40%', '50%']} justifyContent='right'>
					<Flex flexDirection='column' justifyContent='space-between'>
						<HistoryCustomButton
							width={['61px', '61px', '61px', '61px', '61px', '101px']}
							height={['32px', '32px', '32px', '32px', '32px', '48px']}
							backgroundColor='blue.200'
							borderColor='blue.500'
							textColor='blue.500'
							fontSize={['14px', '14px', '14px', '14px', '14px', '22px']}
							fontWeight={'600'}
							buttonTitle='Claimed'
							alignSelf='flex-end'
						/>
						<Box>
							<Text
								textAlign='right'
								fontSize={['16px', '16px', '16px', '16px', '16px', '20px']}
								fontWeight='300'
							>
								Winning Price (PHP)
							</Text>
							<Text
								textAlign='right'
								fontSize={['24px', '24px', '24px', '24px', '24px', '30px']}
								fontWeight='500'
								color='green.500'
							>
								{currencyFormat(winningPrice ?? 0)}
							</Text>
						</Box>
					</Flex>
				</Flex>
			</Flex>
			<Divider marginY='10px' />
			<Text fontWeight='300' fontSize='20px'>
				Winning lists :{' '}
				{winningNumbers.length > 1
					? `${winningNumbers.length} bets`
					: `${winningNumbers.length} bet`}
			</Text>
			<Flex
				fontWeight={'400'}
				fontSize={['16px', '16px', '16px', '16px', '16px', '20px']}
				padding={3}
				alignItems={'center'}
				bgColor={'#EDF3FF'}
				borderRadius={'8px'}
			>
				<Box width={'15%'}>Games</Box>
				<Box width={'30%'}>Numbers</Box>
				<Box width={'25%'} textAlign={'right'}>
					<Text>Total Bets (PHP)</Text>
				</Box>

				<Box width={'30%'} textAlign={'right'}>
					Winning Amount (PHP)
				</Box>
			</Flex>

			{winningNumbers.length > 0 &&
				winningNumbers?.map((data, index) => {
					return (
						<Flex
							key={index}
							width={['100%']}
							padding={3}
							fontWeight='500'
							fontSize={['16px', '16px', '16px', '16px', '16px', '20px']}
						>
							<Box width={'15%'}>{data.gameName}</Box>
							<Flex width={'30%'} justifyContent='space-between'>
								<Text>{data.num}</Text>
								<Text minW={'40px'}>{data.lp === true ? '(LP)' : ''}</Text>
								<Text>{data.betType === 'R' ? 'Ramble' : 'Straight'}</Text>
							</Flex>
							<Box width={'25%'} textAlign={'right'}>
								{data.betAmount}
							</Box>

							<Box width={'30%'} textAlign={'right'}>
								<Text color='green.500' textAlign='right'>
									{currencyFormat(data.winPrice)}
								</Text>
							</Box>
						</Flex>
					);
				})}
			<Divider marginY='10px' />
			<Flex
				width={['100%']}
				paddingX={3}
				fontWeight='500'
				fontSize={['16px', '16px', '16px', '16px', '16px', '20px']}
			>
				<Text width='70%'>Total Win</Text>
				<Text color='green.500' width='30%' textAlign='right'>
					{currencyFormat(winningPrice ?? 0)}
				</Text>
			</Flex>
		</Flex>
	);
};

export default ClaimTicketBodyDesktop;
