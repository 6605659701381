import {
	CircularProgress,
	Divider,
	Flex,
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { putBetClaim } from '../../api/query/betApi';
import { usePermissioStore } from '../../hooks/useStore';
import ImgComp from '../../utils/ImgComp';
import HistoryCustomButton from './HistoryCustomButton';
import PrintPreviewModal from './PrintPreviewModal';

const ClaimAgentButton = ({
	ticketId,
	ticketNo,
	isStrike,
	isClaimed,
	isExpire,
	strikeLength,
	handleWebPrint,
}) => {
	const navigate = useNavigate();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		isOpen: isOpenClaimedSuccess,
		onOpen: onOpenClaimedSuccess,
		onClose: onCloseClaimedSuccess,
	} = useDisclosure();
	const {
		isOpen: isOpenErrorLocation,
		onOpen: onOpenErrorLocation,
		onClose: onCloseErrorLocation,
	} = useDisclosure();

	const { isOpen: isOpenPrint, onOpen: onOpenPrint, onClose: onClosePrint } = useDisclosure();

	const hasPermissionLocation = usePermissioStore((state) => state.hasPermissionLocation);

	// state
	const [latitude, setLatitude] = useState();
	const [longitude, setLongitude] = useState();
	const [isMsg, setIsMsg] = useState('');

	const queryClient = useQueryClient();
	const invalidatePutBetClaim = () => queryClient.invalidateQueries({ queryKey: ['putBetClaim'] });

	const putBetClaimQuery = useMutation({
		mutationFn: () =>
			putBetClaim({
				ticketNo: `${ticketNo}`,
				isFullyClaimed: true,
				// claimAmount: winningPrice,
				latitude: latitude,
				longitude: longitude,
			}),

		onSuccess: (succ) => {
			invalidatePutBetClaim();
			setIsMsg(succ.message);
		},
		onError: (err) => {
			setIsMsg(err.response.data.data.message);
		},
	});

	const [locationEnabled, setLocationEnabled] = useState(false);
	const [locationErrorMessage, setLocationErrorMessage] = useState('');
	const checkLocationStatus = () => {
		if (locationEnabled === false) {
			navigator.permissions
				.query({ name: 'geolocation' })
				.then((result) => {
					if (result.state === 'granted') {
						setLocationEnabled(true);
						setLocationErrorMessage('');
						navigator.geolocation.getCurrentPosition(function (position) {
							setLatitude(position.coords.latitude);
							setLongitude(position.coords.longitude);
						});
					} else if (result.state === 'prompt' || result.state === 'denied') {
						setLocationEnabled(false);
						setLocationErrorMessage(result.state);
					}
				})
				.catch((error) => {
					setLocationEnabled(false);
					setLocationErrorMessage(error.message);
				});
		}
	};

	const handleRequestPermission = () => {
		navigator.geolocation.getCurrentPosition(
			(position) => {
				// Permission granted
				setLocationEnabled(true);
				setLocationErrorMessage('');
				navigator.geolocation.getCurrentPosition(function (position) {
					setLatitude(position.coords.latitude);
					setLongitude(position.coords.longitude);
				});
				setTimeout(() => {
					onOpen();
				}, 500);
			},
			(error) => {
				// Permission denied or error occurred
				setLocationEnabled(false);
				setLocationErrorMessage(error.message);
				setTimeout(() => {
					onOpenErrorLocation();
				}, 500);
			},
		);
	};

	useEffect(() => {
		if (sessionStorage.getItem('isThirdPartyLogin') === 'false' && hasPermissionLocation) {
			navigator.geolocation.getCurrentPosition(function (position) {
				setLatitude(position.coords.latitude);
				setLongitude(position.coords.longitude);
			});
		}
	}, [hasPermissionLocation, sessionStorage.getItem('isThirdPartyLogin')]);

	useEffect(() => {
		if (
			!window.ReactNativeWebView &&
			sessionStorage.getItem('isThirdPartyLogin') === 'false' &&
			hasPermissionLocation
		) {
			checkLocationStatus();
		}
	}, [locationEnabled, hasPermissionLocation]);

	//print
	const authToken = sessionStorage.getItem('accessToken');

	const handlePrint = () => {
		window.ReactNativeWebView.postMessage(
			JSON.stringify({
				function: 'print',
				authToken: authToken,
				ticketId: ticketId,
				apiLink: process.env.REACT_APP_API_BASE_URL,
			}),
		);
	};

	const handleClaim = () => {
		onOpen();
	};

	const desktopPrintComponent = () => {
		return (
			<>
				{isDesktop && (
					<Flex
						marginLeft={[2, 2, 2, 2, 3]}
						justifyContent='center'
						alignItems='center'
						onClick={onOpenPrint}
						userSelect='none'
						cursor='pointer'
						display='flex'
						border='1px'
						borderRadius='8px'
						borderColor='#0052FF'
						padding='2'
						backgroundColor='#ECF2FF'
					>
						<ImgComp
							svgName='printer'
							width={['', '', '', '', '25px', '30px']}
							height={['', '', '', '', '25px', '30px']}
						/>
					</Flex>
				)}
			</>
		);
	};

	return (
		<>
			<Flex>
				{(() => {
					if (strikeLength > 0 && isStrike && !isClaimed && !isExpire) {
						return (
							<>
								<HistoryCustomButton
									onClick={() => {
										if (window.ReactNativeWebView) {
											handleClaim();
										} else {
											if (locationEnabled === false) {
												handleRequestPermission();

												setTimeout(() => {
													onOpenErrorLocation();
												}, 500);
											} else {
												setTimeout(() => {
													onOpen();
												}, 500);
											}
										}
									}}
									_active={{ backgroundColor: 'green.400' }}
									width={['61px', '61px', '61px', '61px', '61px', '101px']}
									height={['32px', '32px', '32px', '32px', '32px', '48px']}
									backgroundColor='green.100'
									borderColor='green.500'
									textColor='green.500'
									fontSize={['14px', '14px', '14px', '14px', '14px', '22px']}
									fontWeight={'600'}
									buttonTitle='Claim'
								/>
								{window.ReactNativeWebView ? (
									<Flex
										marginLeft={[2, 2, 2, 2, 3]}
										justifyContent='center'
										alignItems='center'
										onClick={handlePrint}
										userSelect='none'
										cursor='pointer'
										display='flex'
										border='1px'
										borderRadius='8px'
										borderColor='#0052FF'
										paddingX='2'
										backgroundColor='#ECF2FF'
									>
										<ImgComp
											svgName='printer'
											width={['', '', '', '', '25px', '30px']}
											height={['', '', '', '', '25px', '30px']}
										/>
									</Flex>
								) : (
									desktopPrintComponent()
								)}
							</>
						);
					} else if (strikeLength > 0 && isStrike && isClaimed && !isExpire) {
						return (
							<>
								<HistoryCustomButton
									width={['61px', '61px', '61px', '61px', '61px', '101px']}
									height={['32px', '32px', '32px', '32px', '32px', '48px']}
									backgroundColor='blue.200'
									borderColor='blue.500'
									textColor='blue.500'
									fontSize={['14px', '14px', '14px', '14px', '14px', '22px']}
									fontWeight={'600'}
									buttonTitle='Claimed'
								/>
								{window.ReactNativeWebView ? (
									<Flex
										marginLeft={[2, 2, 2, 2, 3]}
										justifyContent='center'
										alignItems='center'
										onClick={handlePrint}
										userSelect='none'
										cursor='pointer'
										display='flex'
										border='1px'
										borderRadius='8px'
										borderColor='#0052FF'
										paddingX='2'
										backgroundColor='#ECF2FF'
									>
										<ImgComp
											svgName='printer'
											width={['', '', '', '', '25px', '30px']}
											height={['', '', '', '', '25px', '30px']}
										/>
									</Flex>
								) : (
									desktopPrintComponent()
								)}
							</>
						);
					} else if (strikeLength > 0 && isStrike && !isClaimed && isExpire) {
						return (
							<>
								<Flex justifyContent='center' alignItems='center'>
									<Text color='gray.500' fontWeight='500' fontSize='12px'>
										Claim is expired.
									</Text>
								</Flex>
								{window.ReactNativeWebView ? (
									<Flex
										marginLeft={[2, 2, 2, 2, 3]}
										justifyContent='center'
										alignItems='center'
										onClick={handlePrint}
										userSelect='none'
										cursor='pointer'
										display='flex'
										border='1px'
										borderRadius='8px'
										borderColor='#0052FF'
										padding='2'
										backgroundColor='#ECF2FF'
									>
										<ImgComp
											svgName='printer'
											width={['', '', '', '', '25px', '30px']}
											height={['', '', '', '', '25px', '30px']}
										/>
									</Flex>
								) : (
									desktopPrintComponent()
								)}
							</>
						);
					} else {
						return (
							<>
								{window.ReactNativeWebView ? (
									<Flex
										marginLeft={[2, 2, 2, 2, 3]}
										justifyContent='center'
										alignItems='center'
										onClick={handlePrint}
										userSelect='none'
										cursor='pointer'
										display='flex'
										border='1px'
										borderRadius='8px'
										borderColor='#0052FF'
										padding='2'
										backgroundColor='#ECF2FF'
									>
										<ImgComp
											svgName='printer'
											width={['', '', '', '', '25px', '30px']}
											height={['', '', '', '', '25px', '30px']}
										/>
									</Flex>
								) : (
									desktopPrintComponent()
								)}
							</>
						);
					}
				})()}
			</Flex>

			{/* confirm claim modal */}
			{isOpen && (
				<Modal
					isCentered
					closeOnOverlayClick={false}
					size={['sm', 'sm', 'md', 'lg', 'xl']}
					isOpen={isOpen}
				>
					<ModalOverlay />
					<ModalContent marginX={3}>
						<ModalBody
							p={5}
							borderTopWidth={10}
							borderRadius={5}
							borderColor='green.500'
							position='relative'
						>
							<Flex alignItems='center' marginY='15px'>
								<ImgComp svgName='lowBalance' />
								<Flex flexDirection='column' marginX='15px'>
									<Text fontSize='20px' fontWeight='700'>
										Confirm to claim?
									</Text>
									<Flex flexDirection={['column', 'row']}>
										<Text fontWeight='500' fontSize='14px' marginRight={1}>
											Ticket can be claim once only.
										</Text>
										<Text fontWeight='500' fontSize='14px'></Text>
									</Flex>
								</Flex>
							</Flex>
							<Divider borderColor='gray.600' borderWidth={1} />
							<Flex justifyContent='flex-end' marginTop={2} marginY='15px'>
								<Flex
									backgroundColor='gray.100'
									borderRadius={8}
									padding='8px 10px'
									cursor='pointer'
									alignItems='center'
									onClick={onClose}
								>
									<Text fontWeight='600' fontSize='16px'>
										Cancel
									</Text>
								</Flex>
								<Flex
									marginLeft='10px'
									backgroundColor='green.100'
									borderRadius={8}
									padding='8px 10px'
									cursor='pointer'
									alignItems='center'
									onClick={() => {
										putBetClaimQuery.mutate();
										onClose();
										onOpenClaimedSuccess();
									}}
								>
									<ImgComp svgName='greenSmallTick' width={4} height={4} />
									<Text
										paddingLeft={2}
										color='green.500'
										fontWeight='600'
										fontSize='16px'
										userSelect='none'
									>
										Confirm
									</Text>
								</Flex>
							</Flex>
						</ModalBody>
					</ModalContent>
				</Modal>
			)}
			{/* success claim modal */}
			{isOpenClaimedSuccess && (
				<Modal
					isCentered
					closeOnOverlayClick={false}
					size={['sm', 'sm', 'md', 'lg', 'xl']}
					isOpen={isOpenClaimedSuccess}
				>
					<ModalOverlay />
					<ModalContent marginX={3}>
						<ModalBody
							borderTopWidth={10}
							borderRadius={5}
							borderColor={
								putBetClaimQuery.isSuccess
									? 'green.500'
									: putBetClaimQuery.isError
									? 'red.500'
									: 'white'
							}
							position='relative'
						>
							{putBetClaimQuery.isLoading ? (
								<Flex justifyContent='center' alignItems='center' height='150px'>
									<CircularProgress size='50px' isIndeterminate color='blue.600' />
								</Flex>
							) : (
								<>
									<Flex alignItems='center' marginY='10px'>
										<ImgComp svgName={isMsg === 'Ticket claimed.' ? 'greenTick' : 'redCross'} />
										<Flex flexDirection='column' marginX='10px' width='100%'>
											<Text fontSize='20px' fontWeight='700'>
												{isMsg}
											</Text>
										</Flex>
									</Flex>
									<Divider borderColor='gray.600' borderWidth={1} />
									<Flex justifyContent='flex-end' marginTop={2}>
										<Flex
											backgroundColor={
												putBetClaimQuery.isSuccess
													? 'green.500'
													: putBetClaimQuery.isError
													? 'red.500'
													: 'white'
											}
											borderRadius={8}
											padding='8px 10px'
											cursor='pointer'
											alignItems='center'
											onClick={() => {
												onCloseClaimedSuccess();
												navigate('/history');
											}}
											marginY='10px'
										>
											<Text color='white' fontWeight='600' fontSize='16px' userSelect='none'>
												OK
											</Text>
										</Flex>
									</Flex>
								</>
							)}
						</ModalBody>
					</ModalContent>
				</Modal>
			)}

			{/* location denied modal */}
			{locationEnabled === false && locationErrorMessage !== '' ? (
				<>
					{isOpenErrorLocation && (
						<Modal
							isCentered
							closeOnOverlayClick={false}
							size={['sm', 'sm', 'md', 'lg', 'xl']}
							isOpen={isOpenErrorLocation}
						>
							<ModalOverlay />
							<ModalContent marginX={3}>
								<ModalBody
									borderTopWidth={10}
									borderRadius={5}
									borderColor={'red.500'}
									position='relative'
								>
									<Flex alignItems='center' marginY='10px'>
										<ImgComp svgName={'redCross'} />
										<Flex flexDirection='column' marginX='10px'>
											<Text fontSize='20px' fontWeight='700'>
												{locationErrorMessage}
											</Text>
											<Text fontSize='16px' fontWeight='500'>
												Please allow location permission in browser and refresh page before claim
												ticket.
											</Text>
										</Flex>
									</Flex>
									<Divider borderColor='gray.600' borderWidth={1} />
									<Flex justifyContent='flex-end' marginTop={2}>
										<Flex
											backgroundColor={'red.100'}
											borderRadius={8}
											padding='8px 10px'
											cursor='pointer'
											alignItems='center'
											onClick={onCloseErrorLocation}
											marginY='10px'
										>
											<Text color={'red.500'} fontWeight='600' fontSize='16px' userSelect='none'>
												OK
											</Text>
										</Flex>
									</Flex>
								</ModalBody>
							</ModalContent>
						</Modal>
					)}
				</>
			) : null}

			{/* Print Preview */}
			{isOpenPrint && (
				<PrintPreviewModal
					isOpen={isOpenPrint}
					onClose={onClosePrint}
					onClickPrint={() => {
						handleWebPrint();
						onClosePrint();
					}}
				/>
			)}
		</>
	);
};

export default ClaimAgentButton;
