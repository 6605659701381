import { Text } from '@chakra-ui/react';
import React from 'react';

export const CustomText = ({ text, ...props }) => {
  return (
    <Text style={{ fontSize: '12px' }} {...props}>
      {text}
    </Text>
  );
};

export const ErrorText = ({
  text,
  color,
  fontSize,
  fontWeight,
  width,
  ...props
}) => {
  return (
    <Text
      style={{
        color: `${color}`,
        fontSize: `${fontSize}`,
        fontWeight: `${fontWeight}`,
        width: { width },
      }}
      {...props}
    >
      {text}
    </Text>
  );
};
