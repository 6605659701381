import {
	Box,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { QrScanner } from '@yudiel/react-qr-scanner';
import React from 'react';

const QRCodeModal = ({ step, isOpen, onClose, onResult, onError, onDecode }) => {
	return (
		<>
			{window.ReactNativeWebView ? (
				<>
					{step === 2 && (
						<Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false}>
							<ModalOverlay />
							<ModalContent paddingY={5} marginX={3}>
								<ModalCloseButton />

								<ModalBody>
									<Text fontSize='20px' fontWeight='700' marginBottom='10px'>
										Scan QR here
									</Text>
									<Text fontSize='12px' fontWeight='500' marginBottom='10px'>
										Please make sure you have allowed location permission and camera permission in
										your browser.
									</Text>
									<Box marginY={5}>
										<QrScanner
											onDecode={onDecode}
											onResult={onResult}
											constraints={{ facingMode: 'environment' }}
											onError={onError}
											scanDelay={5000}
										/>
									</Box>
								</ModalBody>
							</ModalContent>
						</Modal>
					)}
				</>
			) : (
				<Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false}>
					<ModalOverlay />
					<ModalContent paddingY={5} marginX={3}>
						<ModalCloseButton />

						<ModalBody>
							<Text fontSize='20px' fontWeight='700' marginBottom='10px'>
								Scan QR here
							</Text>
							<Text fontSize='12px' fontWeight='500' marginBottom='10px'>
								Please make sure you have allowed location permission and camera permission in your
								browser.
							</Text>
							<Box marginY={5}>
								<QrScanner
									onDecode={onDecode}
									onResult={onResult}
									constraints={{ facingMode: 'environment' }}
									onError={onError}
									scanDelay={5000}
								/>
							</Box>
						</ModalBody>
					</ModalContent>
				</Modal>
			)}
		</>
	);
};

export default QRCodeModal;
