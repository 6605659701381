import {
	Box,
	CircularProgress,
	Divider,
	Flex,
	Input,
	InputGroup,
	Modal,
	ModalBody,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	getCreditRemainApi,
	getUserProfileApi,
	putProfileTopUpCreditApi,
	putProfileUpdateDownlineApi,
} from '../../api/query/profileApi';
import { useCheckingStore } from '../../hooks/useStore';
import ImgComp from '../../utils/ImgComp';
import Loading from '../../utils/Loading';
import { useGlobalToast } from '../../utils/ShowToast';
import { currencyFormat } from '../../utils/numberFormat';

const DownlineProfileCard = () => {
	const navigate = useNavigate();
	const globalShowToast = useGlobalToast();
	// zustand
	const setCheckPost = useCheckingStore((state) => state.setCheckPost);

	//state
	const [creditLimit, setCreditLimit] = useState('');
	const [commission, setCommission] = useState('');
	const [topUpCredit, setTopUpCredit] = useState('');
	const [btnLoading, setBtnLoading] = useState(false);
	const [step, setStep] = useState(1);
	let downlineId = sessionStorage.getItem('downlineId');
	let userId = sessionStorage.getItem('userId');

	//modal
	const { isOpen, onOpen, onClose } = useDisclosure();
	//api
	const getCreditRemain = useQuery(['getCreditRemainApi'], getCreditRemainApi);

	const putProfileTopUpCredit = useMutation({
		mutationFn: () =>
			putProfileTopUpCreditApi({
				userId: Number(sessionStorage.getItem('downlineId')),
				credit: Number(topUpCredit.replace(/[^0-9]/g, '')),
			}),
		onSuccess: (succ) => {
			onClose();
			setTopUpCredit('');
			globalShowToast({
				title: `${succ.message}`,
				status: 'success',
			});
			getCreditRemain.refetch();
		},
		onError: (err) => {
			onClose();
			setTopUpCredit('');
			globalShowToast({
				title: `${err.response.data.data.message}`,
				status: 'error',
			});
			getCreditRemain.refetch();
		},
	});

	const getUserProfile = useQuery([`getUserProfileApi`, downlineId], () =>
		getUserProfileApi({ userId: downlineId }),
	);

	const getOwnUserProfile = useQuery([`getUserProfileApi`, userId], () =>
		getUserProfileApi({ userId: userId }),
	);

	const putProfileUpdateDownline = useMutation({
		mutationFn: (downline) => putProfileUpdateDownlineApi(downline),
		onSuccess: (succ) => {
			globalShowToast({
				title: `${succ.message}`,
				status: 'success',
			});
			getUserProfile.refetch().then(() => {
				setCreditLimit(currencyFormat(getUserProfile.data?.maxCredit));
				setCommission(getUserProfile.data?.gameCommission[0].commission);
			});

			navigate('/profile/downline');
		},
		onError: (err) => {
			globalShowToast({
				title: `${err.response.data.data.message}`,
				status: 'error',
			});
		},
	});

	const limitCommission = getOwnUserProfile.data?.gameCommission[0]?.commission;

	// function
	const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, '');

	const handleChangeCreditLimit = (event) => {
		const value = event.target.value;
		const newValue = addCommas(removeNonNumeric(value));
		if (newValue.length > 1 && newValue.charAt(0) === '0') {
			setCreditLimit(creditLimit);
		} else {
			setCreditLimit(newValue);
		}
	};

	//@ change commission and credit limit
	const handleChangeCommission = (event) => {
		// Get the input value
		let newValue = event.target.value;

		// Remove any non-numeric characters except decimal point
		newValue = newValue.replace(/[^0-9]/g, '');

		if (parseFloat(newValue) < 0 || newValue === '00') {
			newValue = '0';
		} else if (parseFloat(newValue) > limitCommission) {
			newValue = `${limitCommission}`;
		}
		// Update the input value
		setCommission(newValue);
	};

	const handleTopUp = (event) => {
		const value = event.target.value.slice(0, 11);
		const newValue = addCommas(removeNonNumeric(value));
		if (newValue.length > 1 && newValue.charAt(0) === '0') {
			setTopUpCredit(topUpCredit);
		} else {
			setTopUpCredit(newValue);
		}
	};

	const handleSaveChangeCommission = () => {
		putProfileUpdateDownline.mutate({
			userId: sessionStorage.getItem('downlineId'),
			creditLimit: Number(creditLimit.replace(/[^0-9]/g, '')),
			uplineShareCommRate: 0,
			refGameTypeCommissionDto: [{ id: 0, gameName: '', commissionRate: Number(commission) }],
		});
	};

	// useEffect
	useEffect(() => {
		if (getUserProfile.isSuccess) {
			setCreditLimit(currencyFormat(getUserProfile.data?.maxCredit));
			setCommission(getUserProfile.data?.gameCommission[0].commission);
		}
	}, [getUserProfile.isSuccess]);

	if (getUserProfile.isLoading) return <Loading />;

	return (
		<Flex flexDirection='column' justifyContent='space-between'>
			<Flex width='100%'>
				<Box
					userSelect='none'
					cursor='pointer'
					paddingX={2}
					onClick={() => {
						navigate('/profile/downline');
					}}
				>
					<ImgComp svgName='left-arrow' />
				</Box>
			</Flex>

			<Divider marginY={5} />

			<Flex flexDirection='row' alignItems='center' paddingY={5}>
				<Flex
					width={['30%', '', '', '20%', '', '15%', '']}
					justifyContent='center'
					alignItems='center'
					height={40}
				>
					<ImgComp svgName='user' height={40} width={40} />
				</Flex>
				<Flex
					flexDirection='column'
					width={['40%', '', '', '50%', '60%', '70%', '']}
					justifyContent='center'
					marginLeft='5px'
				>
					<Text
						fontSize={['14px', '20px', '25px', '30px', '38px', '42px']}
						fontWeight={['500', '', '', '600']}
					>
						{sessionStorage.getItem('downlineName')}
					</Text>
					<Text
						fontSize={['11px', '13px', '15px', '17px', '20px']}
						fontWeight={['500', '', '', '600']}
					>
						Mobile No.: {sessionStorage.getItem('downlineMobileNo')}
					</Text>
					<Text
						fontSize={['11px', '13px', '15px', '17px', '20px']}
						fontWeight={['500', '', '', '600']}
					>
						User Type: {getUserProfile?.data?.isAgent === true ? 'Downline Agent' : 'Player'}
					</Text>
				</Flex>
				<Flex
					width={['30%', '', '', '20%', '', '15%', '']}
					justifyContent='center'
					alignItems='center'
					cursor='pointer'
					borderColor='green.300'
					borderWidth={1}
					borderRadius={8}
					backgroundColor='green.500'
					minHeight={10}
					onClick={() => {
						onOpen();
						setStep(1);
					}}
					p={1}
				>
					<Flex marginRight={[1, 1, 2, 2]}>
						<ImgComp
							svgName='whiteAdd'
							width={['14px', '16px', '18px', '', '']}
							height={['14px', '16px', '18px', '', '']}
						/>
					</Flex>
					<Text
						textAlign='center'
						color='#FFFFFF'
						fontSize={['14px', '', '', '', '22px']}
						fontWeight='600'
						height='100%'
					>
						Top Up
					</Text>
				</Flex>
			</Flex>

			<Divider marginY={5} />
			<Flex>
				<Text
					fontSize={['14px', '', '', '20px']}
					fontWeight={['500', '', '', '600']}
					marginBottom={3}
				>
					Balance Amount (PHP):
				</Text>
				<Text
					marginLeft={3}
					fontSize={['14px', '', '', '20px']}
					fontWeight={['600', '', '', '700']}
					color='green.500'
				>
					{currencyFormat(getUserProfile?.data?.remainCredit)}
				</Text>
			</Flex>
			{getUserProfile?.data?.isAgent === true ? (
				<>
					<Text fontSize={['14px', '', '', '20px']} fontWeight={['500', '', '', '600']}>
						Credit Limit (PHP)
					</Text>
					<Flex flexDirection={['row']} height={10} justifyContent='space-between'>
						<Input
							fontSize={['14px', '', '', '20px']}
							fontWeight='500'
							width='100%'
							maxLength={11}
							value={creditLimit}
							onChange={handleChangeCreditLimit}
							onFocus={() => setCreditLimit('')}
						/>
					</Flex>

					<Text fontSize={['14px', '', '', '20px']} fontWeight={['500', '', '', '600']}>
						Commission Rate (%)
					</Text>
					<Flex flexDirection={['row']} height={10} justifyContent='space-between'>
						<Input
							fontSize={['14px', '', '', '20px']}
							fontWeight='500'
							width='100%'
							inputMode='numeric'
							value={commission}
							onChange={handleChangeCommission}
							onFocus={() => setCommission('')}
						/>
					</Flex>

					<Flex flexDirection={['row']} height={10} justifyContent='space-between' marginTop={5}>
						<ButtonSave onClick={handleSaveChangeCommission} />
					</Flex>
				</>
			) : null}
			{isOpen && (
				<Modal
					isOpen={isOpen}
					onClose={() => {
						onClose();
						setTopUpCredit('');
					}}
					isCentered
					size={['sm', 'sm', 'sm', 'lg']}
					closeOnOverlayClick={false}
				>
					<ModalOverlay />
					<ModalContent marginX={3}>
						<ModalHeader borderBottomWidth={step === 1 ? '0px' : '1px'}>
							<Flex justifyContent='space-between' alignItems='center' paddingY={2}>
								<Text>Top up</Text>
								{step === 1 || step === 2 ? (
									<Flex
										cursor='pointer'
										paddingY={1}
										paddingLeft={2}
										onClick={() => {
											onClose();
											setTopUpCredit('');
										}}
									>
										<ImgComp svgName='close-icon' />
									</Flex>
								) : null}
							</Flex>
						</ModalHeader>
						<ModalBody>
							{step === 1 && (
								<>
									<Flex flexDirection='column'>
										<Text marginY={3}>Top Up Amount (PHP)</Text>

										<Flex flexDirection='column' width='100%'>
											<InputGroup>
												<Input
													type={'text'}
													inputMode='numeric'
													onChange={handleTopUp}
													value={topUpCredit}
													variant='filled'
													backgroundColor='blue.200'
													isRequired
												/>
											</InputGroup>
										</Flex>
									</Flex>
									<Flex justifyContent='flex-end' width='100%' align='flex-end' marginY='20px'>
										<Flex
											bgColor={btnLoading ? 'gray.100' : 'green.500'}
											userSelect='none'
											cursor='pointer'
											onClick={() => {
												if (
													Number(topUpCredit.replace(/[^0-9]/g, '')) > 0 &&
													btnLoading === false &&
													Number(topUpCredit.replace(/[^0-9]/g, '')) <=
														getCreditRemain.data.creditRemain
												) {
													setStep(2);
												}
												if (
													Number(topUpCredit.replace(/[^0-9]/g, '')) >
													getCreditRemain.data.creditRemain
												) {
													globalShowToast({
														title: `Credit Remain insufficient`,
														status: 'error',
													});
												}
												if (
													Number(topUpCredit.replace(/[^0-9]/g, '')) <= 0 &&
													btnLoading === false
												) {
													globalShowToast({
														title: `Please enter amount`,
														status: 'error',
													});
												}
											}}
											height='40px'
											width='104px'
											justifyContent='center'
											alignItems='center'
											borderRadius={8}
										>
											<Text color='white' textAlign='center' fontSize='16px' fontWeight={600}>
												Next
											</Text>
										</Flex>
									</Flex>
								</>
							)}
							{step === 2 &&
								(putProfileTopUpCredit.isLoading ? (
									<Flex justifyContent='center' alignItems='center' marginBottom={3}>
										<CircularProgress size='120px' isIndeterminate color='blue.600' />
									</Flex>
								) : (
									<>
										<Flex
											flexDirection='column'
											fontSize={['12px', '14px', '14px', '16px']}
											fontWeight='400'
											alignItems='center'
										>
											<Flex flexDirection='row' alignItems='center' maxW='90vw'>
												<Text marginRight={1}>Confirm top up</Text>
												<Text
													fontWeight='700'
													marginRight={1}
													fontSize={['14px', '16px', '16px', '18px']}
													color='green.500'
												>
													{topUpCredit}
												</Text>
												<Text marginRight={1}>to</Text>
											</Flex>
											<Flex flexDirection='row' alignItems='center'>
												<Text
													fontWeight='700'
													marginRight={1}
													fontSize={['14px', '16px', '16px', '18px']}
													color='blue.500'
													warp='warp'
													maxWidth='80vw'
													textAlign='center'
												>
													{sessionStorage.getItem('downlineName')}
													<Box
														as='span'
														fontWeight='400'
														fontSize={['12px', '14px', '14px', '16px']}
														color='black'
													>
														{' '}
														?
													</Box>
												</Text>
											</Flex>
										</Flex>

										<Flex justifyContent='center' width='100%' alignItems='center' marginY='20px'>
											<Flex
												bgColor={putProfileTopUpCredit.isLoading ? 'gray.100' : 'red.400'}
												onClick={() => {
													setStep(1);
												}}
												height='40px'
												width='104px'
												justifyContent='center'
												alignItems='center'
												borderRadius={8}
												marginRight={2}
												fontWeight={600}
												userSelect='none'
												cursor='pointer'
											>
												<Text color='white' textAlign='center' fontSize='16px' fontWeight={600}>
													Back
												</Text>
											</Flex>
											<Flex
												bgColor={putProfileTopUpCredit.isLoading ? 'gray.100' : 'green.500'}
												onClick={() => {
													if (
														Number(topUpCredit.replace(/[^0-9]/g, '')) > 0 &&
														btnLoading === false
													) {
														setBtnLoading(true);
														setCheckPost(true);
														putProfileTopUpCredit.mutate();
														setTimeout(() => {
															setBtnLoading(false);
														}, 2000);
													}
												}}
												height='40px'
												width='104px'
												justifyContent='center'
												alignItems='center'
												borderRadius={8}
												userSelect='none'
												cursor='pointer'
											>
												<Text color='white' textAlign='center' fontSize='16px' fontWeight={600}>
													Confirm
												</Text>
											</Flex>
										</Flex>
									</>
								))}
						</ModalBody>
					</ModalContent>
				</Modal>
			)}
		</Flex>
	);
};

const ButtonSave = ({ onClick, disable }) => {
	return (
		<>
			{disable ? (
				<Text
					textAlign='center'
					borderColor='gray.300'
					borderWidth={1}
					borderRadius={8}
					backgroundColor='gray.100'
					color='gray.300'
					fontSize='22px'
					fontWeight='600'
					cursor='default'
					userSelect='none'
					height='100%'
					width='100%'
				>
					Save
				</Text>
			) : (
				<Text
					onClick={onClick}
					textAlign='center'
					cursor='pointer'
					borderColor='green.300'
					borderWidth={1}
					borderRadius={8}
					backgroundColor='green.500'
					_active={{ backgroundColor: 'green.400' }}
					color='#FFFFFF'
					fontSize='22px'
					fontWeight='600'
					userSelect='none'
					height='100%'
					width='100%'
				>
					Save
				</Text>
			)}
		</>
	);
};
export default DownlineProfileCard;
