import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

window.history.pushState(null, '', null);
window.onpopstate = () => {
	window.history.forward();
};

const AuthenticateScreen = () => {
	const navigate = useNavigate();

	//Navigate and refesh
	var queryString = window.location.search;
	var urlParams = new URLSearchParams(queryString);
	const refresh = urlParams.get('refresh');

	//live Chat
	useEffect(() => {
		if (window.location.pathname === '/authenticate' && refresh === null) {
			urlParams.set('refresh', '1');
			window.location.search = urlParams;
		} else {
			if (sessionStorage.getItem('accessToken') !== null) {
				navigate('/pcso');
			} else {
				navigate('/');
			}
		}
	}, []);

	//live chat
	useEffect(() => {
		if (window.smartsupp) {
			window.smartsupp(function () {
				document.getElementById('smartsupp-widget-container').style.display = 'none';
			});
		} else {
			setTimeout(() => {
				window.smartsupp(function () {
					document.getElementById('smartsupp-widget-container').style.display = 'none';
				});
			}, 3000);
		}
	}, []);

	return null;
};

export default AuthenticateScreen;
