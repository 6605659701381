import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
	RouterProvider,
	ScrollRestoration,
	createBrowserRouter,
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';
import Error from './components/Error';
import HistoryDetail from './components/history/HistoryDetail';
import Layout from './layouts/Layout';
import ClaimedTicketScreen from './screen/ClaimedTicketScreen';
import DownlineProfileScreen from './screen/DownlineProfileScreen';
import DownlineScreen from './screen/DownlineScreen';
import HistoryScreen from './screen/HistoryScreen';
import LoginScreen from './screen/LoginScreen';
import TransactionScreen from './screen/TransactionScreen';
import ProfileScreen from './screen/ProfileScreen';
import RegisterScreen from './screen/RegisterScreen';
import ReportScreen from './screen/ReportScreen';
import theme from './utils/theme';
import KeyboardScreen from './screen/KeyboardScreen';
import ReportDownlineScreen from './screen/ReportDownlineScreen';
import ReportListsScreen from './screen/ReportListsScreen';
import ReportAgentScreen from './screen/ReportAgentScreen';
import StlKeyboardScreen from './screen/StlKeyboardScreen';
import PrizeScreen from './screen/PrizeScreen';
import CreditWithdrawalScreen from './screen/CreditWithdrawalScreen';
import AuthenticateScreen from './screen/AuthenticateScreen';
import ThirdPartyLoginScreen from './screen/ThirdPartyLoginScreen';
import ThirdPartyLogoutScreen from './screen/ThirdPartyLogoutScreen';
import * as Sentry from '@sentry/react';
if (
	// import.meta.env.VITE_SENTRY_ENVIRONMENT === 'local' ||
	process.env.REACT_APP_SENTRY_ENVIRONMENT === 'development' ||
	process.env.REACT_APP_SENTRY_ENVIRONMENT === 'quality-assurance' ||
	process.env.REACT_APP_SENTRY_ENVIRONMENT === 'production'
) {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DNS,
		release: process.env.REACT_APP_SENTRY_RELEASE,
		environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
		integrations: [
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect: React.useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			}),
			Sentry.replayIntegration(),
		],

		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 1.0,
		replaysOnErrorSampleRate: 1.0,
	});
}

const root = ReactDOM.createRoot(document.getElementById('root'));

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
	{
		element: (
			<>
				<ScrollRestoration getKey={(location) => location.path} />
				<Layout />
			</>
		),
		errorElement: <Error />,
		children: [
			{
				path: '/pcso',
				element: <KeyboardScreen />,
				errorElement: <Error />,
			},
			{
				path: '/stl',
				element: <StlKeyboardScreen />,
				errorElement: <Error />,
			},
			{
				path: '/keyboard',
				element: <KeyboardScreen />,
				errorElement: <Error />,
			},
			{
				path: '/history',
				element: <HistoryScreen />,
				errorElement: <Error />,
			},
			{
				path: '/history/ticketdetail',
				element: <HistoryDetail />,
				errorElement: <Error />,
			},

			{
				path: '/transaction',
				element: <TransactionScreen />,
				errorElement: <Error />,
			},
			{
				path: '/creditwithdrawal',
				element: <CreditWithdrawalScreen />,
				errorElement: <Error />,
			},

			{
				path: '/claimedticket',
				element: <ClaimedTicketScreen />,
				errorElement: <Error />,
			},
			{
				path: '/report',
				element: <ReportScreen />,
				errorElement: <Error />,
			},
			{
				path: '/reportAgent',
				element: <ReportAgentScreen />,
				errorElement: <Error />,
			},

			{
				path: '/reportDownlineList',
				element: <ReportListsScreen />,
				errorElement: <Error />,
			},
			{
				path: '/reportDownline',
				element: <ReportDownlineScreen />,
				errorElement: <Error />,
			},
			{
				path: '/prize',
				element: <PrizeScreen />,
				errorElement: <Error />,
			},

			{
				path: '/profile',
				element: <ProfileScreen />,
				errorElement: <Error />,
			},

			{
				path: '/profile/downline',
				element: <DownlineScreen />,
				errorElement: <Error />,
			},
			{
				path: '/profile/downlineprofile',
				element: <DownlineProfileScreen />,
				errorElement: <Error />,
			},
		],
	},
	{
		path: '/',
		element: <LoginScreen />,
		errorElement: <Error />,
	},
	{
		path: '/authenticate',
		element: <AuthenticateScreen />,
		errorElement: <Error />,
	},
	{
		path: '/panalo29',
		element: <ThirdPartyLoginScreen />,
		errorElement: <Error />,
	},
	{
		path: '/register',
		element: <RegisterScreen />,
		errorElement: <Error />,
	},
	{
		path: '/logout',
		element: <ThirdPartyLogoutScreen />,
		errorElement: <Error />,
	},
]);

const queryClient = new QueryClient();

root.render(
	<QueryClientProvider client={queryClient}>
		<ChakraProvider theme={theme} toastOptions={{ defaultOptions: { position: 'top-right' } }}>
			<RouterProvider router={router} />
		</ChakraProvider>
	</QueryClientProvider>,
);
