import {
	Box,
	Button,
	Divider,
	Flex,
	Grid,
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
	Text,
	useDisclosure,
	useMediaQuery,
} from '@chakra-ui/react';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { getCreditWithdrawalDetailsApi, getCreditWithdrawalListApi } from '../api/query/betApi';
import { getUserProfileApi } from '../api/query/profileApi';
import FilterDrawer from '../components/drawer/FilterDrawer';
import FilterModal from '../components/modal/FilterModal';
import QRCodeModal from '../components/modal/QRCodeModal';
import ShowQRModal from '../components/modal/ShowQRModal';
import WithdrawalModal from '../components/modal/WithdrawalModal';
import WithdrawallQrDataModal from '../components/modal/WithdrawalQrDataModal';
import ImgComp from '../utils/ImgComp';

import WithDrawalCard from '../components/creditWithdrawal/WithDrawalCard';
import {
	DesktopTitleHeader,
	MobileTitleCreditWithdrawal,
} from '../components/titleHeader/TitleHeader';
import Loading from '../utils/Loading';
import { currencyFormat } from '../utils/numberFormat';

const CreditWithdrawalScreen = () => {
	const [isSmallerThan768] = useMediaQuery('(max-width: 768px)');
	const [isSmallerThan1024] = useMediaQuery('(max-width: 1023px)');
	const navigate = useNavigate();
	// outlet
	const [date, setDate, maxDate] = useOutletContext();

	// state
	const [scannedData, setScannedData] = useState();
	const [scannedErrorData, setScannedErrorData] = useState();
	const [hasError, setHasError] = useState(false);
	const [qrString, setQrString] = useState();
	const [requestId, setRequestId] = useState();
	const [ticketStatus, setTicketStatus] = useState('All');
	const [nonShowInputValue, setNonShowInputValue] = useState('');
	const [inputValue, setInputValue] = useState('');
	const [latitude, setLatitude] = useState();
	const [longitude, setLongitude] = useState();
	const [locationEnabled, setLocationEnabled] = useState(false);
	const [locationErrorMessage, setLocationErrorMessage] = useState('');
	const authToken = sessionStorage.getItem('accessToken');

	// modal
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isQROpen, onOpen: onQROpen, onClose: onQRClose } = useDisclosure();
	const { isOpen: isOpenScanned, onOpen: onOpenScanned, onClose: onCloseScanned } = useDisclosure();
	const { isOpen: isOpenWallet, onOpen: onOpenWallet, onClose: onCloseWallet } = useDisclosure();
	const { isOpen: isOpenQrCode, onOpen: onOpenQrCode, onClose: onCloseQrCode } = useDisclosure();
	const {
		isOpen: isOpenErrorLocation,
		onOpen: onOpenErrorLocation,
		onClose: onCloseErrorLocation,
	} = useDisclosure();
	const {
		isOpen: isOpenSearched,
		onOpen: onOpenSearched,
		onClose: onCloseSearched,
	} = useDisclosure();

	//* api
	const userId = sessionStorage.getItem('userId');
	const getUserProfile = useQuery([`getUserProfileApi`, userId], () =>
		getUserProfileApi({ userId: userId }),
	);

	const getCreditWithdrawalList = useInfiniteQuery(
		['getCreditWithdrawalListApi', date, ticketStatus, getUserProfile?.data?.isAgent, inputValue],
		({ pageParam = 1 }) =>
			getCreditWithdrawalListApi({
				date: dayjs(date).format('YYYY-MM-DD HH:mm:ss'),
				status: ticketStatus,
				requestId: inputValue,
				isAgent: getUserProfile?.data?.isAgent,
				pageSize: 10,
				pageParam: pageParam,
			}),
		{
			getNextPageParam: (lastPage, pages) => {
				if (pages.length < lastPage?.paging?.totalPages) {
					return pages.length + 1;
				}
				return undefined;
			},
		},
	);

	const queryClient = useQueryClient();
	const invalidateGetQRStringData = () =>
		queryClient.invalidateQueries({ queryKey: ['getCreditWithdrawalDetailsApi'] });
	const getCreditWithdrawalDetails = useMutation({
		mutationFn: (qr) => getCreditWithdrawalDetailsApi(qr),
		onSuccess: (succ) => {
			invalidateGetQRStringData();
			setScannedErrorData('');
			setScannedData(succ);
			onQRClose();
		},
		onError: (err) => {
			setScannedErrorData(err.response.data.data.message);
			onQRClose();
			setHasError(true);
		},
	});

	// function
	const checkLocationStatus = () => {
		if (locationEnabled === false) {
			navigator.permissions
				.query({ name: 'geolocation' })
				.then((result) => {
					if (result.state === 'granted') {
						setLocationEnabled(true);
						setLocationErrorMessage('');
						navigator.geolocation.getCurrentPosition(function (position) {
							setLatitude(position.coords.latitude);
							setLongitude(position.coords.longitude);
						});
					} else if (result.state === 'prompt' || result.state === 'denied') {
						setLocationEnabled(false);
						setLocationErrorMessage(result.state);
					}
				})
				.catch((error) => {
					setLocationEnabled(false);
					setLocationErrorMessage(error.message);
				});
		}
	};

	const handleRequestPermission = () => {
		navigator.geolocation.getCurrentPosition(
			() => {
				setLocationEnabled(true);
				setLocationErrorMessage('');
				navigator.geolocation.getCurrentPosition(function (position) {
					setLatitude(position.coords.latitude);
					setLongitude(position.coords.longitude);
				});
				setTimeout(() => {
					onOpen();
				}, 500);
			},
			(error) => {
				setLocationEnabled(false);
				setLocationErrorMessage(error.message);
				setTimeout(() => {
					onOpenErrorLocation();
					setHasError(true);
				}, 500);
			},
		);
	};

	// clear input
	const handleClearInput = () => {
		const inputElement = document.getElementById('search-input');
		setInputValue('');
		inputElement.value = '';
	};

	// scan requesr id
	const handleWebviewQr = () => {
		window.ReactNativeWebView.postMessage(
			JSON.stringify({ function: 'webviewQr', authToken: authToken }),
		);
	};

	// useEffect
	useEffect(() => {
		navigator.geolocation.getCurrentPosition(function (position) {
			setLatitude(position.coords.latitude);
			setLongitude(position.coords.longitude);
		});
	}, []);

	useEffect(() => {
		if (window.ReactNativeWebView) {
		} else {
			checkLocationStatus();
		}
	}, [locationEnabled]);

	useEffect(() => {
		if (sessionStorage.getItem('isThirdPartyLogin') === 'true') {
			navigate('/pcso');
		}
	}, []);

	return (
		<Box
			minHeight={['100vh', '100vh', '100vh', '100vh', 'calc(100vh - 150px)']}
			width='100%'
			paddingX={['3', '3', '5', '5', '10']}
			paddingTop={['80px', '80px', '80px', '80px', '30px', '30px', '30px']}
		>
			{isSmallerThan1024 ? (
				<MobileTitleCreditWithdrawal
					onClose={onClose}
					title={'Credit Withdrawal'}
					selected={new Date(date)}
					maxDate={new Date(maxDate)}
					onChangeDate={(newDate) => {
						setDate(newDate);
						setTicketStatus('All');
						setInputValue('');
						handleClearInput();
					}}
					defaultValue={inputValue}
					onChangeValue={(e) => {
						e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
						if (e.target.value === '') {
							setTicketStatus('All');
							setInputValue(e.target.value);
							setNonShowInputValue(e.target.value);
						}
						setNonShowInputValue(e.target.value);
					}}
					onKeyDown={(e) => {
						e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
						if (e.nativeEvent.key === 'Enter' && nonShowInputValue !== '') {
							if (getUserProfile?.data?.isAgent) {
								setHasError(false);
								setScannedData('');
								setTicketStatus('All');
								setInputValue(nonShowInputValue);

								setTimeout(() => {
									getCreditWithdrawalDetails.mutate(nonShowInputValue);
									onOpenSearched();
								}, 1);
							} else {
								setHasError(false);
								setScannedData('');
								setTicketStatus('All');
								setInputValue(nonShowInputValue);
							}
						}
					}}
					handleSearch={() => {
						if (nonShowInputValue !== '') {
							if (getUserProfile?.data?.isAgent) {
								setHasError(false);
								setScannedData('');
								setTicketStatus('All');
								setInputValue(nonShowInputValue);

								onOpenSearched();
								setTimeout(() => {
									getCreditWithdrawalDetails.mutate(nonShowInputValue);
								}, 1);
							} else {
								setHasError(false);
								setScannedData('');
								setTicketStatus('All');
								setInputValue(nonShowInputValue);
							}
						}
					}}
					onQrClick={() => {
						if (window.ReactNativeWebView) {
							handleWebviewQr();
						} else {
							if (locationEnabled === false) {
								handleRequestPermission();
								setTimeout(() => {
									onOpenErrorLocation();
									setHasError(true);
								}, 500);
							} else {
								setTimeout(() => {
									onQROpen();
									setHasError(false);
								}, 500);
							}
						}
					}}
					onWalletClick={() => {
						onOpenWallet();
						if (window.smartsupp) {
							window.smartsupp(function () {
								document.getElementById(
									'smartsupp-widget-container',
								).firstElementChild.style.zIndex = '999';
								document.getElementById('widgetButtonFrame').firstElementChild.style.zIndex = '999';
							});
						}
					}}
					hasInputSearch={true}
					hasCalendar={true}
					hasWallet={getUserProfile?.data?.isAgent === false ? true : false}
					hasQrScan={getUserProfile?.data?.isAgent ? true : false}
					hasFilter={true}
					onFilterClick={() => {
						onOpen();
						if (window.smartsupp) {
							window.smartsupp(function () {
								document.getElementById(
									'smartsupp-widget-container',
								).firstElementChild.style.zIndex = '999';
								document.getElementById('widgetButtonFrame').firstElementChild.style.zIndex = '999';
							});
						}
					}}
					date={date}
					isRequest={true}
				/>
			) : (
				<>
					<DesktopTitleHeader
						title={'Credit Withdrawal'}
						selected={new Date(date)}
						maxDate={new Date(maxDate)}
						onChangeDate={(newDate) => {
							setDate(newDate);
							setTicketStatus('All');
							handleClearInput();
						}}
						defaultValue={inputValue}
						onChangeValue={(e) => {
							e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
							if (e.target.value === '') {
								setTicketStatus('All');
								setInputValue(e.target.value);
								setNonShowInputValue(e.target.value);
							}
							setNonShowInputValue(e.target.value);
						}}
						onKeyDown={(e) => {
							e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
							if (e.nativeEvent.key === 'Enter' && nonShowInputValue !== '') {
								if (getUserProfile?.data?.isAgent) {
									setHasError(false);
									setScannedData('');
									setTicketStatus('All');
									setInputValue(nonShowInputValue);

									setTimeout(() => {
										getCreditWithdrawalDetails.mutate(nonShowInputValue);
										onOpenSearched();
									}, 1);
								} else {
									setHasError(false);
									setScannedData('');
									setTicketStatus('All');
									setInputValue(nonShowInputValue);
								}
							}
						}}
						handleSearch={() => {
							if (nonShowInputValue !== '') {
								if (getUserProfile?.data?.isAgent) {
									setScannedData('');
									setHasError(false);
									setTicketStatus('All');
									setInputValue(nonShowInputValue);
									onOpenSearched();
									setTimeout(() => {
										getCreditWithdrawalDetails.mutate(nonShowInputValue);
									}, 1);
								} else {
									setScannedData('');
									setHasError(false);
									setTicketStatus('All');
									setInputValue(nonShowInputValue);
								}
							}
						}}
						onQrClick={() => {
							if (locationEnabled === false) {
								handleRequestPermission();
								setTimeout(() => {
									onOpenErrorLocation();
									setHasError(true);
								}, 500);
							} else {
								onQROpen();
								setHasError(false);
							}
						}}
						onWalletClick={() => {
							onOpenWallet();
							if (window.smartsupp) {
								window.smartsupp(function () {
									document.getElementById(
										'smartsupp-widget-container',
									).firstElementChild.style.zIndex = '999';
									document.getElementById('widgetButtonFrame').firstElementChild.style.zIndex =
										'999';
								});
							}
						}}
						hasInputSearch={true}
						hasCalendar={true}
						hasFilter={true}
						hasWallet={getUserProfile?.data?.isAgent === false ? true : false}
						hasQrScan={getUserProfile?.data?.isAgent ? true : false}
						onFilterClick={() => {
							onOpen();
							if (window.smartsupp) {
								window.smartsupp(function () {
									document.getElementById(
										'smartsupp-widget-container',
									).firstElementChild.style.zIndex = '999';
									document.getElementById('widgetButtonFrame').firstElementChild.style.zIndex =
										'999';
								});
							}
						}}
						isRequest={true}
					/>
					<CreditWithdrawalyDate date={dayjs(date).format('ddd, DD/MM/YYYY')} />
				</>
			)}

			{getCreditWithdrawalList.isLoading ? (
				<Flex height='70%' alignItems='center' paddingLeft='8vw'>
					<Loading />
				</Flex>
			) : (
				<>
					{getUserProfile?.isSuccess && getCreditWithdrawalList?.isSuccess && (
						<>
							<Grid
								alignItems='center'
								justifyContent='center'
								width='100%'
								templateColumns={
									getCreditWithdrawalList?.data?.pages?.[0]?.content.length > 0
										? ['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(3, 1fr)', 'repeat(3, 1fr)']
										: ['repeat(1, 1fr)']
								}
							>
								{getCreditWithdrawalList?.data?.pages.map((page, index) => {
									return (
										<Fragment key={index}>
											{page?.content.length > 0 ? (
												<>
													{page?.content.map((items) => {
														return (
															<WithDrawalCard
																key={items?.requestId}
																isAgent={getUserProfile?.data?.isAgent}
																status={items?.status}
																requestID={items?.requestId}
																qrString={items?.qrString}
																requestDate={dayjs(items?.requestTime).format('YYYY/MM/DD')}
																requestTime={dayjs(items?.requestTime).format('hh:mm A')}
																requestBy={items?.requestedByUsername}
																requestCreditAmount={currencyFormat(
																	items?.requestCreditAmount ?? 0,
																)}
																processBy={items?.processedByUsername ?? '-'}
																processDate={
																	items?.processedTime
																		? dayjs(items?.processedTime).format('YYYY/MM/DD ')
																		: '-'
																}
																processTime={
																	items?.processedTime
																		? dayjs(items?.processedTime).format('hh:mm A')
																		: null
																}
																isOpenQrCode={isOpenQrCode}
																onCloseQrCode={onCloseQrCode}
																onClick={() => {
																	if (
																		items?.status === 'Pending' &&
																		!getUserProfile?.data?.isAgent
																	) {
																		onOpenQrCode();
																		setQrString(items?.qrString);
																		setRequestId(items?.requestId);
																	} else if (
																		items?.status === 'Pending' &&
																		getUserProfile?.data?.isAgent
																	) {
																		getCreditWithdrawalDetails.mutate(items?.requestId);
																		setInputValue(items?.requestId);
																		onOpenSearched();
																	}
																}}
															/>
														);
													})}
												</>
											) : (
												<Flex height='50vh' justifyContent='center' alignItems='center'>
													<Text>No Request .</Text>
												</Flex>
											)}
										</Fragment>
									);
								})}
							</Grid>

							{getCreditWithdrawalList.hasNextPage ? (
								<Button
									onClick={() => {
										getCreditWithdrawalList.fetchNextPage();
									}}
									width='100%'
									marginBottom={30}
								>
									{getCreditWithdrawalList.isFetchingNextPage ? 'Loading...' : 'Load More'}
								</Button>
							) : null}
						</>
					)}
					{isSmallerThan768 ? (
						<FilterDrawer
							isOpen={isOpen}
							onClose={onClose}
							ticketStatus={ticketStatus}
							setTicketStatus={setTicketStatus}
							onConfirmClick={() => {
								onClose();
							}}
							isRequest={true}
						/>
					) : (
						<FilterModal
							isOpen={isOpen}
							onClose={onClose}
							ticketStatus={ticketStatus}
							setTicketStatus={setTicketStatus}
							onConfirmClick={() => {
								onClose();
							}}
							isRequest={true}
						/>
					)}
					{isQROpen && (
						<QRCodeModal
							isOpen={isQROpen}
							onClose={onQRClose}
							onResult={(result) => {
								getCreditWithdrawalDetails.mutate(result.text);
								onOpenScanned();
							}}
							onError={(error) => {
								setScannedErrorData('Camera ' + error?.message);
								onQRClose();
								onOpenScanned();
								setHasError(true);
							}}
						/>
					)}
					{inputValue === '' && isOpenScanned && (
						<WithdrawallQrDataModal
							headerTitle='Scanned Result'
							setInputValue={setInputValue}
							hasError={hasError}
							setHasError={setHasError}
							getCreditWithdrawalList={getCreditWithdrawalList}
							isLoading={getCreditWithdrawalDetails.isLoading}
							isOpen={isOpenScanned}
							onClose={() => {
								onCloseScanned();
								setInputValue('');
								setNonShowInputValue('');
								handleClearInput();
							}}
							errMsg={scannedErrorData}
							data={scannedData}
							latitude={latitude}
							longitude={longitude}
						/>
					)}

					{inputValue !== '' && isOpenSearched && (
						<WithdrawallQrDataModal
							headerTitle='Searched Result'
							setInputValue={setInputValue}
							hasError={hasError}
							setHasError={setHasError}
							isLoading={getCreditWithdrawalDetails.isLoading}
							getCreditWithdrawalList={getCreditWithdrawalList}
							isOpen={isOpenSearched}
							onClose={() => {
								onCloseSearched();
								setInputValue('');
								setNonShowInputValue('');
								handleClearInput();
							}}
							errMsg={scannedErrorData}
							data={scannedData}
							latitude={latitude}
							longitude={longitude}
						/>
					)}
					{isOpenWallet && (
						<WithdrawalModal
							getCreditWithdrawalList={getCreditWithdrawalList}
							isOpen={isOpenWallet}
							onClose={onCloseWallet}
						/>
					)}
				</>
			)}

			{isOpenQrCode && qrString && requestId !== undefined && (
				<ShowQRModal
					isOpen={isOpenQrCode}
					onClose={onCloseQrCode}
					qrString={qrString}
					requestId={requestId}
				/>
			)}

			{/* location denied modal */}
			{locationEnabled === false && locationErrorMessage !== '' ? (
				<>
					{isOpenErrorLocation && (
						<Modal
							isCentered
							closeOnOverlayClick={false}
							size={['sm', 'sm', 'md', 'lg', 'xl']}
							isOpen={isOpenErrorLocation}
						>
							<ModalOverlay />
							<ModalContent marginX={3}>
								<ModalBody
									borderTopWidth={10}
									borderRadius={5}
									borderColor={'red.500'}
									position='relative'
								>
									<Flex alignItems='center' marginY='10px'>
										<ImgComp svgName={'redCross'} />
										<Flex flexDirection='column' marginX='10px'>
											<Text fontSize='20px' fontWeight='700'>
												{locationErrorMessage}
											</Text>
											<Text fontSize='16px' fontWeight='500'>
												Please allow location permission in browser and refresh page before claim
												ticket.
											</Text>
										</Flex>
									</Flex>
									<Divider borderColor='gray.600' borderWidth={1} />
									<Flex justifyContent='flex-end' marginTop={2}>
										<Flex
											backgroundColor={'red.100'}
											borderRadius={8}
											padding='8px 10px'
											cursor='pointer'
											alignItems='center'
											onClick={onCloseErrorLocation}
											marginY='10px'
										>
											<Text color={'red.500'} fontWeight='600' fontSize='16px' userSelect='none'>
												OK
											</Text>
										</Flex>
									</Flex>
								</ModalBody>
							</ModalContent>
						</Modal>
					)}
				</>
			) : null}
		</Box>
	);
};

export default CreditWithdrawalScreen;

const CreditWithdrawalyDate = ({ date }) => {
	return (
		<Flex marginBottom={'3'}>
			<Text fontWeight='500' fontSize={['16px', '16px', '16px', '16px', '16px', '20px']}>
				{date}
			</Text>
		</Flex>
	);
};
