import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import ImgComp from '../../utils/ImgComp';
import { currencyFormat } from '../../utils/numberFormat';

const ReportCard = ({
	date,
	svgName,
	totalClaim,
	totalClaimAmt,
	totalSales,
	totalSalesAmt,
	totalCommision,
	totalCommisionAmt,
	net,
	checkAgent,
	username,
	isAgent,
	mobileNo,
}) => {
	return (
		<Box
			borderRadius={8}
			borderWidth='1px'
			borderColor='#003198'
			padding={4}
			marginTop={4}
			backgroundColor='white'
		>
			<Box justifyContent='center' align='center'>
				<ImgComp
					svgName={svgName === 'all' ? 'logo' : svgName}
					width={svgName === 'all' ? 14 : 16}
					height={svgName === 'all' ? 14 : 16}
				/>
			</Box>

			<Flex paddingTop='10px' justifyContent='space-between' width='100%'>
				<Flex justifyContent='space-between' width={['50%', '40%', '', '30%', '30%', '', '']}>
					<Text fontSize={['14px', '14px', '14px', '20px']}>Report Date:</Text>
				</Flex>
				<Flex justifyContent='flex-end' width='50%'>
					<Text fontSize={['14px', '14px', '14px', '20px']} fontWeight={700}>
						{dayjs(date).format('DD/MM/YYYY')}
					</Text>
				</Flex>
			</Flex>
			{checkAgent !== false ? (
				<>
					<Flex paddingTop={['0px', '', '', '', '5px']} justifyContent='space-between' width='100%'>
						<Flex justifyContent='space-between' width={['50%', '40%', '', '30%', '20%', '', '']}>
							<Text fontSize={['14px', '14px', '14px', '20px']}>First Name:</Text>
						</Flex>
						<Flex justifyContent='flex-end' width='50%'>
							<Text
								width={['40vw', '42vw', 'w-fit']}
								textAlign='right'
								fontSize={['14px', '14px', '14px', '20px']}
								wrap='wrap'
							>
								{username}
							</Text>
						</Flex>
					</Flex>

					<Flex paddingTop={['0px', '', '', '', '5px']} justifyContent='space-between' width='100%'>
						<Flex justifyContent='space-between' width={['50%', '40%', '', '30%', '20%', '', '']}>
							<Text fontSize={['14px', '14px', '14px', '20px']}>Mobile No.:</Text>
						</Flex>
						<Flex justifyContent='flex-end' width='50%'>
							<Text fontSize={['14px', '14px', '14px', '20px']}>{mobileNo}</Text>
						</Flex>
					</Flex>
					<Flex paddingTop={['0px', '', '', '', '5px']} justifyContent='space-between' width='100%'>
						<Flex justifyContent='space-between' width={['50%', '40%', '', '30%', '20%', '', '']}>
							<Text fontSize={['14px', '14px', '14px', '20px']}>User Type:</Text>
						</Flex>
						<Flex justifyContent='flex-end' width='50%'>
							<Text fontSize={['14px', '14px', '14px', '20px']}>
								{isAgent === true ? 'Downline Agent' : 'Player'}
							</Text>
						</Flex>
					</Flex>
				</>
			) : null}
			<Divider />
			<Flex paddingTop='10px' justifyContent='space-between' width='100%'>
				<Flex justifyContent='space-between' width={['50%', '40%', '', '30%', '20%', '', '']}>
					<Text fontSize={['14px', '14px', '14px', '20px']}>Sales:</Text>
					<Text>{totalSales}</Text>
				</Flex>
				<Flex justifyContent='flex-end' width='50%'>
					<Text
						fontSize={['14px', '14px', '14px', '20px']}
						fontWeight={['600', '600', '600', '700']}
						color='green.500'
					>
						PHP {currencyFormat(totalSalesAmt)}
					</Text>
				</Flex>
			</Flex>
			{isAgent === true ? (
				<>
					<Flex paddingTop='10px' justifyContent='space-between' width='100%'>
						<Flex justifyContent='space-between' width={['50%', '40%', '', '30%', '20%', '', '']}>
							<Text fontSize={['14px', '14px', '14px', '20px']}>Commission:</Text>
							<Text>{totalCommision}</Text>
						</Flex>
						<Flex justifyContent='flex-end' width='50%'>
							<Text
								fontSize={['14px', '14px', '14px', '20px']}
								fontWeight={['600', '600', '600', '700']}
								color='green.500'
							>
								PHP {currencyFormat(totalCommisionAmt)}
							</Text>
						</Flex>
					</Flex>

					<Flex paddingTop='10px' justifyContent='space-between' width='100%'>
						<Flex justifyContent='space-between' width={['50%', '40%', '', '30%', '20%', '', '']}>
							<Text fontSize={['14px', '14px', '14px', '20px']}>Payouts:</Text>
							<Text>{totalClaim}</Text>
						</Flex>
						<Flex justifyContent='flex-end' width='50%'>
							<Text
								fontSize={['14px', '14px', '14px', '20px']}
								fontWeight={['600', '600', '600', '700']}
								color='red.500'
							>
								PHP {currencyFormat(totalClaimAmt)}
							</Text>
						</Flex>
					</Flex>
				</>
			) : null}

			<Divider />
			<Flex paddingY='16px' justifyContent='space-between' width='100%'>
				<Text fontSize={['14px', '14px', '14px', '20px']}>Net:</Text>
				<Text fontSize={['14px', '14px', '14px', '20px']} fontWeight={['600', '600', '600', '700']}>
					PHP {currencyFormat(net)}
				</Text>
			</Flex>
		</Box>
	);
};

export default ReportCard;
