import {
	Box,
	CircularProgress,
	Divider,
	Flex,
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteTicketApi, getTicketIdApi, postRebetApi } from '../../api/query/betApi';
import { getCreditRemainApi } from '../../api/query/profileApi';
import { useCheckingStore } from '../../hooks/useStore';
import ImgComp from '../../utils/ImgComp';
import Time from '../../utils/Time';
import HistoryCustomButton from './HistoryCustomButton';

const VoidButton = () => {
	const navigate = useNavigate();
	const { serverTime } = Time();

	// zustand
	const setCheckDel = useCheckingStore((state) => state.setCheckDel);

	// state
	const [step, setStep] = useState(1);
	const [stepVoid, setStepVoid] = useState(1);
	const [canVoid, setCanVoid] = useState(true);
	const [isVoidModal, setIsVoidModal] = useState(false);
	const [isVoidSuccessModal, setIsVoidSuccessModal] = useState(false);
	const [isRebetModal, setIsRebetModal] = useState(false);
	const [isRebetSuccessModal, setIsRebetSuccessModal] = useState(false);
	const [isMsg, setIsMsg] = useState('');
	const [rebetStatus, setRebetStatus] = useState('');

	//* api
	const getTicketId = useQuery([`getTicketIdApi`, sessionStorage.getItem('accessTicketId')], () =>
		getTicketIdApi(sessionStorage.getItem('accessTicketId')),
	);

	const getCreditRemain = useQuery(['getCreditRemainApi'], getCreditRemainApi);
	const deleteTicket = useMutation({
		mutationFn: (ticketId) => deleteTicketApi(ticketId),
		onSuccess: (data) => {
			setCheckDel(false);
			setIsVoidModal(false);
			setIsVoidSuccessModal(true);
			setIsMsg(data.message);
			getCreditRemain.refetch();
			setStepVoid(1);
		},
		onError: (err) => {
			setCheckDel(false);
			setIsVoidModal(false);
			setIsVoidSuccessModal(true);
			setIsMsg(err.response.data.message);
			setStepVoid(1);
		},
	});

	const postRebet = useMutation({
		mutationFn: (ticketId) => postRebetApi(ticketId),
		onSuccess: (succ) => {
			setStep(1);
			setIsRebetModal(false);
			setIsRebetSuccessModal(true);
			setRebetStatus(succ.message);
			getCreditRemain.refetch();
		},
		onError: (err) => {
			setRebetStatus(err.response.data.message);
			setIsRebetModal(false);
			setIsRebetSuccessModal(true);
			setTimeout(() => {
				setStep(1);
			}, 1000);
		},
	});

	// useEffect
	useEffect(() => {
		if (getTicketId.data?.[0]?.date !== undefined) {
			let voidTime = dayjs(getTicketId.data?.[0]?.date).add(5, 'm');
			let isExpire = dayjs(getTicketId.data?.[0]?.drawTime).diff(`${dayjs(serverTime)}`, 's');
			let diff = dayjs(voidTime).diff(`${dayjs(serverTime)}`, 's');

			if (dayjs(getTicketId.data?.[0]?.drawEndTime) > dayjs(getTicketId.data?.[0]?.date)) {
				if (diff > 0 && isExpire > 0) {
					setCanVoid(false);
				} else {
					setCanVoid(true);
				}
			} else {
				setCanVoid(true);
			}
		}
	}, [getTicketId.data, serverTime]);

	if (getTicketId.isLoading) return null;

	return (
		<>
			<Flex flexDirection={'column'} marginTop={['2', '2', '5', '10']}>
				<Flex marginBottom={2} justifyContent={'center'} alignItems='center'>
					<ImgComp
						svgName={'clock'}
						marginRight={2}
						width={['12px', '12px', '12px', '12px', '16px']}
						height={['12px', '12px', '12px', '12px', '16px']}
					/>
					<Box
						fontSize={['10px', '10px', '10px', '10px', '16px']}
						fontWeight={['500']}
						textColor={'blue.700'}
					>
						{/* Void Time will be ended in 5:00 minutes. */}
						Void ticket within 5 minutes after ticket's bet time.
					</Box>
				</Flex>

				{getTicketId.data?.[0]?.isVoid === false &&
				getTicketId.data?.[0]?.isDenied === false &&
				canVoid === false ? (
					<HistoryCustomButton
						onClick={() => {
							setIsVoidModal(true);
						}}
						_active={{ backgroundColor: 'red.500' }}
						width='full'
						height={['48px', '48px', '48px', '56px']}
						backgroundColor='red.500'
						borderColor='red.300'
						textColor='#FFFFFF'
						fontSize='22px'
						fontWeight='600'
						buttonTitle='Void Ticket'
					/>
				) : (
					<HistoryCustomButton
						width='full'
						height={['48px', '48px', '48px', '56px']}
						backgroundColor='gray.600'
						borderColor='gray.300'
						textColor='gray.300'
						fontSize='22px'
						fontWeight='600'
						buttonTitle='Void Ticket'
					/>
				)}
			</Flex>

			{/* confirm void modal */}
			{isVoidModal && (
				<Modal
					isCentered
					closeOnOverlayClick={false}
					size={['sm', 'sm', 'md', 'lg', 'xl']}
					isOpen={isVoidModal}
				>
					<ModalOverlay />
					<ModalContent marginX={3}>
						<ModalBody
							p={5}
							borderTopWidth={deleteTicket.isLoading ? 0 : 10}
							borderRadius={5}
							borderColor={deleteTicket.isLoading ? null : 'red.500'}
							position='relative'
						>
							{stepVoid === 1 && (
								<>
									<Flex alignItems='center' marginY='15px'>
										<ImgComp svgName='redLowBalance' />
										<Flex flexDirection='column' marginX='15px'>
											<Text fontSize='20px' fontWeight='700'>
												Confirm to void?
											</Text>
											<Flex flexDirection={['column', 'row']}>
												<Text fontWeight='500' fontSize='14px' marginRight={1}>
													Action can’t be edit after voided. Are you confirm to void?
												</Text>
												<Text fontWeight='500' fontSize='14px'></Text>
											</Flex>
										</Flex>
									</Flex>
									<Divider borderColor='gray.600' borderWidth={1} />
									<Flex justifyContent='flex-end' marginTop={2} marginY='15px'>
										<Flex
											backgroundColor='gray.100'
											borderRadius={8}
											padding='8px 10px'
											cursor='pointer'
											alignItems='center'
											onClick={() => {
												setIsVoidModal(false);
											}}
										>
											<Text fontWeight='600' fontSize='16px'>
												Cancel
											</Text>
										</Flex>
										<Flex
											marginLeft='10px'
											backgroundColor='red.100'
											borderRadius={8}
											padding='8px 10px'
											cursor='pointer'
											alignItems='center'
											onClick={() => {
												setCheckDel(true);
												setStepVoid(2);
												deleteTicket.mutate(sessionStorage.getItem('accessTicketId'));
											}}
										>
											<ImgComp svgName='redSmallTick' width={4} height={4} />
											<Text
												paddingLeft={2}
												color='red.500'
												fontWeight='600'
												fontSize='16px'
												userSelect='none'
											>
												Confirm
											</Text>
										</Flex>
									</Flex>
								</>
							)}
							{stepVoid === 2 && (
								<Flex justifyContent='center' alignItems='center' height='150px'>
									<CircularProgress size='50px' isIndeterminate color='blue.600' />
								</Flex>
							)}
						</ModalBody>
					</ModalContent>
				</Modal>
			)}

			{/* success delete modal */}
			{isVoidSuccessModal && (
				<Modal
					isCentered
					closeOnOverlayClick={false}
					size={['sm', 'sm', 'md', 'lg', 'xl']}
					isOpen={isVoidSuccessModal}
				>
					<ModalOverlay />
					<ModalContent marginX={3}>
						<ModalBody
							borderTopWidth={10}
							borderRadius={5}
							borderColor={
								deleteTicket.isSuccess ? 'blue.300' : deleteTicket.isError ? 'red.500' : 'white'
							}
							position='relative'
						>
							<Flex alignItems='center' marginY='10px'>
								<ImgComp
									svgName={
										deleteTicket.isSuccess ? 'greenTick' : deleteTicket.isError ? 'redCross' : null
									}
								/>
								<Flex flexDirection='column' marginX='10px'>
									<Text fontSize='20px' fontWeight='700'>
										{isMsg}
									</Text>
								</Flex>
							</Flex>
							<Divider borderColor='gray.600' borderWidth={1} />
							<Flex justifyContent='flex-end' marginTop={2}>
								{!deleteTicket.isError && (
									<Flex
										backgroundColor={
											deleteTicket.isSuccess
												? 'blue.300'
												: deleteTicket.isError
												? 'red.100'
												: 'white'
										}
										borderRadius={8}
										padding='8px 10px'
										marginRight={3}
										cursor='pointer'
										alignItems='center'
										onClick={() => {
											setIsVoidSuccessModal(false);
											setIsRebetModal(true);
										}}
										marginY='10px'
									>
										<Text
											color={
												deleteTicket.isSuccess
													? 'blue.700'
													: deleteTicket.isError
													? 'red.500'
													: 'white'
											}
											fontWeight='600'
											fontSize='16px'
											userSelect='none'
										>
											Rebet
										</Text>
									</Flex>
								)}
								<Flex
									backgroundColor={
										deleteTicket.isSuccess ? 'blue.100' : deleteTicket.isError ? 'red.100' : 'white'
									}
									borderRadius={8}
									padding='8px 10px'
									cursor='pointer'
									alignItems='center'
									onClick={() => {
										setIsVoidSuccessModal(false);
										navigate('/history');
									}}
									marginY='10px'
								>
									<Text
										color={
											deleteTicket.isSuccess
												? 'blue.500'
												: deleteTicket.isError
												? 'red.500'
												: 'white'
										}
										fontWeight='600'
										fontSize='16px'
										userSelect='none'
									>
										OK
									</Text>
								</Flex>
							</Flex>
						</ModalBody>
					</ModalContent>
				</Modal>
			)}

			{/* Confirm rebet */}
			{isRebetModal && (
				<Modal
					isCentered
					closeOnOverlayClick={false}
					size={['sm', 'sm', 'md', 'lg', 'xl']}
					isOpen={isRebetModal}
				>
					<ModalOverlay />
					<ModalContent marginX={3}>
						<ModalBody
							p={5}
							borderTopWidth={step === 1 ? 10 : 0}
							borderRadius={5}
							borderColor='blue.300'
							position='relative'
						>
							{step === 1 && (
								<>
									<Flex alignItems='center' marginY='15px'>
										<ImgComp svgName='lowBalance' />
										<Flex flexDirection='column' marginX='15px'>
											<Text fontSize='20px' fontWeight='700'>
												Confirm to rebet?
											</Text>
										</Flex>
									</Flex>
									<Divider borderColor='gray.600' borderWidth={1} />
									<Flex justifyContent='flex-end' marginTop={2} marginY='15px'>
										<Flex
											backgroundColor='gray.100'
											borderRadius={8}
											padding='8px 10px'
											cursor='pointer'
											alignItems='center'
											onClick={() => {
												setIsRebetModal(false);
												navigate('/history');
											}}
										>
											<Text fontWeight='600' fontSize='16px'>
												Cancel
											</Text>
										</Flex>
										<Flex
											marginLeft='10px'
											backgroundColor='blue.300'
											borderRadius={8}
											padding='8px 10px'
											cursor='pointer'
											alignItems='center'
											onClick={
												postRebet.isLoading
													? () => {}
													: () => {
															setStep(2);
															postRebet.mutate({
																ticketId: Number(sessionStorage.getItem('accessTicketId')),
															});
													  }
											}
										>
											<Text color='blue.500' fontWeight='600' fontSize='16px' userSelect='none'>
												Confirm
											</Text>
										</Flex>
									</Flex>
								</>
							)}

							{step === 2 && (
								<Flex justifyContent='center' alignItems='center' height='150px'>
									<CircularProgress size='50px' isIndeterminate color='blue.600' />
								</Flex>
							)}
						</ModalBody>
					</ModalContent>
				</Modal>
			)}

			{/* success rebet modal */}
			{isRebetSuccessModal && (
				<Modal
					isCentered
					closeOnOverlayClick={false}
					size={['sm', 'sm', 'md', 'lg', 'xl']}
					isOpen={isRebetSuccessModal}
				>
					<ModalOverlay />
					<ModalContent marginX={3}>
						<ModalBody
							borderTopWidth={10}
							borderRadius={5}
							borderColor={postRebet.isError ? 'red.500' : 'green.500'}
							position='relative'
						>
							<Flex alignItems='center' marginY='10px'>
								<ImgComp svgName={postRebet.isError ? 'redCross' : 'greenTick'} />
								<Flex flexDirection='column' marginX='10px'>
									<Text fontSize='20px' fontWeight='700'>
										{rebetStatus}
									</Text>
								</Flex>
							</Flex>
							<Divider borderColor='gray.600' borderWidth={1} />
							<Flex justifyContent='flex-end' marginTop={2}>
								<Flex
									backgroundColor={postRebet.isError ? 'red.100' : 'green.100'}
									borderRadius={8}
									padding='8px 10px'
									cursor='pointer'
									alignItems='center'
									onClick={() => {
										setIsRebetSuccessModal(false);
										navigate('/history');
									}}
									marginY='10px'
								>
									<Text
										color={postRebet.isError ? 'red.500' : 'green.500'}
										fontWeight='600'
										fontSize='16px'
										userSelect='none'
									>
										OK
									</Text>
								</Flex>
							</Flex>
						</ModalBody>
					</ModalContent>
				</Modal>
			)}
		</>
	);
};

export default VoidButton;
