import {
	Divider,
	Flex,
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import React from 'react';
import ImgComp from '../../utils/ImgComp';
import { currencyFormat } from '../../utils/numberFormat';

const LowBalanceModal = ({ isOpen, balance = 0, onClick }) => {
	return (
		<Modal
			isCentered
			closeOnOverlayClick={false}
			size={['sm', 'sm', 'md', 'lg', 'xl']}
			isOpen={isOpen}
		>
			<ModalOverlay />
			<ModalContent marginX={3}>
				<ModalBody
					p={5}
					borderTopWidth={10}
					borderRadius={5}
					borderColor='red.500'
					position='relative'
				>
					<Flex alignItems='center'>
						<ImgComp svgName='redLowBalance' width={14} height={12} />
						<Flex flexDirection='column'>
							<Text fontSize='20px' fontWeight='700'>
								Low balance
							</Text>
							<Flex flexDirection={['column', 'row']}>
								<Text fontWeight='500' fontSize='14px' marginRight={1}>
									Current Balance :
								</Text>
								<Text fontWeight={window.ReactNativeWebView ? '500' : '700'} fontSize='16px' color='red.500'>
									PHP {currencyFormat(balance)}
								</Text>
							</Flex>
						</Flex>
					</Flex>
					<Divider borderColor='gray.600' borderWidth={2} marginY={window.ReactNativeWebView ? 2 : 3} />
					<Flex justifyContent='flex-end' marginTop={2}>
						<Flex
							backgroundColor='red.200'
							borderRadius={8}
							padding='8px 10px'
							cursor='pointer'
							alignItems='center'
							onClick={onClick}
						>
							<Text color='red.400' fontWeight='600' fontSize='16px' userSelect='none'>
								OK
							</Text>
						</Flex>
					</Flex>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default LowBalanceModal;
