import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { getSrvTimeApi } from '../api/query/authApi';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';

const ServerTime = () => {
	const [serverTime, setServerTime] = useState(null);
	const getSrvTime = useQuery(['getSrvTimeApi'], getSrvTimeApi);

	const queryClient = useQueryClient();
	const invalidatePutBetClaim = () =>
		queryClient.invalidateQueries({ queryKey: ['getSrvTimeMutate'] });

	const getSrvTimeMutate = useMutation({
		mutationFn: () => getSrvTimeApi(),

		onSuccess: () => {
			invalidatePutBetClaim();
		},
		onError: () => {},
	});

	useEffect(() => {
		if (getSrvTime.isSuccess) {
			const serverDateTime = new Date(getSrvTime.data?.currTime);
			setServerTime(serverDateTime);
		}
	}, [getSrvTime.data]);

	useEffect(() => {
		const interval = setInterval(() => {
			setServerTime((prevTime) => {
				if (prevTime) {
					const currentTime = new Date(prevTime);
					currentTime.setSeconds(currentTime.getSeconds() + 1);
					return currentTime;
				}
				return null;
			});
		}, 1000);
		return () => {
			clearInterval(interval);
		};
	}, []);

	useEffect(() => {
		const handleVisibilityChange = () => {
			getSrvTimeMutate.mutate();
		};

		document.addEventListener('visibilitychange', handleVisibilityChange);
		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, [getSrvTime.isSuccess]);

	return { serverTime };
};

export default ServerTime;

export const ConvertedServerTime = () => {
	dayjs.extend(utc);
	dayjs.extend(timezone);

	const { serverTime } = ServerTime();
	const currentTime = dayjs(serverTime).utc().tz('Asia/Kuala_Lumpur').format('hh:mm A');
	return currentTime;
};

export const ConvertedTime = ({ time, format, minuteTime = 0, duration = 'm' }) => {
	dayjs.extend(utc);
	dayjs.extend(timezone);

	const convertedTime = dayjs(time)
		.add(`${minuteTime}`, `${duration}`)
		.utc()
		.tz('Asia/Kuala_Lumpur')
		.format(`${format}`);
	return convertedTime;
};

export const ConvertedDiffTime = ({ time, diffTime, duration = 's' }) => {
	dayjs.extend(utc);
	dayjs.extend(timezone);

	const convertedDiffTime = dayjs(time).diff(`${diffTime}`, `${duration}`);

	return convertedDiffTime;
};
