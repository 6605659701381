import {
	Box,
	Button,
	Divider,
	Flex,
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
	Text,
	useDisclosure,
	useMediaQuery,
} from '@chakra-ui/react';
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { Fragment, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { getClaimedTicketApi, getQRStringDataQueryApi } from '../api/query/betApi.js';
import ClaimTicketBodyDesktop from '../components/claimTicket/ClaimTicketBodyDesktop';
import ClaimTicketBodyMobile from '../components/claimTicket/ClaimTicketBodyMobile';
import ScannedQrData from '../components/claimTicket/ScannedData.js';
import SearchedData from '../components/claimTicket/SearchedData.js';
import QRCodeModal from '../components/modal/QRCodeModal.js';
import MobileTitleHeader, { DesktopTitleHeader } from '../components/titleHeader/TitleHeader';
import ImgComp from '../utils/ImgComp.js';
import Loading from '../utils/Loading';

//!components at bottom

const ClaimedTicketScreen = () => {
	const [isSmallerThan1024] = useMediaQuery('(max-width: 1023px)');
	const navigate = useNavigate();
	// outlet
	const [date, setDate, maxDate] = useOutletContext();

	// state
	const [scannedData, setScannedData] = useState();
	const [scannedErrorData, setScannedErrorData] = useState();
	const [inputValue, setInputValue] = useState('');
	const [nonShowInputValue, setNonShowInputValue] = useState('');
	const [latitude, setLatitude] = useState();
	const [longitude, setLongitude] = useState();
	const [pageNum, setPageNum] = useState(1);
	const [locationEnabled, setLocationEnabled] = useState(false);
	const [locationErrorMessage, setLocationErrorMessage] = useState('');
	const authToken = sessionStorage.getItem('accessToken');

	// modal
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isOpenScanned, onOpen: onOpenScanned, onClose: onCloseScanned } = useDisclosure();
	const {
		isOpen: isOpenSearched,
		onOpen: onOpenSearched,
		onClose: onCloseSearched,
	} = useDisclosure();

	const {
		isOpen: isOpenErrorLocation,
		onOpen: onOpenErrorLocation,
		onClose: onCloseErrorLocation,
	} = useDisclosure();

	//* api
	const queryClient = useQueryClient();
	const invalidateGetQRStringData = () =>
		queryClient.invalidateQueries({ queryKey: ['getQRStringData'] });
	const getQRStringData = useMutation({
		mutationFn: (qrString) => getQRStringDataQueryApi(qrString),
		onSuccess: (succ) => {
			invalidateGetQRStringData();
			setScannedErrorData('');
			setScannedData(succ);
			if (inputValue === '') {
				onOpenScanned();
			} else {
				onOpenSearched();
			}
		},
		onError: (err) => {
			setScannedErrorData(err.response.data.data.message);
			if (inputValue === '') {
				onOpenScanned();
			} else {
				onOpenSearched();
			}
		},
	});

	const getClaimedTicket = useInfiniteQuery(
		['getClaimedTicketApi', date, inputValue],
		({ pageParam = 1 }) =>
			getClaimedTicketApi({
				date: dayjs(date).format('YYYY-MM-DD hh:mm:ss'),
				ticketNumber: inputValue,
				pageParam: pageParam,
				pageSize: 10,
			}),
		{
			getNextPageParam: (lastPage, pages) => {
				if (pages.length < lastPage?.totalPages) {
					return pages.length + 1;
				}
				return undefined;
			},
		},
	);

	// function
	const checkLocationStatus = () => {
		if (locationEnabled === false) {
			navigator.permissions
				.query({ name: 'geolocation' })
				.then((result) => {
					if (result.state === 'granted') {
						setLocationEnabled(true);
						setLocationErrorMessage('');
						navigator.geolocation.getCurrentPosition(function (position) {
							setLatitude(position.coords.latitude);
							setLongitude(position.coords.longitude);
						});
					} else if (result.state === 'prompt' || result.state === 'denied') {
						setLocationEnabled(false);
						setLocationErrorMessage(result.state);
					}
				})
				.catch((error) => {
					setLocationEnabled(false);
					setLocationErrorMessage(error);
				});
		}
	};

	const handleRequestPermission = () => {
		navigator.geolocation.getCurrentPosition(
			() => {
				setLocationEnabled(true);
				setLocationErrorMessage('');
				navigator.geolocation.getCurrentPosition(function (position) {
					setLatitude(position.coords.latitude);
					setLongitude(position.coords.longitude);
				});
				setTimeout(() => {
					onOpen();
				}, 500);
			},
			(error) => {
				setLocationEnabled(false);
				setLocationErrorMessage(error.message);
				setTimeout(() => {
					onOpenErrorLocation();
				}, 500);
			},
		);
	};

	// clear input
	const handleClearInput = () => {
		const inputElement = document.getElementById('search-input');
		setInputValue('');
		inputElement.value = '';
	};

	const handleGetLocation = () => {
		window.ReactNativeWebView.postMessage(
			JSON.stringify({ function: 'getLocation', authToken: authToken }),
		);
	};

	// useEffect
	useEffect(() => {
		navigator.geolocation.getCurrentPosition(function (position) {
			setLatitude(position.coords.latitude);
			setLongitude(position.coords.longitude);
		});
	}, []);

	useEffect(() => {
		if (window.ReactNativeWebView) {
		} else {
			checkLocationStatus();
		}
	}, [locationEnabled]);

	useEffect(() => {
		if (sessionStorage.getItem('isThirdPartyLogin') === 'true') {
			navigate('/pcso');
		}
	}, []);
	return (
		<Box
			minHeight={
				window.ReactNativeWebView
					? '100vh'
					: ['100vh', '100vh', '100vh', '100vh', 'calc(100vh - 150px)']
			}
			width='100%'
			paddingX={['3', '3', '5', '5', '10']}
			paddingTop={['80px', '80px', '80px', '80px', '30px', '30px', '30px']}
		>
			{isSmallerThan1024 ? (
				<MobileTitleHeader
					title={'Claimed Ticket'}
					selected={new Date(date)}
					maxDate={new Date(new Date(maxDate).setDate(new Date(maxDate).getDate() + 1))}
					onChangeDate={(date) => {
						setDate(date);
						setInputValue('');
						handleClearInput();
					}}
					defaultValue={inputValue}
					onChangeValue={(e) => {
						e.target.value = e.target.value.replace(/[^0-9]/g, '');
						if (e.target.value === '') {
							setNonShowInputValue(e.target.value);
							setInputValue(e.target.value);
						}
						setNonShowInputValue(e.target.value);
					}}
					onKeyDown={(e) => {
						e.target.value = e.target.value.replace(/[^0-9]/g, '');
						if (e.nativeEvent.key === 'Enter') {
							setInputValue(nonShowInputValue);
							getQRStringData.mutate(e.target.value);
						}
					}}
					handleSearch={(e) => {
						if (nonShowInputValue !== '') {
							setInputValue(nonShowInputValue);
							getQRStringData.mutate(nonShowInputValue);
						}
					}}
					hasInputSearch={true}
					hasCalendar={true}
					hasQrScan={true}
					onQrClick={() => {
						if (window.ReactNativeWebView) {
							handleGetLocation();
						} else {
							setInputValue('');
							if (locationEnabled === false) {
								handleRequestPermission();
								setTimeout(() => {
									onOpenErrorLocation();
								}, 500);
							} else {
								setTimeout(() => {
									onOpen();
								}, 500);
							}
						}
					}}
				/>
			) : (
				<DesktopTitleHeader
					title={'Claimed Ticket'}
					selected={new Date(date)}
					maxDate={new Date(new Date(maxDate).setDate(new Date(maxDate).getDate() + 1))}
					onChangeDate={(date) => {
						setDate(date);
						setInputValue('');
						handleClearInput();
					}}
					defaultValue={inputValue}
					onChangeValue={(e) => {
						e.target.value = e.target.value.replace(/[^0-9]/g, '');
						if (e.target.value === '') {
							setNonShowInputValue(e.target.value);
							setInputValue(e.target.value);
						}
						setNonShowInputValue(e.target.value);
					}}
					onKeyDown={(e) => {
						e.target.value = e.target.value.replace(/[^0-9]/g, '');
						if (e.nativeEvent.key === 'Enter') {
							setInputValue(nonShowInputValue);
							getQRStringData.mutate(e.target.value);
						}
					}}
					handleSearch={(e) => {
						if (nonShowInputValue !== '') {
							setInputValue(nonShowInputValue);
							getQRStringData.mutate(nonShowInputValue);
						}
					}}
					hasInputSearch={true}
					hasCalendar={true}
					hasQrScan={true}
					onQrClick={() => {
						if (window.ReactNativeWebView) {
							handleGetLocation();
						} else {
							setInputValue('');
							if (locationEnabled === false) {
								handleRequestPermission();
								setTimeout(() => {
									onOpenErrorLocation();
								}, 500);
							} else {
								setTimeout(() => {
									onOpen();
								}, 500);
							}
						}
					}}
				/>
			)}

			<Flex flexDirection='column' marginY='5' width='100%'>
				<HistoryDate date={dayjs(date).format('ddd, DD/MM/YYYY')} />

				{getClaimedTicket.isLoading ? (
					<Loading />
				) : (
					<>
						{getClaimedTicket.data?.pages[0]?.content?.length > 0 ? (
							<>
								{getClaimedTicket.data?.pages.map((page, index) => {
									return (
										<Fragment key={index}>
											{page?.content?.map((items, idx) => {
												return (
													<Fragment key={idx}>
														<ClaimTicketBodyDesktop
															ticketDate={items.ticketDate}
															ticketNo={items.ticketNo}
															ticketAgentCode={items.ticketAgentCode}
															ticketAgentName={items.userFirstName}
															claimedDate={items.claimDate}
															winningNumbers={items.winningNumbers}
															winningPrice={items.winningPrice}
														/>
														<ClaimTicketBodyMobile
															ticketDate={items.ticketDate}
															ticketNo={items.ticketNo}
															ticketAgentCode={items.ticketAgentCode}
															ticketAgentName={items.userFirstName}
															claimedDate={items.claimDate}
															winningNumbers={items.winningNumbers}
															winningPrice={items.winningPrice}
														/>
													</Fragment>
												);
											})}
										</Fragment>
									);
								})}

								{getClaimedTicket.hasNextPage ? (
									<Button
										marginTop={3}
										onClick={() => {
											setPageNum(pageNum + 1);
											getClaimedTicket.fetchNextPage();
										}}
										width='100%'
									>
										{getClaimedTicket.isFetchingNextPage ? 'Loading...' : 'Load More'}
									</Button>
								) : null}
							</>
						) : (
							<Flex height='50vh' justifyContent='center' alignItems='center'>
								<Text>No Claimed Ticket.</Text>
							</Flex>
						)}
					</>
				)}
			</Flex>

			{/* qr scan */}
			<QRCodeModal
				isOpen={isOpen}
				onClose={onClose}
				onDecode={onClose}
				onResult={(result) => {
					if (result.text.includes('/register')) {
						setScannedErrorData('Invalid QR code.');
						onOpenScanned();
					} else {
						getQRStringData.mutate(result.text);
					}
				}}
				onError={(error) => {
					setScannedErrorData(error?.message);
					onClose();
					onOpenScanned();
				}}
			/>

			{inputValue === '' ? (
				<ScannedQrData
					setInputValue={setInputValue}
					isOpen={isOpenScanned}
					onClose={onCloseScanned}
					qrError={scannedErrorData}
					data={scannedData}
					latitude={latitude}
					longitude={longitude}
				/>
			) : (
				<SearchedData
					isClaim={getQRStringData.data?.[0]?.claim}
					isOpen={isOpenSearched}
					onClose={() => {
						onCloseSearched();
						handleClearInput();
						setInputValue('');
						setNonShowInputValue('');
					}}
					qrError={scannedErrorData}
					data={scannedData}
					latitude={latitude}
					longitude={longitude}
					setInputValue={setInputValue}
				/>
			)}

			{/* location denied modal */}
			{locationEnabled === false && locationErrorMessage !== '' ? (
				<>
					{isOpenErrorLocation && (
						<Modal
							isCentered
							closeOnOverlayClick={false}
							size={['xs', 'sm', 'md', 'lg', 'xl']}
							isOpen={isOpenErrorLocation}
						>
							<ModalOverlay />
							<ModalContent marginX={3}>
								<ModalBody
									borderTopWidth={10}
									borderRadius={5}
									borderColor={'red.500'}
									position='relative'
								>
									<Flex alignItems='center' marginY='10px'>
										<ImgComp svgName={'redCross'} />
										<Flex flexDirection='column' marginX='10px'>
											<Text fontSize='20px' fontWeight='700'>
												{locationErrorMessage}
											</Text>
											<Text fontSize='16px' fontWeight='500'>
												Please allow location permission in browser and refresh page to scan ticket.
											</Text>
										</Flex>
									</Flex>
									<Divider borderColor='gray.600' borderWidth={1} />
									<Flex justifyContent='flex-end' marginTop={2}>
										<Flex
											backgroundColor={'red.100'}
											borderRadius={8}
											padding='8px 10px'
											cursor='pointer'
											alignItems='center'
											onClick={onCloseErrorLocation}
											marginY='10px'
										>
											<Text color={'red.500'} fontWeight='600' fontSize='16px' userSelect='none'>
												OK
											</Text>
										</Flex>
									</Flex>
								</ModalBody>
							</ModalContent>
						</Modal>
					)}
				</>
			) : null}
		</Box>
	);
};

export default ClaimedTicketScreen;

const HistoryDate = ({ date }) => {
	return (
		<Flex marginBottom={'3'}>
			<Text fontWeight='500' fontSize={['16px', '16px', '16px', '16px', '16px', '20px']}>
				{date}
			</Text>
		</Flex>
	);
};
