import {
	Box,
	Divider,
	Flex,
	Grid,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useRadio,
	useRadioGroup,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { getUserProfileApi } from '../../api/query/profileApi';
import { useQuery } from '@tanstack/react-query';
import { getCreditTransactionTypeApi } from '../../api/query/setupApi';

const FilterModal = ({
	isOpen,
	onClose,
	siteFilter,
	setSiteFilter,
	ticketStatus,
	setTicketStatus,
	isTransaction = false,
	isRequest = false,
}) => {
	// state
	const [childSite, setChildSite] = useState('');
	const [childStatus, setChildStatus] = useState('');
	const row1Options = ['All', 'PCSO', 'STL'];
	const row2Options =
		sessionStorage.getItem('isThirdPartyLogin') === 'true'
			? ['All', 'Posted', 'Voidable', 'Voided', 'Denied', 'Striked']
			: ['All', 'Posted', 'Voidable', 'Voided', 'Denied', 'Striked', 'Claimable', 'Claimed'];
	const rowOptionsPlayer = ['All', 'Posted', 'Voidable', 'Voided', 'Denied', 'Striked'];
	const rowCreditDrawalOptions = ['All', 'Pending', 'Approved', 'Rejected'];

	const { getRootProps: getRow1RootProps, getRadioProps: getRow1RadioProps } = useRadioGroup({
		name: 'row1',
		value: childSite,
		onChange: (e) => {
			setChildSite(e);
		},
	});

	const { getRootProps: getRow2RootProps, getRadioProps: getRow2RadioProps } = useRadioGroup({
		name: 'row2',
		value: childStatus,
		onChange: (e) => {
			setChildStatus(e);
		},
	});
	const row1 = getRow1RootProps();
	const row2 = getRow2RootProps();

	//* api
	const getCreditTransactionType = useQuery(
		['getCreditTransactionTypeApi'],
		getCreditTransactionTypeApi,
	);
	const userId = sessionStorage.getItem('userId');
	const getUserProfile = useQuery([`getUserProfileApi`, userId], () =>
		getUserProfileApi({ userId: userId }),
	);

	// useEffect

	useEffect(() => {
		setChildSite(siteFilter);
		setChildStatus(ticketStatus);
	}, [isOpen]);

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Filter</ModalHeader>
				<Divider />
				<ModalCloseButton
					_hover={{ bg: 'none', color: 'gray.500' }}
					_active={{ bg: 'none', color: 'gray.700' }}
					_focus={{ boxShadow: 'none' }}
					// onClick={() => {
					// 	setSiteFilter(siteFilter);
					// 	setTicketStatus(ticketStatus);
					// }}
				/>
				<ModalBody>
					<Flex direction={'column'}>
						{!isRequest && !isTransaction && (
							<>
								<Text fontSize={'lg'} fontWeight={500}>
									Site
								</Text>
								<Grid templateColumns='repeat(3,1fr)' {...row1} textAlign='center'>
									{row1Options.map((value) => {
										const radio = getRow1RadioProps({ value });
										return (
											<RadioCard key={value} {...radio} name={value === childSite ? true : false}>
												{value}
											</RadioCard>
										);
									})}
								</Grid>
							</>
						)}

						<Text fontSize={'lg'} fontWeight={500}>
							{isRequest ? 'Request Status' : isTransaction ? 'Transaction Type' : '	Ticket Status'}
						</Text>
						<Grid templateColumns='repeat(3,1fr)' {...row2} textAlign='center'>
							{isRequest ? (
								<>
									{rowCreditDrawalOptions.map((value) => {
										const radio = getRow2RadioProps({ value });
										return (
											<RadioCard key={value} {...radio} name={value === childStatus ? true : false}>
												{value}
											</RadioCard>
										);
									})}
								</>
							) : isTransaction ? (
								<>
									{getCreditTransactionType.isSuccess &&
										getCreditTransactionType.data.map((value) => {
											const radio = getRow2RadioProps({ value: value.code });
											return (
												<RadioCard
													key={value.description}
													{...radio}
													name={childStatus === value.code ? true : false}
												>
													{value.description}
												</RadioCard>
											);
										})}
								</>
							) : (
								<>
									{getUserProfile.data?.isAgent === true ? (
										<>
											{row2Options.map((value) => {
												const radio = getRow2RadioProps({ value });
												return (
													<RadioCard
														key={value}
														{...radio}
														name={value === childStatus ? true : false}
													>
														{value}
													</RadioCard>
												);
											})}
										</>
									) : (
										<>
											{rowOptionsPlayer.map((value) => {
												const radio = getRow2RadioProps({ value });
												return (
													<RadioCard
														key={value}
														{...radio}
														name={value === childStatus ? true : false}
													>
														{value}
													</RadioCard>
												);
											})}
										</>
									)}
								</>
							)}
						</Grid>
						{/* <Text fontSize={'lg'} fontWeight={500}>
							Claim Status
						</Text>
						<Flex direction={'row'} gap={3} {...row3}>
							{row3Options.map((value) => {
								const radio = getRow3RadioProps({ value });
								return (
									<RadioCard key={value} {...radio}>
										{value}
									</RadioCard>
								);
							})}
						</Flex> */}
					</Flex>
				</ModalBody>

				<ModalFooter>
					<Flex marginTop={3} gap={3} width={'100%'}>
						<Box
							as='button'
							width={'50%'}
							fontWeight={600}
							bgColor={'#FCE5E6'}
							color={'#E52A2A'}
							borderRadius={'8px'}
							height={'40px'}
							onClick={() => {
								if (isRequest) {
									getRow1RadioProps().onChange('All');
									getRow2RadioProps().onChange('All');
									setTicketStatus('All');
									onClose();
								} else if (isTransaction) {
									getRow1RadioProps().onChange('ALL');
									getRow2RadioProps().onChange('ALL');
									setTicketStatus('ALL');
									onClose();
								} else {
									getRow1RadioProps().onChange('All');
									getRow2RadioProps().onChange('All');
									setTicketStatus('All');
									onClose();
									setSiteFilter('All');
								}
							}}
						>
							Reset
						</Box>
						<Box
							as='button'
							width={'50%'}
							fontWeight={600}
							bgColor={'#CFF1D7'}
							color={'#22943B'}
							borderRadius={'8px'}
							height={'40px'}
							onClick={() => {
								if (isRequest) {
									setTicketStatus(childStatus);
									onClose();
								} else if (isTransaction) {
									setTicketStatus(childStatus);
									onClose();
								} else {
									setTicketStatus(childStatus);
									onClose();
									setSiteFilter(childSite);
								}
							}}
						>
							Confirm
						</Box>
					</Flex>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default FilterModal;

function RadioCard(props) {
	const { getInputProps, getCheckboxProps } = useRadio(props);

	const input = getInputProps();
	const checkbox = getCheckboxProps();

	return (
		<Box as='label' margin={2}>
			<input {...input} />
			<Box
				{...checkbox}
				cursor='pointer'
				bgColor={props.name ? '#ECF2FF' : '#f0f0f0'}
				borderWidth='1px'
				borderRadius='lg'
				boxShadow='sm'
				color='black'
				px={4}
				py={2}
			>
				{props.children}
			</Box>
		</Box>
	);
}
