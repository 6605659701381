import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Error = () => {
	const navigate = useNavigate();
	//live chat
	useEffect(() => {
		if (window.smartsupp) {
			window.smartsupp(function () {
				document.getElementById('smartsupp-widget-container').style.display = 'none';
			});
		} else {
			setTimeout(() => {
				window.smartsupp(function () {
					document.getElementById('smartsupp-widget-container').style.display = 'none';
				});
			}, 3000);
		}
	}, []);
	return (
		<Flex
			flexDirection='column'
			justifyContent='center'
			alignItems='center'
			height='100vh'
			width='100%'
			textAlign='center'
			bgGradient={'linear(to-b, #ffffff, #e8f0ff)'}
		>
			<Text
				textAlign='center'
				fontSize={['120px', '', '', '300px']}
				fontWeight='bold'
				color='blue.300'
			>
				404
			</Text>
			<Text
				textAlign='center'
				fontSize={['18px', '', '', '24px']}
				fontWeight='normal'
				color='black'
				marginY='5px'
			>
				Page not found.
			</Text>

			<Box
				borderRadius='8px'
				borderWidth='1px'
				borderColor='#0052FF'
				backgroundColor='#ECF2FF'
				width={['213px', '', '', '335px']}
				height={['48px', '', '', '56px']}
				_hover={{ backgroundColor: '#BDCCF3' }}
				onClick={() => {
					navigate('/');
				}}
			>
				<Text
					textAlign='center'
					fontWeight='600'
					justifyContent='center'
					lineHeight={['45px', '', '', '50px']}
					fontSize={['16px', '', '', '22px']}
					color='#0052FF'
				>
					Back to Home Page.
				</Text>
			</Box>
		</Flex>
	);
};

export default Error;
