import { Box, Flex, Text } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Outlet, useNavigate } from 'react-router-dom';
import { severAxiosInstance } from '../api/query/api';
import { getSrvTimeApi, getSuppKeyApi } from '../api/query/authApi';
import {
	getLiveChatAccessApi,
	getUserProfileApi,
	putCreateConversationApi,
} from '../api/query/profileApi';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import MobileToggleHeader from '../components/Header/MobileToggleHeader';
import Drawer from '../components/drawer/Drawer';
import ImgComp from '../utils/ImgComp';

window.onload = () => {
	severAxiosInstance.defaults.headers.common.Authorization =
		'Bearer ' + sessionStorage.getItem('accessToken');
};

window.history.pushState(null, '', null);
window.onpopstate = () => {
	window.history.forward();
};

const Layout = () => {
	// lock back and forward
	dayjs.extend(utc);
	dayjs.extend(timezone);
	const navigate = useNavigate();

	//state
	const [date, setDate] = useState(null);
	const [maxDate, setMaxDate] = useState(null);
	//state rotate
	const [isMobileLandscape, setIsMobileLandscape] = useState(false);

	const [jsParseLocal, setJsParseLocal] = useState();
	const [userId, setUserId] = useState(sessionStorage.getItem('userId'));

	//* api
	const getSrvTime = useQuery(['getSrvTimeApi'], getSrvTimeApi);
	const getSuppKey = useQuery(['getSuppKeyApi'], getSuppKeyApi);
	const getUserProfile = useQuery([`getUserProfileApi`, userId], () =>
		getUserProfileApi({ userId: userId }),
	);
	const getLiveChatAccess = useQuery([`getLiveChatAccessApi`], getLiveChatAccessApi);

	const putCreateConversation = useMutation({
		mutationFn: (id) => putCreateConversationApi(id),
		onSuccess: () => {},
		onError: () => {},
	});

	//storage when change kick player logout (token and userid)
	function handleStorageChange(event) {
		if (event.key === 'accessToken' || event.key === 'userId') {
			if (event.oldValue !== event.newValue) {
				if (
					sessionStorage.getItem('isThirdPartyLogin') === 'true' ||
					localStorage.getItem('isThirdPartyLogin') === 'true'
				) {
					navigate('/logout');
				} else {
					navigate('/');
				}
				sessionStorage.setItem('editSessionStorage', true);
				localStorage.setItem('editSessionStorage', true);
				localStorage.removeItem('PCSOpermission');
			}
		}

		if (event.key === 'isThirdPartyLogin') {
			if (event.oldValue === 'true') {
				sessionStorage.setItem('editSessionStorage', true);
				sessionStorage.setItem('isThirdPartyLogin', true);
				localStorage.setItem('editSessionStorage', true);
				localStorage.setItem('isThirdPartyLogin', true);
				navigate('/logout');
			} else {
				sessionStorage.setItem('editSessionStorage', true);
				sessionStorage.setItem('isThirdPartyLogin', false);
				localStorage.setItem('editSessionStorage', true);
				localStorage.setItem('isThirdPartyLogin', false);
				navigate('/');
			}
		}

		if (event.key === 'returnUrl') {
			sessionStorage.setItem('returnUrl', event.oldValue);
			localStorage.setItem('returnUrl', event.oldValue);
		}

		if (event.key === 'chakra-ui-color-mode') {
			if (event.oldValue !== event.newValue) {
				sessionStorage.setItem('editSessionStorage', true);
				localStorage.setItem('editSessionStorage', true);
				navigate('/');
				localStorage.removeItem('PCSOpermission');
				localStorage.setItem('chakra-ui-color-mode', event.oldValue);
			}
		}
	}

	// useEffect
	//set date time with server
	useEffect(() => {
		if (getSrvTime.isSuccess) {
			setDate(dayjs(getSrvTime.data?.currTime).utc().tz('Asia/Kuala_Lumpur').$d);
			setMaxDate(dayjs(getSrvTime.data?.currTime).utc().tz('Asia/Kuala_Lumpur').$d);
		}
	}, [getSrvTime.isSuccess]);

	useEffect(() => {
		//checking token
		if (!window.ReactNativeWebView && sessionStorage.getItem('accessToken') === null) {
			// navigate('/');
		} else {
			setUserId(sessionStorage.getItem('userId'));
		}
		localStorage.setItem('PCSOpermission', true);
		// =================================================================
		//detect rotation in mobile
		const handleOrientationChange = () => {
			setIsMobileLandscape(window.orientation === 90 || window.orientation === -90);
		};

		handleOrientationChange();

		window.addEventListener('orientationchange', handleOrientationChange);

		// =================================================================
		const handleKeyDown = (event) => {
			// command + option + I (ios)
			// command + shift + C (ios)
			// F12
			// ctrl + shift + C (windows)
			if (
				(event.metaKey && 'KeyI' && event.altKey) ||
				(event.metaKey && event.shiftKey && event.keyCode === 67) ||
				event.keyCode === 123 ||
				(event.keyCode === 17 && event.shiftKey && event.keyCode === 67) ||
				(event.keyCode === 16 && event.keyCode === 17 && 'KeyI')
			) {
				event.preventDefault(); // Prevent opening inspect mode
			}
		};
		window.addEventListener('keydown', handleKeyDown);

		// =================================================================
		//storage when change kick player logout (token and userid)
		window.addEventListener('storage', handleStorageChange);
		return () => {
			window.removeEventListener('orientationchange', handleOrientationChange);
			window.removeEventListener('keydown', handleKeyDown);
			window.removeEventListener('storage', handleStorageChange);
		};
	}, []);

	// change tab refersh time
	useEffect(() => {
		const handleVisibilityChange = () => {
			getSrvTime.refetch();
		};

		document.addEventListener('visibilitychange', handleVisibilityChange);
		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, [getSrvTime.isSuccess]);

	useEffect(() => {
		if (getSuppKey.isSuccess && getSuppKey.data) {
			if (localStorage.getItem(`ssupp_${getSuppKey.data?.data?.string}`)) {
				setJsParseLocal(JSON.parse(localStorage.getItem(`ssupp_${getSuppKey.data?.data?.string}`)));
			}
		}
	}, [
		getSuppKey.isSuccess,
		getSuppKey.data,
		localStorage.getItem(`ssupp_${getSuppKey.data?.data?.string}`),
	]);

	// live chat
	useEffect(() => {
		if (
			getLiveChatAccess.isSuccess &&
			getLiveChatAccess.data?.flag &&
			sessionStorage.getItem('isThirdPartyLogin') === 'false'
		) {
			if (window.smartsupp) {
				if (window.location.pathname === '/pcso' || window.location.pathname === '/stl') {
					window.smartsupp(function () {
						document.getElementById('smartsupp-widget-container').style.display = 'none';
					});
				} else {
					window.smartsupp(function () {
						document.getElementById('smartsupp-widget-container').style.display = 'block';
					});
				}
			} else {
				setTimeout(() => {
					if (window.location.pathname === '/pcso' || window.location.pathname === '/stl') {
						window.smartsupp(function () {
							document.getElementById('smartsupp-widget-container').style.display = 'none';
						});
					} else {
						window.smartsupp(function () {
							document.getElementById('smartsupp-widget-container').style.display = 'block';
						});
					}
				}, 3000);
			}

			if (jsParseLocal) {
				putCreateConversation.mutate(jsParseLocal.vid);
			}
		} else {
			if (window.smartsupp) {
				window.smartsupp(function () {
					document.getElementById('smartsupp-widget-container').style.display = 'none';
				});
			} else {
				setTimeout(() => {
					window.smartsupp(function () {
						document.getElementById('smartsupp-widget-container').style.display = 'none';
					});
				}, 3000);
			}
		}
	}, [
		window.location.pathname,
		sessionStorage.getItem('isThirdPartyLogin'),
		getLiveChatAccess.data,
		getLiveChatAccess.isSuccess,
		jsParseLocal,
	]);

	if (isMobileOnly && isMobileLandscape)
		return (
			<Flex
				bgColor='black'
				height='100vh'
				width='100vw'
				justifyContent='center'
				alignItems='center'
				flexDirection='column'
			>
				<ImgComp svgName='rotate' />
				<Text fontSize='3xl' color='white'>
					Please rotate your device.
				</Text>
			</Flex>
		);

	return (
		<>
			<Box
				bgGradient='linear(to-b, blue.100, blue.200)'
				onContextMenu={(e) => {
					e.preventDefault();
				}}
			>
				{}
				<MobileToggleHeader isAgent={getUserProfile.data?.isAgent} setDate={setDate} />
				<Drawer isAgent={getUserProfile.data?.isAgent} setDate={setDate} />
				<Header username={getUserProfile.data?.firstName} />

				<Flex marginLeft={['', '', '', '', '250px', '280px', '330px']}>
					<Outlet context={[date, setDate, maxDate]} />
				</Flex>
				<Flex height={['440px', '', '', '', '250px']} display={['none', '', '', '', 'flex']}>
					<Footer />
				</Flex>
			</Box>
		</>
	);
};

export default Layout;
