import React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import { currencyFormat } from '../../utils/numberFormat';

const BetListFlex = ({
	gameType,
	combineText,
	betType,
	betTotal,
	odds,
	winAmount,
	showText,
	number,
}) => {
	return (
		<>
			<Flex
				// justifyContent={'space-between'}
				fontWeight={'500'}
				fontSize={'20px'}
				padding={3}
				display={['none', 'none', 'none', 'none', 'none', 'flex']}
			>
				<Box width={'12%'}>{gameType}</Box>
				<Flex width={'22%'}>
					<Box width={'30%'}>
						<Flex>
							{number.map((num, index) => {
								return (
									<Box marginRight={2} key={index}>
										{num.digit}
									</Box>
								);
							})}
						</Flex>
					</Box>
					<Box width={'25%'} textAlign={'center'}>
						{combineText === true ? <Text>(LP)</Text> : ''}
					</Box>
					<Box width={'40%'}>{betType === true ? <Text>R</Text> : <Text>S</Text>}</Box>
				</Flex>
				<Box width={'15%'} textAlign={'right'}>
					{betTotal}
				</Box>
				<Box width={'15%'} textAlign={'right'}>
					{odds && Number(odds)?.toFixed(2)}
				</Box>
				<Box
					width={['', '', '', '', '', '12%', '15%']}
					textAlign={'right'}
					visibility={showText ? 'visible' : 'hidden'}
				>
					{winAmount > 0 ? <Text color='#22943B'>Win</Text> : '--'}
				</Box>
				<Box
					width={['', '', '', '', '', '24%', '21%']}
					textAlign={'right'}
					color={winAmount > 0 ? '#22943B' : '#000000'}
					visibility={showText ? 'visible' : 'hidden'}
				>
					{winAmount > 0 ? currencyFormat(winAmount) : '--'}
				</Box>
			</Flex>
		</>
	);
};

export default BetListFlex;
