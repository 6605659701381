import { CircularProgress, Flex, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState, useRef } from 'react';
import { severAxiosInstance } from '../../api/query/api';
import { getCreditRemainApi } from '../../api/query/profileApi';
import { usePermissioStore } from '../../hooks/useStore';
import ImgComp from '../../utils/ImgComp';
import { ConvertedServerTime } from '../../utils/Time';
import { currencyFormat } from '../../utils/numberFormat';

const Header = ({ username }) => {
	const serverTime = ConvertedServerTime();
	const isSeamless = sessionStorage.getItem('isSeamless');
	const balanceSyncInterval = sessionStorage.getItem('balanceSyncInterval');

	// zustand
	const setHasPermissionLocation = usePermissioStore((state) => state.setHasPermissionLocation);

	// state
	const [isLoading, setIsLoading] = useState(false);
	//* api
	const getCreditRemain = useQuery(['getCreditRemainApi'], getCreditRemainApi, { enabled: false });
	// function
	const handleReloadCredit = () => {
		setIsLoading(true);
		getCreditRemain.refetch();
		setTimeout(() => {
			setIsLoading(false);
		}, 300);
	};
	// Function to refresh balance
	const refreshSeamlessBalance = () => {
		setIsLoading(true);
		getCreditRemain.refetch();
		setTimeout(() => {
			setIsLoading(false);
		}, 300);
	};

	// Interval logic
	const intervalRef = useRef(null);

	const startInterval = () => {
		if (isSeamless === 'true') {
			intervalRef.current = setInterval(() => {
				refreshSeamlessBalance();
			}, balanceSyncInterval);
		}
	};

	const stopInterval = () => {
		clearInterval(intervalRef.current);
	};

	// Start interval on initial mount
	useEffect(() => {
		startInterval();

		// Clean up the interval on component unmount
		return () => {
			stopInterval();
		};
	}, []);

	// Clean up interval on component unmount
	useEffect(() => {
		return () => {
			stopInterval();
		};
	}, []);

	useEffect(() => {
		severAxiosInstance.defaults.headers.common.Authorization =
			'Bearer ' + sessionStorage.getItem('accessToken');

		if (
			sessionStorage.getItem('accessToken') !== null &&
			sessionStorage.getItem('accessToken') !== undefined
		) {
			getCreditRemain.refetch();
		}
	}, [sessionStorage.getItem('accessToken')]);

	useEffect(() => {
		setHasPermissionLocation(true);
	}, []);

	return (
		<Flex
			justifyContent='end'
			paddingRight={10}
			position='fixed'
			top='0'
			left='0'
			right='0'
			zIndex={999}
			bgColor={'blue.100'}
		>
			<Flex
				justifyContent='space-between'
				padding={5}
				alignItems='center'
				marginLeft={['', '', '', '', '280px', '310px', '360px']}
				// width={['68vw']}
				width='100%'
				height='52px'
				left='0px'
				marginTop='8px'
				boxShadow='0px 2px 12px rgba(0, 0, 0, 0.1)'
				borderRadius='8px'
				bgColor='#ffffff'
				display={['none', 'none', 'none', 'none', 'flex', 'flex']}
			>
				<Flex>
					<ImgComp svgName={'user'} />
					<Text fontWeight='bold' marginLeft={2}>
						{username}
					</Text>
				</Flex>

				<Flex justifyContent='center' alignItems='center'>
					<Flex>
						<Flex
							padding='5px'
							borderRadius='8px'
							border='1px'
							borderColor='green.500'
							backgroundColor='green.100'
							color='green.500'
						>
							<Text>PHP</Text>
							<Text marginLeft={2} fontWeight='bold'>
								{getCreditRemain.data?.creditRemain === undefined
									? 0
									: currencyFormat(Number(getCreditRemain.data?.creditRemain))}
							</Text>

							{isLoading === true ? (
								<CircularProgress marginLeft={3} isIndeterminate size='20px' color='blue.600' />
							) : (
								<Flex
									marginLeft={3}
									onClick={handleReloadCredit}
									cursor='pointer'
									userSelect='none'
								>
									<ImgComp svgName='reload' />
								</Flex>
							)}
						</Flex>
					</Flex>

					<Flex direction='column' marginLeft={4}>
						<Text fontWeight='light'>Current Time</Text>
						<Text fontWeight='bold'>{serverTime === 'Invalid Date' ? '--:-- --' : serverTime}</Text>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default Header;
